import dayjs from 'dayjs';
import { forwardRef, useImperativeHandle } from 'react';
import { z } from 'zod';

import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';

import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';

import {
  AddLedgerRecordAdjustmentInput,
  LedgerRecordDTO,
  RecordTypeDisplayNames,
} from '@aster/shared/dtos/billing';

import { ReadonlyField } from './components/ReadonlyField';
import { Selectable } from './components/Selectable';

import ButtonType from '../../../components/Button';
import CurrencyField from '../../../components/CurrencyField';

export type AddAdjustmentFormDialogProps = {
  record?: LedgerRecordDTO | null;
  open?: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: (adjustment: AddLedgerRecordAdjustmentInput) => void;
};

export type AddAdjustmentFormDialogHandle = {
  reset: () => void;
};

export const AddAdjustmentDialog = forwardRef<
  AddAdjustmentFormDialogHandle,
  AddAdjustmentFormDialogProps
>(({ record, open = false, isLoading = false, onClose, onSubmit }, ref) => {
  const form = useForm({
    defaultValues: {
      recordID: record?.id,
      type: {
        [-1]: 'deduct',
        0: 'deduct',
        1: 'add',
      }[Math.sign(record?.adjustment ?? 0)],
      amount: `${
        record?.adjustment ? Math.abs(record?.adjustment).toString() : ''
      }`,
    },
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      if (!value.recordID) return;

      const sign = value.type === 'deduct' ? -1 : 1;
      const adjustment = Number(value.amount) * sign;

      onSubmit({
        id: value.recordID,
        patientID: record?.patientID as string,
        adjustment,
      });
    },
  });

  useImperativeHandle(ref, () => ({
    reset: form.reset,
  }));

  const renderPatientName = (firstName: string, lastName: string) =>
    [firstName, lastName].join(' ');

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className="p-6 border-b border-b-grayBackground flex justify-between items-center w-[544px]">
        <Typography variant="h5" className="font-semibold">
          Add adjustment
        </Typography>
        <ButtonType variant="text" text="Cancel" onClick={onClose} notRounded />
      </DialogTitle>
      <DialogContent className="p-6">
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            form.handleSubmit();
          }}
        >
          <div className="bg-grayBackground p-5 rounded-[20px] my-6 space-y-[18px]">
            <div className="grid grid-cols-3">
              <ReadonlyField
                label={`Original ${
                  RecordTypeDisplayNames[record?.recordType as string]
                }`}
                text={`$${record?.amount ?? '0'}`}
              />
              <ReadonlyField
                label="Date"
                text={dayjs(record?.date).format('MM/DD/YYYY')}
              />
              <ReadonlyField
                label="Patient"
                text={renderPatientName(
                  record?.patientMetadata.firstName as string,
                  record?.patientMetadata.lastName as string
                )}
              />
            </div>
            <div className="space-x-2">
              <form.Field
                name="type"
                children={(field) => (
                  <>
                    <Selectable
                      text="Deduct"
                      selected={field.state.value === 'deduct'}
                      onClick={() => field.handleChange('deduct')}
                    />
                    <Selectable
                      text="Add"
                      selected={field.state.value === 'add'}
                      onClick={() => field.handleChange('add')}
                    />
                  </>
                )}
              />
            </div>
            <form.Field
              name="amount"
              validators={{
                onBlur: z.coerce
                  .number()
                  .gte(
                    0,
                    'Amount must be a number greater than or equal to zero'
                  ),
              }}
              children={(field) => (
                <CurrencyField
                  label="Amount"
                  placeholder="Add an amount"
                  value={field.state.value}
                  onBlur={field.handleBlur}
                  onChange={(evt) => field.handleChange(evt.target.value)}
                  error={field.state.meta.errors.length > 0}
                  helperText={field.state.meta.errors.join('\r')}
                  required
                />
              )}
            />
          </div>
          <form.Subscribe
            selector={(state) => [state.canSubmit]}
            children={([canSubmit]) => (
              <ButtonType
                variant="contained"
                text="Save"
                className="w-full"
                type="submit"
                disabled={!canSubmit}
                loading={isLoading}
              />
            )}
          />
        </form>
      </DialogContent>
    </Dialog>
  );
});
