import ButtonType from '../../components/Button';
import BasicTextfield from '../../components/Textfield';
import { FormControl, FormHelperText } from '@mui/material';
import { useForm } from '@tanstack/react-form';
import { usePatientForm } from '../patients/hooks/usePatientForm';
import { z } from 'zod';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { useState } from 'react';
import { colors } from '../../theme';
import dayjs from 'dayjs';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import SelectDropdown from '../../components/SelectDropdown';
import { useQueryClient } from '@tanstack/react-query';
import Typography from '../../components/Typography';
import CircleInfo from '../../assets/icons/CircleInfo';
import { US_STATES } from './constants';
import DoseSpot from '../../assets/dosespot.webp';
import { useDosespot } from './DosespotProvider';
import {
  ContactInfo,
  PatientProfile,
  PersonalInfo,
} from '../patients/types/patient-profile';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { formatPhone } from './util/formatPhone';
import { PatientListItemDTO } from '@aster/shared/dtos/patient';

type AddPatientFormProps = Pick<
  ReturnType<typeof usePatientForm>,
  | 'handlePersonalInfoSelect'
  | 'handlePersonalInfo'
  | 'handleBirthday'
  | 'handleContactInfo'
  | 'handleContactInfoSelect'
  | 'saveInfo'
> & {
  patient: PatientProfile & { id: string };
  refetchPatient: () => void;
};

const AddPatientForm = ({
  patient,
  refetchPatient,
  handlePersonalInfoSelect,
  handlePersonalInfo,
  handleBirthday,
  handleContactInfo,
  handleContactInfoSelect,
  saveInfo,
}: AddPatientFormProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const dosespotContext = useDosespot();

  const queryClient = useQueryClient();

  const form = useForm({
    defaultValues: {
      firstName: patient.firstName as PersonalInfo['firstName'],
      lastName: patient.lastName as PersonalInfo['lastName'],
      dateOfBirth: patient.dateOfBirth
        ? dayjs(patient.dateOfBirth)
        : (null as dayjs.Dayjs | null as PersonalInfo['dateOfBirth']),
      streetAddress1: patient.streetAddress1 as ContactInfo['streetAddress1'],
      city: patient.city as ContactInfo['city'],
      state: patient.state as ContactInfo['state'],
      zipCode: patient.zipCode as ContactInfo['zipCode'],
      phoneNumber: patient.phoneNumber as ContactInfo['phoneNumber'],
      legalSex: patient.legalSex as PatientListItemDTO['legalSex'],
    },
    validatorAdapter: zodValidator,
    onSubmit: async (values) => {
      setErrorMessage(null);

      saveInfo();

      const { client_id, client_secret, username, password } =
        dosespotContext.dosespotData;

      if (!client_id || !client_secret) {
        setErrorMessage('Could not find dosespot clinic');
        return;
      }

      if (!username || !password) {
        setErrorMessage('Could not find dosespot clinician');
        return;
      }
      await createDosespotPatient().then((res) => {
        if (res && res.data.Result.ResultCode === 'ERROR') {
          const errors = res.data.Result.ResultDescription;
          setErrorMessage(errors);
          return;
        }
      });
      await refetchPatient();
      return;
    },
  });

  form.validateAllFields('blur');

  const createDosespotPatient = async () => {
    const phone = formatPhone(form.getFieldValue('phoneNumber'));
    const dosespotPatient = await dosespotContext.createDosespotPatient({
      patientId: patient.id,
      // values capitalized for dosespot
      FirstName: form.getFieldValue('firstName'),
      LastName: form.getFieldValue('lastName'),
      DateOfBirth: form.getFieldValue('dateOfBirth')
        ? dayjs(form.getFieldValue('dateOfBirth')).format('YYYY-MM-DD')
        : '',
      Address1: form.getFieldValue('streetAddress1'),
      Gender: form.getFieldValue('legalSex') ?? '',
      City: form.getFieldValue('city'),
      State: form.getFieldValue('state'),
      ZipCode: form.getFieldValue('zipCode'),
      PrimaryPhone: phone,
      PrimaryPhoneType: '1',
      Active: 'true',
    });

    queryClient.invalidateQueries({
      queryKey: ['patient', patient],
    });
    return dosespotPatient;
  };

  return (
    <div className="flex flex-col items-center w-full justify-center rounded-[20px] bg-white px-[35px] mt-4 mb-10 relative">
      <img
        src={DoseSpot}
        className="top-0 right-10 absolute max-w-[110px]"
        alt="Dosespot"
      />

      <Typography variant="h3" text="Add patient to Dosespot" />
      <Typography
        variant="bodyMedium"
        text="In order to add a patient to DoseSpot, information must be up to date. "
        customClass="text-asterGray text-center mt-5"
      />

      <form
        className="max-w-[500px]"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
      >
        <div className="flex items-center gap-4 bg-pastelOrange p-3 mt-8 rounded-2xl bg-opacity-[25%]">
          <CircleInfo className="flex h-[24px] w-[24px]" />
          <Typography
            customClass="text-asterGray leading-1"
            variant="bodyMedium"
            text="Please review the missing fields and update the information in the patient profile."
          />
        </div>
        <FormControl
          fullWidth
          className="bg-grayBackground p-5 rounded-[20px] mt-4"
        >
          {errorMessage && (
            <Typography
              variant="bodySmall"
              text={errorMessage}
              customClass="text-red-500"
            />
          )}
          <div className="grid grid-cols-2 gap-4 w-full my-5">
            <form.Field
              name="firstName"
              validators={{
                onBlur: z.string().min(1, {
                  message: 'Please enter a first name',
                }),
              }}
              children={(field) => (
                <BasicTextfield
                  id="firstName"
                  error={field.state.meta.errors.length > 0}
                  helperText={field.state.meta.errors.join('\r')}
                  name={field.name}
                  value={field.state.value}
                  onBlur={field.handleBlur}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    field.handleChange(event.target.value);
                    handlePersonalInfo(event);
                  }}
                  required
                  variant="filled"
                  label="First name"
                  width="100%"
                />
              )}
            />
            <form.Field
              name="lastName"
              validators={{
                onBlur: z.string().min(1, {
                  message: 'Please enter a last name',
                }),
              }}
              children={(field) => (
                <BasicTextfield
                  id="lastname"
                  error={field.state.meta.errors.length > 0}
                  helperText={field.state.meta.errors.join('\r')}
                  name={field.name}
                  value={field.state.value}
                  onBlur={field.handleBlur}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    field.handleChange(event.target.value);
                    handlePersonalInfo(event);
                  }}
                  required
                  variant="filled"
                  label="Last name"
                  width="100%"
                />
              )}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <form.Field
                name="dateOfBirth"
                defaultMeta={{ isTouched: true }}
                validators={{
                  onChange: z.coerce
                    .date()
                    .max(
                      dayjs().subtract(18, 'years').toDate(),
                      'The patient must be at least 18 years old'
                    ),
                }}
                children={(field) => (
                  <div>
                    <DateField
                      required
                      onBlur={field.handleBlur}
                      minDate={dayjs().subtract(100, 'year')}
                      maxDate={dayjs().subtract(18, 'year')}
                      disableFuture
                      sx={{
                        '& .MuiTextField-root': {
                          backgroundColor: colors.white,
                        },
                      }}
                      defaultValue={field.state.value}
                      name={field.name}
                      value={field.state.value}
                      onChange={(value) => {
                        field.handleChange(value);
                        if (value) {
                          handleBirthday(value);
                        }
                      }}
                      label="Patient DOB"
                    />
                    <FormHelperText error={true}>
                      {field.state.meta.errors?.join('\r')}
                    </FormHelperText>
                  </div>
                )}
              />
            </LocalizationProvider>
            <form.Field
              name="streetAddress1"
              validators={{
                onBlur: z.string().min(1, {
                  message: 'Please enter a valid address',
                }),
              }}
              children={(field) => (
                <BasicTextfield
                  id="address"
                  error={field.state.meta.errors.length > 0}
                  helperText={field.state.meta.errors.join('\r')}
                  name={field.name}
                  value={field.state.value}
                  onBlur={field.handleBlur}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    field.handleChange(event.target.value);
                    handleContactInfo(event);
                  }}
                  required
                  variant="filled"
                  label="Address"
                  width="100%"
                />
              )}
            />
            <form.Field
              name="city"
              validators={{
                onBlur: z.string().min(1, { message: 'Please enter a city' }),
              }}
              children={(field) => (
                <BasicTextfield
                  id="city"
                  error={field.state.meta.errors.length > 0}
                  helperText={field.state.meta.errors.join('\r')}
                  name={field.name}
                  value={field.state.value}
                  onBlur={field.handleBlur}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    field.handleChange(event.target.value);
                    handleContactInfo(event);
                  }}
                  required
                  variant="filled"
                  label="City"
                  width="100%"
                />
              )}
            />
            <form.Field
              name="state"
              validators={{
                onBlur: z.string().min(1, {
                  message: 'Please select a state',
                }),
              }}
              children={(field) => (
                <SelectDropdown
                  handleChange={(
                    event: React.ChangeEvent<HTMLSelectElement>
                  ) => {
                    field.handleChange(event.target.value);
                    handleContactInfoSelect(event, 'state');
                  }}
                  options={US_STATES}
                  name={field.name}
                  value={field.state.value}
                  required
                  label="Provider state"
                  width="100%"
                />
              )}
            />
            <form.Field
              name="zipCode"
              validators={{
                onBlur: z.string().min(1, {
                  message: 'Please enter a valid zip code',
                }),
              }}
              children={(field) => (
                <BasicTextfield
                  id="zip"
                  error={field.state.meta.errors.length > 0}
                  helperText={field.state.meta.errors.join('\r')}
                  name={field.name}
                  value={field.state.value}
                  onBlur={field.handleBlur}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    field.handleChange(event.target.value);
                    handleContactInfo(event);
                  }}
                  required
                  variant="filled"
                  label="Zip"
                  width="100%"
                />
              )}
            />
            <form.Field
              name="phoneNumber"
              validators={{
                onBlur: z.string().min(1, {
                  message: 'Please enter a valid phone number',
                }),
              }}
              children={(field) => (
                <BasicTextfield
                  id="phone"
                  error={field.state.meta.errors.length > 0}
                  helperText={field.state.meta.errors.join('\r')}
                  name={field.name}
                  value={field.state.value}
                  onBlur={field.handleBlur}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    field.handleChange(event.target.value);
                    handleContactInfo(event);
                  }}
                  required
                  variant="filled"
                  label="Phone"
                  width="100%"
                />
              )}
            />
            <form.Field
              name="legalSex"
              validators={{
                onBlur: z.string().min(1, {
                  message: 'Please select a legal sex',
                }),
              }}
              children={(field) => (
                <SelectDropdown
                  label="Legal sex"
                  options={[
                    { value: 'female', text: 'Female' },
                    { value: 'male', text: 'Male' },
                    { value: 'other', text: 'Other' },
                  ]}
                  value={field.state.value}
                  handleChange={(event: any) => {
                    field.handleChange(event.target.value);
                    handlePersonalInfoSelect(event, 'legalSex');
                  }}
                  required
                />
              )}
            />
          </div>

          <form.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => (
              <div className="flex w-full justify-center">
                <ButtonType
                  loading={isSubmitting}
                  text="Update patient profile"
                  variant="contained"
                  type="submit"
                  disabled={!canSubmit}
                  classes="px-12 py-6 mt-1"
                />
              </div>
            )}
          ></form.Subscribe>
        </FormControl>
      </form>
    </div>
  );
};

export default AddPatientForm;
