import React, { useState } from 'react';
import CollapsibleComponent from '../../../components/CollapsibleComponent';
import BasicTextfield from '../../../components/Textfield';
import dayjs from 'dayjs';
import { BabyMeasurementsInfo } from './types/baby.types';

type BabyMeasurementsProps = {
  data: BabyMeasurementsInfo;
  disabled: boolean;
  displayVertical?: boolean;
  handleNote: (note: string) => void;
  handleSave: (property: string, value: BabyMeasurementsInfo) => void;
};

const BabyMeasurements = ({
  data,
  disabled,
  handleSave,
  handleNote,
}: BabyMeasurementsProps) => {
  const [state, setState] = useState(data);

  const handleFormInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const newNote = (property: string, value: string) => {
    const note = `Baby measurements: ${
      property ? `${property} ` : ''
    } ${value} at ${dayjs(new Date()).format('MM/DD/YYYY HH:mm:ss')}`;
    handleNote(note);
  };

  const saveMeasurements = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleSave('babyMeasurements', state);
    newNote(name, value);
  };

  return (
    <CollapsibleComponent label="Measurements" color="bg-beige">
      <div className="flex flex-col items-center w-full h-full rounded-b-lg bg-beige px-2">
        <div className="flex flex-col md:flex-row gap-4 pb-2 mt-4 justify-center w-full">
          <BasicTextfield
            id="lenght"
            variant="filled"
            name="length"
            label="Length (cm)"
            disabled={disabled}
            value={state.length}
            onChange={handleFormInput}
            onBlur={saveMeasurements}
          />
          <BasicTextfield
            id="weight"
            variant="filled"
            name="weight"
            label="Weight (lb)"
            disabled={disabled}
            value={state.weight}
            onChange={handleFormInput}
            onBlur={saveMeasurements}
          />
        </div>
        <div className="flex flex-col md:flex-row gap-4 pb-2 justify-center w-full">
          <BasicTextfield
            id="hc"
            variant="filled"
            name="hc"
            label="HC (cm)"
            value={state.hc}
            disabled={disabled}
            onChange={handleFormInput}
            onBlur={saveMeasurements}
          />
          <BasicTextfield
            id="cc"
            variant="filled"
            name="cc"
            label="CC (cm)"
            value={state.cc}
            disabled={disabled}
            onChange={handleFormInput}
            onBlur={saveMeasurements}
          />
        </div>
      </div>
    </CollapsibleComponent>
  );
};

export default BabyMeasurements;
