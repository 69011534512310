import { forwardRef, useImperativeHandle } from 'react';
import { z } from 'zod';

import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';

import {
  CreateLedgerRecordInput,
  LedgerRecordDTO,
  PaymentType,
  PaymentTypeDisplayNames,
  RecordType,
} from '@aster/shared/dtos/billing';

import { ReadonlyField } from './components/ReadonlyField';
import { Selectable } from './components/Selectable';

import ButtonType from '../../../components/Button';
import CurrencyField from '../../../components/CurrencyField';
import SelectDropdown from '../../../components/SelectDropdown';
import BasicTextfield from '../../../components/Textfield';

export type AddRecordDialogProps = {
  patient: LedgerRecordDTO['patientMetadata'] | null;
  patients?: LedgerRecordDTO['patientMetadata'][];
  open?: boolean;
  isLoading?: boolean;
  showDropdown?: boolean;
  onClose: () => void;
  onPatientChange: (patientID: string) => void;
  onSubmit: (record: CreateLedgerRecordInput) => void;
};

export type AddRecordFormDialogHandle = {
  reset: () => void;
};

export const AddRecordDialog = forwardRef<
  AddRecordFormDialogHandle,
  AddRecordDialogProps
>(
  (
    {
      patient,
      patients = [],
      open = false,
      isLoading = false,
      showDropdown,
      onClose,
      onPatientChange,
      onSubmit: onFormSubmit,
    },
    ref
  ) => {
    const form = useForm({
      defaultValues: {
        practiceID: patient?.practiceID,
        patientID: patient?.id,
        recordType: RecordType.charge,
        paymentType: PaymentType.cash,
        note: '',
        extra: '',
        amount: '',
      },
      validatorAdapter: zodValidator,
      onSubmit: async ({ value }) => {
        const record = {
          practiceID: patient?.practiceID as string,
          patientID: patient?.id as string,
          recordType: value.recordType as LedgerRecordDTO['recordType'],
          paymentType: (value.recordType === RecordType.payment
            ? value.paymentType
            : (PaymentType.none as LedgerRecordDTO['paymentType'])) as LedgerRecordDTO['paymentType'],
          note: value.note,
          extra: value.extra,
          amount: Number(value.amount),
          adjustment: 0,
          patientMetadata: {
            id: patient?.id as string,
            practiceID: patient?.practiceID as string,
            firstName: patient?.firstName as string,
            lastName: patient?.lastName as string,
          },
        };

        onFormSubmit(record);
      },
    });

    useImperativeHandle(ref, () => ({
      reset: form.reset,
    }));

    const renderPatientName = (firstName = '', lastName = '') =>
      [firstName, lastName].join(' ');

    const patientDropdownOptions = patients
      .map((patient) => ({
        value: patient.id,
        text: renderPatientName(patient.firstName, patient.lastName),
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
    const paymentTypeDropdownOptions = Object.entries(
      PaymentTypeDisplayNames
    ).map(([value, text]) => ({ value, text }));

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle className="p-6 border-b border-b-grayBackground flex justify-between items-center w-[544px]">
          <Typography variant="h5" className="font-semibold">
            Add charge/payment
          </Typography>
          <ButtonType variant="text" text="Cancel" onClick={onClose} />
        </DialogTitle>
        <DialogContent className="p-6">
          <form
            onSubmit={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              form.handleSubmit();
            }}
          >
            <div className="bg-grayBackground p-5 rounded-[20px] my-6 space-y-[18px]">
              {showDropdown ? (
                <SelectDropdown
                  value={patient?.id ?? ''}
                  options={patientDropdownOptions}
                  label="Patient"
                  handleChange={(evt: React.ChangeEvent<HTMLSelectElement>) =>
                    onPatientChange(evt.target.value)
                  }
                />
              ) : (
                <ReadonlyField
                  label="Patient"
                  text={renderPatientName(
                    patient?.firstName,
                    patient?.lastName
                  )}
                />
              )}
              <div className="space-x-2">
                <form.Field
                  name="recordType"
                  children={(field) => (
                    <>
                      <Selectable
                        text="Charge"
                        selected={field.state.value === RecordType.charge}
                        onClick={() => field.handleChange(RecordType.charge)}
                      />
                      <Selectable
                        text="Payment"
                        selected={field.state.value === RecordType.payment}
                        onClick={() => field.handleChange(RecordType.payment)}
                      />
                    </>
                  )}
                />
              </div>
              <div className="flex gap-1.5">
                {form.useStore((state) => state.values.recordType) ===
                  RecordType.payment && (
                  <form.Field
                    name="paymentType"
                    children={(field) => (
                      <SelectDropdown
                        name={field.name}
                        value={field.state.value}
                        options={paymentTypeDropdownOptions}
                        label="Payment type"
                        handleChange={(
                          evt: React.ChangeEvent<HTMLSelectElement>
                        ) =>
                          field.handleChange(
                            evt.target.value as keyof typeof PaymentType
                          )
                        }
                      />
                    )}
                  />
                )}
                <form.Field
                  name="note"
                  children={(field) => (
                    <BasicTextfield
                      variant="filled"
                      label="Note"
                      name={field.name}
                      placeholder={'Add a note'}
                      value={field.state.value}
                      onChange={(evt) => field.handleChange(evt.target.value)}
                    />
                  )}
                />
              </div>
              {[PaymentType.other].includes(
                form.useStore((state) => state.values.paymentType)
              ) && (
                <form.Field
                  name="extra"
                  children={(field) => (
                    <BasicTextfield
                      variant="filled"
                      label="Custom payment method"
                      placeholder={'Specify the payment method'}
                      value={field.state.value}
                      onChange={(evt) => field.handleChange(evt.target.value)}
                      required
                    />
                  )}
                />
              )}
              <div>
                <form.Field
                  name="amount"
                  validators={{
                    onBlur: z.coerce
                      .number()
                      .positive('Amount must be a number greater than zero'),
                  }}
                  children={(field) => (
                    <CurrencyField
                      label="Amount"
                      placeholder="Add an amount"
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(evt) => field.handleChange(evt.target.value)}
                      error={field.state.meta.errors.length > 0}
                      helperText={field.state.meta.errors.join('\r')}
                      required
                    />
                  )}
                />
              </div>
            </div>
            <form.Subscribe
              selector={(state) => [state.canSubmit]}
              children={([canSubmit]) => (
                <ButtonType
                  variant="contained"
                  text="Save"
                  className="w-full"
                  type="submit"
                  disabled={!patient?.id || !canSubmit}
                  loading={isLoading}
                />
              )}
            />
          </form>
        </DialogContent>
      </Dialog>
    );
  }
);

AddRecordDialog.displayName = 'AddRecordDialog';
