import Members from '../features/members/Members';

const MembersPage = () => {
  return (
    <div className="h-screen">
      <Members />
    </div>
  );
};

export default MembersPage;
