import dayjs from 'dayjs';
import { useMemo, useRef, useState } from 'react';

import { RequestQuoteOutlined } from '@mui/icons-material';
import { CircularProgress, Typography } from '@mui/material';

import { Ledger, LedgerHandle } from './Ledger';
import { useLedgerRecordsQuery } from './queries/use-ledger-records-query';

import { useSearchPatientsQuery } from '../patients/queries/search-patients.query';

import ButtonType from '../../components/Button';
import SelectDropdown from '../../components/SelectDropdown';

export type Timeframe = '30d' | '60d' | '90d';

const timeframeDropdownOptions: { value: Timeframe; text: string }[] = [
  { value: '30d', text: '30 Days' },
  { value: '60d', text: '60 Days' },
  { value: '90d', text: '90 Days' },
];

export const Payments = () => {
  const [timeframe, setTimeframe] = useState<Timeframe>('30d');

  const ledgerRef = useRef<LedgerHandle>(null);

  const { ledgerRecords = [], isLoading } = useLedgerRecordsQuery();

  // NOTE: This filtering should be made on the server, however, we're
  // doing it client-side for this initial featue version.
  //
  // This approach may be revisited in the future.
  const filteredRecords = useMemo(() => {
    const days = Number(timeframe.replace('d', ''));

    return ledgerRecords.filter(
      (record) => dayjs(new Date()).diff(record.date, 'days') < days
    );
  }, [ledgerRecords, timeframe]);

  const {
    paginatedPatients: { items: patients } = { items: [] },
    arePatientsLoading,
  } = useSearchPatientsQuery({ pageSize: Number.MAX_SAFE_INTEGER });

  if (isLoading || arePatientsLoading) {
    return (
      <div className="flex flex-col justify-center items-center w-full h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex flex-col full-w-container">
      <div className="flex justify-between items-end mb-5">
        <div className="space-y-1">
          <div className="flex items-center gap-1 text-gray-500">
            <RequestQuoteOutlined fontSize="small" sx={{ strokeWidth: 1 }} />{' '}
            <Typography variant="body2">Payments</Typography>
          </div>
          <div className="flex gap-2.5 items-end">
            <Typography variant="h4" className="text-h4 font-semibold">
              Payments
            </Typography>
            <SelectDropdown
              value={timeframe}
              options={timeframeDropdownOptions}
              classes="[&_.MuiOutlinedInput-notchedOutline]:!border-none [&_.MuiSelect-select]:p-0 mb-[-0.075rem]"
              label=""
              handleChange={(evt: React.ChangeEvent<HTMLSelectElement>) =>
                setTimeframe(evt.target.value as Timeframe)
              }
            />
          </div>
        </div>
        <ButtonType
          variant="contained"
          text="Add charge/payment"
          onClick={() => ledgerRef?.current?.addRecord()}
        />
      </div>
      <Ledger ref={ledgerRef} records={filteredRecords} patients={patients} />
    </div>
  );
};
