import { useQuery } from '@tanstack/react-query';

import { AppointmentWithInviteesDTO } from '@aster/shared/dtos/appointment';
import { PatientProfileForEncounterDTO } from '@aster/shared/dtos/patient';

import axios from '../../../../../app/axiosConfig';
import dayjs from 'dayjs';

const getAppointmentsByPatient = async (
  patient: PatientProfileForEncounterDTO
) => {
  const startTime = dayjs().startOf('day').toISOString();
  const endTime = dayjs().startOf('day').add(5, 'years').toISOString();

  const response = await axios.get<AppointmentWithInviteesDTO[]>(
    `appointments/byPatient/${patient.patientID}?startTime=${startTime}&endTime=${endTime}`
  );

  return (
    response?.data?.sort(
      (a, b) =>
        new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
    ) ?? []
  );
};

export const useAppointmentsByPatientQuery = (
  patient: PatientProfileForEncounterDTO,
  opts = {}
) => {
  const {
    data: appointments,
    isLoading: areAppointmentsLoading,
    ...rest
  } = useQuery({
    queryKey: ['appointmentsByPatient', patient?.patientID],
    queryFn: () => getAppointmentsByPatient(patient),
    enabled: !!patient?.patientID,
    ...opts,
  });

  return { appointments, areAppointmentsLoading, ...rest };
};
