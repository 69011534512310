export const CALENDAR_VIEW = 'calendar_view';

export const CALENDAR_VIEW_OPTIONS = [
  { value: 'timeGridDay', text: 'Day' },
  { value: 'timeGridWeek', text: 'Week' },
  { value: 'dayGridMonth', text: 'Month' },
  { value: 'multiMonthYear', text: 'Year' },
  { value: 'Divider' },
  { value: 'listMonth', text: 'Schedule' },
  { value: 'timeGridFiveDay', text: '5 day' },
];
