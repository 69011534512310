import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Divider from '@mui/material/Divider';

import Chip from './Chip';
import NotificationBadge from './StyledBadge';
import Typography from './Typography';

import { useAuth } from '../authentication/AuthProvider';
import { useDosespot } from '../features/dosespot/DosespotProvider';
import { cn } from '@aster/client/utils/cn';

import AsterLogo from '../assets/aster';
import Analytics from '../assets/icons/Analytics';
import Billing from '../assets/icons/Billing';
import Calendar from '../assets/icons/Calendar';
import Clients from '../assets/icons/Clients';
import Documents from '../assets/icons/Documents';
import Forms from '../assets/icons/Forms';
import House from '../assets/icons/House';
import LeftArrow from '../assets/icons/LeftArrow';
import Messages from '../assets/icons/Messages';
import Prescription from '../assets/icons/Prescription';
import Programs from '../assets/icons/Programs';
import Settings from '../assets/icons/Settings';
import SignOut from '../assets/icons/SignOut';
import Workflows from '../assets/icons/Workflows';
import EfaxIcon from '../assets/icons/EfaxIcon';

type MenuOptionProps = {
  link?: string;
  icon: React.ReactElement;
  title: string;
  className?: string;
  notificationCount?: number;
  children?: React.ReactNode;
  onClick?: () => void;
  active?: boolean;
  collapsed?: boolean;
  disabled?: boolean;
  badge?: 'new';
  activePaths?: RegExp[];
};

const SidebarOption = ({
  link,
  icon,
  title,
  active = false,
  collapsed = false,
  notificationCount = 0,
  badge,
  onClick,
}: MenuOptionProps) => {
  const Component = onClick ? 'button' : Link;

  return (
    <Component
      to={link ?? '/'}
      onClick={onClick}
      className={cn(
        'flex no-underline items-center px-3 py-2 rounded-full mb-2 w-[201px] relative cursor-pointer',
        {
          'hover:bg-white hover:bg-opacity-75': !active,
          'bg-white': active,
          'w-fit': collapsed,
        }
      )}
    >
      <span
        className={cn(
          'flex w-6 h-6 items-center justify-center scale-[1.125]',
          {
            '[&>svg>path]:fill-primary': active,
          }
        )}
      >
        {icon}
      </span>
      {!collapsed && (
        <Typography
          variant="bodySmall"
          customClass={cn('text-gray-500 font-semibold ml-3', {
            'text-primary': active,
          })}
        >
          {title}
        </Typography>
      )}
      {badge && !collapsed && (
        <Chip
          label="New"
          className="h-4 ml-1.5 bg-aster-main"
          classes={{
            label: 'font-[500] text-[9px] px-1.5 text-white',
          }}
        />
      )}
      {notificationCount > 0 && (
        <NotificationBadge
          className={cn('ml-1.5', {
            'm-0 absolute -top-1 -right-1': collapsed,
          })}
          count={notificationCount}
        />
      )}
    </Component>
  );
};

const Sidebar = () => {
  const dosespotContext = useDosespot();

  const { logout } = useAuth();
  const { pathname } = useLocation();

  const [collapsed, setCollapsed] = useState(false);

  const sections: MenuOptionProps[][] = [
    [
      { link: '/', icon: <House />, title: 'Home' },
      {
        link: '/messages',
        icon: <Messages />,
        title: 'Messages',
        disabled: true,
      },
      { link: '/calendar', icon: <Calendar />, title: 'Calendar' },
      {
        link: '/patients',
        icon: <Clients />,
        title: 'Patients',
        activePaths: [/^\/patientProfile.*?/i, /^\/encounter.*?/i],
      },
      {
        link: '/prescription',
        icon: <Prescription />,
        title: 'Prescriptions',
        notificationCount: dosespotContext.dosespotData.notificationsCount,
      },
    ],
    [
      { link: '/forms', icon: <Forms />, title: 'Forms', disabled: true },
      { link: '/payments', icon: <Billing />, title: 'Payments' },
      { link: '/efax', icon: <EfaxIcon />, title: 'Fax', badge: 'new' },
      {
        link: '/workflows',
        icon: <Workflows />,
        title: 'Workflow',
        disabled: true,
      },
      {
        link: '/programs',
        icon: <Programs />,
        title: 'Programs',
        disabled: true,
      },
    ],
    [
      { link: '/documents', icon: <Documents />, title: 'Documents' },
      {
        link: '/analytics',
        icon: <Analytics />,
        title: 'Analytics',
        disabled: true,
      },
      {
        link: '/settings',
        icon: <Settings />,
        title: 'Settings',
        disabled: true,
      },
    ],
    [{ onClick: () => logout(), icon: <SignOut />, title: 'Sign out' }],
  ];

  const getActiveStatus = (option: MenuOptionProps, pathname: string) => {
    if (!option.link) return false;
    if (option.link === '/') return option.link === pathname;

    return (
      pathname.startsWith(option.link) ||
      option.activePaths?.some((expression) => expression.test(pathname))
    );
  };

  return (
    <aside className="bg-grayBackground border-r border-r-grayLight px-3 py-5">
      <div className="flex justify-between mb-8">
        {!collapsed && (
          <Link to="/" className="px-[17.15px] flex items-center">
            <AsterLogo className="max-w-[92px]" />
          </Link>
        )}
        <button
          onClick={() => setCollapsed((collapsed) => !collapsed)}
          className={cn('h-10 flex justify-center items-center px-2.5', {
            'rotate-180 mx-auto': collapsed,
          })}
        >
          <LeftArrow />
        </button>
      </div>
      <nav className="items">
        {sections.map((section, i) => (
          <div className="mb-2" key={section[0].title}>
            {section
              .filter((section) => !section.disabled)
              .map((item) => (
                <SidebarOption
                  key={item.title}
                  {...item}
                  active={getActiveStatus(item, pathname)}
                  collapsed={collapsed}
                />
              ))}
            {i === sections.length - 2 && (
              <Divider className="border-grayLight" />
            )}
          </div>
        ))}
      </nav>
    </aside>
  );
};

export default Sidebar;
