import React, { useState } from 'react';
import { TabProps } from '@mui/material/Tab';
import BasicTextfield from '../../../components/Textfield';
import TabsSelectComponent from '../../../components/TabsSelectComponent';
import CollapsibleComponent from '../../../components/CollapsibleComponent';
import dayjs from 'dayjs';
import { UterusInfo } from './types/birth.types';

const tabsFirmness: TabProps[] = [
  { label: 'Firm', value: 'firm' },
  { label: 'Soft', value: 'soft' },
  { label: 'Boggy', value: 'boggy' },
];

const tabsPosition: TabProps[] = [
  { label: 'Left', value: 'left' },
  { label: 'Bilateral', value: 'bilateral' },
  { label: 'Right', value: 'right' },
];

const tabsHeight: TabProps[] = [
  { label: 'U+1', value: 'u+1' },
  { label: 'U', value: 'u°' },
  { label: 'U-1', value: 'u-1' },
  { label: 'U-2', value: 'u-2' },
];

type UterusProps = {
  data: UterusInfo;
  disabled: boolean;
  handleNote: (note: string) => void;
  handleSave: (property: string, event: any) => void;
};

const Uterus = ({ data, disabled, handleSave, handleNote }: UterusProps) => {
  const [extraNote, setExtraNote] = useState('');
  const newNote = (property: string, value: string) => {
    const note = `Uterus: ${property ? `${property} ` : ''} ${value} at ${dayjs(
      new Date()
    ).format('MM/DD/YYYY HH:mm:ss')}`;
    handleNote(note);
  };

  const handleUterus = (property: string, newValue: string) => {
    handleSave('uterus', { ...data, [property]: newValue });
    newNote(property, newValue);
  };

  const handleExtraNote = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleSave('uterus', { ...data, [name]: value });
    setExtraNote(value);
  };

  return (
    <div className="mb-4">
      <CollapsibleComponent label="Uterus">
        <div className="flex flex-col h-full rounded-b-lg bg-grayCard px-2.5">
          <TabsSelectComponent
            question="Firmness"
            disabled={disabled}
            tabs={tabsFirmness}
            value={data.firmness}
            handleChange={handleUterus}
            property="firmness"
          />
          <TabsSelectComponent
            question="Position"
            disabled={disabled}
            tabs={tabsPosition}
            value={data.position}
            handleChange={handleUterus}
            property="position"
          />
          <TabsSelectComponent
            question="Height"
            disabled={disabled}
            tabs={tabsHeight}
            value={data.height}
            handleChange={handleUterus}
            property="height"
          />
          <BasicTextfield
            id="note"
            variant="filled"
            name="note"
            label="Note"
            disabled={disabled}
            value={data.note}
            onChange={handleExtraNote}
            onBlur={() => newNote('note', extraNote)}
            classes="mb-4"
          />
        </div>
      </CollapsibleComponent>
    </div>
  );
};

export default Uterus;
