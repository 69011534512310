import { Box, TextField, TextFieldProps, styled } from '@mui/material';
import { colors } from '../theme';
import { useMask } from '@react-input/mask';
import { cn } from '@aster/client/utils/cn';

export type CustomTextFieldProps = TextFieldProps & {
  width?: string;
  height?: string;
  classes?: string;
  containerClasses?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
};

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: colors.primary,
    fontWeight: 600,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: colors.grayLight,
    borderRadius: 10,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    backgroundColor: colors.white,
    overflow: 'hidden',
    padding: '10px 15px',
    '& fieldset': {
      borderColor: colors.grayLight,
    },
    '&:hover fieldset': {
      borderColor: colors.grayLight,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.primary,
    },
    '&.Mui-disabled fieldset': {
      pointerEvents: 'none !important',
    },
  },
  '& .MuiFilledInput-root': {
    color: colors.gray,
    overflow: 'hidden',
    borderRadius: '10px',
    height: '100%',
    backgroundColor: colors.white,
    border: '1px solid',
    borderColor: colors.grayLight,
    '&.Mui-disabled': {
      '&:hover': {
        backgroundColor: colors.disabledGray,
        borderColor: `${colors.grayLight} !important`,
      },
    },
    '&:hover': {
      backgroundColor: colors.white,
      borderColor: `${colors.aster} !important`,
    },
    '&.Mui-focused': {
      backgroundColor: colors.white,
      boxShadow: colors.grayLight,
    },
    '&.Mui-error': {
      borderColor: colors.red,
    },
  },
  'input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
  },
});

const BasicTextfield = (props: CustomTextFieldProps) => {
  const {
    id,
    variant,
    label,
    placeholder,
    value,
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
    multiline,
    classes,
    error,
    rows,
    minRows,
    maxRows,
    width,
    name,
    required,
    disabled,
    helperText,
    type,
    inputRef,
    containerClasses,
    startAdornment,
    endAdornment,
    inputProps,
    InputProps = {},
  } = props;

  const handleEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      if (onKeyDown) {
        onKeyDown(event);
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };

  return (
    <Box
      component="div"
      sx={{
        maxWidth: '100%',
      }}
      width={width}
      className={cn('w-full', containerClasses)}
    >
      <CssTextField
        inputRef={inputRef}
        fullWidth
        inputProps={{
          ...inputProps,
          disableUnderline: true,
        }}
        // NOTE: Seems like `inputProps` and `InputProps` do make
        // a difference in the `TextField` component, which would
        // explain why adornments did not work at all when passed
        // to this component.
        //
        // Similarly, the new approach (`slotProps`) doesn't seem
        // to be supported by our version, as newer versions mark
        // `InputProps` as **deprecated**.
        //
        // See: https://mui.com/material-ui/api/text-field/#text-field-prop-InputProps
        InputProps={{
          startAdornment: startAdornment ?? null,
          endAdornment: endAdornment ?? null,
          ...InputProps,
        }}
        id={id}
        name={name}
        label={label}
        value={value}
        variant={variant}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={handleEnterKeyPress}
        multiline={multiline}
        rows={rows}
        minRows={minRows}
        maxRows={maxRows}
        className={`full-rounded border ${classes}`}
        error={error}
        disabled={disabled}
        helperText={helperText}
        required={required}
        type={type}
        placeholder={placeholder}
      />
    </Box>
  );
};

export const MaskedBasicTextfield = ({
  mask,
  replacement,
  ...props
}: CustomTextFieldProps & {
  mask?: string;
  replacement?: Record<string, any>;
}) => {
  const inputRef = useMask({ mask, replacement });
  return <BasicTextfield {...props} inputRef={inputRef} />;
};

export default BasicTextfield;
