const Stop = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
    >
      <rect x="0.5" y="1" width="24" height="24" rx="5" fill="white" />
      <rect x="0.5" y="1" width="24" height="24" rx="5" stroke="#E8E5DA" />
      <rect x="7.5" y="8" width="10" height="10" rx="1" fill="#FB3640" />
    </svg>
  );
};
export default Stop;
