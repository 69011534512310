const Documents = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.01234 2.26471H2.10792C1.74395 2.26471 1.46087 2.58823 1.46087 2.91176V14.5588C1.46087 14.9228 1.74395 15.2059 2.10792 15.2059H18.9315C19.255 15.2059 19.5785 14.9228 19.5785 14.5588V5.5C19.5785 5.17647 19.255 4.85294 18.9315 4.85294H11.1667C10.8028 4.85294 10.4792 4.73162 10.2366 4.48897L8.01234 2.26471ZM2.10792 0.970588H8.01234C8.37631 0.970588 8.69984 1.13235 8.94248 1.375L11.1667 3.55882H18.9315C19.9829 3.55882 20.8726 4.44853 20.8726 5.5V14.5588C20.8726 15.6507 19.9829 16.5 18.9315 16.5H2.10792C1.01601 16.5 0.166748 15.6507 0.166748 14.5588V2.91176C0.166748 1.86029 1.01601 0.970588 2.10792 0.970588Z"
        fill="#586068"
      />
    </svg>
  );
};

export default Documents;
