import dayjs from 'dayjs';

import { pdf } from '@react-pdf/renderer';
import { useMutation } from '@tanstack/react-query';

import { PatientProfileForEncounterDTO } from '@aster/shared/dtos/patient';

import { FlowsheetRow } from '../components/Flowsheet';
import { FlowsheetPDF } from '../templates/FlowsheetPDF';

import { saveAs } from '../../../utils/save-as';

const downloadFlowsheetInner = async ({
  patient,
  flowsheet,
}: {
  patient: PatientProfileForEncounterDTO;
  flowsheet: FlowsheetRow[];
}) => {
  const Flowsheet = FlowsheetPDF({
    patient,
    flowsheet,
  });

  const filename = `${patient?.name}-${dayjs().format('MM-DD-YY')}.pdf`;
  const blob = await pdf(Flowsheet).toBlob();

  saveAs(blob, filename);
};

export const useDownloadFlowsheetMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) => {
  const {
    mutate: downloadFlowsheet,
    isPending: isGeneratingFlowsheet,
    ...rest
  } = useMutation({
    mutationFn: downloadFlowsheetInner,
    onError: () => {
      onError?.();
    },
    onSuccess: () => {
      onSuccess?.();
    },
  });

  return { downloadFlowsheet, isGeneratingFlowsheet, ...rest };
};
