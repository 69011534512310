import React, { useState } from 'react';
import dayjs from 'dayjs';
import Typography from '../../../components/Typography';
import BasicSwitches from '../../../components/Switch';

import { MilestoneInfo, MilestonesInfo } from './types/birth.types';
import { cn } from '@aster/client/utils/cn';

type MilestonesProps = {
  data: MilestonesInfo;
  disabled: boolean;
  handleNote: (note: string) => void;
  handleSave: (property: string, event: any) => void;
};

const Milestones = ({
  data,
  disabled,
  handleSave,
  handleNote,
}: MilestonesProps) => {
  const [breech, setBreech] = useState(false);

  const newNote = (title: string) => {
    const note = `Milestone: ${title} was at ${dayjs(new Date()).format(
      'MM/DD/YYYY HH:mm:ss'
    )}`;
    handleNote(note);
  };

  const handleFormInput = (name: string, title: string) => {
    handleSave('milestones', { ...data, [name]: dayjs().toISOString() });
    newNote(title);
  };

  const Milestone = ({ title, color, date, name }: MilestoneInfo) => {
    return (
      <button
        className={`flex flex-col items-start mt-2.5 w-full ${
          disabled ? 'bg-grayCard pointer-events-none' : 'bg-white'
        } rounded-xl hover:bg-grayCard`}
        onClick={() => handleFormInput(name, title)}
      >
        <div className="flex gap-0 p-2.5">
          <div
            className={cn(
              'mix-blend-multiply h-[70px] rounded-[250px] w-[5px]',
              `bg-${color}`
            )}
          />
          <div className="flex flex-col flex-wrap grow gap-y-1.5 justify-between content-center items-start basis-0 w-fit">
            <Typography
              text={title}
              variant="body"
              customClass="font-semibold ml-3"
            />
            <div
              className={cn(
                'flex gap-1 pl-2 mt-1.5 text-center whitespace-nowrap',
                date ? 'text-black' : 'text-gray-500Block'
              )}
            >
              <div className="justify-center px-2.5 py-1.5 rounded-xl bg-stone-50 text-ellipsis text-bodySmall">
                {date ? dayjs(date).format('MM/DD/YYYY') : 'Date'}
              </div>
              <div className="justify-center px-4 py-1.5 rounded-xl bg-stone-50 text-ellipsis mr-2 text-bodySmall">
                {date ? dayjs(date).format('HH:mm:ss') : 'Time'}
              </div>
            </div>
          </div>
        </div>
      </button>
    );
  };

  const gridColumns = breech ? 'grid-cols-4' : 'grid-cols-3';

  return (
    <div className="flex flex-col h-full rounded-b-lg bg-grayCard self-stretch p-2.5">
      <div className="mt-[-38px] ml-[80%]">
        <BasicSwitches
          placement="start"
          label="Breech"
          checked={breech}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setBreech(event.target.checked);
          }}
        />
      </div>

      <div className={`pb-2.5 max-w-full w-full grid ${gridColumns} gap-4`}>
        <div className="w-full">
          <Milestone
            title="Time of Arrival"
            color="pastelBlue"
            name="arrival"
            date={data.arrival}
          />
          <Milestone
            title="Rupture of Membrane"
            color="pastelBlue"
            name="rupture"
            date={data.rupture}
          />
          <Milestone
            title="Onset of labor"
            color="pastelBlue"
            name="onsetLabor"
            date={data.onsetLabor}
          />
        </div>
        <div className="w-full">
          <Milestone
            title="Labor 6 cm"
            color="pastelPurple"
            name="labor6"
            date={data.labor6}
          />
          <Milestone
            title="Labor 10 cm"
            color="pastelPurple"
            name="labor10"
            date={data.labor10}
          />
          <Milestone
            title="Pushing"
            color="pastelPurple"
            name="pushing"
            date={data.pushing}
          />
        </div>
        <div className="w-full">
          <Milestone
            title="Head out"
            color="pastelOrange"
            name="headOut"
            date={data.headOut}
          />
          <Milestone
            title="Baby born"
            color="pastelOrange"
            name="babyBorn"
            date={data.babyBorn}
          />
          <Milestone
            title="First breath"
            color="pastelOrange"
            name="firstBreath"
            date={data.firstBreath}
          />
        </div>
        {breech && (
          <div className="w-full">
            <Milestone
              title="Presenting part"
              color="pastelGreen"
              name="presentingPart"
              date={data.presentingPart}
            />
            <Milestone
              title="Umbilicus"
              color="pastelGreen"
              name="umbilicus"
              date={data.umbilicus}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Milestones;
