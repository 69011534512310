export type TemplateType =
  | 'basic'
  | 'prenatal'
  | 'soap'
  | 'postpartum'
  | 'birthflow'
  | 'newborn';

export const EncounterType = {
  basic: 'basic',
  prenatal: 'prenatal',
  soap: 'soap',
  postpartum: 'postpartum',
  birthflow: 'birthflow',
  newborn: 'newborn',
};

export const EncounterTypeDisplayNames = {
  [EncounterType.basic]: 'Basic',
  [EncounterType.prenatal]: 'Prenatal',
  [EncounterType.soap]: 'SOAP',
  [EncounterType.postpartum]: 'Postpartum',
  [EncounterType.birthflow]: 'Birthflow',
  [EncounterType.newborn]: 'Newborn',
};

export type CreateEncounterDTO = {
  content: Record<string, any>;
  patientID: string;
  signed: boolean;
  templateType: TemplateType;
  appointmentID?: string;
  startTime?: string;
};

export type EncounterDTO = CreateEncounterDTO & {
  checklistVersionID: string;
  cptCodes: string[];
  endTime: string | null;
  id: string;
  icdCodes: string[];
  ownerID: string;
  ownerName: string;
  patientName: string;
  patientVersionID: string;
  practiceID: string;
  practiceName: string;
  signedBy: string | null;
  signedByName: string | null;
  lastModifiedByName: string | null;
  startTime: string;
  vitalsVersionID: string | null;
  lastModifiedBy: string;
  lastModified: string;
};
