import { LabOrderDetailsDialog } from './order-details/LabOrderDetails';
import { CreateLabsOrderDialog } from './create-order/CreateLabsOrder';
import { LabOrderList } from './order-list/LabOrderList';
import ButtonType from '../../components/Button';
import { usePatientInfoQuery } from '../patients/queries/patient-info.query';
import { useParams } from 'react-router-dom';
import { useLabsStore } from './store/labs.store';
import { ManuallyAddLabResultsDialog } from './manually-add-results/ManuallyAddLabResults';

type EncounterLabsProps = {
  patientID?: string;
  isEncounterSigned: boolean;
};

const EncounterLabs = ({
  isEncounterSigned,
  patientID,
}: EncounterLabsProps) => {
  const selectedLabOrderID = useLabsStore((state) => state.selectedLabOrderID);
  const closeLabOrderDetails = useLabsStore(
    (state) => state.closeLabOrderDetails
  );
  const openCreateOrderDialog = useLabsStore(
    (state) => state.openCreateOrderDialog
  );
  const closeCreateOrderDialog = useLabsStore(
    (state) => state.closeCreateOrderDialog
  );
  const isCreateOrderDialogOpened = useLabsStore(
    (state) => state.isCreateOrderDialogOpened
  );
  const manualUploadResultsDialogState = useLabsStore(
    (state) => state.manualUploadResultsDialogState
  );
  const closeManualUploadResultsDialog = useLabsStore(
    (state) => state.closeManualUploadResultsDialog
  );

  const { patient } = useParams();

  const { patientInfo } = usePatientInfoQuery(patient);

  return (
    <section className="flex flex-col gap-4">
      <ButtonType
        disabled={isEncounterSigned || patientInfo?.status === 'archived'}
        classes="w-full mt-4"
        text="Order Labs"
        variant="contained"
        onClick={openCreateOrderDialog}
      />
      <LabOrderList />
      {Boolean(selectedLabOrderID) && (
        <LabOrderDetailsDialog
          fullWidth
          open={Boolean(selectedLabOrderID)}
          onClose={() => closeLabOrderDetails()}
          handleClose={() => closeLabOrderDetails()}
          labOrderID={selectedLabOrderID ?? undefined}
          patientID={patientID}
        />
      )}

      <CreateLabsOrderDialog
        fullWidth
        open={isCreateOrderDialogOpened}
        onClose={closeCreateOrderDialog}
        handleClose={closeCreateOrderDialog}
      />
      {manualUploadResultsDialogState !== 'closed' && (
        <ManuallyAddLabResultsDialog
          fullWidth
          open={true}
          handleClose={closeManualUploadResultsDialog}
        />
      )}
    </section>
  );
};

export default EncounterLabs;
