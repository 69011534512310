import { cn } from '@aster/client/utils/cn';
import Typography from '../components/Typography';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { useState } from 'react';

type CollapsibleComponentProps = {
  label: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
  color?: string;
  containerClass?: string;
};

const CollapsibleComponent = ({
  label,
  actions,
  children,
  color,
  containerClass,
}: CollapsibleComponentProps) => {
  const [isCollapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed(!isCollapsed);
  };

  return (
    <div className={`w-full h-fit ${containerClass}`}>
      <div aria-expanded={!isCollapsed}>
        <button
          onClick={toggleCollapse}
          className={cn(
            'w-full rounded-t-lg flex justify-start items-center p-2',
            color ? color : 'bg-grayCard',
            !isCollapsed
              ? 'rounded-lg bg-white hover:bg-grayBackground'
              : 'rounded-t-lg'
          )}
        >
          {!isCollapsed && <ArrowRightRoundedIcon />}
          {isCollapsed && <ArrowDropDownRoundedIcon />}
          <div className="flex items-center justify-between w-full">
            <Typography variant="h6" text={label} />
            {actions}
          </div>
        </button>
      </div>

      {isCollapsed && children}
    </div>
  );
};

export default CollapsibleComponent;
