import { SvgIconProps } from '@mui/material';

const CircleInfo = (props: SvgIconProps) => (
  <svg {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g id="circle-info" clip-path="url(#clip0_14986_57309)">
      <path
        id="Primary"
        d="M8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM6.5 11C6.225 11 6 11.225 6 11.5C6 11.775 6.225 12 6.5 12H9.5C9.775 12 10 11.775 10 11.5C10 11.225 9.775 11 9.5 11H8.5V7.5C8.5 7.225 8.275 7 8 7H6.75C6.475 7 6.25 7.225 6.25 7.5C6.25 7.775 6.475 8 6.75 8H7.5V11H6.5ZM8 5.75C8.19891 5.75 8.38968 5.67098 8.53033 5.53033C8.67098 5.38968 8.75 5.19891 8.75 5C8.75 4.80109 8.67098 4.61032 8.53033 4.46967C8.38968 4.32902 8.19891 4.25 8 4.25C7.80109 4.25 7.61032 4.32902 7.46967 4.46967C7.32902 4.61032 7.25 4.80109 7.25 5C7.25 5.19891 7.32902 5.38968 7.46967 5.53033C7.61032 5.67098 7.80109 5.75 8 5.75Z"
        fill="#F59E0B"
      />
    </g>
    <defs>
      <clipPath id="clip0_14986_57309">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CircleInfo;
