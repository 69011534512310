export const rgbWithOpacity = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  const opacity = 0.5;
  const result = `rgba(${r}, ${g}, ${b}, ${opacity})`;
  return result;
};

export const isEmailValid = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const apptOwnerColors = [
  'gray',
  'red',
  'amber',
  'orange',
  'lime',
  'emerald',
  'teal',
  'cyan',
  'yellow',
  'sky',
  'blue',
  'indigo',
  'green',
  'violet',
  'rose',
  'purple',
  'fuchsia',
  'pink',
];

export const colorPerInitial = (initial: string) => {
  switch (initial) {
    case 'A':
    case 'I':
    case 'Q':
    case 'Y':
      return 'red';
    case 'B':
    case 'J':
    case 'R':
    case 'Z':
      return 'orange';
    case 'C':
    case 'K':
    case 'S':
      return 'yellow';
    case 'D':
    case 'L':
    case 'T':
      return 'green';
    case 'E':
    case 'M':
    case 'U':
      return 'cyan';
    case 'F':
    case 'N':
    case 'V':
      return 'blue';
    case 'G':
    case 'O':
    case 'W':
      return 'purple';
    case 'H':
    case 'P':
    case 'X':
      return 'pink';
    default:
      return 'gray';
  }
};

// Transform an MRN with structure 'A12345678' to 'A 1234 5678'
export function formatMRN(mrn: string | null) {
  if (!mrn) {
    return '-';
  }
  return `${mrn.slice(0, 1)} ${mrn.slice(1, 5)} ${mrn.slice(5)}`;
}

export function scrollToFirstInvalidField() {
  const firstInvalidField = document.querySelector('[aria-invalid="true"]');
  if (firstInvalidField) {
    firstInvalidField.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    (firstInvalidField as HTMLElement).focus();
  }
}
