import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useResizeDetector } from 'react-resize-detector';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import Typography from '../../../../components/Typography';
import { Button } from '@aster/client/ui/Button/Button';
import { cn } from '@aster/client/utils/cn';

type FillResultsPDFProps = {
  pdfFileUrl?: string | null;
};

export default function FillResultsPDF({ pdfFileUrl }: FillResultsPDFProps) {
  const [numPages, setNumPages] = useState(0);
  const { width, ref } = useResizeDetector();
  const [scale, setScale] = useState(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }
  return (
    <div className="grid grid-rows-fill-first col-start-1 col-end-2 overflow-y-hidden">
      <div
        className="overflow-y-auto flex items-center justify-center rounded-md border border-gray-200 p-5 duration-300 ease-in-out"
        ref={ref}
      >
        <ErrorBoundary
          fallback={
            <div className="flex items-center justify-center h-40">
              <Typography variant="h6" text="Failed to load PDF" />
            </div>
          }
        >
          {!pdfFileUrl ? (
            <CircularProgress />
          ) : (
            pdfFileUrl && (
              <Document
                className="h-full w-full"
                loading={<CircularProgress />}
                onLoadSuccess={onDocumentLoadSuccess}
                file={pdfFileUrl}
              >
                {Array.from(new Array(numPages), (_el, index) => (
                  <Page
                    scale={scale}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={width}
                  />
                ))}
              </Document>
            )
          )}
        </ErrorBoundary>
      </div>
      <div className="flex justify-center gap-4">
        <Button
          className={cn({
            'text-primary': scale === 1,
          })}
          onClick={() => setScale(1)}
          variant="text"
        >
          1x
        </Button>
        <Button
          className={cn({
            'text-primary': scale === 2,
          })}
          onClick={() => setScale(2)}
          variant="text"
        >
          2x
        </Button>
        <Button
          className={cn({
            'text-primary': scale === 2.5,
          })}
          onClick={() => setScale(2.5)}
          variant="text"
        >
          2.5x
        </Button>
      </div>
    </div>
  );
}
