import { CircularProgress } from '@mui/material';
import FormIllustration from '../../assets/icons/FormIllustration';
import Typography from '../../components/Typography';
import AsterLogo from '../../assets/aster';

export interface LoadingGuestTokenProps {
  text: string;
  icon?: JSX.Element;
}

const LoadingGuestToken = ({
  text,
  icon = <FormIllustration />,
}: LoadingGuestTokenProps) => {
  return (
    <div className="grid grid-rows-3 place-content-center h-full p-10">
      <div className="row-start-2 flex flex-col justify-center items-center">
        {icon}
        <CircularProgress className="mt-8 mb-3" size={32} />
        <Typography variant="bodySmall" text={text} />
      </div>
      <div className="flex justify-center items-end row-start-3">
        <AsterLogo />
      </div>
    </div>
  );
};

export default LoadingGuestToken;
