const Calendar = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3432 2.97059C17.3947 2.97059 18.2844 3.86029 18.2844 4.91176V19.1471C18.2844 20.239 17.3947 21.0882 16.3432 21.0882H2.10792C1.01601 21.0882 0.166748 20.239 0.166748 19.1471V4.91176C0.166748 3.86029 1.01601 2.97059 2.10792 2.97059H4.0491V0.867646C4.0491 0.624999 4.25131 0.382352 4.5344 0.382352H4.85792C5.10057 0.382352 5.34322 0.624999 5.34322 0.867646V2.97059H13.1079V0.867646C13.1079 0.624999 13.3101 0.382352 13.5932 0.382352H13.9167C14.1594 0.382352 14.402 0.624999 14.402 0.867646V2.97059H16.3432ZM2.10792 4.26471C1.74395 4.26471 1.46087 4.58823 1.46087 4.91176V6.85294H16.9903V4.91176C16.9903 4.58823 16.6667 4.26471 16.3432 4.26471H2.10792ZM16.3432 19.7941C16.6667 19.7941 16.9903 19.511 16.9903 19.1471V8.14706H1.46087V19.1471C1.46087 19.511 1.74395 19.7941 2.10792 19.7941H16.3432Z"
        fill="#586068"
      />
    </svg>
  );
};

export default Calendar;
