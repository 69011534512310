import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  AppointmentDTO,
  AppointmentWithInviteesDTO,
} from '@aster/shared/dtos/appointment';

import {
  createMutationScopeID,
  MutationScope,
} from '../../../../../mutations/utils/create-mutation-scope-id';

import { useSnackbar } from '../../../../../components/Snack';

import axios from '../../../../../app/axiosConfig';

const deleteAppointmentInner = async (
  appointment: AppointmentWithInviteesDTO
) => {
  return await axios.delete(`/appointments/${appointment.id}`);
};

export const useDeleteAppointmentMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) => {
  const queryClient = useQueryClient();

  const { showMessage } = useSnackbar();

  const {
    mutate: deleteAppointment,
    isPending: isDeletingAppointment,
    ...rest
  } = useMutation({
    mutationKey: ['deleteAppointment'],
    mutationFn: deleteAppointmentInner,
    scope: {
      id: createMutationScopeID(MutationScope.APPOINTMENT),
    },
    onMutate: (appointment) => {
      const [patient] = appointment.invitedPatients;

      const previousAppointments = queryClient.getQueryData([
        'appointmentsByPatient',
        patient?.id,
      ]);

      queryClient.setQueryData<AppointmentDTO[]>(
        ['appointmentsByPatient', patient.id],
        (old = []) => old.filter((e) => e.id !== appointment.id)
      );

      return { previousAppointments };
    },
    onError: (_, appointment, context) => {
      const [patient] = appointment.invitedPatients;

      queryClient.setQueryData(
        ['appointmentsByPatient', patient.id],
        context?.previousAppointments
      );

      showMessage({
        message: 'Error deleting appointment',
        type: 'error',
      });

      onError?.();
    },
    onSuccess: (_, variables) => {
      const [patient] = variables.invitedPatients;

      queryClient.invalidateQueries({
        queryKey: ['appointmentsByPatient', patient.id],
      });

      showMessage({
        message: 'Appointment deleted',
        type: 'success',
      });

      onSuccess?.();
    },
  });

  return { deleteAppointment, isDeletingAppointment, ...rest };
};
