import { useMutation } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';
import { useSnackbar } from '../../../components/Snack';

const sendIntakeForm = async (patientID: string) =>
  axios.post(`/v2/patients/${patientID}/send-intake-form`);

export function useSendIntakeFormMutation(patientEmail?: string) {
  const { showMessage } = useSnackbar();

  const sendIntakeFormMutation = useMutation({
    mutationFn: sendIntakeForm,
    onSuccess: () => {
      showMessage({
        type: 'success',
        message: `Intake form link sent to ${
          patientEmail ?? 'the patient'
        } successfully`,
      });
    },
    onError: () => {
      showMessage({
        type: 'error',
        message: 'An error occurred while sending the intake form link',
      });
    },
  });

  return { sendIntakeFormMutation };
}
