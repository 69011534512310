const Programs = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9204 18.864C24.0013 19.1066 23.88 19.3897 23.5969 19.4706L23.3138 19.5919C23.0712 19.6728 22.7881 19.5919 22.6667 19.1471C21.9792 19.2684 20.2807 19.8346 19.5932 20.239C19.755 20.6434 19.5932 20.886 19.3506 20.9669C19.0675 21.0478 18.9866 21.0882 18.8653 21.0882C18.6631 21.0882 18.5013 20.9669 18.4204 20.7647L12.3138 5.19485V19.7941C12.3138 20.5221 11.7072 21.0882 11.0197 21.0882H7.13734C6.89469 21.0882 6.65204 21.0074 6.49028 20.9265C6.28807 21.0074 6.04542 21.0882 5.84322 21.0882H1.96087C1.23292 21.0882 0.666748 20.5221 0.666748 19.7941V1.67647C0.666748 0.98897 1.23292 0.382352 1.96087 0.382352H5.84322C6.04542 0.382352 6.28807 0.503676 6.44984 0.584558C6.65204 0.503676 6.89469 0.382352 7.0969 0.382352H10.9792C11.7072 0.382352 12.2734 0.98897 12.2734 1.67647V1.95956C12.4756 2.08088 12.516 2.20221 12.5565 2.36397C13.2035 2.24265 14.9425 1.67647 15.5895 1.27206C15.4682 0.867646 15.5895 0.624999 15.8726 0.544117C16.1153 0.463235 16.1962 0.382352 16.3175 0.382352C16.5197 0.382352 16.7219 0.544117 16.8028 0.746323L23.9204 18.864ZM5.84322 19.7941V17.2059H1.96087V19.7941H5.84322ZM5.84322 15.9118V5.55882H1.96087V15.9118H5.84322ZM5.84322 4.26471V1.67647H1.96087V4.26471H5.84322ZM11.0197 19.7941V17.2059H7.13734V19.7941H11.0197ZM11.0197 15.9118V5.55882H7.13734V15.9118H11.0197ZM11.0197 4.26471V1.67647H7.13734V4.26471H11.0197ZM19.2292 19.1471C19.6741 18.9044 21.2513 18.2574 22.2623 18.0956L15.994 2.36397C15.5087 2.60662 13.9719 3.25368 12.9204 3.41544L19.2292 19.1471Z"
        fill="#586068"
      />
    </svg>
  );
};

export default Programs;
