// NOTE: Grabbed from https://stackoverflow.com/a/19328891
//
// See also: https://github.com/eligrey/FileSaver.js/blob/master/src/FileSaver.js
export const saveAs = (data: Blob, name: string) => {
  const url = URL.createObjectURL(data);

  const link = document.createElement('a');

  link.setAttribute('href', url);
  link.setAttribute('download', name);

  link.click();

  URL.revokeObjectURL(url);
};
