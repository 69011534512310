import {
  InputLabel,
  FormControl,
  Divider,
  MenuItem,
  Select,
  SelectProps,
  styled,
  FormHelperText,
} from '@mui/material';
import { colors } from '../theme';

export type SelectProperties = SelectProps & {
  name?: string;
  value?: string | null;
  label?: string;
  options: object[];
  width?: string;
  handleChange: any;
  classes?: string;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  dark?: boolean;
  loading?: boolean;
  noContentPadding?: boolean;
};

type SelectOption = {
  value: string;
  text: string;
};

const SelectDropdown = (props: SelectProperties) => {
  const {
    value,
    name,
    label,
    options,
    width,
    handleChange,
    placeholder,
    disabled,
    classes,
    helperText,
    required,
    dark,
    loading,
    error,
    noContentPadding,
  } = props;
  const CustomSelect = styled(Select)(() => ({
    '& .MuiSelect-outlined': {
      overflow: 'hidden',
      borderRadius: 10,
      backgroundColor: dark ? colors.aster : colors.white,
      paddingTop: noContentPadding ? 15 : value ? 22 : 15,
      marginTop: 0,
      color: dark ? colors.white : colors.gray,
      fontWeight: 'semi-bold',
      borderColor: dark ? colors.white : colors.aster,
      '&.Mui-focused': {
        backgroundColor: colors.white,
        boxShadow: colors.grayLight,
      },
      '&.Mui-disabled': {
        color: dark ? colors.white : colors.gray,
        backgroundColor: colors.grayBackground,
      },
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 10,
      border: '1px solid',
      overflow: 'hidden',
      '& fieldset': {
        borderColor: colors.transparent,
        color: dark ? colors.white : colors.aster,
        borderRadius: 10,
      },
      '&:hover fieldset': {
        borderColor: colors.transparent,
        borderRadius: 10,
      },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
    },
    '& .MuiSvgIcon-root.MuiSelect-icon': {
      color: dark ? colors.white : colors.gray,
    },
  }));

  const CustomInputLabel = styled(InputLabel)({
    marginTop: value ? 0.5 : 0,
    color: dark ? (value ? colors.gray : colors.white) : colors.gray,
    '&:hover': {
      color: dark ? colors.white : colors.aster,
    },
    '&:disabled': {
      color: dark ? colors.black : colors.aster,
    },
  });

  return loading ? (
    <div className="flex justify-center items-center">
      <i className={'fa fa-spinner px-6 fa-spin'}></i>
    </div>
  ) : (
    <FormControl
      fullWidth
      sx={{ width: width }}
      focused={false}
      className={classes}
      error={error}
      required={required}
    >
      {label && <CustomInputLabel>{label}</CustomInputLabel>}
      <CustomSelect
        {...props}
        name={name}
        variant="outlined"
        defaultValue={value}
        value={value}
        label={label}
        onChange={handleChange}
        disabled={disabled}
        {...(!label && placeholder
          ? {
              displayEmpty: true,
              renderValue: (value: unknown) => {
                const { text } =
                  (options as SelectOption[]).find(
                    (option) => option.value === value
                  ) ?? {};

                return text ?? placeholder;
              },
            }
          : {})}
      >
        {options.map((item: any) =>
          item.value === 'Divider' ? (
            <Divider className="bg-grayLight" key={item.value} />
          ) : (
            <MenuItem key={item.value} value={item.value}>
              {item.extra && item.extra}
              {item.text}
            </MenuItem>
          )
        )}
      </CustomSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectDropdown;
