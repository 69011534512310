import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from './AuthProvider';

function SignOutRedirecter() {
  const currentUrl = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    const logoutUser = async () => {
      await logout(currentUrl.pathname + currentUrl.search);
    };
    logoutUser();
  }, [currentUrl.pathname, currentUrl.search, logout, navigate]);

  return null;
}

export default SignOutRedirecter;
