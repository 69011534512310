import { EncounterDTO } from '@aster/shared/dtos/encounter';
import React, { useCallback, useEffect, useState } from 'react';
import { Codes } from '../types/encounter';
import { useAuth } from '../../../authentication/AuthProvider';
import { useUpdateEncounterMutation } from '../mutations/update-encounter.mutation';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import NoteHeader from '../sections/NoteHeader';
import TemplateComponent from '../templates/TemplateComponent';
import TextArea from '../../../components/TextArea';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { usePatientProfileQuery } from '../queries/patient-profile.query';
import { isPatientReadonly } from '../../patients/utils/is-patient-readonly';

const buttons = [
  { value: 'history', text: 'History' },
  { value: 'labs', text: 'Labs/Ultrasounds' },
  { value: 'laborSOAP', text: 'Labor SOAP' },
];

type SoapNoteContentProps = {
  encounterData: EncounterDTO | undefined;
  enforceSaveMissing: boolean;
  setEnforceSaveMissing: (value: boolean) => void;
  handleUnsaved: (isDirty: boolean) => void;
  isEncounterLoading: boolean;
};

export default function SoapNoteContent({
  encounterData,
  enforceSaveMissing,
  setEnforceSaveMissing,
  handleUnsaved,
  isEncounterLoading,
}: SoapNoteContentProps) {
  const { patient, encounterId } = useParams();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [noteInfo, setNoteInfo] = useState(() => ({
    subjective: encounterData?.content?.subjective || '',
    objective: encounterData?.content?.objective || '',
    assessment: encounterData?.content?.assessment || '',
    plan: encounterData?.content?.plan || '',
  }));
  const [selectedCodes, setSelectedCodes] = useState<Codes>(() => ({
    cptCodes: encounterData?.cptCodes ?? [],
    icdCodes: encounterData?.icdCodes ?? [],
  }));

  const { profile } = useAuth();

  const handleChangeCodes = (codes: Codes) => {
    setSelectedCodes(codes);
  };
  const isEncounterSigned = encounterData?.signedBy !== null;

  const { updateEncounter } = useUpdateEncounterMutation(encounterId, () => {
    handleUnsaved(false);
    setEnforceSaveMissing(false);
  });
  const { patientProfile } = usePatientProfileQuery(patient);

  const saveInfo = useCallback(
    (signed: boolean) => {
      const info = {
        patientID: patient,
        startTime: encounterData?.startTime,
        endTime: encounterData?.endTime,
        signed,
        content: {
          subjective: noteInfo.subjective,
          objective: noteInfo.objective,
          assessment: noteInfo.assessment,
          plan: noteInfo.plan,
        },
        templateType: encounterData?.templateType,
        cptCodes: selectedCodes.cptCodes,
        icdCodes: selectedCodes.icdCodes,
      };
      if (signed) {
        setOpenConfirmation(false);
      }
      updateEncounter.mutate(info);
    },
    [
      encounterData?.endTime,
      encounterData?.startTime,
      encounterData?.templateType,
      noteInfo.assessment,
      noteInfo.objective,
      noteInfo.plan,
      noteInfo.subjective,
      patient,
      selectedCodes.cptCodes,
      selectedCodes.icdCodes,
      updateEncounter,
    ]
  );

  useEffect(() => {
    if (enforceSaveMissing) {
      saveInfo(false);
      setEnforceSaveMissing(false);
    }
  }, [enforceSaveMissing, saveInfo, setEnforceSaveMissing]);

  const handleFormInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNoteInfo((prevNote) => ({
      ...prevNote,
      [name]: value,
    }));
    handleUnsaved(true);
  };

  const handleChangeNote = (content: string) => {
    setNoteInfo((prevNote) => ({
      ...prevNote,
      subjective: content,
    }));
    handleUnsaved(true);
  };

  if (isEncounterLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-full w-full">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className="flex flex-col max-w-[1000px] lg:w-full mt-5 mx-10 lg:overflow-y-auto">
      <NoteHeader
        encounterInfo={encounterData}
        selectedCodes={selectedCodes}
        disabled={updateEncounter.status === 'pending'}
        setSelectedCodes={handleChangeCodes}
        title="SOAP Note"
        buttonMessageLeft="Save draft"
        actionLeft={() => saveInfo(false)}
        buttonMessageRight="Sign note"
        actionRight={() => setOpenConfirmation(true)}
        hide={isEncounterSigned || isPatientReadonly(patientProfile)}
      />
      <div className="flex flex-col">
        <div className="w-full">
          <TemplateComponent
            noteInfo={noteInfo.subjective}
            label="Subjective"
            signed={isEncounterSigned || isPatientReadonly(patientProfile)}
            handleChangeNote={handleChangeNote}
            buttons={buttons}
          />
        </div>
        <div className="w-full">
          <TextArea
            name="objective"
            textValue={noteInfo.objective}
            topLabel="Objective"
            topLabelVariant="h6"
            headerClassName="mb-2"
            placeholder=" "
            handleChange={handleFormInput}
            border="border-grayBackground"
            disabled={isEncounterSigned || isPatientReadonly(patientProfile)}
            rows={4}
          />
        </div>
        <div className="w-full">
          <TextArea
            name="assessment"
            textValue={noteInfo.assessment}
            topLabel="Assessment"
            topLabelVariant="h6"
            headerClassName="mb-2"
            placeholder=" "
            handleChange={handleFormInput}
            border="border-grayBackground"
            disabled={isEncounterSigned || isPatientReadonly(patientProfile)}
            rows={4}
          />
        </div>
        <div className="w-full">
          <TextArea
            name="plan"
            textValue={noteInfo.plan}
            topLabel="Plan"
            topLabelVariant="h6"
            headerClassName="mb-2"
            placeholder=" "
            handleChange={handleFormInput}
            border="border-grayBackground"
            disabled={isEncounterSigned || isPatientReadonly(patientProfile)}
            rows={4}
          />
        </div>
      </div>
      <ConfirmationModal
        open={openConfirmation}
        title="Sign this encounter note?"
        description={`You're signing as ${profile && profile.firstName} ${
          profile && profile.lastName
        }`}
        confirm="Sign"
        dismiss="Cancel"
        handleClose={() => setOpenConfirmation(false)}
        handleConfirm={() => saveInfo(true)}
        handleCancel={() => setOpenConfirmation(false)}
      />
    </div>
  );
}
