import { FaxSortBy } from '@aster/shared/dtos/efax';
import useDataGridSearchQueryArgs from '../../../hooks/useDataGridSearchQueryArgs';
import { useSearchFaxesQuery } from '../queries/search-efax.query';

export function useEFaxSearch(
  direction: 'sent' | 'received',
  isSetup: boolean,
  useStoredPreferences = false
) {
  const {
    paginationModel,
    setPaginationModel,
    searchQuery,
    search,
    dataGridSortModel,
    sortModel,
    setDataGridSortModel,
  } = useDataGridSearchQueryArgs({}, useStoredPreferences);

  const { areFaxesLoading, paginatedFaxes, areFaxesFetching } =
    useSearchFaxesQuery(
      {
        search: searchQuery,
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        sortBy: sortModel?.sortBy as FaxSortBy,
        sortDir: sortModel?.sortDir,
        isSendDirection: direction === 'sent',
      },
      isSetup
    );

  return {
    areFaxesLoading,
    paginatedFaxes,
    areFaxesFetching,
    paginationModel,
    dataGridSortModel,
    search,
    setPaginationModel,
    setDataGridSortModel,
  };
}
