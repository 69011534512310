import { useMutation } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';
import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';

type AgreeWithLabConsentsMutationProps = {
  onSuccess?: () => void;
};

export const useAgreeWithLabConsentsMutation = (
  props?: AgreeWithLabConsentsMutationProps
) => {
  const completeForm = async (token: string) =>
    axios.post(`/v2/i/patients/agree-with-lab-consents/${token}`);

  const agreeWithLabConsentsMutation = useMutation({
    mutationKey: ['agreeWithLabConsents'],
    mutationFn: completeForm,
    scope: {
      id: createMutationScopeID(MutationScope.PATIENT),
    },
    onSuccess: () => {
      props?.onSuccess?.();
    },
  });

  return { agreeWithLabConsentsMutation };
};
