import RadioYN from '../../../components/RadioYN';
import BasicTextfield from '../../../components/Textfield';
import MultipleCheckboxes from '../../../components/MultipleCheckboxes';
import RadioOptions from '../../../components/RadioOptions';
import Typography from '../../../components/Typography';
import { LifestyleInfo } from '../types/patient-profile';

type LifestyleInfoProps = {
  lifestyleInfo: LifestyleInfo;
  handleOption: (property: string, value: any) => void;
  handleCheckboxes: (values: any, property: string) => void;
  handleInfo: (event: any) => void;
  readonly: boolean;
};

const Lifestyle = (props: LifestyleInfoProps) => {
  const {
    usesTobacco,
    tobaccoOrCigarettesPerDay,
    consumesAlcohol,
    alcoholUseDetails,
    consumesRecreationalDrugs,
    recreationalDrugUseDetails,
    hasHealthyDiet,
    hasDieataryRequirements,
    otherDieataryRequirements,
    practiceRegularExercise,
    regularExerciseType,
    regularExerciseHoursPerWeek,
  } = props.lifestyleInfo;
  const handleOption = props.handleOption;
  const handleInfo = props.handleInfo;
  const handleCheckboxes = props.handleCheckboxes;

  const handleCheckboxExercise = (value: string) => {
    const updatedItems = regularExerciseType.map((item: any) =>
      item.value === value ? { ...item, checked: !item.checked } : item
    );
    handleCheckboxes(updatedItems, 'regularExerciseType');
  };

  return (
    <div className="w-full h-full overflow-y-auto">
      <p className="text-h5 font-semibold mb-5">Lifestyle</p>

      <div className="flex flex-col justify-center mb-5">
        <RadioOptions
          disabled={props.readonly}
          classes="mb-1"
          value={usesTobacco}
          label="Do you smoke cigarettes or use tobacco products?"
          handleChange={(event: any) =>
            handleOption('usesTobacco', event.target.value)
          }
          options={[
            { value: 'no', text: 'No', key: 'no' },
            {
              value: 'quit-1-plus-year-ago',
              text: 'Quit 1+ year ago',
              key: 'quit1plus',
            },
            {
              value: 'quite-under-1-year-ago',
              text: 'Quit under 1 year ago',
              key: 'quitunder1',
            },
            { value: 'yes', text: 'Yes', key: 'yes' },
          ]}
        />
        {usesTobacco === 'yes' && (
          <BasicTextfield
            disabled={props.readonly}
            id="qty"
            variant="filled"
            type="number"
            label="qty/day"
            name="tobaccoOrCigarettesPerDay"
            value={tobaccoOrCigarettesPerDay}
            onChange={(event: any) => handleInfo(event)}
            classes={`mt-1 w-[300px]`}
          />
        )}
      </div>
      <RadioYN
        disabled={props.readonly}
        classes="mb-5"
        value={consumesAlcohol}
        field="alcoholUseDetails"
        info={alcoholUseDetails}
        question="Do you drink alcohol?"
        handleOption={(event: any) =>
          handleOption('consumesAlcohol', event.target.value === 'true')
        }
        handleInfo={handleInfo}
        label="qty/day"
        inforWidth="300px"
      />
      <RadioYN
        classes="mb-5"
        disabled={props.readonly}
        value={consumesRecreationalDrugs}
        field="recreationalDrugUseDetails"
        info={recreationalDrugUseDetails ?? undefined}
        question="Do you use recreational drugs?"
        handleOption={(event: any) =>
          handleOption(
            'consumesRecreationalDrugs',
            event.target.value === 'true'
          )
        }
        handleInfo={handleInfo}
        inforWidth="300px"
      />
      <RadioOptions
        classes="mb-5"
        disabled={props.readonly}
        value={hasHealthyDiet}
        label="Do you consider yourself to have a healthy balanced diet?"
        handleChange={(event: any) =>
          handleOption('hasHealthyDiet', event.target.value)
        }
        options={[
          { value: 'no', text: 'No', key: 'no' },
          { value: 'somewhat', text: 'Somewhat', key: 'somewhat' },
          { value: 'mostly', text: 'Mostly', key: 'mostly' },
          { value: 'yes', text: 'Yes', key: 'yes' },
        ]}
      />
      <div className="flex flex-col justify-center mb-5">
        <RadioOptions
          classes="mb-1"
          disabled={props.readonly}
          value={practiceRegularExercise}
          label="Do you engage in regular physical exercise?"
          handleChange={(event: any) => {
            handleOption(
              'practiceRegularExercise',
              event.target.value === 'true'
            );
          }}
          options={[
            { value: false, text: 'No', key: 'no' },
            { value: true, text: 'Yes', key: 'yes' },
          ]}
        />
        {practiceRegularExercise && (
          <>
            <BasicTextfield
              id="details"
              disabled={props.readonly}
              variant="filled"
              label="Hrs/Week"
              type="number"
              name="regularExerciseHoursPerWeek"
              value={regularExerciseHoursPerWeek}
              onChange={(event: any) => handleInfo(event)}
              classes={`my-1 text-gray-500 mb-4 w-[300px]`}
            />
            <Typography
              text="What type of exercise?"
              variant="body"
              customClass="text-gray-500 font-semibold"
            />
            <div className="flex flex-row">
              <MultipleCheckboxes
                handleCheckboxChange={handleCheckboxExercise}
                disabled={props.readonly}
                items={regularExerciseType}
              />
            </div>
          </>
        )}
      </div>
      <div className="flex flex-col justify-center mb-5">
        <RadioOptions
          classes="mb-1"
          disabled={props.readonly}
          value={hasDieataryRequirements}
          label="Do you have any dietary requirements?"
          handleChange={(event: any) =>
            handleOption('hasDieataryRequirements', event.target.value)
          }
          options={[
            {
              value: 'glucose_intolerance',
              text: 'Gluten intolerance',
              key: 'glutenintolerance',
            },
            { value: 'vegetarian', text: 'Vegetarian', key: 'vegetarian' },
            { value: 'vegan', text: 'Vegan', key: 'vegan' },
            {
              value: 'lactose_intolerance',
              text: 'Lactose intolerance',
              key: 'lactose',
            },
            { value: 'pescatarian', text: 'Pescatarian', key: 'pescatarian' },
            { value: 'other', text: 'Other', key: 'other' },
            { value: 'na', text: 'N/A', key: 'na' },
          ]}
        />
        {hasDieataryRequirements === 'other' && (
          <BasicTextfield
            id="details"
            disabled={props.readonly}
            variant="filled"
            label="Details"
            name="otherDieataryRequirements"
            value={otherDieataryRequirements}
            onChange={(event: any) => handleInfo(event)}
            classes={`mt-1 w-[300px]`}
          />
        )}
      </div>
    </div>
  );
};

export default Lifestyle;
