import { MouseEvent } from 'react';
import Link from '@mui/material/Link';
import { useAuth } from '../../../authentication/AuthProvider';
import { downloadFileFromS3 } from '../../documents/utils/s3-files.utils';
import { createStorageKey } from '@aster/shared/utils/storage';
import { useSnackbar } from '../../../components/Snack';

interface DownloadPdfButtonProps {
  faxId: string;
  disabled?: boolean;
}

export const DownloadPdfButton = ({
  faxId,
  disabled = false,
}: DownloadPdfButtonProps) => {
  const { profile } = useAuth();
  const { showMessage } = useSnackbar();
  const downloadPdf = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await downloadFileFromS3(
      createStorageKey({
        practiceId: profile?.practiceId as string,
        scope: 'efax',
        name: `${faxId}.pdf`,
      }),
      `${faxId}.pdf`,
      () =>
        showMessage({
          message: 'An error ocurred while downloading the PDF',
          type: 'error',
        })
    );
  };

  return (
    <Link
      component="button"
      onClick={downloadPdf}
      disabled={disabled}
      className="disabled:no-underline disabled:text-gray-500"
    >
      EFax PDF
    </Link>
  );
};
