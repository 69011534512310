import Typography from '../../components/Typography';

export const NoRowsOverlay = () => (
  <div className="flex flex-col ml-4">
    <Typography variant="h6" text="No files found." customClass="mb-1" />
    <Typography
      variant="bodySmall"
      text="Use the 'Upload Files' button to add documents to your practice."
    />
  </div>
);
