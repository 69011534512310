import { GridColDef } from '@mui/x-data-grid';
import { DownloadPdfButton } from './utils/DownloadPdfButton';
import { names } from '@aster/shared/utils/names';

const ColumnsSentEfax: GridColDef[] = [
  {
    field: 'to',
    headerName: 'To',
    flex: 1,
    headerClassName: 'bg-grayBackground',
    valueGetter: (params) => params.value || '-',
  },
  {
    field: 'patientName',
    headerName: 'Patient Name',
    flex: 1,
    headerClassName: 'bg-grayBackground',
    valueGetter: (params) => params.value || '-',
  },
  {
    field: 'sentByName',
    headerName: 'Sent By',
    flex: 1,
    headerClassName: 'bg-grayBackground',
    valueGetter: (params) => params.value || '-',
  },
  {
    field: 'pages',
    headerName: 'Pages',
    flex: 1 / 2,
    headerClassName: 'bg-grayBackground',
    valueGetter: (params) => (params.value !== null ? params.value : '-'),
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    headerClassName: 'bg-grayBackground',
    valueGetter: (params) => names(params.value).sentenceCase || '-',
  },
  {
    field: 'download',
    headerName: 'Download',
    flex: 1,
    sortable: false,
    headerClassName: 'bg-grayBackground',
    renderCell: (params) => (
      <DownloadPdfButton
        faxId={params.row.id}
        disabled={params.row.status !== 'COMPLETE'}
      />
    ),
  },
];

export default ColumnsSentEfax;
