import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateEncounterDTO } from '@aster/shared/dtos/encounter';

import {
  createMutationScopeID,
  MutationScope,
} from '../../../../../mutations/utils/create-mutation-scope-id';

import { useSnackbar } from '../../../../../components/Snack';

import axios from '../../../../../app/axiosConfig';
import { AppointmentDTO } from '@aster/shared/dtos/appointment';
import cuid2 from '@paralleldrive/cuid2';

const createEncounterInner = async (input: Partial<CreateEncounterDTO>) => {
  return await axios.post(`/encounters/`, input);
};

export const useCreateEncounterMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) => {
  const queryClient = useQueryClient();

  const { showMessage } = useSnackbar();

  const {
    mutate: createEncounter,
    isPending: isCreatingEncounter,
    ...rest
  } = useMutation({
    mutationKey: ['addNote'],
    mutationFn: createEncounterInner,
    scope: {
      id: createMutationScopeID(MutationScope.ENCOUNTER),
    },
    onMutate: (input) => {
      const { patientID, appointmentID } = input;
      const previousAppointments = queryClient.getQueryData([
        'appointmentsByPatient',
        patientID,
      ]);

      queryClient.setQueryData<Partial<AppointmentDTO>[]>(
        ['appointmentsByPatient', patientID],
        (old = []) => {
          return old.map((oldAppointment) =>
            oldAppointment.id === appointmentID
              ? {
                  ...oldAppointment,
                  loading: true,
                  encounterID: cuid2.createId(),
                }
              : oldAppointment
          );
        }
      );

      return { previousAppointments };
    },
    onError: (_, input, context) => {
      const { patientID } = input;

      queryClient.setQueryData(
        ['appointmentsByPatient', patientID],
        context?.previousAppointments
      );

      showMessage({
        message: 'Error creating appointment',
        type: 'error',
      });

      onError?.();
    },
    onSuccess: (response, variables) => {
      const { patientID } = variables;

      queryClient.invalidateQueries({
        queryKey: ['appointmentsByPatient', patientID],
      });

      showMessage({
        message: 'Encounter created',
        type: 'success',
      });

      onSuccess?.();
    },
  });

  return {
    createEncounter,
    isCreatingEncounter,
    ...rest,
  };
};
