import {
  DUE_DATE_FILTERS,
  FilterState,
  GESTATIONAL_AGE_FILTERS,
} from './hooks/usePatientFilters';
import CloseIcon from '../../assets/icons/Close';
import ButtonType from '../../components/Button';
import FilterChip from '../../components/FilterChip';

type FilterChipsProps = {
  filterState: FilterState;
  clearFilters: () => void;
  removeFilter: <K extends keyof FilterState>(
    category: K,
    value: FilterState[K] extends (infer U)[] ? U : FilterState[K]
  ) => void;
};

export function FilterChips({
  filterState,
  removeFilter,
  clearFilters,
}: FilterChipsProps) {
  const chips = [];

  if (filterState.dueDate) {
    chips.push(
      <FilterChip
        key="dueDate"
        deleteIcon={<CloseIcon />}
        label={
          <p>
            <b>Due Date:</b> {DUE_DATE_FILTERS[filterState.dueDate]}
          </p>
        }
        onDelete={() => removeFilter('dueDate', filterState.dueDate)}
      />
    );
  }

  filterState.gestationalAge.forEach((age) => {
    chips.push(
      <FilterChip
        key={`gestationalAge-${age}`}
        label={
          <p>
            <b>Gest. Age:</b> {GESTATIONAL_AGE_FILTERS[age]}
          </p>
        }
        deleteIcon={<CloseIcon />}
        onDelete={() => removeFilter('gestationalAge', age)}
      />
    );
  });

  filterState.patientStatus.forEach((status) => {
    chips.push(
      <FilterChip
        key={`patientStatus-${status}`}
        label={
          <p>
            <b>Patient Status:</b> <span className="capitalize">{status}</span>
          </p>
        }
        deleteIcon={<CloseIcon />}
        onDelete={() => removeFilter('patientStatus', status)}
      />
    );
  });

  return (
    <div className="flex flex-1 gap-2 ml-2 flex-wrap items-center">
      {chips}{' '}
      {chips.length > 0 && (
        <ButtonType
          className="text-bodySmall text-gray-500 font-semibold"
          text="Clear filters"
          variant="text"
          onClick={clearFilters}
        />
      )}
    </div>
  );
}
