import { useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ButtonType from '../../../components/Button';
import Typography from '../../../components/Typography';
import Input from '../../../components/Input';
import DeleteIcon from '@mui/icons-material/Delete';
import { colors } from '../../../theme';
import axios from '../../../app/axiosConfig';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Codes } from '../types/encounter';
import { EncounterDTO } from '@aster/shared/dtos/encounter';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';
import IconButton from '@mui/material/IconButton';

type ModalProps = {
  encounterInfo: EncounterDTO | undefined;
  selectedCodes: Codes;
  setSelectedCodes: (codes: Codes) => void;
  confirm: string;
  dismiss: string;
  open: boolean;
  handleClose: () => void;
  readonly: boolean;
};

const CodingModal = ({
  encounterInfo,
  selectedCodes,
  setSelectedCodes,
  open,
  confirm,
  dismiss,
  handleClose,
  readonly,
}: ModalProps) => {
  const [currentIcdCode, setCurrentIcdCode] = useState<string>('');
  const [currentCptCode, setCurrentCptCode] = useState<string>('');
  const [isUpdatingEncounter, setIsUpdatingEncounter] = useState(false);

  const queryClient = useQueryClient();

  const isDirty = (originalCodes: string[], newCodes: string[]) => {
    if (newCodes.length !== originalCodes.length) {
      return true;
    }
    const sortedOriginalCodes = originalCodes.slice().sort();
    const sortedNewCodes = newCodes.slice().sort();

    for (let index = 0; index < sortedOriginalCodes.length; index++) {
      if (
        sortedOriginalCodes[index].toLowerCase() !==
        sortedNewCodes[index].toLowerCase()
      ) {
        return true;
      }
    }
    return false;
  };

  const codesAreDirty = useMemo(() => {
    const loadedCodes = {
      cptCodes: encounterInfo?.cptCodes || [],
      icdCodes: encounterInfo?.icdCodes || [],
    };
    const cptIsDirty = isDirty(loadedCodes.cptCodes, selectedCodes.cptCodes);
    const icdIsDirty = isDirty(loadedCodes.icdCodes, selectedCodes.icdCodes);
    return cptIsDirty || icdIsDirty;
  }, [
    encounterInfo?.cptCodes,
    encounterInfo?.icdCodes,
    selectedCodes.cptCodes,
    selectedCodes.icdCodes,
  ]);

  const deleteIcdCode = (string: string) => {
    setSelectedCodes({
      icdCodes: selectedCodes.icdCodes.filter((code) => code !== string),
      cptCodes: selectedCodes.cptCodes,
    });
  };

  const deleteCptCode = (string: string) => {
    setSelectedCodes({
      icdCodes: selectedCodes.icdCodes,
      cptCodes: selectedCodes.cptCodes.filter((code) => code !== string),
    });
  };

  const addIcdCode = () => {
    if (currentIcdCode) {
      setSelectedCodes({
        icdCodes: [currentIcdCode, ...selectedCodes.icdCodes],
        cptCodes: selectedCodes.cptCodes,
      });
      setCurrentIcdCode('');
    }
  };

  const addCptCode = () => {
    if (currentCptCode) {
      setSelectedCodes({
        icdCodes: selectedCodes.icdCodes,
        cptCodes: [currentCptCode, ...selectedCodes.cptCodes],
      });
      setCurrentCptCode('');
    }
  };

  const putEncounterRequest = async (
    info: Pick<EncounterDTO, 'cptCodes' & 'icdCodes'>
  ) => axios.put(`encounters/${encounterInfo?.id}`, info);

  const updateNote = useMutation({
    mutationKey: ['updateNote'],
    mutationFn: putEncounterRequest,
    scope: {
      id: createMutationScopeID(MutationScope.ENCOUNTER, encounterInfo?.id),
    },
    onMutate: () => {
      setIsUpdatingEncounter(true);
    },
    onSuccess: () => {
      setIsUpdatingEncounter(false);
      queryClient.invalidateQueries({ queryKey: ['fetchEncounters'] });
      handleClose();
    },
  });

  const handleSave = async () => {
    const updatedEncounter = {
      cptCodes: selectedCodes.cptCodes,
      icdCodes: selectedCodes.icdCodes,
    };
    if (!encounterInfo) return;
    updateNote.mutate(updatedEncounter);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="flex flex-col w-[500px]">
        <DialogContent className="flex flex-col items-center">
          <Typography text="Code" variant={'h4'} />
        </DialogContent>

        <DialogContent className="w-full flex flex-col">
          <Typography customClass="mb-1" text="ICD-10" variant={'h5'} />
          <div className="flex items-center">
            <Input
              disabled={readonly}
              name="icd-code"
              className="w-full"
              placeholder="Enter diagnosis code and press enter"
              textValue={currentIcdCode}
              handleChange={(e: any) => {
                setCurrentIcdCode(e.target.value);
              }}
              handleOnKeyDown={addIcdCode}
            />
            <IconButton
              disabled={readonly}
              onClick={currentIcdCode.length ? addIcdCode : undefined}
              disableRipple
              className={`${
                currentIcdCode ? 'cursor-pointer' : 'cursor-not-allowed'
              } ml-2 p-0`}
            >
              <AddCircleIcon
                sx={{
                  height: 40,
                  width: 40,
                  color: currentIcdCode ? colors.asterGray : colors.grayLight,
                }}
              />
            </IconButton>
          </div>

          {selectedCodes.icdCodes.length > 0 ? (
            <div className="flex flex-col mt-4">
              {selectedCodes.icdCodes.map((code, index) => {
                return (
                  <div
                    key={`${code}${index}`}
                    className="flex justify-between my-1"
                  >
                    <div className="bg-grayLight rounded-[5px] px-1">
                      <Typography
                        customClass="leading-1"
                        text={code}
                        variant={'h6'}
                      />
                    </div>
                    <IconButton
                      disabled={readonly}
                      disableRipple
                      onClick={() => deleteIcdCode(code)}
                      className="p-0"
                    >
                      <DeleteIcon sx={{ color: colors.gray }} />
                    </IconButton>
                  </div>
                );
              })}
            </div>
          ) : (
            <Typography
              customClass="mt-1"
              text="No ICD-10 codes added"
              variant={'bodySmall'}
            />
          )}
        </DialogContent>
        <DialogContent className="w-full flex flex-col">
          <Typography customClass="mb-1" text="CPT" variant={'h5'} />
          <div className="flex items-center">
            <Input
              className="w-full"
              disabled={readonly}
              name="cpt-code"
              placeholder="Enter procedure code and press enter"
              textValue={currentCptCode}
              handleChange={(e: any) => {
                setCurrentCptCode(e.target.value);
              }}
              handleOnKeyDown={addCptCode}
            />
            <IconButton
              onClick={currentCptCode.length ? addCptCode : undefined}
              disableRipple
              className={`${
                currentCptCode ? 'cursor-pointer' : 'cursor-not-allowed'
              } ml-2 p-0`}
            >
              <AddCircleIcon
                sx={{
                  height: 40,
                  width: 40,
                  color: currentCptCode ? colors.asterGray : colors.grayLight,
                }}
              />
            </IconButton>
          </div>
          {selectedCodes.cptCodes.length > 0 ? (
            <div className="flex flex-col mt-4">
              {selectedCodes.cptCodes.map((code, index) => {
                return (
                  <div
                    key={`${code}${index}`}
                    className="flex justify-between my-1"
                  >
                    <div className={`bg-pastelBlue rounded-[5px] px-1`}>
                      <Typography
                        customClass={`leading-1`}
                        text={code}
                        variant={'h6'}
                      />
                    </div>
                    <IconButton
                      disabled={readonly}
                      disableRipple
                      onClick={() => deleteIcdCode(code)}
                      className="p-0"
                    >
                      <DeleteIcon
                        className="cursor-pointer"
                        onClick={() => deleteCptCode(code)}
                        sx={{ color: colors.gray }}
                      />
                    </IconButton>
                  </div>
                );
              })}
            </div>
          ) : (
            <Typography
              customClass="mt-1"
              text="No CPT codes added"
              variant={'bodySmall'}
            />
          )}
        </DialogContent>

        <DialogActions
          className="
          bg-grayBackground
          "
        >
          <ButtonType
            variant="text"
            onClick={handleClose}
            text={dismiss}
            className="w-[120px] h-[44px]"
          />
          <ButtonType
            variant="contained"
            loading={isUpdatingEncounter}
            onClick={handleSave}
            text={confirm}
            className="w-[100px] h-[44px]"
            disabled={!codesAreDirty || readonly}
          />
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default CodingModal;
