import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useMemo, useState } from 'react';
import ButtonType from '../../components/Button';
import Typography from '../../components/Typography';
import { useDropzone } from 'react-dropzone';
import { UseMutationResult } from '@tanstack/react-query';
import { colors } from '../../theme';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type ModalProps = {
  title: string;
  description?: string;
  confirm: string;
  dismiss: string;
  open: boolean;
  handleConfirm: UseMutationResult<
    void,
    unknown,
    File[],
    {
      previousFiles: unknown;
    }
  >;
  isUploading: boolean;
  handleCancel?: () => void;
  handleClose: () => void;
};

const UploadDocumentModal = (props: ModalProps) => {
  const {
    open,
    title,
    description,
    confirm,
    handleConfirm,
    dismiss,
    handleClose,
    isUploading,
  } = props;

  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [uploadComplete, setUploadComplete] = useState(false);

  const closeUploadModal = () => {
    if (!isUploading && uploadComplete) {
      setUploadComplete(false);
    }
    handleClose();
  };

  const validFiles = () => {
    if (files.length > 20) {
      setError('20 file limit exceeded');
      return false;
    }
    if (totalFileSize > 250000000) {
      setError(`Total file size of ${totalFileSize} exceeds 250MB maximum`);
      return false;
    }
    return true;
  };

  const handleUpload = (files: File[]) => {
    if (validFiles()) {
      handleConfirm.mutate(files);
      setFiles([]);
      setUploadComplete(true);
    }
  };

  const totalFileSize = useMemo(() => {
    return files.reduce((acc, file) => acc + file.size, 0);
  }, [files]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
      'application/pdf': [],
      'application/msword': [],
      'text/csv': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    },
    onDrop: (acceptedFiles) => {
      if (files.length > 19) {
        setError('20 file limit exceeded');
        return;
      }
      setFiles((prev) =>
        prev ? [...prev, ...acceptedFiles] : [...acceptedFiles]
      );
    },
  });

  const removeFileFromList = (file: File) => {
    setFiles((prev) => prev.filter((f) => f !== file));
  };

  useEffect(() => {
    setError(null);
  }, [files]);

  const thumbnails = useMemo(
    () =>
      files &&
      files.map((file) => (
        <div className="br-2 ml-8 mr-8  overflow-hidden" key={file?.name}>
          <div className="flex">
            <DeleteIcon
              sx={{ color: colors.gray, cursor: 'pointer' }}
              onClick={() => removeFileFromList(file)}
            />
            <div className="flex justify-between w-full">
              <Typography
                customClass="ml-2 flex-wrap"
                variant="body"
                text={file?.name}
              />
              <Typography
                customClass="ml-2 flex-wrap"
                variant="body"
                text={(file?.size / 1000).toFixed() + 'KB'}
              />
            </div>
          </div>
        </div>
      )),
    [files]
  );

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={closeUploadModal}
      aria-describedby="alert-dialog-slide-description"
    >
      <>
        <div className="flex flex-col w-[500px] pb-4">
          <DialogContent className="flex flex-col items-center">
            <Typography
              variant={'h4'}
              customClass="font-semibold"
              text={title}
            />
            {!isUploading && !uploadComplete && (
              <>
                {description && (
                  <Typography
                    customClass="mt-1"
                    variant={'h4'}
                    text={description}
                  />
                )}
                <Typography
                  variant={'body'}
                  customClass="mt-2 text-gray-500"
                  text="Maximum of 20 files and 250MB"
                />
                <Typography
                  variant={'body'}
                  customClass="mt-1 text-gray-500"
                  text="File types: PDF, DOC, DOCX, CSV, XLS, XLSX, JPG, PNG"
                />

                <div className="border-dashed border-2 border-grayLight mt-4 flex items-center">
                  <div
                    {...getRootProps({
                      className:
                        'dropzone flex justify-center items-center w-[350px] h-[70px]',
                    })}
                  >
                    <input {...getInputProps()} />
                    <Typography
                      variant="body"
                      text="Drag file here, or click to select file"
                    />
                  </div>
                </div>
              </>
            )}
          </DialogContent>
          {!isUploading && !uploadComplete && (
            <>
              {error && (
                <Typography
                  variant="bodySmall"
                  text={error}
                  customClass="ml-8 mb-2 text-red-500"
                />
              )}
              {thumbnails}
            </>
          )}
          {isUploading && (
            <div className="flex flex-col items-center mt-4 px-8">
              <Typography
                variant="h4"
                text="Upload in progress... it's safe to close this popup but do not navigate away from page until complete."
                customClass="text-gray-500"
              />
            </div>
          )}
          {!isUploading && uploadComplete && (
            <div className="flex flex-col items-center mt-4 px-8">
              <div className="flex items-center">
                <CheckCircleIcon
                  className="mr-1"
                  sx={{ color: colors.green }}
                />
                <Typography
                  variant="h4"
                  text="Upload complete!"
                  customClass="text-green-500"
                />
              </div>
            </div>
          )}
        </div>
        <DialogActions className="bg-grayBackground">
          <ButtonType
            variant="text"
            onClick={() => {
              closeUploadModal();
            }}
            text={isUploading || uploadComplete ? 'Close' : dismiss}
            className="w-[150px] h-[44px]"
          />
          {!isUploading && uploadComplete ? (
            <ButtonType
              variant="contained"
              onClick={() => {
                setUploadComplete(false);
              }}
              text="New Upload"
              className="w-[150px] h-[44px]"
            />
          ) : (
            <ButtonType
              loading={isUploading}
              disabled={!files || !files.length}
              variant="contained"
              onClick={() => handleUpload(files)}
              text={confirm}
              className="w-[150px] h-[44px]"
            />
          )}
        </DialogActions>
      </>
    </Dialog>
  );
};

export default UploadDocumentModal;
