import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import { colors } from '../../theme';
import PersonalInformation from './forms/PersonalInformation';
import ContactInformation from './forms/ContactInformation';
import PregnancyInformation from './forms/PregancyInformation';
import ObstetricInformation from './forms/ObstetricInformation';
import MedicalHistoryInformation from './forms/MedicalHistory';
import Lifestyle from './forms/Lifestyle';
import SocialInformation from './forms/SocialInformation';
import Relationship from './forms/Relationship';
import Insurance from './forms/Insurance';
import ContactList from './forms/ContactList';
import GynecologicHistory from './forms/GynecologicHistory';
import Preferences from './forms/Preferences';
import AdditionalInformation from './forms/AdditionalInformation';
import ConfirmationModal from '../../components/ConfirmationModal';
import { usePatientForm } from './hooks/usePatientForm';
import { Token } from '@aster/shared/dtos/token';
import { StyledTab } from '../../components/StyledTab';
import { LinkTab } from '../../components/Tabs';
import ButtonType from '../../components/Button';
import { useLeavingPageProtection } from '../../hooks/usePageLeavingProtection';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import LoadingGuestToken from '../public-forms/LoadingGuestToken';
import { useGuestPatientInfoByToken } from './queries/guest-patient-info.query';
import { useUpdatePatientByTokenMutation } from './mutations/update-patient-by-token.mutation';
import { isPatientReadonly } from './utils/is-patient-readonly';

export type PublicIntakeFormProps = {
  token: Token;
  onComplete: () => void;
};

function PublicIntakeForm({ token, onComplete }: PublicIntakeFormProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('twmd'));
  const [error, setError] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState(0);

  const [lastClickedButton, setLastClickedButton] = useState<
    'next' | 'previous' | 'complete' | null
  >(null);
  const { patient, isPatientLoading } = useGuestPatientInfoByToken(token.token);
  const { updatePatientMutation } = useUpdatePatientByTokenMutation(
    token.token,
    () => {
      setSnackBarMessage('Your information has been saved');
      setIsDirty(false);
    },
    () => {
      setError(true);
      setIsDirty(false);
    }
  );

  const {
    isDirty,
    setIsDirty,
    acccessInfo,
    commuInformation,
    additionalInformation,
    socialInformation,
    lifestyleInformation,
    medicalHistory,
    obstetricInformation,
    pregnancyInformation,
    gynHis,
    referralInfo,
    preferredPharmacyInfo,
    contactListInfo,
    insurance,
    relationship,
    contactInformation,
    personalInformation,
    handleSocialInfo,
    handleCheckboxesSocialInfo,
    handleGynDate,
    handleBirthday,
    handleGynHisInfo,
    handleContactInfo,
    handleContactInfoBulk,
    handleGynHisOption,
    handlePersonalInfo,
    handlePregancyInfo,
    handlePregnancyCheck,
    handleReferralInfo,
    handleInsuranceInfo,
    handleAdditionalInfo,
    handleLifestylelInfo,
    handlePregnancyDates,
    handleCheckboxesCommu,
    handleCheckGynHisInfo,
    handleContactListInfo,
    handleLifestyleOption,
    handleCheckboxesAccess,
    handleRelationshipInfo,
    handleSocialInfoOption,
    handleCheckboxesGynHist,
    handleCheckboxesMedical,
    handleCheckInsuranceInfo,
    handleMedicalHistoryInfo,
    handleMedicalHistoryAllergies,
    handleMedicalHistoryCurrentMedications,
    handlePersonalInfoSelect,
    handlePregancyInfoOption,
    handleReferralInfoSelect,
    handlePreferredPharmacyInfo,
    handleCheckboxesLifestyle,
    handleMedicalHistoryOption,
    handleContactListInfoSelect,
    handlePregnanciesInformation,
    saveInfo,
  } = usePatientForm({
    data: patient,
    patientID: token.metadata?.patientID as string,
    updatePatientMutation,
  });

  const lastTab = 14;
  const isLastTab = activeTab === lastTab;

  const goToPreviousTab = () => {
    setLastClickedButton('previous');
    saveInfo();
    if (activeTab === 6 || activeTab === 11) {
      setActiveTab((prev) => prev - 2);
    } else {
      setActiveTab((prev) => (prev > 0 ? prev - 1 : prev));
    }
  };

  const goToNextTab = () => {
    setLastClickedButton('next');
    saveInfo();
    if (activeTab === 4 || activeTab === 9) {
      setActiveTab((prev) => prev + 2);
    } else {
      setActiveTab((prev) => (prev < lastTab ? prev + 1 : prev));
    }
  };

  const completeForm = () => {
    setLastClickedButton('complete');
    saveInfo();
    onComplete();
  };

  // Block navigating elsewhere when form is dirty
  const { blocker } = useLeavingPageProtection({ canLeave: !isDirty });

  const handleClose = (confirmed: boolean) => {
    if (confirmed) {
      blocker?.proceed?.();
      setIsDirty(false); // Reset only if the user confirms leaving
    } else {
      blocker?.reset?.();
    }
  };

  if (isPatientLoading) {
    return (
      <LoadingGuestToken text="Just a moment, we're getting your intake form ready." />
    );
  }

  if (patient) {
    return (
      <div className="flex flex-col flex-1 h-full">
        <div className="w-full md:!h-[100px] bg-white px-10 border-b border-grayLight pb-2">
          <div className="grid grid-cols-12">
            <div className="md:col-span-7 col-span-12">
              <div className="flex flex-row mt-5">
                <AssignmentRoundedIcon className="text-gray-500 text-h5 " />
                <span className="text-h5 text-gray-500 ml-1 mt-[-5px]">
                  Patient Profile
                </span>
              </div>
              <div className="">
                <p className="text-h4 text-black font-semibold ml-1">{`${
                  personalInformation.firstName
                } ${
                  personalInformation.preferredName
                    ? `"${personalInformation.preferredName}"`
                    : ''
                } ${personalInformation.lastName}`}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row w-full bg-white md:px-5 flex-1 overflow-y-auto border-bottom">
          <div className="grid w-full grid-flow-row md:grid-rows-fill-first auto-rows-fill-middle bg-white mt-4">
            <Tabs
              orientation={isMobile ? 'horizontal' : 'vertical'}
              scrollButtons
              allowScrollButtonsMobile
              variant="scrollable"
              value={activeTab}
              onChange={(_, newTab) => setActiveTab(newTab)}
              aria-label="Vertical tabs"
              className="min-w-full w-fit md:min-w-[220px] max-w-screen md:row-start-1 md:row-span-2 mb-4"
              sx={{
                borderRight: 1,
                borderColor: 'divider',
                overflow: 'auto',
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
              }}
            >
              <StyledTab label="Personal Information" />
              <StyledTab label="Contact Information" />
              <StyledTab label="Relationship" />
              <StyledTab label="Insurance" />
              <StyledTab label="Contact List" />
              <Divider className="bg-grayLight" variant="middle" />
              <StyledTab label="Gynecologic History" />
              <StyledTab label="Pregnancy Information" />
              <StyledTab label="Obstetric History" />
              <StyledTab label="Medical History" />
              <Divider className="bg-grayLight" variant="middle" />
              <StyledTab label="Lifestyle" />
              <StyledTab label="Social Information" />
              <StyledTab label="Additional Information" />
              <StyledTab label="Preferences" />
            </Tabs>
            <LinkTab
              value={activeTab}
              index={0}
              classes="md:col-span-2 md:pt-2"
            >
              <PersonalInformation
                readonly={isPatientReadonly(patient)}
                personalInfo={personalInformation}
                handleInfo={handlePersonalInfo}
                handleBirthday={handleBirthday}
                handlePersonalInfoSelect={handlePersonalInfoSelect}
              />
            </LinkTab>
            <LinkTab
              value={activeTab}
              index={1}
              classes="md:col-span-2 md:pt-2"
            >
              <ContactInformation
                readonly={isPatientReadonly(patient)}
                contactInfo={contactInformation}
                handleInfo={handleContactInfo}
                handleContactInfoBulk={handleContactInfoBulk}
              />
            </LinkTab>
            <LinkTab
              value={activeTab}
              index={2}
              classes="md:col-span-2 md:pt-2"
            >
              <Relationship
                readonly={isPatientReadonly(patient)}
                relationship={relationship}
                handleInfo={handleRelationshipInfo}
              />
            </LinkTab>
            <LinkTab
              value={activeTab}
              index={3}
              classes="md:col-span-2 md:pt-2"
            >
              <Insurance
                readonly={isPatientReadonly(patient)}
                insurance={insurance}
                handleInfo={handleInsuranceInfo}
                handleCheck={handleCheckInsuranceInfo}
              />
            </LinkTab>
            <LinkTab
              value={activeTab}
              index={4}
              classes="md:col-span-2 md:pt-2"
            >
              <ContactList
                readonly={isPatientReadonly(patient)}
                contactList={contactListInfo}
                preferredPharmacyInfo={preferredPharmacyInfo}
                referralInfo={referralInfo}
                handleReferralInfo={handleReferralInfo}
                handleReferralInfoSelect={handleReferralInfoSelect}
                handlePreferredPharmacyInfo={handlePreferredPharmacyInfo}
                handleInfo={handleContactListInfo}
                handleInfoSelect={handleContactListInfoSelect}
              />
            </LinkTab>
            <LinkTab
              value={activeTab}
              index={6}
              classes="md:col-span-2 md:pt-2"
            >
              <GynecologicHistory
                readonly={isPatientReadonly(patient)}
                gynHisInfo={gynHis}
                handleOption={handleGynHisOption}
                handleInfo={handleGynHisInfo}
                handleCheck={handleCheckGynHisInfo}
                handleCheckboxes={handleCheckboxesGynHist}
                handleDate={handleGynDate}
              />
            </LinkTab>
            <LinkTab
              value={activeTab}
              index={7}
              classes="md:col-span-2 md:pt-2"
            >
              <PregnancyInformation
                readonly={isPatientReadonly(patient)}
                pregnancyInfo={pregnancyInformation}
                handleInfo={handlePregancyInfo}
                handleCheck={handlePregnancyCheck}
                handleOption={handlePregancyInfoOption}
                handlePregnancyDates={handlePregnancyDates}
              />
            </LinkTab>
            <LinkTab
              value={activeTab}
              index={8}
              classes="md:col-span-2 md:pt-2"
            >
              <ObstetricInformation
                readonly={isPatientReadonly(patient)}
                obstetricInfo={obstetricInformation}
                handlePregnanciesInformation={handlePregnanciesInformation}
              />
            </LinkTab>
            <LinkTab
              value={activeTab}
              index={9}
              classes="md:col-span-2 md:pt-2"
            >
              <MedicalHistoryInformation
                readonly={isPatientReadonly(patient)}
                medicalHistory={medicalHistory}
                handleOption={handleMedicalHistoryOption}
                handleInfo={handleMedicalHistoryInfo}
                handleAllergies={handleMedicalHistoryAllergies}
                handleMedications={handleMedicalHistoryCurrentMedications}
                handleCheckboxes={handleCheckboxesMedical}
              />
            </LinkTab>
            <LinkTab
              value={activeTab}
              index={11}
              classes="md:col-span-2 md:pt-2"
            >
              <Lifestyle
                lifestyleInfo={lifestyleInformation}
                readonly={isPatientReadonly(patient)}
                handleOption={handleLifestyleOption}
                handleInfo={handleLifestylelInfo}
                handleCheckboxes={handleCheckboxesLifestyle}
              />
            </LinkTab>
            <LinkTab
              value={activeTab}
              index={12}
              classes="md:col-span-2 md:pt-2"
            >
              <SocialInformation
                readonly={isPatientReadonly(patient)}
                socialInfo={socialInformation}
                handleCheckboxes={handleCheckboxesSocialInfo}
                handleOption={handleSocialInfoOption}
                handleInfo={handleSocialInfo}
              />
            </LinkTab>
            <LinkTab
              value={activeTab}
              index={13}
              classes="md:col-span-2 md:pt-2"
            >
              <AdditionalInformation
                readonly={isPatientReadonly(patient)}
                additionalInfo={additionalInformation}
                handleInfo={handleAdditionalInfo}
              />
            </LinkTab>
            <LinkTab
              value={activeTab}
              index={14}
              classes="md:col-span-2 md:pt-2"
            >
              <Preferences
                readonly={isPatientReadonly(patient)}
                accessInfo={acccessInfo}
                handleCheckboxesAccess={handleCheckboxesAccess}
                handleCheckboxesCommu={handleCheckboxesCommu}
                commuInfo={commuInformation}
              />
            </LinkTab>
            <div className="p-6 flex justify-evenly md:justify-start my-4 md:my-0 h-fit md:row-start-2 md:col-start-2 md:col-span-2 pb-5 md:gap-14 lg:gap-40">
              <ButtonType
                className="mx-auto"
                classes="w-[150px] md:w-[250px]"
                loading={
                  lastClickedButton === 'previous' &&
                  updatePatientMutation.status === 'pending'
                }
                disabled={
                  updatePatientMutation.status === 'pending' || activeTab === 0
                }
                variant="outlined"
                text="Previous"
                onClick={goToPreviousTab}
              />
              <ButtonType
                className="mx-auto"
                classes="w-[150px] md:w-[250px]"
                loading={
                  lastClickedButton === 'complete' ||
                  (lastClickedButton === 'next' &&
                    updatePatientMutation.status === 'pending')
                }
                disabled={updatePatientMutation.status === 'pending'}
                variant="contained"
                text={isLastTab ? 'Complete' : 'Next'}
                onClick={isLastTab ? completeForm : goToNextTab}
              />
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackBarMessage !== null}
          onClose={() => setSnackBarMessage(null)}
          autoHideDuration={2000}
          message={snackBarMessage}
          sx={{
            backgroundColor: colors.gray,
            color: colors.white,
          }}
          action={<CloseIcon onClick={() => setSnackBarMessage(null)} />}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={error}
          onClose={() => setError(false)}
          autoHideDuration={2000}
          message="Error, please try again."
          sx={{
            backgroundColor: colors.gray,
            color: colors.white,
          }}
          action={<CloseIcon onClick={() => setError(false)} />}
        />
        <ConfirmationModal
          open={blocker.state === 'blocked'}
          title="Unsaved Changes"
          description={`Are you sure you want to leave? Changes may not be saved.`}
          confirm="Leave"
          dismiss="Cancel"
          handleClose={() => handleClose(false)}
          handleConfirm={() => handleClose(true)}
          handleCancel={() => handleClose(false)}
        />
      </div>
    );
  }

  return null;
}

export default PublicIntakeForm;
