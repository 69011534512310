const Asterisk = () => {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33594 6.79297C9.57031 6.91016 9.64844 7.20312 9.51172 7.4375L9.12109 8.08203C9.00391 8.31641 8.71094 8.39453 8.47656 8.25781L5.78125 6.61719L5.83984 9.78125C5.83984 10.0352 5.625 10.25 5.37109 10.25H4.60938C4.35547 10.25 4.14062 10.0352 4.14062 9.78125L4.21875 6.61719L1.50391 8.25781C1.26953 8.39453 0.976562 8.31641 0.859375 8.08203L0.46875 7.4375C0.332031 7.20312 0.410156 6.91016 0.644531 6.79297L3.4375 5.25L0.644531 3.72656C0.410156 3.60938 0.332031 3.31641 0.46875 3.08203L0.859375 2.4375C0.976562 2.20312 1.26953 2.125 1.50391 2.26172L4.21875 3.90234L4.14062 0.738281C4.14062 0.484375 4.35547 0.25 4.60938 0.25H5.37109C5.625 0.25 5.83984 0.484375 5.83984 0.738281L5.78125 3.90234L8.47656 2.26172C8.71094 2.125 9.00391 2.20312 9.12109 2.4375L9.51172 3.08203C9.64844 3.31641 9.57031 3.60938 9.33594 3.72656L6.5625 5.25L9.33594 6.79297Z"
        fill="#203A3B"
      />
    </svg>
  );
};

export default Asterisk;
