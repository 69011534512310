import React, { useState, useCallback, useEffect } from 'react';
import ButtonType from '../../../components/Button';
import { Option } from '../../../components/Autocomplete';
import BasicTextfield from '../../../components/Textfield';
import dayjs from 'dayjs';
import Typography from '../../../components/Typography';
import { EventImpl } from '@fullcalendar/core/internal';
import {
  AppointmentTypeDisplayNames,
  CreateAppointmentDTO,
  InviteeDTO,
} from '@aster/shared/dtos/appointment';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CalendarDatePicker from './CalendarDatePicker';
import DialogActions from '@mui/material/DialogActions';
import SelectInvitees from './SelectInvitees';
import { InviteeWithColor } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectDropdown from '../../../../src/components/SelectDropdown';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import AppointmentOptions from '../AppointmentOptions';
import { getAppointmentType } from '../utils/get-appointment-type';
import { SelectChangeEvent } from '@mui/material';

type ModalProps = {
  open: boolean;
  eventInfo: EventImpl;
  handleConfirm: (e: Partial<EventImpl>) => void;
  handleCancel?: () => void;
  handleClose: () => void;
  handleRemove?: any;
  staffColorMap: Record<string, InviteeWithColor>;
};

const EditApptModal = (props: ModalProps) => {
  const { open, handleClose, handleConfirm, eventInfo, staffColorMap } = props;
  const [note, setNote] = useState<string>(
    eventInfo?._def?.extendedProps?.note
  );

  const [startDate, setStartDate] = useState(
    eventInfo?.start
      ? dayjs(eventInfo.start)
      : dayjs().set('hour', 7).set('minute', 0)
  );
  const [endDate, setEndDate] = useState(() =>
    eventInfo?.end
      ? dayjs(eventInfo.end)
      : dayjs().set('hour', 7).set('minute', 30)
  );
  const [loading, setLoading] = useState(false);
  const [appointmentType, setAppointmentType] = useState<
    CreateAppointmentDTO['type']
  >(getAppointmentType(eventInfo?._def?.extendedProps?.appointment));
  const [invitedPatients, setInvitedPatients] = useState<Option[]>(
    eventInfo?._def?.extendedProps?.invitedPatients.map((p: InviteeDTO) => ({
      id: p.id,
      label: `${p.firstName} ${p.lastName}`,
    })) || []
  );
  const [invitedStaff, setInvitedStaff] = useState<Option[]>(
    () =>
      eventInfo?._def?.extendedProps?.invitedStaffs.map((p: InviteeDTO) => ({
        id: p.id,
        label: `${p.firstName} ${p.lastName}`,
      })) || []
  );

  useEffect(() => {
    setStartDate(dayjs(eventInfo.start));
    setEndDate(dayjs(eventInfo.end));
    setInvitedPatients(
      eventInfo?._def?.extendedProps?.invitedPatients?.map((p: InviteeDTO) => ({
        id: p.id,
        label: `${p.firstName} ${p.lastName}`,
      })) || []
    );
    setInvitedStaff(
      eventInfo?._def?.extendedProps?.invitedStaffs?.map((p: InviteeDTO) => ({
        id: p.id,
        label: `${p.firstName} ${p.lastName}`,
      })) || []
    );
    setNote(eventInfo?._def?.extendedProps?.note);
    setAppointmentType(
      getAppointmentType(eventInfo?._def?.extendedProps?.appointment)
    );
  }, [eventInfo]);

  const handleApptChange = (event: SelectChangeEvent) => {
    setAppointmentType(event.target.value as CreateAppointmentDTO['type']);
  };

  const prepareInfo = useCallback(() => {
    setLoading(true);

    const start = startDate;
    const end = endDate;

    const apptInfo: Partial<EventImpl> & Record<string, unknown> = {
      invitedPatientIDs: invitedPatients.map((option) => option.id),
      invitedStaffIDs: invitedStaff.map((option) => option.id),
      apptId: eventInfo?._def?.extendedProps?.originalId,
      dispAppt: AppointmentTypeDisplayNames[appointmentType],
      start: start.toDate(),
      end: end.toDate(),
      note,
      checked: eventInfo?._def?.extendedProps?.telehealth,
      currentColor: eventInfo?._def?.extendedProps?.currentColor,
    };
    handleConfirm(apptInfo);
    setLoading(false);
  }, [
    endDate,
    eventInfo?._def?.extendedProps.appointment,
    eventInfo?._def?.extendedProps?.telehealth,
    eventInfo?._def?.publicId,
    eventInfo?.borderColor,
    handleConfirm,
    invitedPatients,
    invitedStaff,
    note,
    startDate,
    appointmentType,
  ]);

  return (
    <Dialog
      open={open}
      keepMounted
      fullWidth
      maxWidth={'sm'}
      onClose={() => {
        handleClose();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent className="bg-white w-full flex flex-col items-center p-6">
        <Typography
          variant="h5"
          customClass="font-semibold self-start"
          text={'Edit Appointment'}
        />
        <div className="flex flex-col gap-4 w-full h-auto mt-4">
          <CalendarDatePicker
            startDate={startDate}
            onStartDateChange={setStartDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            isEdit={true}
          />
          <SelectInvitees
            invitedPatients={invitedPatients}
            setInvitedPatients={setInvitedPatients}
            invitedStaff={invitedStaff}
            setInvitedStaff={setInvitedStaff}
            staffColorMap={staffColorMap}
          />
          <SelectDropdown
            IconComponent={() => (
              <FontAwesomeIcon
                style={{
                  position: 'absolute',
                  fontSize: 12,
                  right: 20,
                  pointerEvents: 'none',
                }}
                icon={faAngleDown}
              />
            )}
            required
            options={AppointmentOptions}
            handleChange={handleApptChange}
            value={appointmentType}
            label="Appointment type"
          />
          <BasicTextfield
            id="note"
            variant="filled"
            label="Short note..."
            rows={4}
            value={note}
            multiline
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNote(event.target.value);
            }}
          />
        </div>
      </DialogContent>
      <DialogActions className=" bg-white flex !pt-0">
        <ButtonType
          variant="contained"
          onClick={prepareInfo}
          text="Save"
          loading={loading}
          classes={
            'bg-asterGreen-900 hover:bg-asterGreen-700 rounded-[5px] h-[28px] px-6'
          }
        />
      </DialogActions>
    </Dialog>
  );
};

export default EditApptModal;
