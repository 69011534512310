import styled from '@mui/system/styled';
import { colors } from '../theme';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

export const ThemedCardHeader = styled(CardHeader)({
  '.MuiCardHeader-title': {
    fontSize: '0.88rem',
    fontWeight: '600',
    lineHeight: 1.5,
  },
  '.MuiCardHeader-subheader': {
    fontSize: '0.625rem',
    color: colors.asterGray,
    lineHeight: 1.5,
  },
});

export const ThemedCardContent = styled(CardContent)({
  '&.MuiCardContent-root': {
    backgroundColor: colors.grayCard,
    padding: '0.5rem 1rem',
    display: 'flex',
    textTransform: 'capitalize',
  },
});
