const SalivaTest = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
  >
    <path
      d="M2.06152 3.26259H1.82832C1.69416 3.28233 1.55759 3.26232 1.43306 3.20468V8.66471C1.43315 9.54619 1.43299 10.6164 1.43306 11.4977C1.44837 12.1652 2.44026 12.1697 2.45678 11.4977V8.97567C2.45663 8.35385 2.45688 7.47211 2.45678 6.85042V3.20468C2.33177 3.26043 2.19567 3.28037 2.06152 3.26259Z"
      fill="#15803D"
    />
    <path
      d="M2.50422 0.255938C2.10976 -0.259377 1.15265 0.0504577 1.22756 0.766374C1.25378 1.03589 1.41162 2.62567 1.43308 2.85514C1.43786 2.90789 1.46052 2.95685 1.49667 2.99251C1.53282 3.02817 1.57988 3.048 1.62873 3.04815H1.82834C1.93519 3.04686 2.15306 3.04907 2.25917 3.04815C2.30815 3.04772 2.35529 3.02785 2.3917 2.9923C2.42811 2.95674 2.45126 2.90795 2.45679 2.85514L2.66035 0.766342C2.66926 0.674112 2.65994 0.580881 2.63301 0.492868C2.60609 0.404855 2.56219 0.324083 2.50422 0.255938Z"
      fill="#15803D"
    />
    <path
      d="M4.56549 4.42279L4.36391 4.28769C4.26404 4.22241 4.10493 4.13447 4.05561 4.01533L2.65443 6.49872V7.31794C2.65245 7.61878 2.65585 8.3194 2.65443 8.62613L4.92715 4.59651C4.79483 4.57335 4.67026 4.51352 4.56549 4.42279Z"
      fill="#15803D"
    />
    <path
      d="M0.000285892 11.339C-0.0162279 11.8378 0.687187 12.1272 0.937043 11.6692L1.23546 11.1395C1.23424 10.8356 1.23634 10.1327 1.23546 9.83566V9.01644L0.0635266 11.0924C0.0220524 11.1667 0.000178356 11.252 0.000285892 11.339Z"
      fill="#15803D"
    />
    <path
      d="M6.38762 2.10886C6.29958 1.44625 5.33235 1.16671 5.05362 1.82152L4.23348 3.70011C4.21992 3.72403 4.21086 3.75062 4.2068 3.77836C4.20275 3.80609 4.20378 3.83442 4.20984 3.86172C4.2159 3.88902 4.22687 3.91475 4.24211 3.93744C4.25736 3.96012 4.27659 3.97932 4.29869 3.99392C4.38903 4.05449 4.57688 4.17797 4.66826 4.2384C4.79953 4.32593 4.96379 4.4761 5.09711 4.29844L6.27695 2.63213C6.32902 2.55869 6.36587 2.47392 6.38497 2.38361C6.40407 2.2933 6.40498 2.19958 6.38762 2.10886Z"
      fill="#15803D"
    />
  </svg>
);

export default SalivaTest;
