import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ButtonType from '../../components/Button';
import Typography from '../../components/Typography';

type ModalProps = {
  open: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
};

const DeleteDocumentsModal = (props: ModalProps) => {
  const { open, handleConfirm, handleClose } = props;

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="p-2"
      >
        <DialogContent className="flex flex-col items-center">
          <Typography
            variant={'h4'}
            customClass="font-semibold my-5"
            text={'Delete Documents?'}
          />
          <Typography
            variant="body"
            text={
              "Pressing 'Delete' will permanently remove all the selected documents."
            }
          />
        </DialogContent>
        <DialogActions>
          <ButtonType
            variant="text"
            onClick={() => {
              handleClose();
            }}
            text={'Close'}
          />
          <ButtonType
            variant="contained"
            onClick={() => {
              handleConfirm();
              handleClose();
            }}
            text={'Delete'}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteDocumentsModal;
