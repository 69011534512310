import { PutObjectCommand } from '@aws-sdk/client-s3';
import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ExtendedError } from '../../../components/ErrorBoundary';
import { useSnackbar } from '../../../components/Snack';

import { createStorageKey } from '@aster/shared/utils/storage';

import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';

import { Profile, useAuth } from '../../../authentication/AuthProvider';
import { getS3ClientWithCredentials } from '@aster/shared/utils/s3-util';

const uploadPracticeLogoInner = async ({
  logo,
  profile,
}: {
  logo: File;
  profile: Profile;
}) => {
  // Since we're not concerned on storing old logos, we'll always
  // rename the incoming picture file to simply `logo`.
  const renamedLogo = new File([logo], 'logo', {
    type: logo.type,
    lastModified: logo.lastModified,
  });

  const s3 = await getS3ClientWithCredentials();

  if (!s3) throw new Error('Permission required to upload files');

  const Bucket = import.meta.env.VITE_DOCUMENTS_BUCKET;
  const Key = createStorageKey({
    practiceId: profile.practiceId,
    scope: 'assets',
    name: renamedLogo.name,
  });
  const Body = renamedLogo;
  const ContentType = renamedLogo.type;

  const object = await s3.send(
    new PutObjectCommand({
      Bucket,
      Key,
      Body,
      ContentType,
    })
  );

  return object;
};

export const useUploadPracticeLogoMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) => {
  const { profile } = useAuth();
  const { showMessage } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutate: uploadPracticeLogo, ...rest } = useMutation({
    mutationKey: ['uploadPracticeLogo'],
    mutationFn: (logo: File) =>
      uploadPracticeLogoInner({ logo, profile: profile as Profile }),
    scope: {
      id: createMutationScopeID(MutationScope.PRACTICE),
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['practiceSettings'],
      });

      showMessage({
        type: 'success',
        message: 'Practice logo uploaded successfully!',
      });

      onSuccess?.();
    },
    onError: (error) => {
      const practiceLogoUploadError: ExtendedError = new Error(error.message);

      practiceLogoUploadError.name = `PracticeLogoUploadError`;
      practiceLogoUploadError.stack = error.stack as string | undefined;
      practiceLogoUploadError.cause = error;

      datadogRum.addError(practiceLogoUploadError);

      showMessage({
        type: 'error',
        message:
          'An error occurred while uploading your practice logo. Please try again!',
      });

      onError?.();
    },
  });

  return { uploadPracticeLogo, ...rest };
};
