import { FormEvent, useCallback, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  AdditionalInfo,
  ContactInfo,
  ContactListInfo,
  GynHisInfo,
  InsuranceInfo,
  LifestyleInfo,
  MedicalHistoryInfo,
  ObstetricHistoryInfo,
  PersonalInfo,
  PreferredPharmacyInfo,
  PregrancyInfo,
  ReferralInfo,
  RelationshipInfo,
  SocialInfo,
} from '../types/patient-profile';
import {
  defaultBloodBorneIllnessDetails,
  defaultChronicMedicalConditions,
  defaultCurrentMentalHealthProblemOrDiagnosisDetails,
  defaultFamilyMedicalConditions,
  defaultNationalOrLocalPrograms,
  defaultPreferredCommunicationMethods,
  defaultPreviousInfectionsDiseasesOrSTI,
  defaultProblemsWithPeriods,
  defaultRegularExerciseType,
  defaultSexualActivityType,
  defaultSexualProtectionType,
  defaultSexuallyActiveWith,
  defaultSpecialNeeds,
} from '../defaults';
import { mergeCheckboxLists } from '../utils/merge-checkbox-lists';
import { UseMutationResult } from '@tanstack/react-query';
import { AllergyDetails, MedicationDetails } from '@aster/shared/dtos/patient';
import { AxiosResponse } from 'axios';

type MuiSelectEvent = { target: { name: string; value: unknown } };

function isReactChangeEvent(
  event: FormEvent<HTMLInputElement> | MuiSelectEvent
): event is FormEvent<HTMLInputElement> {
  // eslint-disable-next-line eqeqeq
  return (event as FormEvent<HTMLInputElement>).currentTarget != undefined;
}

export const usePatientForm = ({
  patientID,
  data,
  updatePatientMutation,
}: {
  patientID: string | undefined;
  data: any;
  updatePatientMutation: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    any,
    unknown
  >;
}) => {
  const [isDirty, setIsDirty] = useState(false);
  // Personal information
  const [personalInformation, setPersonalInformation] = useState<PersonalInfo>({
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    legalSex: null,
    ethnicity: '',
    race: '',
    preferredLanguage: '',
    preferredName: '',
    pronouns: '',
    genderIdentity: '',
  });

  // Contact information
  const [contactInformation, setContactInformation] = useState<ContactInfo>({
    phoneNumber: '',
    email: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zipCode: '',
  });

  //Relationship Information
  const [relationship, setRelationship] = useState<RelationshipInfo>({
    relationshipStatus: '',
    partnerName: '',
  });

  //Insurance
  const [insurance, setInsurance] = useState<InsuranceInfo>({
    insuranceFirstProvider: '',
    insuranceFirstNumber: '',
    insuranceFirstGroupId: '',
    useFirstForBaby: false,
    insuranceSecondProvider: '',
    insuranceSecondNumber: '',
    insuranceSecondGroupId: '',
    useSecondForBaby: false,
    cashOnly: false,
  });

  // Contact list
  const [contactListInfo, setContactListInfo] = useState<ContactListInfo>({
    emergencyName: '',
    emergencyRelationship: '',
    emergencyPhone: '',
    emergencyEmail: '',
    emergencyAddress: '',
    physicianName: '',
    physicianPhone: '',
    physicianEmail: '',
    physicianAddress: '',
    providerName: '',
    providerType: '',
    providerPhone: '',
    providerEmail: '',
    providerAddress: '',
    otherName: '',
    otherType: '',
    otherPhone: '',
    otherEmail: '',
    otherAddress: '',
  });

  // Referral info
  const [referralInfo, setReferralInfo] = useState<ReferralInfo>({
    practiceFindingDetails: '',
    howDidFindPractice: null,
  });

  // Preferred pharmacy info
  const [preferredPharmacyInfo, setPreferredPharmacyInfo] =
    useState<PreferredPharmacyInfo>({
      preferredPharmacyName: '',
      preferredPharmacyAddress: '',
      preferredPharmacyPhone: '',
    });

  // Gynecologic History
  const [gynHis, setGynHis] = useState<GynHisInfo>({
    hasPeriods: null,
    arePeriodsRegular: null,
    periodUnknown: false,
    averagePeriodLength: null,
    cycleUnknown: false,
    averagePeriodCycleLength: '',
    problemsWithPeriods: defaultProblemsWithPeriods,
    sexuallyActive: null,
    sexuallyActiveWith: defaultSexuallyActiveWith,
    sexualActivityType: defaultSexualActivityType,
    ageOfFirstSexualActivity: '',
    sexualPartnersNumber: 0,
    sexualProtectionType: defaultSexualProtectionType,
    lastPapTestDate: '',
    unknownPap: '',
    lastPapTestResult: '',
    papAbnormalityHistoryAndTreatment: '',
    unknownSTI: '',
    lastSTIScreeningDate: '',
    previousInfectionDiseasesOrSTI: defaultPreviousInfectionsDiseasesOrSTI,
    lastMammogramDate: '',
    unknownMammogram: '',
    mammogramAbnormalityHistoryAndTreatment: '',
  });

  // Pregnancy information
  const [pregnancyInformation, setPregnancyInformation] =
    useState<PregrancyInfo>({
      pregnant: null,
      hadUltrasounds: null,
      ultraSoundDetails: '',
      lmp: null,
      edd: null,
      dateOfConception: null,
      dateToCalculateDueDate: null,
    });

  // Obstetric history
  const [obstetricInformation, setObstetricInformation] =
    useState<ObstetricHistoryInfo>({
      pregnancies: [],
    });

  // Medical history
  const [medicalHistory, setMedicalHistory] = useState<MedicalHistoryInfo>({
    hasChronicMedicalConditions: null,
    chronicMedicalConditions: defaultChronicMedicalConditions,
    hasCurrentMentalHealthProblemOrDiagnosis: null,
    currentMentalHealthProblemOrDiagnosisDetails:
      defaultCurrentMentalHealthProblemOrDiagnosisDetails,
    hasBloodBorneIllness: null,
    bloodBorneIllnessDetails: defaultBloodBorneIllnessDetails,
    hasPreviousAbdominalSurgery: null,
    abdominalSurgeryDetails: '',
    isCurrentlyTakingMedicationsOrSuplements: null,
    hasAllergies: null,
    hasFamilyMedicalConditions: null,
    familyMedicalConditions: defaultFamilyMedicalConditions,
    allergyList: [],
    currentMedicationsOrSupplementsList: [],
  });

  // Lifestyle
  const [lifestyleInformation, setLifestyleInformation] =
    useState<LifestyleInfo>({
      usesTobacco: null,
      tobaccoOrCigarettesPerDay: null,
      consumesAlcohol: null,
      alcoholUseDetails: '',
      consumesRecreationalDrugs: null,
      recreationalDrugUseDetails: '',
      hasHealthyDiet: null,
      hasDieataryRequirements: null,
      otherDieataryRequirements: '',
      practiceRegularExercise: null,
      regularExerciseType: defaultRegularExerciseType,
      regularExerciseHoursPerWeek: '',
    });

  // Social information
  const [socialInformation, setSocialInformation] = useState<SocialInfo>({
    hasSocialSupport: null,
    employmentStatus: null,
    doFeelSafe: null,
    doFeelFinanciallySecure: null,
    doHaveAdequateTransportation: null,
    housingSituation: null,
    doHaveEnoughHealthyFood: null,
    lackOfHealthyFoodDetails: null,
    nationalOrLocalPrograms: defaultNationalOrLocalPrograms,

    doFeelSafeOnCurrentRelationship: null,
    isPartnerControlling: null,
    doHaveConcernsAboutChildSafety: null,
    hasPartnerForcedSexually: null,
    hasPartnerEverThreatenedOrMadeFeelUnsafe: null,
    hasPreviousPartnerAbusiveExperience: null,
    previousPartnerAbusiveExperienceDetails: null,
  });

  const [additionalInformation, setAdditionalInformation] =
    useState<AdditionalInfo>({
      additionalInfo: '',
    });

  const [commuInformation, setCommuInformation] = useState<any>({
    preferredCommunicationMethods: defaultPreferredCommunicationMethods,
  });

  const [acccessInfo, setAccessInfo] = useState<any>({
    specialNeeds: defaultSpecialNeeds,
  });

  useEffect(() => {
    if (data) {
      setPersonalInformation({
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        dateOfBirth: data.dateOfBirth ? dayjs(data.dateOfBirth) : null,
        legalSex: data.legalSex,
        ethnicity: data.ethnicity,
        race: data.race,
        preferredLanguage: data.preferredLanguage,
        preferredName: data.preferredName,
        pronouns: data.pronouns,
        genderIdentity: data.genderIdentity,
      });

      setContactInformation({
        phoneNumber: data.phoneNumber,
        email: data.email,
        streetAddress1: data.streetAddress1,
        streetAddress2: data.streetAddress2,
        city: data.city,
        state: data.state,
        zipCode: data.zipCode,
      });

      setRelationship({
        relationshipStatus: data.relationshipStatus,
        partnerName: data.partnerName,
      });

      setInsurance({
        cashOnly: data.cashPayOnly,
        insuranceFirstProvider: data.insurances
          ? data.insurances[0]?.insuranceProvider
          : '',
        insuranceFirstNumber: data.insurances
          ? data.insurances[0]?.insuranceNumber
          : '',
        insuranceFirstGroupId: data.insurances
          ? data.insurances[0]?.insuranceGroupId
          : '',
        useFirstForBaby: data.insurances
          ? data.insurances[0]?.useForBaby
          : false,
        insuranceSecondProvider: data.insurances
          ? data.insurances[1]?.insuranceProvider
          : '',
        insuranceSecondNumber: data.insurances
          ? data.insurances[1]?.insuranceNumber
          : '',
        insuranceSecondGroupId: data.insurances
          ? data.insurances[1]?.insuranceGroupId
          : '',
        useSecondForBaby: data.insurances
          ? data.insurances[1]?.useForBaby
          : false,
      });

      setContactListInfo({
        emergencyName: data.contactList ? data.contactList[0]?.name : '',
        emergencyRelationship: data.contactList
          ? data.contactList[0]?.relationship
          : '',
        emergencyPhone: data.contactList
          ? data.contactList[0]?.phoneNumber
          : '',
        emergencyEmail: data.contactList ? data.contactList[0]?.email : '',
        emergencyAddress: data.contactList ? data.contactList[0]?.notes : '',
        physicianName: data.contactList ? data.contactList[1]?.name : '',
        physicianPhone: data.contactList
          ? data.contactList[1]?.phoneNumber
          : '',
        physicianEmail: data.contactList ? data.contactList[1]?.email : '',
        physicianAddress: data.contactList ? data.contactList[1]?.notes : '',
        providerName: data.contactList ? data.contactList[2]?.name : '',
        providerType: data.contactList ? data.contactList[2]?.relationship : '',
        providerPhone: data.contactList ? data.contactList[2]?.phoneNumber : '',
        providerEmail: data.contactList ? data.contactList[2]?.email : '',
        providerAddress: data.contactList ? data.contactList[2]?.notes : '',
        otherName: data.contactList ? data.contactList[3]?.name : '',
        otherType: data.contactList ? data.contactList[3]?.relationship : '',
        otherPhone: data.contactList ? data.contactList[3]?.phoneNumber : '',
        otherEmail: data.contactList ? data.contactList[3]?.email : '',
        otherAddress: data.contactList ? data.contactList[3]?.notes : '',
      });
      setReferralInfo({
        howDidFindPractice: data?.howDidFindPractice,
        practiceFindingDetails: data?.practiceFindingDetails,
      });
      setPreferredPharmacyInfo({
        preferredPharmacyName: data?.preferredPharmacyName,
        preferredPharmacyAddress: data?.preferredPharmacyAddress,
        preferredPharmacyPhone: data?.preferredPharmacyPhone,
      });
      setGynHis({
        hasPeriods: data.hasPeriods,
        arePeriodsRegular: data.arePeriodsRegular,
        periodUnknown: data.averagePeriodLength === -1,
        averagePeriodLength:
          data.averagePeriodLength === -1 ? null : data.averagePeriodLength,
        cycleUnknown: data.averagePeriodCycleLength === -1,
        averagePeriodCycleLength:
          data.averagePeriodCycleLength === -1
            ? null
            : data.averagePeriodCycleLength,
        problemsWithPeriods:
          data.problemsWithPeriods?.length > 0
            ? mergeCheckboxLists(
                defaultProblemsWithPeriods,
                data.problemsWithPeriods
              )
            : defaultProblemsWithPeriods,
        sexuallyActive: data.sexuallyActive,
        sexuallyActiveWith:
          data.sexuallyActiveWith?.length > 0
            ? mergeCheckboxLists(
                defaultSexuallyActiveWith,
                data.sexuallyActiveWith
              )
            : defaultSexuallyActiveWith,
        sexualActivityType:
          data.sexualActivityType?.length > 0
            ? mergeCheckboxLists(
                defaultSexualActivityType,
                data.sexualActivityType
              )
            : defaultSexualActivityType,
        ageOfFirstSexualActivity: data.ageOfFirstSexualActivity,
        sexualPartnersNumber: data.sexualPartnersNumber,
        sexualProtectionType:
          data.sexualProtectionType?.length > 0
            ? mergeCheckboxLists(
                defaultSexualProtectionType,
                data.sexualProtectionType
              )
            : defaultSexualProtectionType,
        lastPapTestDate: data.lastPapTestDate,
        unknownPap:
          data.lastPapTestDate === 'unsure' || data.lastPapTestDate === 'never'
            ? data.lastPapTestDate
            : 'yes',
        lastPapTestResult: data.lastPapTestResult,
        papAbnormalityHistoryAndTreatment:
          data.papAbnormalityHistoryAndTreatment,
        unknownSTI:
          data.lastSTIScreeningDate === 'unsure' ||
          data.lastSTIScreeningDate === 'never'
            ? data.lastSTIScreeningDate
            : 'yes',
        lastSTIScreeningDate: data.lastSTIScreeningDate,
        previousInfectionDiseasesOrSTI: data.previousInfectionDiseasesOrSTI
          ? mergeCheckboxLists(
              defaultPreviousInfectionsDiseasesOrSTI,
              data.previousInfectionDiseasesOrSTI
            )
          : defaultPreviousInfectionsDiseasesOrSTI,
        lastMammogramDate: data.lastMammogramDate,
        unknownMammogram:
          data.lastMammogramDate === 'unsure' ||
          data.lastMammogramDate === 'never'
            ? data.lastMammogramDate
            : 'yes',
        mammogramAbnormalityHistoryAndTreatment:
          data.mammogramAbnormalityHistoryAndTreatment,
      });

      setPregnancyInformation({
        pregnant: data.pregnant,
        hadUltrasounds: data.hadUltrasounds,
        ultraSoundDetails: data.ultraSoundDetails,
        lmp: data.lmp ? dayjs(data.lmp) : null,
        edd: data.edd ? dayjs(data.edd) : null,
        unsureDueDate: data.unsureDueDate ?? false,
        dateOfConception: data.dateOfConception
          ? dayjs(data.dateOfConception)
          : null,
        dateToCalculateDueDate: data.dateToCalculateDueDate,
      });

      setObstetricInformation({
        pregnancies: [...data.pregnancies],
      });

      setMedicalHistory({
        hasChronicMedicalConditions:
          data.hasChronicMedicalConditions != null
            ? data.hasChronicMedicalConditions ??
              data.hasChronicMedicalConditions
            : null,
        chronicMedicalConditions:
          data.chronicMedicalConditions &&
          data.chronicMedicalConditions.length > 0
            ? mergeCheckboxLists(
                defaultChronicMedicalConditions,
                data.chronicMedicalConditions
              )
            : defaultChronicMedicalConditions,
        hasCurrentMentalHealthProblemOrDiagnosis:
          data.hasCurrentMentalHealthProblemOrDiagnosis != null
            ? data.hasCurrentMentalHealthProblemOrDiagnosis
            : null,
        currentMentalHealthProblemOrDiagnosisDetails:
          data.currentMentalHealthProblemOrDiagnosisDetails &&
          data.currentMentalHealthProblemOrDiagnosisDetails.length > 0
            ? mergeCheckboxLists(
                defaultCurrentMentalHealthProblemOrDiagnosisDetails,
                data.currentMentalHealthProblemOrDiagnosisDetails
              )
            : defaultCurrentMentalHealthProblemOrDiagnosisDetails,
        hasBloodBorneIllness:
          data.hasBloodBorneIllness != null ? data.hasBloodBorneIllness : null,
        bloodBorneIllnessDetails:
          data.bloodBorneIllnessDetails &&
          data.bloodBorneIllnessDetails.length > 0
            ? mergeCheckboxLists(
                defaultBloodBorneIllnessDetails,
                data.bloodBorneIllnessDetails
              )
            : defaultBloodBorneIllnessDetails,
        hasPreviousAbdominalSurgery: data.hasPreviousAbdominalSurgery,
        abdominalSurgeryDetails: data.abdominalSurgeryDetails,
        isCurrentlyTakingMedicationsOrSuplements:
          data.isCurrentlyTakingMedicationsOrSuplements,
        currentMedicationsOrSupplementsList: [
          ...data.currentMedicationsOrSupplementsList,
        ],
        hasAllergies: data.hasAllergies,
        allergyList: [...data.allergyList],
        hasFamilyMedicalConditions: data.hasFamilyMedicalConditions,
        familyMedicalConditions:
          data.familyMedicalConditions &&
          data.familyMedicalConditions.length > 0
            ? mergeCheckboxLists(
                defaultFamilyMedicalConditions,
                data.familyMedicalConditions
              )
            : defaultFamilyMedicalConditions,
      });

      setLifestyleInformation({
        usesTobacco: data.usesTobacco,
        tobaccoOrCigarettesPerDay: data.tobaccoOrCigarettesPerDay,
        consumesAlcohol:
          data.consumesAlcohol != null ? data.consumesAlcohol : null,
        alcoholUseDetails: data.alcoholUseDetails,
        consumesRecreationalDrugs:
          data.consumesRecreationalDrugs != null
            ? data.consumesRecreationalDrugs
            : null,
        recreationalDrugUseDetails: data.recreationalDrugUseDetails,
        hasHealthyDiet: data.hasHealthyDiet,
        hasDieataryRequirements: data.hasDieataryRequirements,
        practiceRegularExercise: data.practiceRegularExercise,
        otherDieataryRequirements: data.otherDieataryRequirements,
        regularExerciseType:
          data.regularExerciseType?.length > 0
            ? mergeCheckboxLists(
                defaultRegularExerciseType,
                data.regularExerciseType
              )
            : defaultRegularExerciseType,
        regularExerciseHoursPerWeek: data.regularExerciseHoursPerWeek,
      });

      setSocialInformation({
        hasSocialSupport: data.hasSocialSupport,
        employmentStatus: data.employmentStatus,
        doFeelSafe: data.doFeelSafe,
        doFeelFinanciallySecure: data.doFeelFinanciallySecure,
        doHaveAdequateTransportation: data.doHaveAdequateTransportation,
        housingSituation: data.housingSituation,
        doHaveEnoughHealthyFood: data.doHaveEnoughHealthyFood,
        lackOfHealthyFoodDetails: data.lackOfHealthyFoodDetails,
        nationalOrLocalPrograms:
          data.nationalOrLocalPrograms?.length > 0
            ? mergeCheckboxLists(
                defaultNationalOrLocalPrograms,
                data.nationalOrLocalPrograms
              )
            : defaultNationalOrLocalPrograms,
        doFeelSafeOnCurrentRelationship: data.doFeelSafeOnCurrentRelationship,
        isPartnerControlling: data.isPartnerControlling,
        doHaveConcernsAboutChildSafety: data.doHaveConcernsAboutChildSafety,
        hasPartnerForcedSexually: data.hasPartnerForcedSexually,
        hasPartnerEverThreatenedOrMadeFeelUnsafe:
          data.hasPartnerEverThreatenedOrMadeFeelUnsafe,
        hasPreviousPartnerAbusiveExperience:
          data.hasPreviousPartnerAbusiveExperience,
        previousPartnerAbusiveExperienceDetails:
          data.previousPartnerAbusiveExperienceDetails,
      });

      setAdditionalInformation({
        additionalInfo: data.additionalInfo,
      });

      setCommuInformation({
        preferredCommunicationMethods: data.preferredCommunicationMethods
          ? mergeCheckboxLists(
              defaultPreferredCommunicationMethods,
              data.preferredCommunicationMethods
            )
          : defaultPreferredCommunicationMethods,
      });

      setAccessInfo({
        specialNeeds: data.specialNeeds
          ? mergeCheckboxLists(defaultSpecialNeeds, data.specialNeeds)
          : defaultSpecialNeeds,
      });
    }
  }, [data]);

  const handlePersonalInfo = (event: FormEvent<HTMLInputElement>) => {
    setIsDirty(true);
    setPersonalInformation({
      ...personalInformation,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handlePersonalInfoSelect = (
    event: MuiSelectEvent,
    selectType: string
  ) => {
    setIsDirty(true);
    setPersonalInformation({
      ...personalInformation,
      [selectType]: event.target.value,
    });
  };

  const handleBirthday = (event: Dayjs | string) => {
    setIsDirty(true);
    setPersonalInformation({
      ...personalInformation,
      dateOfBirth: event,
    });
  };

  const handleContactInfo = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      setIsDirty(true);
      setContactInformation({
        ...contactInformation,
        [event.currentTarget.name]: event.currentTarget.value,
      });
    },
    [contactInformation]
  );

  const handleContactInfoBulk = (info: Partial<ContactInfo>) => {
    setIsDirty(true);
    setContactInformation({
      ...contactInformation,
      ...info,
    });
  };

  const handleContactInfoSelect = (
    event: MuiSelectEvent,
    selectType: string
  ) => {
    setIsDirty(true);
    setContactInformation({
      ...contactInformation,
      [selectType]: event.target.value,
    });
  };

  const handleRelationshipInfo = (
    event: FormEvent<HTMLInputElement> | MuiSelectEvent,
    selectType: string
  ) => {
    const isReactEvent = isReactChangeEvent(event);
    setIsDirty(true);
    setRelationship({
      ...relationship,
      [selectType]: isReactEvent
        ? event.currentTarget.value
        : event.target.value,
    });
  };

  const handleInsuranceInfo = (event: FormEvent<HTMLInputElement>) => {
    setIsDirty(true);
    setInsurance({
      ...insurance,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleCheckInsuranceInfo = (
    event: FormEvent<HTMLInputElement>,
    property: string
  ) => {
    setIsDirty(true);
    setInsurance({
      ...insurance,
      [property]: event.currentTarget.checked,
    });
  };

  const handleContactListInfo = (event: FormEvent<HTMLInputElement>) => {
    setIsDirty(true);
    setContactListInfo({
      ...contactListInfo,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleContactListInfoSelect = (
    event: MuiSelectEvent,
    selectType: string
  ) => {
    setIsDirty(true);
    setContactListInfo({
      ...contactListInfo,
      [selectType]: event.target.value,
    });
  };

  const handleReferralInfo = (event: FormEvent<HTMLInputElement>) => {
    setIsDirty(true);
    setReferralInfo({
      ...referralInfo,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handlePreferredPharmacyInfo = (event: FormEvent<HTMLInputElement>) => {
    setIsDirty(true);
    setPreferredPharmacyInfo({
      ...preferredPharmacyInfo,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleReferralInfoSelect = (
    event: MuiSelectEvent,
    selectType: string
  ) => {
    setIsDirty(true);
    setReferralInfo({
      ...referralInfo,
      [selectType]: event.target.value,
    });
  };

  const handleGynHisInfo = (event: FormEvent<HTMLInputElement>) => {
    setIsDirty(true);
    setGynHis({
      ...gynHis,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleGynDate = (fieldName: string, event: Dayjs) => {
    setIsDirty(true);
    setGynHis({
      ...gynHis,
      [fieldName]: event,
    });
  };

  const handleGynHisOption = (option: string, value: any) => {
    setIsDirty(true);
    setGynHis({
      ...gynHis,
      [option]: value,
    });
  };

  const handleCheckGynHisInfo = (
    event: FormEvent<HTMLInputElement>,
    property: string
  ) => {
    setIsDirty(true);
    setGynHis({
      ...gynHis,
      [property]: event.currentTarget.checked,
    });
  };

  const handleCheckboxesGynHist = (items: any, type: string) => {
    setIsDirty(true);
    setGynHis({
      ...gynHis,
      [type]: items,
    });
  };

  const handleCheckboxesMedical = (items: any, type: string) => {
    setIsDirty(true);
    setMedicalHistory({
      ...medicalHistory,
      [type]: items,
    });
  };

  const handlePregancyInfo = (
    event: FormEvent<HTMLInputElement> | MuiSelectEvent
  ) => {
    const isReactEvent = isReactChangeEvent(event);
    setIsDirty(true);
    setPregnancyInformation({
      ...pregnancyInformation,
      [isReactEvent ? event.currentTarget.name : event.target.name]:
        isReactEvent ? event.currentTarget.value : event.target?.value,
    });
  };

  const handlePregnancyCheck = (
    event: FormEvent<HTMLInputElement>,
    property: string
  ) => {
    setIsDirty(true);
    setPregnancyInformation({
      ...pregnancyInformation,
      [property]: event.currentTarget.checked,
    });
  };

  const handlePregancyInfoOption = (option: string, value: any) => {
    setIsDirty(true);
    setPregnancyInformation({
      ...pregnancyInformation,
      [option]: value,
    });
  };

  const handlePregnancyDates = (
    event: FormEvent<HTMLInputElement>,
    option: string
  ) => {
    setIsDirty(true);
    setPregnancyInformation({
      ...pregnancyInformation,
      [option]: event,
    });
  };

  const handlePregnanciesInformation = (info: any) => {
    setIsDirty(true);
    setObstetricInformation({
      ...obstetricInformation,
      pregnancies: info,
    });
  };

  const handleMedicalHistoryInfo = (event: FormEvent<HTMLInputElement>) => {
    setIsDirty(true);
    setMedicalHistory({
      ...medicalHistory,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleMedicalHistoryOption = (option: string, value: any) => {
    setIsDirty(true);
    setMedicalHistory({
      ...medicalHistory,
      [option]: value,
    });
  };

  const handleMedicalHistoryAllergies = (items: AllergyDetails[]) => {
    setIsDirty(true);
    setMedicalHistory({
      ...medicalHistory,
      allergyList: items,
    });
  };

  const handleMedicalHistoryCurrentMedications = (
    items: MedicationDetails[]
  ) => {
    setIsDirty(true);
    setMedicalHistory({
      ...medicalHistory,
      currentMedicationsOrSupplementsList: items,
    });
  };

  const handleLifestylelInfo = (event: FormEvent<HTMLInputElement>) => {
    setIsDirty(true);
    setLifestyleInformation({
      ...lifestyleInformation,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleLifestyleOption = (option: string, value: any) => {
    setIsDirty(true);
    setLifestyleInformation({
      ...lifestyleInformation,
      [option]: value,
    });
  };

  const handleCheckboxesLifestyle = (items: any, type: string) => {
    setIsDirty(true);
    setLifestyleInformation({
      ...lifestyleInformation,
      [type]: items,
    });
  };

  const handleCheckboxesSocialInfo = (items: any, type: string) => {
    setIsDirty(true);
    setSocialInformation({
      ...socialInformation,
      [type]: items,
    });
  };

  const handleSocialInfo = (event: FormEvent<HTMLInputElement>) => {
    setIsDirty(true);
    setSocialInformation({
      ...socialInformation,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleSocialInfoOption = (option: string, value: any) => {
    setIsDirty(true);
    setSocialInformation({
      ...socialInformation,
      [option]: value,
    });
  };

  const handleAdditionalInfo = (event: FormEvent<HTMLInputElement>) => {
    setIsDirty(true);
    setAdditionalInformation({
      ...additionalInformation,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleCheckboxesAccess = (items: any) => {
    setIsDirty(true);
    setAccessInfo({
      ...acccessInfo,
      specialNeeds: items,
    });
  };

  const handleCheckboxesCommu = (items: any, type: string) => {
    setIsDirty(true);
    setCommuInformation({
      [type]: items,
    });
  };

  const saveInfo = () => {
    const info = {
      patientID,
      firstName: personalInformation.firstName,
      lastName: personalInformation.lastName,
      middleName: personalInformation.middleName,
      legalSex: personalInformation.legalSex,
      preferredName: personalInformation.preferredName,
      genderIdentity: personalInformation.genderIdentity,
      ethnicity: personalInformation.ethnicity,
      race: personalInformation.race,
      pronouns: personalInformation.pronouns,
      preferredLanguage: personalInformation.preferredLanguage,
      dateOfBirth: personalInformation.dateOfBirth || null,
      email: contactInformation.email,
      phoneNumber: contactInformation.phoneNumber,
      streetAddress1: contactInformation.streetAddress1,
      streetAddress2: contactInformation.streetAddress2,
      city: contactInformation.city,
      state: contactInformation.state,
      zipCode: contactInformation.zipCode,
      relationshipStatus: relationship.relationshipStatus,
      partnerName: relationship.partnerName,
      insurances: [
        {
          insuranceProvider: insurance.insuranceFirstProvider ?? '',
          insuranceNumber: insurance.insuranceFirstNumber ?? '',
          insuranceGroupId: insurance.insuranceFirstGroupId ?? '',
          useForBaby: insurance.useFirstForBaby ?? false,
        },
        {
          insuranceProvider: insurance.insuranceSecondProvider ?? '',
          insuranceNumber: insurance.insuranceSecondNumber ?? '',
          insuranceGroupId: insurance.insuranceSecondGroupId ?? '',
          useForBaby: insurance.useSecondForBaby ?? false,
        },
      ],
      cashPayOnly: insurance.cashOnly,
      contactList: [
        {
          name: contactListInfo.emergencyName ?? '',
          phoneNumber: contactListInfo.emergencyPhone ?? '',
          email: contactListInfo.emergencyEmail ?? '',
          type: 'emergency',
          relationship: contactListInfo.emergencyRelationship ?? '',
          notes: contactListInfo.emergencyAddress ?? '',
        },
        {
          name: contactListInfo.physicianName ?? '',
          phoneNumber: contactListInfo.physicianPhone ?? '',
          email: contactListInfo.physicianEmail ?? '',
          type: 'pcp',
          relationship: 'pcp',
          notes: contactListInfo.physicianAddress ?? '',
        },
        {
          name: contactListInfo.providerName ?? '',
          phoneNumber: contactListInfo.providerPhone ?? '',
          email: contactListInfo.providerEmail ?? '',
          type: 'provider',
          relationship: contactListInfo.providerType ?? '',
          notes: contactListInfo.providerAddress ?? '',
        },
        {
          name: contactListInfo.otherName ?? '',
          phoneNumber: contactListInfo.otherPhone ?? '',
          email: contactListInfo.otherEmail ?? '',
          type: 'other',
          relationship: contactListInfo.otherType ?? '',
          notes: contactListInfo.otherAddress ?? '',
        },
      ],
      howDidFindPractice: referralInfo.howDidFindPractice,
      practiceFindingDetails: referralInfo.practiceFindingDetails,
      preferredPharmacyName: preferredPharmacyInfo.preferredPharmacyName,
      preferredPharmacyAddress: preferredPharmacyInfo.preferredPharmacyAddress,
      preferredPharmacyPhone: preferredPharmacyInfo.preferredPharmacyPhone,
      hasPeriods: gynHis.hasPeriods,
      arePeriodsRegular: gynHis.arePeriodsRegular,
      averagePeriodLength: gynHis.periodUnknown
        ? -1
        : Number(gynHis.averagePeriodLength),
      averagePeriodCycleLength: gynHis.cycleUnknown
        ? -1
        : Number(gynHis.averagePeriodCycleLength),
      problemsWithPeriods: gynHis.problemsWithPeriods,
      sexuallyActive: gynHis.sexuallyActive,
      sexuallyActiveWith: gynHis.sexuallyActiveWith,
      sexualActivityType: gynHis.sexualActivityType,
      ageOfFirstSexualActivity: Number(gynHis.ageOfFirstSexualActivity),
      sexualPartnersNumber: gynHis.sexualPartnersNumber,
      sexualProtectionType: gynHis.sexualProtectionType,
      lastPapTestDate:
        gynHis.unknownPap === 'unsure' || gynHis.unknownPap === 'never'
          ? gynHis.unknownPap
          : gynHis.lastPapTestDate,
      lastPapTestResult: gynHis.lastPapTestResult,
      papAbnormalityHistoryAndTreatment:
        gynHis.papAbnormalityHistoryAndTreatment,
      lastSTIScreeningDate:
        gynHis.unknownSTI === 'unsure' || gynHis.unknownSTI === 'never'
          ? gynHis.unknownSTI
          : gynHis.lastSTIScreeningDate,

      previousInfectionDiseasesOrSTI: gynHis.previousInfectionDiseasesOrSTI,
      lastMammogramDate:
        gynHis.unknownMammogram === 'unsure' ||
        gynHis.unknownMammogram === 'never'
          ? gynHis.unknownMammogram
          : gynHis.lastMammogramDate,
      mammogramAbnormalityHistoryAndTreatment:
        gynHis.mammogramAbnormalityHistoryAndTreatment,

      pregnant: pregnancyInformation.pregnant,
      lmp: pregnancyInformation.lmp
        ? dayjs(pregnancyInformation.lmp).format('YYYY-MM-DD')
        : null,
      edd: pregnancyInformation.edd
        ? dayjs(pregnancyInformation.edd).format('YYYY-MM-DD')
        : null,
      dateOfConception: pregnancyInformation.dateOfConception
        ? dayjs(pregnancyInformation.dateOfConception).format('YYYY-MM-DD')
        : null,
      unsureDueDate: pregnancyInformation.unsureDueDate,
      dateToCalculateDueDate: pregnancyInformation.dateToCalculateDueDate,
      hadUltrasounds: pregnancyInformation.hadUltrasounds,
      ultraSoundDetails: pregnancyInformation.ultraSoundDetails,

      pregnancies: obstetricInformation.pregnancies.map((pregnancy) => ({
        ...pregnancy,
        pregnancy: { ...pregnancy.pregnancy, patientID },
      })),

      hasChronicMedicalConditions: medicalHistory.hasChronicMedicalConditions,
      chronicMedicalConditions: medicalHistory.chronicMedicalConditions,
      hasCurrentMentalHealthProblemOrDiagnosis:
        medicalHistory.hasCurrentMentalHealthProblemOrDiagnosis,
      currentMentalHealthProblemOrDiagnosisDetails:
        medicalHistory.currentMentalHealthProblemOrDiagnosisDetails,
      hasBloodBorneIllness: medicalHistory.hasBloodBorneIllness,
      bloodBorneIllnessDetails: medicalHistory.bloodBorneIllnessDetails,
      hasPreviousAbdominalSurgery: medicalHistory.hasPreviousAbdominalSurgery,
      abdominalSurgeryDetails: medicalHistory.abdominalSurgeryDetails,
      isCurrentlyTakingMedicationsOrSuplements:
        medicalHistory.isCurrentlyTakingMedicationsOrSuplements,
      currentMedicationsOrSupplementsList:
        medicalHistory.currentMedicationsOrSupplementsList,
      hasAllergies: medicalHistory.hasAllergies,
      allergyList: medicalHistory.allergyList,
      hasFamilyMedicalConditions: medicalHistory.hasFamilyMedicalConditions,
      familyMedicalConditions: medicalHistory.familyMedicalConditions,

      usesTobacco: lifestyleInformation.usesTobacco,
      tobaccoOrCigarettesPerDay: Number(
        lifestyleInformation.tobaccoOrCigarettesPerDay
      ),
      consumesAlcohol: lifestyleInformation.consumesAlcohol,
      alcoholUseDetails: lifestyleInformation.alcoholUseDetails,
      consumesRecreationalDrugs: lifestyleInformation.consumesRecreationalDrugs,
      recreationalDrugUseDetails:
        lifestyleInformation.recreationalDrugUseDetails,
      hasHealthyDiet: lifestyleInformation.hasHealthyDiet,
      hasDieataryRequirements: lifestyleInformation.hasDieataryRequirements,
      practiceRegularExercise: lifestyleInformation.practiceRegularExercise,
      regularExerciseType: lifestyleInformation.regularExerciseType,
      regularExerciseHoursPerWeek:
        lifestyleInformation.regularExerciseHoursPerWeek
          ? Number(lifestyleInformation.regularExerciseHoursPerWeek)
          : null,
      // social information
      hasSocialSupport: socialInformation.hasSocialSupport,
      employmentStatus: socialInformation.employmentStatus,
      doFeelSafe: socialInformation.doFeelSafe,
      doFeelFinanciallySecure: socialInformation.doFeelFinanciallySecure,
      doHaveAdequateTransportation:
        socialInformation.doHaveAdequateTransportation,
      housingSituation: socialInformation.housingSituation,
      doHaveEnoughHealthyFood: socialInformation.doHaveEnoughHealthyFood,
      lackOfHealthyFoodDetails: socialInformation.lackOfHealthyFoodDetails,
      nationalOrLocalPrograms: socialInformation.nationalOrLocalPrograms,
      doFeelSafeOnCurrentRelationship:
        socialInformation.doFeelSafeOnCurrentRelationship,
      isPartnerControlling: socialInformation.isPartnerControlling,
      doHaveConcernsAboutChildSafety:
        socialInformation.doHaveConcernsAboutChildSafety,
      hasPartnerForcedSexually: socialInformation.hasPartnerForcedSexually,
      hasPartnerEverThreatenedOrMadeFeelUnsafe:
        socialInformation.hasPartnerEverThreatenedOrMadeFeelUnsafe,
      hasPreviousPartnerAbusiveExperience:
        socialInformation.hasPreviousPartnerAbusiveExperience,
      previousPartnerAbusiveExperienceDetails:
        socialInformation.previousPartnerAbusiveExperienceDetails,
      additionalInfo: additionalInformation.additionalInfo,
      specialNeeds: acccessInfo.specialNeeds,
      preferredCommunicationMethods:
        commuInformation.preferredCommunicationMethods,
    };
    updatePatientMutation.mutate(info);
  };

  return {
    isDirty,
    setIsDirty,
    personalInformation,
    handlePersonalInfo,
    handlePersonalInfoSelect,
    handleBirthday,
    handleContactInfo,
    handleContactInfoBulk,
    handleContactInfoSelect,
    handleRelationshipInfo,
    handleInsuranceInfo,
    handleCheckInsuranceInfo,
    handleContactListInfo,
    handleContactListInfoSelect,
    handleReferralInfo,
    handlePreferredPharmacyInfo,
    handleReferralInfoSelect,
    handleGynHisInfo,
    handleGynDate,
    handleGynHisOption,
    handleCheckGynHisInfo,
    handleCheckboxesGynHist,
    handlePregancyInfo,
    handlePregancyInfoOption,
    handlePregnancyDates,
    handlePregnanciesInformation,
    handleMedicalHistoryInfo,
    handleMedicalHistoryOption,
    handleMedicalHistoryAllergies,
    handleMedicalHistoryCurrentMedications,
    handleCheckboxesMedical,
    handleLifestylelInfo,
    handleLifestyleOption,
    handleCheckboxesLifestyle,
    handlePregnancyCheck,
    handleSocialInfo,
    handleSocialInfoOption,
    handleAdditionalInfo,
    handleCheckboxesSocialInfo,
    handleCheckboxesAccess,
    handleCheckboxesCommu,
    contactInformation,
    relationship,
    insurance,
    contactListInfo,
    referralInfo,
    preferredPharmacyInfo,
    gynHis,
    pregnancyInformation,
    obstetricInformation,
    medicalHistory,
    lifestyleInformation,
    socialInformation,
    additionalInformation,
    commuInformation,
    acccessInfo,
    saveInfo,
  };
};
