import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UpdatePracticeInput } from '@aster/shared/dtos/practice';

import axios from '../../../app/axiosConfig';

import { ExtendedError } from '../../../components/ErrorBoundary';
import { useSnackbar } from '../../../components/Snack';

import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';

const updatePracticeSettingsInner = async (
  practiceSettings: UpdatePracticeInput
) => {
  const { data: record } = await axios.put(
    `/v2/practices/${practiceSettings.id}`,
    {
      ...practiceSettings,
    }
  );

  return record;
};

export const useUpdatePracticeSettingsMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) => {
  const { showMessage } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutate: updatePracticeSettings, ...rest } = useMutation({
    mutationKey: ['updatePracticeSettings'],
    mutationFn: updatePracticeSettingsInner,
    scope: {
      id: createMutationScopeID(MutationScope.PRACTICE),
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['practiceSettings'],
      });

      showMessage({
        type: 'success',
        message: 'Practice settings updated successfully!',
      });

      onSuccess?.();
    },
    onError: (error, _, context) => {
      const practiceSettingsUpdateError: ExtendedError = new Error(
        error.message
      );

      practiceSettingsUpdateError.name = `PracticeSettingsUpdateError`;
      practiceSettingsUpdateError.stack = error.stack as string | undefined;
      practiceSettingsUpdateError.cause = error;

      datadogRum.addError(practiceSettingsUpdateError);

      showMessage({
        type: 'error',
        message:
          'An error occurred while updating your practice settings. Please try again!',
      });

      onError?.();
    },
  });

  return { updatePracticeSettings, ...rest };
};
