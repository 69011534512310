import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLeavingPageProtection } from '../../../hooks/usePageLeavingProtection';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { useEncounterDetailQuery } from '../queries/encounter-detail.query';
import PatientProfileForEncounter from '../sections/PatientProfileForEncounter';
import PrenatalNoteContent from './PrenatalNoteContent';

const PrenatalNote = () => {
  const { encounterId } = useParams();
  const { encounterData, isEncounterLoading } = useEncounterDetailQuery(
    encounterId as string
  );

  const [isDirty, setIsDirty] = useState(false);
  const [enforceSaveMissing, setEnforceSaveMissing] = useState(false);
  const { blocker } = useLeavingPageProtection({ canLeave: !isDirty });

  const handleClose = (confirmed: boolean) => {
    if (confirmed) {
      setEnforceSaveMissing(true);
      blocker?.reset?.();
    } else {
      blocker?.proceed?.();
      setIsDirty(false);
      setEnforceSaveMissing(false);
    }
  };

  const handleUnsaved = (isDirty: boolean) => {
    setIsDirty(isDirty);
  };

  const isEncounterSigned = encounterData?.signedBy !== null;

  return (
    <div className="flex flex-col lg:flex-row overflow-auto">
      <div className="w-fit lg:max-w-[400px]">
        <PatientProfileForEncounter
          isEncounterSigned={isEncounterSigned}
          enforceSaveMissing={enforceSaveMissing}
          handleUnsaved={handleUnsaved}
        />
      </div>
      <PrenatalNoteContent
        encounterData={encounterData}
        enforceSaveMissing={enforceSaveMissing}
        setEnforceSaveMissing={setEnforceSaveMissing}
        handleUnsaved={handleUnsaved}
        isEncounterLoading={isEncounterLoading}
      />
      <ConfirmationModal
        open={blocker.state === 'blocked'}
        title="Unsaved Changes"
        description={`Are you sure you want to leave? Changes may not be saved.`}
        confirm="Save"
        dismiss="Leave"
        handleClose={() => handleClose(true)}
        handleConfirm={() => handleClose(true)}
        handleCancel={() => handleClose(false)}
      />
    </div>
  );
};

export default PrenatalNote;
