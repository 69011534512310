export function useSearch<T>(
  items: T[],
  search: string,
  filterValue: (item: T) => string
) {
  if (!search) return items;

  return items.filter((item) =>
    filterValue(item)
      .trim()
      .toLowerCase()
      .startsWith(search.trim().toLowerCase())
  );
}
