import Typography from '../../../components/Typography';
import { useSelectedLabTestQuestions } from '../queries/get-lab-test-questions.query';
import { useLabsStore } from '../store/labs.store';
import LabTestMarkerQuestion from './LabTestMarkerQuestion';

export default function LabTestMarkerQuestions() {
  const selectedLabTest = useLabsStore((state) => state.selectedLabTest);
  const changeAnswer = useLabsStore((state) => state.changeAnswer);
  const { labTestQuestions } = useSelectedLabTestQuestions(selectedLabTest?.id);

  return (
    <section className="flex flex-col p-5 overflow-hidden">
      <Typography
        text="To finalize this test please confirm the following information"
        variant="h6"
        customClass="mb-4"
      />
      <ul className="flex flex-col gap-2 pr-4 overflow-auto">
        {labTestQuestions?.map((question) => (
          <li>
            <LabTestMarkerQuestion
              key={question.id}
              question={question}
              handleAnswerChange={changeAnswer}
            />
          </li>
        ))}
      </ul>
    </section>
  );
}
