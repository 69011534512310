import { cn } from '@aster/client/utils/cn';
import React from 'react';

export default function ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        'text-p10 font-semibold absolute z-10 bg-neutral-600 text-white inline-flex items-center justify-center w-[8.5rem] h-[1.5625rem] rotate-45',
        className
      )}
      style={{
        clipPath: 'polygon(18% 0%, 81% 0%, 100% 100%, 0% 100%)',
      }}
    >
      {children}
    </div>
  );
}
