import { cn } from '@aster/client/utils';
import React from 'react';

const FormError = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  if (!children) {
    return null;
  }

  return (
    <p
      data-haserror
      ref={ref}
      className={cn('text-bodyMedium font-normal text-red-600', className)}
      {...props}
    >
      {children}
    </p>
  );
});
FormError.displayName = 'FormMessage';

export { FormError };
