import axios from 'axios';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { getSessionProfile } from '../authentication/AuthProvider';
import { LOCAL_STORAGE_KEYS } from '../authentication/util/constants';

const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
});

instance.interceptors.request.use(
  async (config) => {
    const profile = getSessionProfile();
    const token = (await fetchAuthSession()).tokens?.idToken?.toString();
    const practiceId = profile ? profile.practiceId : '';

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers['Cache-Control'] = 'no-cache';
    }

    if (practiceId) {
      config.headers['x-aster-practice'] = practiceId;
    }

    config.headers['x-aster-tz'] =
      Intl.DateTimeFormat().resolvedOptions().timeZone;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.PROFILE);
      signOut();
      window.location.replace('/signin');
    }
    return Promise.reject(error);
  }
);

export default instance;
