import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

export type LeavingPageProtection = {
  canLeave: boolean;
};

export const useLeavingPageProtection = ({
  canLeave,
}: LeavingPageProtection) => {
  // Block navigating elsewhere when form is dirty
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !canLeave && currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (!canLeave) {
        event.preventDefault();
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    // To capture leaving the component:
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload); // Cleanup
    };
  }, [canLeave]);

  return { blocker };
};
