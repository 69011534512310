const ChevronRight = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5312 13.6562C16.6875 13.5 16.9062 13.5 17.0625 13.6562L23.6562 20.25C23.8125 20.4062 23.8125 20.625 23.6562 20.7812L17.0625 27.375C16.9062 27.5312 16.6875 27.5312 16.5312 27.375L16.3125 27.1562C16.1562 27 16.1562 26.7812 16.3125 26.625L22.4062 20.5L16.3125 14.4062C16.1562 14.25 16.1562 14.0312 16.3125 13.875L16.5312 13.6562Z"
        fill="#586068"
      />
    </svg>
  );
};

export default ChevronRight;
