import { SVGProps } from 'react';

const Users = ({ ref, ...props }: SVGProps<HTMLOrSVGElement>) => {
  return (
    <svg
      {...props}
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.4 2.3C2.4 2.75 2.625 3.125 3 3.35C3.35 3.575 3.825 3.575 4.2 3.35C4.55 3.125 4.8 2.75 4.8 2.3C4.8 1.875 4.55 1.5 4.2 1.275C3.825 1.05 3.35 1.05 3 1.275C2.625 1.5 2.4 1.875 2.4 2.3ZM5.6 2.3C5.6 3.025 5.2 3.675 4.6 4.05C3.975 4.4 3.2 4.4 2.6 4.05C1.975 3.675 1.6 3.025 1.6 2.3C1.6 1.6 1.975 0.95 2.6 0.575C3.2 0.225 3.975 0.225 4.6 0.575C5.2 0.95 5.6 1.6 5.6 2.3ZM8 4.3C7.425 4.3 6.9 4.625 6.6 5.1C6.325 5.6 6.325 6.225 6.6 6.7C6.9 7.2 7.425 7.5 8 7.5C8.55 7.5 9.075 7.2 9.375 6.7C9.65 6.225 9.65 5.6 9.375 5.1C9.075 4.625 8.55 4.3 8 4.3ZM8 8.3C7.125 8.3 6.35 7.85 5.9 7.1C5.475 6.375 5.475 5.45 5.9 4.7C6.35 3.975 7.125 3.5 8 3.5C8.85 3.5 9.625 3.975 10.075 4.7C10.5 5.45 10.5 6.375 10.075 7.1C9.625 7.85 8.85 8.3 8 8.3ZM6.525 9.9C5.175 9.9 4.05 10.975 4 12.3H11.975C11.925 10.975 10.8 9.9 9.45 9.9H6.525ZM6.525 9.1H9.45C11.3 9.1 12.8 10.6 12.8 12.45C12.8 12.825 12.5 13.1 12.125 13.1H3.85C3.475 13.1 3.2 12.8 3.2 12.45C3.2 10.6 4.675 9.1 6.525 9.1ZM12.8 1.1C12.35 1.1 11.975 1.35 11.75 1.7C11.525 2.075 11.525 2.55 11.75 2.9C11.975 3.275 12.35 3.5 12.8 3.5C13.225 3.5 13.6 3.275 13.825 2.9C14.05 2.55 14.05 2.075 13.825 1.7C13.6 1.35 13.225 1.1 12.8 1.1ZM12.8 4.3C12.075 4.3 11.425 3.925 11.05 3.3C10.7 2.7 10.7 1.925 11.05 1.3C11.425 0.7 12.075 0.3 12.8 0.3C13.5 0.3 14.15 0.7 14.525 1.3C14.875 1.925 14.875 2.7 14.525 3.3C14.15 3.925 13.5 4.3 12.8 4.3ZM13.2 5.9H11.2C11.2 5.625 11.15 5.375 11.1 5.1H13.2C14.725 5.1 16 6.375 16 7.9C16 8.125 15.8 8.3 15.6 8.3C15.375 8.3 15.2 8.125 15.2 7.9C15.2 6.8 14.3 5.9 13.2 5.9ZM4.8 5.9H2.8C1.675 5.9 0.8 6.8 0.8 7.9C0.8 8.125 0.6 8.3 0.4 8.3C0.175 8.3 0 8.125 0 7.9C0 6.375 1.25 5.1 2.8 5.1H4.9C4.825 5.375 4.8 5.625 4.8 5.9Z"
        fill="#586068"
      />
    </svg>
  );
};

export default Users;
