import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ButtonType from '../../components/Button';
import Typography from '../../components/Typography';
import { useEFaxSetup } from './EFaxSetupContext';
import efaxEmptyImage from '../../assets/empty-images/efax.webp';

export default function EFaxNotSetup() {
  const { startEFaxSetup } = useEFaxSetup();
  return (
    <Box className="flex flex-col mt-56 h-full justify-center items-center text-center w-full">
      <Typography
        variant="h3"
        text="Add a fax line"
        customClass="mb-2 font-bold"
      />
      <Typography customClass="max-w-[56ch]" variant="body">
        Get started with fax, powered by EFax. By signing up you accept
        additional charges of $7.99 per month, inclusive of 200 pages per month.
        If you’d like to port over an existing number you can learn more about
        it{' '}
        <Link
          target="_blank"
          href="https://help.astercare.com/en/articles/3175297"
        >
          here
        </Link>
        .
      </Typography>
      <img src={efaxEmptyImage} alt="Add a fax line" className="w-80 my-4" />
      <ButtonType
        variant="contained"
        onClick={startEFaxSetup}
        text="Set up fax"
        className="mt-4"
      />
    </Box>
  );
}
