const ChevronLeft = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4375 27.375C23.2812 27.5312 23.0625 27.5312 22.9062 27.375L16.3125 20.7812C16.1562 20.625 16.1562 20.4062 16.3125 20.25L22.9062 13.6562C23.0625 13.5 23.2812 13.5 23.4375 13.6562L23.6562 13.875C23.8125 14.0312 23.8125 14.25 23.6562 14.4062L17.5625 20.5L23.6562 26.625C23.8125 26.7812 23.8125 27 23.6562 27.1562L23.4375 27.375Z"
        fill="#586068"
      />
    </svg>
  );
};

export default ChevronLeft;
