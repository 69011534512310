import { SetupPracticeEFaxDTO } from '@aster/shared/dtos/efax';
import axios from '../../../app/axiosConfig';
import { useSnackbar } from '../../../components/Snack';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';
import { AxiosError } from 'axios';

const setupPracticeEFax = async (setupData: SetupPracticeEFaxDTO) =>
  await axios.post('/v2/efax/setup', setupData);

export const useSetupEFaxMutation = (onSuccess?: () => void) => {
  const { showMessage } = useSnackbar();
  const queryClient = useQueryClient();

  const setupPracticeEFaxMutation = useMutation({
    mutationKey: ['setupEFax'],
    mutationFn: setupPracticeEFax,
    scope: {
      id: createMutationScopeID(MutationScope.EFAX),
    },
    onSuccess: () => {
      showMessage({
        message: 'EFax has been set up successfully',
        type: 'success',
      });
      onSuccess?.();
      queryClient.invalidateQueries({ queryKey: ['practices'] });
    },
    onError: (error) => {
      if (
        error instanceof AxiosError &&
        typeof error.response?.data === 'string' &&
        error.response.data.startsWith('No fax numbers available')
      ) {
        showMessage({
          message:
            'There are no fax numbers available for the provided area code. Please contact support.',
          type: 'error',
        });
      } else {
        showMessage({
          message: 'An error occurred while setting up EFax. Please try again.',
          type: 'error',
        });
      }
    },
  });

  return { setupPracticeEFaxMutation };
};
