import { Tab, styled } from '@mui/material';
import { colors } from '../theme';

export const StyledTab = styled((props: any, index: any) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  fontWeight: 400,
  marginTop: '2px',
  paddingRight: 10,
  textTransform: 'initial',
  fontSize: 15,
  width: '210px',
  minWidth: '210px !important',
  alignItems: 'start',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
  },
  whiteSpace: 'nowrap',
  overflow: 'auto',
  '&.Mui-selected': {
    color: '#FFF !important',
    fontWeight: 700,
    backgroundColor: colors.aster,
    borderRadius: '10px',
  },
}));
