const BloodDrop = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="11"
    viewBox="0 0 9 11"
    fill="none"
  >
    <path
      d="M7.63992 6.63913C7.63003 6.5837 7.61843 6.52913 7.60511 6.47499C7.38853 5.56793 6.83635 4.79322 6.28504 4.05842C5.30616 2.75042 4.61943 1.4103 3.84422 0C3.06858 1.40634 2.36683 2.73843 1.39702 4.04632C0.795413 4.85885 0.146575 5.78739 0.0241375 6.81654C-0.106495 7.9161 0.30044 9.04787 1.06705 9.83899C2.6419 11.4649 5.41879 11.3815 6.84458 9.59879C7.487 8.79228 7.82727 7.66653 7.63992 6.63913ZM2.5947 3.96439C2.45891 4.30084 2.22128 4.64373 2.08076 4.96814C1.88868 5.41286 1.69617 5.86102 1.56768 6.32982C1.44607 6.77755 1.38892 7.24977 1.47787 7.7091C1.57584 8.18002 1.80617 8.61316 2.14134 8.95814C2.33342 9.15837 2.79322 9.52274 2.41464 9.7784C2.23459 9.90043 2.05798 9.82008 1.91832 9.68215C1.76363 9.52102 1.62656 9.34399 1.50923 9.15364C0.991863 8.34798 0.8333 7.37132 1.14226 6.45866C1.31759 5.94089 1.56982 5.45965 1.85086 4.99215C2.10439 4.56419 2.33858 4.10657 2.66774 3.73233C2.64926 3.81139 2.62478 3.88877 2.5947 3.96439Z"
      fill="#FB3640"
    />
    <ellipse
      cx="3.79015"
      cy="6.66347"
      rx="2.96154"
      ry="3.70192"
      fill="#FB3640"
    />
    <path
      d="M8.95608 2.29897C8.95236 2.2797 8.94865 2.26128 8.94452 2.242C8.8719 1.92844 8.68786 1.65943 8.50423 1.40584C8.17742 0.952211 7.94841 0.487867 7.68969 0C7.43138 0.486617 7.1974 0.947529 6.87389 1.40113C6.67336 1.6817 6.45878 2.00383 6.41628 2.35894C6.37667 2.74403 6.50417 3.12742 6.76414 3.40543C7.28943 3.96872 8.21536 3.94002 8.6903 3.3219C8.90446 3.04346 9.01798 2.65408 8.95608 2.29897ZM7.27246 1.372C7.22624 1.48852 7.14784 1.60717 7.1008 1.71941C7.03312 1.87233 6.97618 2.02954 6.92955 2.19061C6.88499 2.3461 6.87467 2.50974 6.89984 2.66994C6.93285 2.83229 7.00961 2.98179 7.12143 3.10087C7.18457 3.16984 7.3389 3.2962 7.21387 3.38444C7.15362 3.42728 7.09503 3.39901 7.0484 3.35103C6.99558 3.29534 6.94895 3.23366 6.90934 3.16726C6.73314 2.89226 6.68816 2.54871 6.7872 2.23472C6.84827 2.05738 6.9275 1.88689 7.02323 1.72712C7.10783 1.58062 7.18788 1.42169 7.29765 1.29319C7.29104 1.32018 7.28277 1.34673 7.27246 1.372Z"
      fill="#FB3640"
    />
    <rect
      x="0.0883789"
      y="8.00635"
      width="2.22115"
      height="2.96154"
      transform="rotate(-37.1962 0.0883789 8.00635)"
      fill="#FB3640"
    />
  </svg>
);

export default BloodDrop;
