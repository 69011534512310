import { useQuery } from '@tanstack/react-query';

import { PatientProfileForEncounterDTO } from '@aster/shared/dtos/patient';
import { LabsOrderDTO } from '@aster/shared/dtos/labs';

import axios from '../../../../../app/axiosConfig';

const getLabOrders = async (patient: PatientProfileForEncounterDTO) => {
  const result = await axios.get<LabsOrderDTO[]>(
    `/v2/labs/orders/patient/${patient.patientID}`
  );

  return result.data ?? [];
};

export const useLabOrdersQuery = (
  patient: PatientProfileForEncounterDTO,
  opts = {}
) => {
  const {
    data: labOrders,
    isLoading: areLabOrdersLoading,
    ...rest
  } = useQuery({
    queryKey: ['labOrders', patient?.patientID],
    queryFn: () => getLabOrders(patient),
    enabled: !!patient?.patientID,
    ...opts,
  });

  return {
    labOrders,
    areLabOrdersLoading,
    ...rest,
  };
};
