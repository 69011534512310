import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
} from 'react';
import Typography from '../components/Typography';

type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body' | 'bodySmall';

type PropsInput = {
  name: string;
  textValue: string;
  topLabel?: string;
  topLabelVariant?: Variant;
  placeholder?: string;
  disabled?: boolean;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleOnBlur?: FocusEventHandler<HTMLInputElement>;
  handleOnKeyDown?: () => void;
  className?: string;
};
const Input = ({
  name,
  textValue,
  topLabel,
  topLabelVariant,
  disabled,
  placeholder,
  handleChange,
  handleOnBlur,
  handleOnKeyDown,
  className,
}: PropsInput) => {
  const handleEnterKeyPress: KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key === 'Enter') {
      handleOnKeyDown?.();
    }
  };

  return (
    <div className={className}>
      {topLabel && (
        <Typography
          variant={topLabelVariant ? topLabelVariant : 'bodySmall'}
          text={topLabel}
        />
      )}
      <input
        name={name}
        placeholder={placeholder ? placeholder : 'Insert notes...'}
        value={textValue}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleOnBlur}
        onKeyDown={handleEnterKeyPress}
        className={`w-full rounded-[10px] border border-grayLight focus:border-gray focus:outline-none p-2`}
      ></input>
    </div>
  );
};

export default Input;
