import { GridColDef } from '@mui/x-data-grid';
import { DownloadPdfButton } from './utils/DownloadPdfButton';

const ColumnsInboxEfax: GridColDef[] = [
  {
    field: 'from',
    headerName: 'From',
    flex: 1,
    headerClassName: 'bg-grayBackground',
    valueGetter: (params) => params.value || '-',
  },
  {
    field: 'patientName',
    headerName: 'Patient Name',
    flex: 1,
    headerClassName: 'bg-grayBackground',
    valueGetter: (params) => params.value || '-',
  },
  {
    field: 'pages',
    headerName: 'Pages',
    flex: 1 / 2,
    headerClassName: 'bg-grayBackground',
    valueGetter: (params) => (params.value !== null ? params.value : '-'),
  },
  {
    field: 'download',
    headerName: 'Download',
    sortable: false,
    flex: 1,
    headerClassName: 'bg-grayBackground',
    renderCell: (params) => <DownloadPdfButton faxId={params.row.id} />,
  },
];

export default ColumnsInboxEfax;
