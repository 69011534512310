import { useQuery } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';
import dayjs from 'dayjs';
import { VitalsDTO } from '@aster/shared/dtos/vitals';

export const useVitalsData = ({ patientID }: { patientID: string }) => {
  const fetchEncounterVitals = async () =>
    axios.get<VitalsDTO[]>(`/vitals/byPatient/${patientID}`).then((response) =>
      response.data.map((vital) => ({
        ...vital,
        timeCreated: dayjs(vital.timeCreated).format('MM/DD/YYYY hh:mm A'),
      }))
    );

  const { data: vitals, isLoading } = useQuery({
    queryKey: ['vitals', patientID],
    queryFn: fetchEncounterVitals,
  });

  return { isLoading, vitals };
};
