import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ButtonType from '../../../components/Button';

type ModalProps = {
  title: string;
  email: string;
  token: string;
  confirm: string;
  dismiss: string;
  open: boolean;
  handleConfirm: any;
  handleCancel?: () => void;
  handleClose: () => void;
};

const RevokeInviteModal = (props: ModalProps) => {
  const {
    open,
    title,
    email,
    confirm,
    dismiss,
    handleClose,
    handleConfirm,
    handleCancel,
  } = props;

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="flex flex-col items-center w-[600px]">
        <DialogContent className="flex flex-col items-center">
          <p className="text-h4 font-semibold">{title}</p>
          <p className="mt-1">{`Are you sure you want to revoke the invite for ${email}?`}</p>
        </DialogContent>
      </div>
      <DialogActions className="bg-grayBackground">
        <ButtonType
          variant="text"
          onClick={handleCancel}
          text={dismiss}
          className="w-[120px] h-[44px]"
        />
        <ButtonType
          variant="contained"
          onClick={() => {
            handleConfirm(email);
            handleClose();
          }}
          text={confirm}
          className="w-[150px] h-[44px]"
        />
      </DialogActions>
    </Dialog>
  );
};

export default RevokeInviteModal;
