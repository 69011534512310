const FormIllustration = () => (
  <svg
    width="136"
    height="180"
    viewBox="0 0 136 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.484 24.8078L120.549 20.9645C119.361 16.0882 115.671 12.2121 110.859 10.7874L78.07 1.07978C73.2586 -0.344528 68.0526 0.897604 64.4017 4.34161L61.5239 7.05563L121.484 24.8078Z"
      fill="#C7CBD1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.4802 179.732L4.52629 155.468C1.17815 154.477 -0.730192 150.961 0.260916 147.613L41.664 7.76746C42.6551 4.42112 46.1709 2.51098 49.5172 3.50191L131.473 27.7659C134.819 28.7566 136.729 32.2729 135.738 35.6198L94.3333 175.467C93.344 178.813 89.8265 180.723 86.4802 179.732Z"
      fill="#AF8358"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.3417 161.617L15.6974 140.998C12.8538 140.155 11.232 137.167 12.0736 134.323L47.2561 15.4848C48.0995 12.6412 51.0872 11.018 53.9308 11.8601L123.573 32.4791C126.419 33.3208 128.04 36.3091 127.199 39.153L92.0146 157.991C91.173 160.834 88.1853 162.458 85.3417 161.617Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.521 24.6837L113.898 20.0327L70.8675 7.29297L69.4907 11.9441L77.0952 14.1954L77.4845 17.0503C78.2107 22.3596 81.8417 26.8164 86.8874 28.6016L87.1451 28.6899C92.3529 30.4112 98.0581 29.0262 101.878 25.1273L104.608 22.3414L112.521 24.6837ZM82.1967 15.706L99.3447 20.7827L98.4149 21.7323C95.8884 24.3094 92.1186 25.2248 88.6912 24.0924L88.4822 24.0204L88.2677 23.9414C85.0673 22.7206 82.7608 19.8267 82.2904 16.3939L82.1967 15.706Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.5961 104.024L4.44909 32.6342C3.25075 29.093 5.15008 25.2511 8.69284 24.0532C10.0552 23.592 11.5346 24.3231 11.9959 25.6863L37.4782 101.02C39.9434 108.308 41.2174 115.947 41.2517 123.641V123.656C41.2553 124.679 39.956 125.117 39.3397 124.303L39.3307 124.292C34.6869 118.156 31.0631 111.312 28.5961 104.024Z"
      fill="#C7CBD1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.8218 93.1614L14.1329 31.999L5.25073 35.003L25.9397 96.1672L34.8218 93.1614Z"
      fill="#7D95B7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.0263 110.764C39.4082 107.466 38.5577 104.21 37.4783 101.022L34.8221 93.165L25.9399 96.169L28.5979 104.026C29.6773 107.214 30.9766 110.318 32.4885 113.313L40.0263 110.764Z"
      fill="#406189"
    />
    <path
      d="M16.1689 27.7812L20.9802 42.0035C21.8921 44.7009 20.445 47.6274 17.7474 48.5403C15.0949 49.4371 12.2206 48.0527 11.2584 45.4415L11.2115 45.3078L6.40015 31.0856L9.95191 29.884L14.7633 44.1061C15.012 44.8418 15.8103 45.2366 16.5455 44.988C17.2537 44.7483 17.6465 43.999 17.4519 43.2867L17.4267 43.2047L12.6171 28.9828L16.1689 27.7812Z"
      fill="black"
    />
    <path
      d="M110.688 50.6509L54.804 34.3682L53.2774 39.6073L109.161 55.8901L110.688 50.6509Z"
      fill="black"
    />
    <path
      d="M106.76 64.398L50.8757 48.1152L49.3492 53.3544L105.233 69.6371L106.76 64.398Z"
      fill="black"
    />
    <path
      d="M46.3151 61.0508L78.4775 70.3901L76.9494 75.6286L44.7888 66.2905L46.3151 61.0508Z"
      fill="black"
    />
    <path
      d="M42.3884 74.7939L63.0142 80.9983L61.4879 86.2385L40.8621 80.0324L42.3884 74.7939Z"
      fill="black"
    />
  </svg>
);

export default FormIllustration;
