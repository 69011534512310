const HouseMedical = () => (
  <svg
    width="36"
    height="32"
    viewBox="0 0 36 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="house-medical" clip-path="url(#clip0_14979_1806)">
      <path
        id="Primary"
        d="M22.1651 6.20187C21.8604 5.93271 21.3982 5.93271 21.0884 6.20187L7.27527 18.39C6.9401 18.6845 6.90455 19.2025 7.20418 19.5377C7.5038 19.8728 8.01672 19.9084 8.35189 19.6088L10.2512 17.9329V27.9373C10.2512 30.1819 12.0693 32 14.3139 32H28.9396C31.1843 32 33.0023 30.1819 33.0023 27.9373V17.9329L34.9016 19.6088C35.2368 19.9033 35.7497 19.8728 36.0493 19.5377C36.349 19.2025 36.3134 18.6896 35.9782 18.39L22.1651 6.20187ZM11.8763 27.9373V16.4957L21.6268 7.89296L31.3772 16.4957V27.9373C31.3772 29.2831 30.2854 30.3749 28.9396 30.3749H14.3139C12.9681 30.3749 11.8763 29.2831 11.8763 27.9373ZM20.8142 14.9367C19.9153 14.9367 19.1891 15.6629 19.1891 16.5617V18.1868H17.5641C16.6652 18.1868 15.939 18.913 15.939 19.8119V21.437C15.939 22.3359 16.6652 23.0621 17.5641 23.0621H19.1891V24.6871C19.1891 25.586 19.9153 26.3122 20.8142 26.3122H22.4393C23.3382 26.3122 24.0644 25.586 24.0644 24.6871V23.0621H25.6895C26.5883 23.0621 27.3145 22.3359 27.3145 21.437V19.8119C27.3145 18.913 26.5883 18.1868 25.6895 18.1868H24.0644V16.5617C24.0644 15.6629 23.3382 14.9367 22.4393 14.9367H20.8142ZM20.8142 16.5617H22.4393V18.9994C22.4393 19.4463 22.8049 19.8119 23.2518 19.8119H25.6895V21.437H23.2518C22.8049 21.437 22.4393 21.8026 22.4393 22.2495V24.6871H20.8142V22.2495C20.8142 21.8026 20.4486 21.437 20.0017 21.437H17.5641V19.8119H20.0017C20.215 19.8119 20.4232 19.7256 20.5755 19.5732C20.7279 19.4209 20.8142 19.2127 20.8142 18.9994V16.5617Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_14979_1806">
        <rect width="36" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HouseMedical;
