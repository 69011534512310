import React, { useCallback, useEffect, useState } from 'react';
import NoteHeader from '../sections/NoteHeader';
import { useAuth } from '../../../authentication/AuthProvider';
import { useParams } from 'react-router-dom';
import { EncounterDTO } from '@aster/shared/dtos/encounter';
import { useUpdateEncounterMutation } from '../mutations/update-encounter.mutation';
import ConfirmationModal from '../../../components/ConfirmationModal';
import TextArea from '../../../components/TextArea';
import TemplateComponent from '../templates/TemplateComponent';
import CircularProgress from '@mui/material/CircularProgress';
import { Codes } from '../types/encounter';
import { usePatientProfileQuery } from '../queries/patient-profile.query';
import { isPatientReadonly } from '../../patients/utils/is-patient-readonly';

type BasicNoteContentProps = {
  encounterData: EncounterDTO | undefined;
  enforceSaveMissing: boolean;
  setEnforceSaveMissing: (value: boolean) => void;
  handleUnsaved: (isDirty: boolean) => void;
  isEncounterLoading: boolean;
};

export default function BasicNoteContent({
  encounterData,
  enforceSaveMissing,
  setEnforceSaveMissing,
  handleUnsaved,
  isEncounterLoading,
}: BasicNoteContentProps) {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [noteInfo, setNoteInfo] = useState(() => ({
    notes: encounterData?.content?.notes || '',
    additional: encounterData?.content?.additional || '',
  }));

  const [selectedCodes, setSelectedCodes] = useState<Codes>(() => ({
    cptCodes: encounterData?.cptCodes ?? [],
    icdCodes: encounterData?.icdCodes ?? [],
  }));
  const isEncounterSigned = encounterData?.signedBy !== null;

  const { patient, encounterId } = useParams();
  const { profile } = useAuth();

  const handleChangeCodes = (codes: Codes) => {
    setSelectedCodes(codes);
  };

  const { updateEncounter } = useUpdateEncounterMutation(encounterId, () => {
    handleUnsaved(false);
    setEnforceSaveMissing(false);
  });

  const { patientProfile } = usePatientProfileQuery(patient);

  const saveInfo = useCallback(
    (signed: boolean) => {
      const info = {
        patientID: patient,
        startTime: encounterData?.startTime,
        endTime: encounterData?.endTime,
        signed,
        content: {
          notes: noteInfo.notes,
          additional: noteInfo.additional,
        },
        templateType: encounterData?.templateType,
        cptCodes: selectedCodes.cptCodes,
        icdCodes: selectedCodes.icdCodes,
      };
      if (signed) {
        setOpenConfirmation(false);
      }
      updateEncounter.mutate(info);
    },
    [
      encounterData?.endTime,
      encounterData?.startTime,
      encounterData?.templateType,
      noteInfo.additional,
      noteInfo.notes,
      patient,
      selectedCodes.cptCodes,
      selectedCodes.icdCodes,
      updateEncounter,
    ]
  );

  useEffect(() => {
    if (enforceSaveMissing) {
      saveInfo(false);
      setEnforceSaveMissing(false);
    }
  }, [enforceSaveMissing, saveInfo, setEnforceSaveMissing]);

  const buttons = [
    { value: 'history', text: 'History' },
    { value: 'labs', text: 'Labs/Ultrasounds' },
    { value: 'imaging', text: 'Imaging' },
  ];

  if (isEncounterLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-full w-full">
        <CircularProgress />
      </div>
    );
  }

  const handleChangeNote = (content: string) => {
    setNoteInfo((prevNote) => ({
      ...prevNote,
      notes: content,
    }));
    handleUnsaved(true);
  };

  const handleChangeAdditional = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNoteInfo((prevNote) => ({
      ...prevNote,
      additional: e.target.value,
    }));
    handleUnsaved(true);
  };
  return (
    <div className="flex flex-col lg:w-full mx-10 mt-5 lg:overflow-y-auto">
      <NoteHeader
        encounterInfo={encounterData}
        selectedCodes={selectedCodes}
        setSelectedCodes={handleChangeCodes}
        title="Basic Note"
        disabled={updateEncounter.status === 'pending'}
        buttonMessageLeft="Save draft"
        actionLeft={() => saveInfo(false)}
        buttonMessageRight="Sign note"
        actionRight={() => setOpenConfirmation(true)}
        hide={isEncounterSigned || isPatientReadonly(patientProfile)}
      />
      <div className="flex flex-col pb-5">
        <TemplateComponent
          noteInfo={noteInfo.notes}
          signed={isEncounterSigned || isPatientReadonly(patientProfile)}
          handleChangeNote={handleChangeNote}
          buttons={buttons}
        />

        <div className="flex flex-col mt-10 w-full">
          <TextArea
            name="additional"
            textValue={noteInfo.additional}
            topLabel="Additional"
            headerClassName="mb-2"
            topLabelVariant="h6"
            placeholder=" "
            handleChange={handleChangeAdditional}
            border="border-grayBackground"
            disabled={isEncounterSigned || isPatientReadonly(patientProfile)}
            rows={4}
          />
        </div>
      </div>

      <ConfirmationModal
        open={openConfirmation}
        title="Sign this encounter note?"
        description={`You're signing as ${profile && profile.firstName} ${
          profile && profile.lastName
        }`}
        confirm="Sign"
        dismiss="Cancel"
        handleClose={() => setOpenConfirmation(false)}
        handleConfirm={() => saveInfo(true)}
        handleCancel={() => setOpenConfirmation(false)}
      />
    </div>
  );
}
