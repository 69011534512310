import { createSlice } from '@reduxjs/toolkit';

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    appts: [],
  },
  reducers: {
    appointments: (state, action) => {
      state.appts = action.payload;
    },
  },
});

export const { appointments } = calendarSlice.actions;

export default calendarSlice.reducer;
