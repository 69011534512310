const UserPlus = () => {
  return (
    <svg
      width="64"
      height="51"
      viewBox="0 0 64 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.6875 20.5625C62.4688 20.5625 63.25 21.3438 63.25 22.125V25.25C63.25 26.1289 62.4688 26.8125 61.6875 26.8125H55.4375V33.0625C55.4375 33.9414 54.6562 34.625 53.875 34.625H50.75C49.8711 34.625 49.1875 33.9414 49.1875 33.0625V26.8125H42.9375C42.0586 26.8125 41.375 26.1289 41.375 25.25V22.125C41.375 21.3438 42.0586 20.5625 42.9375 20.5625H49.1875V14.3125C49.1875 13.5312 49.8711 12.75 50.75 12.75H53.875C54.6562 12.75 55.4375 13.5312 55.4375 14.3125V20.5625H61.6875ZM22.625 25.25C15.6914 25.25 10.125 19.6836 10.125 12.75C10.125 5.91406 15.6914 0.25 22.625 0.25C29.4609 0.25 35.125 5.91406 35.125 12.75C35.125 19.6836 29.4609 25.25 22.625 25.25ZM31.3164 28.375C38.543 28.375 44.5 34.332 44.5 41.5586V45.5625C44.5 48.1992 42.3516 50.25 39.8125 50.25H5.4375C2.80078 50.25 0.75 48.1992 0.75 45.5625V41.5586C0.75 34.332 6.60938 28.375 13.8359 28.375H15.4961C17.6445 29.4492 20.0859 29.9375 22.625 29.9375C25.1641 29.9375 27.5078 29.4492 29.6562 28.375H31.3164Z"
        fill="#A0C4FF"
      />
    </svg>
  );
};

export default UserPlus;
