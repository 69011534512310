import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useSetupEFaxMutation } from './mutations/setup-practice.mutation';

type EFaxSetupType = {
  isSettingUpEFax: boolean;
  isEFaxSetupOpen: boolean;
  eFaxSetupStep: 'initial' | 'confirmation';
  areaCode?: string;
  goToConfirmationStep: (areaCode: string) => void;
  goToInitialStep: () => void;
  confirmSetup: () => void;
  cancelSetup: () => void;
  startEFaxSetup: () => void;
};

const defaultEFaxSetupState: EFaxSetupType = {
  isSettingUpEFax: false,
  isEFaxSetupOpen: false,
  eFaxSetupStep: 'initial',
  startEFaxSetup: () => {
    console.error('startSetup not implemented');
  },
  goToConfirmationStep: () => {
    console.error('goToNextStep not implemented');
  },
  goToInitialStep: () => {
    console.error('goToInitialStep not implemented');
  },
  confirmSetup: () => {
    console.error('confirmSetup not implemented');
  },
  cancelSetup: () => {
    console.error('cancelSetup not implemented');
  },
};

const EFaxSetupContext = createContext<EFaxSetupType>(defaultEFaxSetupState);

function EFaxSetupProvider({ children }: { children: React.ReactNode }) {
  const [isEFaxSetupOpen, setIsEFaxSetupOpen] = useState(
    defaultEFaxSetupState.isEFaxSetupOpen
  );
  const [eFaxSetupStep, setEFaxSetupStep] = useState<
    EFaxSetupType['eFaxSetupStep']
  >(defaultEFaxSetupState.eFaxSetupStep);
  const [areaCode, setAreaCode] = useState<string | undefined>(
    defaultEFaxSetupState.areaCode
  );
  const { setupPracticeEFaxMutation } = useSetupEFaxMutation(() => {
    setIsEFaxSetupOpen(false);
  });
  const goToConfirmationStep = (areaCode: string) => {
    setAreaCode(areaCode);
    setEFaxSetupStep('confirmation');
  };
  const goToInitialStep = () => {
    setEFaxSetupStep('initial');
  };
  const confirmSetup = useCallback(() => {
    if (areaCode) {
      setupPracticeEFaxMutation.mutate({ areaCode });
    }
  }, [areaCode, setupPracticeEFaxMutation]);
  const cancelSetup = () => {
    setIsEFaxSetupOpen(false);
  };
  const startEFaxSetup = () => {
    setIsEFaxSetupOpen(true);
  };

  const value: EFaxSetupType = useMemo(
    () => ({
      isSettingUpEFax: setupPracticeEFaxMutation.status === 'pending',
      isEFaxSetupOpen,
      eFaxSetupStep,
      areaCode,
      goToConfirmationStep,
      goToInitialStep,
      confirmSetup,
      cancelSetup,
      startEFaxSetup,
    }),
    [
      areaCode,
      confirmSetup,
      eFaxSetupStep,
      isEFaxSetupOpen,
      setupPracticeEFaxMutation.status,
    ]
  );

  return (
    <EFaxSetupContext.Provider value={value}>
      {children}
    </EFaxSetupContext.Provider>
  );
}

function useEFaxSetup() {
  const context = useContext(EFaxSetupContext);
  if (context === undefined) {
    throw new Error('useEFaxSetup must be used within a EFaxSetupContext');
  }
  return context;
}

export { EFaxSetupProvider, useEFaxSetup };
