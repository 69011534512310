import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Divider,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { useAuth } from '../../authentication/AuthProvider';
import { FaxListItemDTO } from '@aster/shared/dtos/efax';
import { createStorageKey } from '@aster/shared/utils/storage';
import { useSnackbar } from '../../components/Snack';
import { getS3SignedUrl } from '@aster/shared/utils/s3-util';
import { useResizeDetector } from 'react-resize-detector';
import { Document, Page } from 'react-pdf';

interface FaxDetailModalProps {
  open: boolean;
  onClose: () => void;
  fax: FaxListItemDTO;
}

const FaxDetailModal: React.FC<FaxDetailModalProps> = ({
  open,
  onClose,
  fax,
}) => {
  const [pdfFileUrl, setPdfFileUrl] = useState<string>();
  const { profile } = useAuth();
  const { showMessage } = useSnackbar();
  const { width, ref } = useResizeDetector();
  const [numPages, setNumPages] = useState<number>();
  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  useEffect(() => {
    async function showFaxPDF() {
      const url = await getS3SignedUrl({
        Bucket: import.meta.env.VITE_DOCUMENTS_BUCKET,
        s3key: createStorageKey({
          practiceId: profile?.practiceId as string,
          scope: 'efax',
          name: `${fax.id}.pdf`,
        }),
        fileName: `${fax.id}.pdf`,
        onError: () =>
          showMessage({ message: 'Failed to load fax PDF', type: 'error' }),
      });
      setPdfFileUrl(url ?? undefined);
    }
    if (open) {
      showFaxPDF();
    } else {
      setPdfFileUrl(undefined);
    }
  }, [fax.id, open, profile?.practiceId, showMessage]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      classes={{ paper: 'h-full' }}
    >
      <div className="flex justify-between items-center p-4">
        <DialogTitle>View Fax</DialogTitle>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <DialogContent className="grid grid-cols-fill-last overflow-hidden">
        <div className="flex flex-col p-4 space-y-2">
          {fax.from && (
            <Typography variant="body2">
              <strong>From:</strong> {fax.from || '-'}
            </Typography>
          )}
          {fax.to && (
            <Typography variant="body2">
              <strong>To:</strong> {fax.to || '-'}
            </Typography>
          )}
          {fax.patientName && (
            <Typography variant="body2">
              <strong>Patient Name:</strong> {fax.patientName || '-'}
            </Typography>
          )}
          {fax.sentByName && (
            <Typography variant="body2">
              <strong>Sent By:</strong> {fax.sentByName || '-'}
            </Typography>
          )}
        </div>
        <div
          className="overflow-y-auto flex items-center justify-center rounded-md border border-gray-200 p-5 duration-300 ease-in-out"
          ref={ref}
        >
          {!pdfFileUrl ? (
            <CircularProgress />
          ) : (
            pdfFileUrl && (
              <Document
                className="overflow-x-auto h-full w-full"
                loading={<CircularProgress />}
                onLoadSuccess={onDocumentLoadSuccess}
                file={pdfFileUrl}
              >
                {Array.from(new Array(numPages), (_el, index) => (
                  <Page
                    scale={1}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={width}
                  />
                ))}
              </Document>
            )
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FaxDetailModal;
