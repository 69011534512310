import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@aster/client/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center gap-2 whitespace-nowrap transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 group',
  {
    variants: {
      variant: {
        default: 'bg-aster-main text-white hover:bg-aster-dark',
        destructive: 'bg-red-500 text-white hover:bg-red-500/90',
        outline:
          'border border-gray-300 bg-white text-aster-primary hover:border-gray-600',
        text: 'text-aster-secondary hover:text-primary',
        trigger:
          'border border-gray-300 text-aster-secondary bg-white text-aster-secondary focus:outline-none focus:border-gray-900 focus:shadow-elevation1 hover:border-gray-600',
        ghost: 'hover:bg-neutral-100 hover:text-neutral-900',
        link: 'text-aster-secondary underline-offset-2 hover:underline decoration-aster-secondary',
        'danger-link':
          'text-red-600 underline-offset-2 underline decoration-red-600 hover:text-red-800 hover:decoration-red-800',
      },
      size: {
        default: 'py-3 px-5 rounded-5xl font-semibold',
        trigger: 'p-2 rounded-md text-sm',
        sm: 'rounded px-3 py-2 text-xs font-semibold',
        icon: 'h-9 w-9',
        link: 'p-0 text-sm',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
