import { Amplify } from 'aws-amplify';

export function configureAmplify() {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: import.meta.env.VITE_USER_POOL_ID,
        userPoolClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
        identityPoolId: import.meta.env.VITE_IDENTITY_POOL_ID,
      },
    },
    Storage: {
      S3: {
        region: 'us-east-1',
        bucket: import.meta.env.VITE_DOCUMENTS_BUCKET,
      },
    },
  });
}
