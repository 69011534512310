const Forms = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 17 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.255 2.97059C15.3065 2.97059 16.1962 3.86029 16.1962 4.91176V19.1471C16.1962 20.239 15.3065 21.0882 14.255 21.0882H2.60792C1.51601 21.0882 0.666748 20.239 0.666748 19.1471V4.91176C0.666748 3.86029 1.51601 2.97059 2.60792 2.97059H6.16675C6.16675 2.88971 6.16675 2.76838 6.16675 2.64706C6.16675 1.43382 7.17778 0.382352 8.43145 0.382352C9.64469 0.382352 10.6962 1.43382 10.6962 2.64706C10.6962 2.76838 10.6557 2.88971 10.6557 2.97059H14.255ZM8.43145 1.67647C7.86528 1.67647 7.46087 2.12132 7.46087 2.64706C7.46087 3.21323 7.86528 3.61765 8.43145 3.61765C8.95719 3.61765 9.40204 3.21323 9.40204 2.64706C9.40204 2.12132 8.95719 1.67647 8.43145 1.67647ZM14.902 19.1471V4.91176C14.902 4.58823 14.5785 4.26471 14.255 4.26471H12.3138V5.07353C12.3138 5.35662 12.0712 5.55882 11.8285 5.55882H5.0344C4.75131 5.55882 4.5491 5.35662 4.5491 5.07353V4.26471H2.60792C2.24395 4.26471 1.96087 4.58823 1.96087 4.91176V19.1471C1.96087 19.511 2.24395 19.7941 2.60792 19.7941H14.255C14.5785 19.7941 14.902 19.511 14.902 19.1471ZM5.19616 13.6471C5.7219 13.6471 6.16675 14.0919 6.16675 14.6176C6.16675 15.1838 5.7219 15.5882 5.19616 15.5882C4.62998 15.5882 4.22557 15.1838 4.22557 14.6176C4.22557 14.0919 4.62998 13.6471 5.19616 13.6471ZM11.9903 13.9706C12.152 13.9706 12.3138 14.1324 12.3138 14.2941V14.9412C12.3138 15.1434 12.152 15.2647 11.9903 15.2647H7.46087C7.25866 15.2647 7.13734 15.1434 7.13734 14.9412V14.2941C7.13734 14.1324 7.25866 13.9706 7.46087 13.9706H11.9903ZM5.76234 11.3419C5.68145 11.4228 5.51969 11.4228 5.43881 11.3419L3.94248 9.80515C3.8616 9.72426 3.8616 9.60294 3.94248 9.52206L4.46822 8.99632C4.5491 8.91544 4.67042 8.91544 4.79175 8.99632L5.60057 9.84559L7.54175 7.94485C7.62263 7.86397 7.74395 7.86397 7.82484 7.94485L8.35057 8.47059C8.43145 8.55147 8.43145 8.67279 8.35057 8.75368L5.76234 11.3419ZM11.9903 10.0882C12.152 10.0882 12.3138 10.25 12.3138 10.4118V11.0588C12.3138 11.261 12.152 11.3824 11.9903 11.3824H7.54175L8.83587 10.0882H11.9903Z"
        fill="#586068"
      />
    </svg>
  );
};

export default Forms;
