import { datadogRum } from '@datadog/browser-rum';

const APPLICATION_ID = 'b80401a4-9f3f-42ea-b19a-110aab8261ef';
const CLIENT_TOKEN = 'pubbffd52b9886cfe58691fa77061d59a6d';
const SITE = 'datadoghq.com';
const SERVICE = 'app.astercare';

export function initDatadogRum() {
  if (
    import.meta.env.VITE_STAGE === 'production' ||
    import.meta.env.VITE_STAGE === 'staging'
  ) {
    datadogRum.init({
      applicationId: APPLICATION_ID,
      clientToken: CLIENT_TOKEN,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: SITE,
      service: SERVICE,
      env: import.meta.env.VITE_STAGE,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: '0.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }
}
