import { useMapsLibrary } from '@vis.gl/react-google-maps';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import BasicTextfield, {
  CustomTextFieldProps,
} from '../../../../src/components/Textfield';

type GoogleAutoCompleteProps = {
  selectedAutocomplete: (
    place: google.maps.places.PlaceResult,
    ref: React.RefObject<HTMLInputElement>
  ) => void;
  textFieldProps?: CustomTextFieldProps;
};

export const GoogleAutoComplete = forwardRef<
  HTMLInputElement,
  GoogleAutoCompleteProps
>((props, forwardedRef) => {
  const { selectedAutocomplete, textFieldProps } = props;
  const ref = useRef<HTMLInputElement>(null);
  useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement);

  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const places = useMapsLibrary('places');

  useEffect(() => {
    if (!places || !ref.current) return;
    const options = {
      fields: ['address_components'],
    };
    setPlaceAutocomplete(new places.Autocomplete(ref.current, options));
  }, [places, ref]);

  useEffect(() => {
    if (!placeAutocomplete) return;
    placeAutocomplete.addListener('place_changed', () => {
      selectedAutocomplete(placeAutocomplete.getPlace(), ref);
    });
  }, [selectedAutocomplete, placeAutocomplete]);

  return <BasicTextfield {...textFieldProps} inputRef={ref} />;
});
