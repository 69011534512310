import { TabProps } from '@mui/material/Tab';
import Typography from '../../../components/Typography';
import TabsSelectComponent from '../../../components/TabsSelectComponent';
import CollapsibleComponent from '../../../components/CollapsibleComponent';

import dayjs from 'dayjs';
import { PlacentaInfo, MilestoneInfo } from './types/birth.types';
import { cn } from '@aster/client/utils/cn';

const tabsIntegrity: TabProps[] = [
  { label: 'Complete', value: 'complete' },
  { label: 'Incomplete', value: 'incomplete' },
];

const tabsAppearance: TabProps[] = [
  { label: 'Normal', value: 'normal' },
  { label: 'Abnormal', value: 'abnormal' },
];

const tabsMechanism: TabProps[] = [
  { label: 'Schultz', value: 'shultz' },
  { label: 'Duncan', value: 'duncan' },
];

const tabsMembranes: TabProps[] = [
  { label: 'Complete', value: 'complete' },
  { label: 'Incomplete', value: 'incomplete' },
  { label: 'Trailing', value: 'trailing' },
];

const tabsCord: TabProps[] = [
  { label: 'Central', value: 'central' },
  { label: 'Marginal', value: 'marginal' },
  { label: 'Velamentous', value: 'velamentous' },
];

type PlacentaProps = {
  data: PlacentaInfo;
  disabled: boolean;
  handleNote: (note: string) => void;
  handleSave: (property: string, data: PlacentaInfo) => void;
};

const Placenta = ({
  data,
  disabled,
  handleSave,
  handleNote,
}: PlacentaProps) => {
  const newNote = (property: string, value: string) => {
    const note = `Placenta: ${
      property ? `${property}: ` : ''
    } ${value} at ${dayjs(new Date()).format('MM/DD/YYYY HH:mm:ss')}`;
    handleNote(note);
  };

  const handlePlacenta = (property: string, newValue: string) => {
    handleSave('placenta', { ...data, [property]: newValue });
    newNote(property, newValue);
  };

  const handleClick = (name: string, title: string) => {
    handleSave('placenta', { ...data, [name]: dayjs().toISOString() });
    newNote('', title);
  };

  const Milestone = ({ title, date, name }: MilestoneInfo) => {
    return (
      <button
        className={cn(
          'flex justify-center items-center my-2.5 hover:bg-grayBackground rounded-xl w-full',
          disabled ? 'bg-grayCard pointer-events-none' : 'bg-white'
        )}
        onClick={() => handleClick(name, title)}
      >
        <div className="flex flex-grow items-start mx-2 my-3 px-2">
          <Typography
            text={title}
            variant="bodySmall"
            customClass="font-semibold py-1"
          />
        </div>

        <div className="flex items-center justify-center p-2 mr-4 rounded-xl bg-stone-50 text-ellipsis text-bodySmall">
          {date ? dayjs(date).format('MM/DD/YYYY HH:mm') : 'Date/Time'}
        </div>
      </button>
    );
  };

  return (
    <div className="mb-4">
      <CollapsibleComponent label="Placenta">
        <div className="flex flex-col h-full rounded-b-lg bg-grayCard px-2.5">
          <div className="flex flex-row gap-2 justify-between">
            <Milestone
              title="Placenta out"
              name="placentaOut"
              date={data.placentaOut}
            />
            <Milestone title="Cord cut" name="cordCut" date={data.cordCut} />
          </div>
          <TabsSelectComponent
            question="Integrity"
            disabled={disabled}
            tabs={tabsIntegrity}
            value={data.integrity}
            handleChange={handlePlacenta}
            property="integrity"
          />
          <TabsSelectComponent
            question="Appearance"
            disabled={disabled}
            tabs={tabsAppearance}
            value={data.appearance}
            handleChange={handlePlacenta}
            property="appearance"
          />
          <TabsSelectComponent
            question="Mechanism"
            disabled={disabled}
            tabs={tabsMechanism}
            value={data.mechanism}
            handleChange={handlePlacenta}
            property="mechanism"
          />
          <TabsSelectComponent
            question="Membranes"
            disabled={disabled}
            tabs={tabsMembranes}
            value={data.membranes}
            handleChange={handlePlacenta}
            property="membranes"
          />
          <TabsSelectComponent
            question="Cord attach."
            disabled={disabled}
            tabs={tabsCord}
            value={data.cord}
            handleChange={handlePlacenta}
            property="cord"
          />
        </div>
      </CollapsibleComponent>
    </div>
  );
};

export default Placenta;
