import { useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useUserUsagePreferences } from './useUserUsagePreference';

type PaginationModel = {
  page: number;
  pageSize: number;
};

type SortModel = {
  sortBy?: string;
  sortDir?: 'asc' | 'desc' | null;
};

export type UseQueryArgsProps = {
  searchDelay?: number;
  initialPaginationModel?: PaginationModel;
  initialSortModel?: SortModel;
};

const DEFAULT_PAGINATION_MODEL: PaginationModel = {
  page: 0,
  pageSize: 10,
};

const DEFAULT_SORT_MODEL: SortModel = {
  sortBy: 'timeUpdated',
  sortDir: 'desc',
};

const PATIENT_PAGINATION_PREFERENCE_KEY = '__patient-search-pagination__';
const PATIENT_SORT_PREFERENCE_KEY = '__patient-search-sort__';

function useSearchQueryArgs(
  {
    searchDelay = 300,
    initialPaginationModel = DEFAULT_PAGINATION_MODEL,
    initialSortModel = DEFAULT_SORT_MODEL,
  }: UseQueryArgsProps = {},
  useStoredPreferences = false
) {
  const { storePreference, readPreference } = useUserUsagePreferences();
  const [paginationModel, setPaginationModel] = useState(
    useStoredPreferences
      ? readPreference<PaginationModel>(PATIENT_PAGINATION_PREFERENCE_KEY) ??
          initialPaginationModel
      : initialPaginationModel
  );

  const [sortModel, setSortModel] = useState<SortModel>(
    useStoredPreferences
      ? readPreference<SortModel>(PATIENT_SORT_PREFERENCE_KEY) ??
          initialSortModel
      : initialSortModel
  );
  const [searchQuery, setSearchQuery] = useState<string>('');
  const search = useDebouncedCallback((value: string) => {
    setSearchQuery(value);
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
  }, searchDelay);

  const patchedSetPaginationModel = useCallback(
    (newModel: Partial<PaginationModel>) => {
      setPaginationModel((prev) => {
        if (useStoredPreferences) {
          storePreference(PATIENT_PAGINATION_PREFERENCE_KEY, {
            ...prev,
            ...newModel,
          });
        }
        return {
          ...prev,
          ...newModel,
          page: prev.pageSize !== newModel.pageSize ? 0 : newModel.page ?? 0,
        };
      });
    },
    [storePreference, useStoredPreferences]
  );

  const patchedSetSortModel = useCallback(
    (newModel: Partial<SortModel>) => {
      setSortModel((prev) => {
        if (useStoredPreferences) {
          storePreference(PATIENT_SORT_PREFERENCE_KEY, {
            ...prev,
            ...newModel,
          });
        }
        return { ...prev, ...newModel };
      });
    },
    [storePreference, useStoredPreferences]
  );

  return {
    paginationModel,
    setPaginationModel: patchedSetPaginationModel,
    searchQuery,
    search,
    sortModel,
    setSortModel: patchedSetSortModel,
  };
}

export default useSearchQueryArgs;
