import * as React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover/Popover';
import { cn } from '@aster/client/utils';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../Command/Command';
import { Button } from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { PopoverContentProps } from '@radix-ui/react-popover';

interface ComboboxItem {
  value: string;
  label: string;
}

interface ComboboxProps {
  items: ComboboxItem[];
  placeholder: string;
  emptyMessage: string;
  onSelect: (value: string) => void;
  onSearch?: (searchTerm: string) => void;
  className?: string;
  popperClassName?: string;
  loading?: boolean;
  hasError?: boolean;
  popoverAlign?: PopoverContentProps['align'];
  errorMessageId?: string;
}

export function Combobox({
  items,
  placeholder,
  emptyMessage,
  onSelect,
  onSearch,
  className,
  popperClassName,
  loading = false,
  popoverAlign = 'center',
  hasError,
  errorMessageId,
}: ComboboxProps) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="trigger"
          size="trigger"
          role="combobox"
          aria-expanded={open}
          className={cn(
            'w-full justify-between',
            {
              'border-red-600': hasError,
            },
            className
          )}
        >
          {value
            ? items.find((item) => item.value === value)?.label
            : placeholder}
          <FontAwesomeIcon icon={faChevronDown} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align={popoverAlign}
        className={cn('p-0 w-96', popperClassName)}
      >
        <Command>
          <CommandInput
            aria-invalid={hasError}
            aria-errormessage={errorMessageId}
            placeholder={`Search ${placeholder.toLowerCase()}...`}
            onValueChange={onSearch}
          />
          <CommandList>
            <CommandEmpty>
              {loading ? 'Searching...' : emptyMessage}
            </CommandEmpty>
            <CommandGroup>
              {items.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? '' : currentValue);
                    onSelect(currentValue === value ? '' : currentValue);
                    setOpen(false);
                  }}
                >
                  <FontAwesomeIcon
                    className={cn(
                      'mr-2',
                      value === item.value ? 'opacity-100' : 'opacity-0'
                    )}
                    icon={faCheck}
                  />
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
