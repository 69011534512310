import { useSnackbar } from '../../../components/Snack';
import axios from '../../../app/axiosConfig';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';

const ensurePatientActivePregnancyMutation = async (patientID: string) => {
  await axios.post(`/v2/patients/${patientID}/ensure-pregnancy`);
};

export const useEnsureActivePregnancy = (onSuccess?: () => void) => {
  const { showMessage } = useSnackbar();
  const queryClient = useQueryClient();

  const ensurePatientActivePregnancy = useMutation({
    mutationFn: ensurePatientActivePregnancyMutation,
    scope: {
      id: createMutationScopeID(MutationScope.ENCOUNTER), // NOTE - Is it okay to use `ENCOUNTER` as the scope for a note?
    },
    onSuccess: (_data, patientID) => {
      queryClient.invalidateQueries({ queryKey: ['patient', patientID] });
      queryClient.invalidateQueries({
        queryKey: ['patientProfile', patientID],
      });
      queryClient.invalidateQueries({
        queryKey: ['patientForLabs', patientID],
      });
      onSuccess && onSuccess();
      showMessage({
        message: 'Patient active pregnancy created.',
        type: 'success',
      });
    },
    onError: (error) => {
      showMessage({
        message:
          'An error occurred while creating the patient active pregnancy. Please try again.',
        type: 'error',
        autoClose: false,
      });
    },
    retry: 3,
  });

  return { ensurePatientActivePregnancy };
};
