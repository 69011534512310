export type ByteUnit =
  | 'B'
  | 'KB'
  | 'MB'
  | 'GB'
  | 'TB'
  | 'PB'
  | 'EB'
  | 'ZB'
  | 'YB';
const units: ByteUnit[] = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export function convertFromBytes(bytes: number, unit: ByteUnit): number {
  const unitIndex = units.indexOf(unit);
  return bytes / Math.pow(1024, unitIndex);
}

export function convertToBytes(value: number, unit: ByteUnit): number {
  const units: ByteUnit[] = [
    'B',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB',
    'EB',
    'ZB',
    'YB',
  ];
  const unitIndex = units.indexOf(unit);

  return value * Math.pow(1024, unitIndex);
}

export function getBestRepresentation(bytes: number): {
  value: number;
  unit: ByteUnit;
} {
  let unitIndex = 0;
  while (bytes >= 1024 && unitIndex < units.length - 1) {
    bytes /= 1024;
    unitIndex++;
  }
  return { value: Number(bytes.toFixed(2)), unit: units[unitIndex] };
}
