import { PatientInfoDTO } from '../patient';

type PatientMetadata = Pick<
  PatientInfoDTO,
  'id' | 'practiceID' | 'firstName' | 'lastName'
>;

export const RecordType = {
  charge: 'charge',
  payment: 'payment',
};

export const RecordTypeDisplayNames = {
  [RecordType.charge]: 'Charge',
  [RecordType.payment]: 'Payment',
};

export const PaymentType = {
  cash: 'cash',
  credit_card: 'credit_card',
  debit_card: 'debit_card',
  medical_insurance: 'medical_insurance',
  check: 'check',
  bank_transfer_ach_wire: 'bank_transfer_ach_wire',
  other: 'other',
  none: '',
};

export const PaymentTypeDisplayNames = {
  [PaymentType.cash]: 'Cash',
  [PaymentType.credit_card]: 'Credit Card',
  [PaymentType.debit_card]: 'Debit Card',
  [PaymentType.medical_insurance]: 'Medical Insurance',
  [PaymentType.check]: 'Check',
  [PaymentType.bank_transfer_ach_wire]: 'Bank Transfer (ACH/Wire)',
  [PaymentType.other]: 'Other',
};

export type LedgerRecordDTO = {
  id: string;
  practiceID: string;
  patientID: string;
  patientMetadata: PatientMetadata;
  date: string;
  recordType: keyof typeof RecordType;
  paymentType: keyof typeof PaymentType;
  note: string;
  extra: string;
  amount: number;
  adjustment: number;
};

export type GetLedgerRecordsInput = Partial<Pick<LedgerRecordDTO, 'patientID'>>;

export type CreateLedgerRecordInput = Omit<LedgerRecordDTO, 'id' | 'date'>;

export type AddLedgerRecordAdjustmentInput = Pick<
  LedgerRecordDTO,
  'id' | 'patientID' | 'adjustment'
>;
