const Capsules = () => (
  <svg
    width="36"
    height="32"
    viewBox="0 0 36 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="capsules" clip-path="url(#clip0_14979_1796)">
      <path
        id="Primary"
        d="M4.85522 18.0589H14.1313V12.4933C14.1313 9.93075 12.0558 7.85522 9.49327 7.85522C6.93075 7.85522 4.85522 9.93075 4.85522 12.4933V18.0589ZM3 18.9865V12.4933C3 8.90458 5.90458 6 9.49327 6C13.082 6 15.9865 8.90458 15.9865 12.4933V13.8963C16.1605 12.0295 17.1055 10.2438 18.7288 9.0843C21.6681 6.98559 25.7148 7.71608 27.773 10.7134L35.217 21.5722C37.2752 24.5696 36.5621 28.7032 33.6227 30.802C30.6834 32.9007 26.6367 32.1702 24.5785 29.1728L17.1345 18.314C16.4677 17.34 16.0909 16.2443 15.9865 15.137V18.9865V25.4798C15.9865 29.0685 13.082 31.9731 9.49327 31.9731C5.90458 31.9731 3 29.0685 3 25.4798V18.9865ZM14.1313 19.9141H4.85522V25.4798C4.85522 28.0423 6.93075 30.1179 9.49327 30.1179C12.0558 30.1179 14.1313 28.0423 14.1313 25.4798V19.9141ZM26.1091 28.1235C27.5875 30.2744 30.463 30.7788 32.5444 29.2888C34.6489 27.7814 35.1823 24.7899 33.6865 22.6158L30.6312 18.1633L30.5036 18.256L22.9668 23.5434L26.1091 28.1293V28.1235ZM29.4427 16.7371L29.5876 16.6385L26.2424 11.757C24.764 9.60608 21.8885 9.10169 19.8071 10.5917C17.7026 12.099 17.1692 15.0906 18.665 17.2647L21.9174 22.0071L29.4427 16.7371Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_14979_1796">
        <rect width="36" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Capsules;
