import { GridSortModel } from '@mui/x-data-grid/models';
import useSearchQueryArgs, { UseQueryArgsProps } from './useSearchQueryArgs';
import { useMemo } from 'react';

function useDataGridSearchQueryArgs(
  props: UseQueryArgsProps = {},
  useStoredPreferences = false
) {
  const searchQueryArgs = useSearchQueryArgs(props, useStoredPreferences);
  const dataGridSortModel = useMemo(() => {
    return [
      {
        field: searchQueryArgs.sortModel.sortBy,
        sort: searchQueryArgs.sortModel.sortDir,
      },
    ];
  }, [searchQueryArgs.sortModel]);

  const setDataGridSortModel = (sortModel: GridSortModel) => {
    if (!sortModel.length) {
      searchQueryArgs.setSortModel({});
      return;
    }
    const sort = sortModel[0];
    searchQueryArgs.setSortModel({
      sortBy: sort.field,
      sortDir: sort.sort,
    });
    searchQueryArgs.setPaginationModel({ page: 0 });
  };

  return { ...searchQueryArgs, setDataGridSortModel, dataGridSortModel };
}

export default useDataGridSearchQueryArgs;
