import React, { SVGProps } from 'react';

export default function CloseIcon({
  ref,
  ...props
}: SVGProps<HTMLOrSVGElement>) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 12L12 4M4 4L12 12"
        stroke="#586068"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
