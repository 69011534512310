const Signature = () => {
  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.28125 3.625V4.79688C5.28125 5.20703 4.95898 5.5 4.57812 5.5C4.16797 5.5 3.875 5.20703 3.875 4.79688V3.625C3.875 2.07227 5.13477 0.8125 6.6875 0.8125C8.24023 0.8125 9.5 2.07227 9.5 3.625V3.85938C9.5 4.5332 9.44141 5.17773 9.35352 5.82227L13.25 4.62109C13.5137 4.5332 13.8066 4.5918 13.9824 4.79688C14.1582 4.97266 14.2168 5.26562 14.1289 5.5293L13.3086 7.84375H16.2969C16.6777 7.84375 17 8.16602 17 8.54688C17 8.95703 16.6777 9.25 16.2969 9.25H12.3125C12.0781 9.25 11.8438 9.16211 11.7266 8.95703C11.5801 8.78125 11.5508 8.54688 11.6387 8.3125L12.3418 6.37891L9.08984 7.375L9.06055 7.52148C8.70898 9.16211 8.06445 10.7441 7.18555 12.1797L6.74609 12.9414C6.36523 13.5859 5.69141 13.9375 4.98828 13.9375C3.8457 13.9375 2.9375 13.0293 2.9375 11.8867V9.71875C2.9375 8.57617 3.66992 7.58008 4.75391 7.25781L7.88867 6.26172C8.00586 5.4707 8.09375 4.67969 8.09375 3.85938V3.625C8.09375 2.86328 7.44922 2.21875 6.6875 2.21875C5.89648 2.21875 5.28125 2.86328 5.28125 3.625ZM5.16406 8.57617C4.66602 8.75195 4.34375 9.19141 4.34375 9.71875V11.916C4.34375 12.2676 4.60742 12.5605 4.98828 12.5605C5.19336 12.5605 5.39844 12.4141 5.51562 12.2383L5.98438 11.4473C6.6582 10.334 7.18555 9.13281 7.53711 7.84375L5.16406 8.57617ZM2 10.6562V11.8867C2 11.9453 2 12.0039 2 12.0625H0.828125C0.417969 12.0625 0.125 11.7695 0.125 11.3594C0.125 10.9785 0.417969 10.6562 0.828125 10.6562H2ZM8.32812 12.0625C8.5918 11.623 8.82617 11.1543 9.03125 10.6562H18.1719C18.5527 10.6562 18.875 10.9785 18.875 11.3594C18.875 11.7695 18.5527 12.0625 18.1719 12.0625H8.32812Z"
        fill="white"
      />
    </svg>
  );
};

export default Signature;
