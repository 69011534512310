const Prescription = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.40542 2H6.86165C8.31563 2 9.5313 3.19415 9.5313 4.62244C9.5313 6.02732 8.41103 7.17464 7.00465 7.2449L9.5313 9.72681L12.6776 6.61265C12.8207 6.47219 13.0829 6.47219 13.2259 6.61265C13.3689 6.75318 13.3689 7.01073 13.2259 7.15119L10.0557 10.242L13.2259 13.3561C13.3689 13.4966 13.3689 13.7541 13.2259 13.8946C13.0829 14.0351 12.8207 14.0351 12.6776 13.8946L9.5313 10.7805L6.3611 13.8946C6.21808 14.0351 5.95588 14.0351 5.81286 13.8946C5.66985 13.7541 5.66985 13.4966 5.81286 13.3561L8.98306 10.242L5.93205 7.2449H3.42926V9.11807C3.42926 9.3288 3.23857 9.49271 3.04788 9.49271C2.83335 9.49271 2.6665 9.3288 2.6665 9.11807V6.87027V2.74927C2.6665 2.32781 2.97637 2 3.40542 2ZM6.0989 6.49563H6.86165C7.91046 6.49563 8.76854 5.6761 8.76854 4.62244C8.76854 3.5922 7.91046 2.74927 6.86165 2.74927H3.42926V6.49563H6.0989Z"
      fill="#586068"
    />
  </svg>
);

export default Prescription;
