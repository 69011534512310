const Trash = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.53125 1.5C6.375 1.5 6.21875 1.59375 6.125 1.75L5.625 2.5H10.3438L9.84375 1.75C9.75 1.59375 9.59375 1.5 9.4375 1.5H6.53125ZM11.5 2.5H12.9688H14H14.5C14.75 2.5 15 2.75 15 3C15 3.28125 14.75 3.5 14.5 3.5H13.9062L13.125 14.6562C13.0312 15.7188 12.1875 16.5 11.125 16.5H4.84375C3.8125 16.5 2.9375 15.7188 2.84375 14.6562L2.0625 3.5H1.5C1.21875 3.5 1 3.28125 1 3C1 2.75 1.21875 2.5 1.5 2.5H2H3H4.46875L5.28125 1.21875C5.53125 0.78125 6.03125 0.5 6.53125 0.5H9.4375C9.9375 0.5 10.4375 0.78125 10.7188 1.21875L11.5 2.5ZM12.9062 3.5H3.0625L3.84375 14.5938C3.875 15.0938 4.3125 15.5 4.84375 15.5H11.125C11.6562 15.5 12.0938 15.0938 12.125 14.5938L12.9062 3.5Z"
        fill="#223A3B"
      />
    </svg>
  );
};

export default Trash;
