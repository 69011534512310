import { z } from 'zod';
import { CheckListDTO, UpdateCheckListDTO } from '../checklist';
import { SearchArgs } from '../search';

export type StatusFilter = PatientStatus[];
export type GestationalAgeFilter = { min: number; max: number }[];
export type DueDateFilter =
  | 'next-week'
  | 'next-month'
  | 'next-three-months'
  | null;

export type GestationalAgeFilterKeys =
  | 'zero-to-twelve'
  | 'thirteen-to-twentyseven'
  | 'twentyeight-to-forty'
  | 'more-than-forty';

export type PatientSortBy =
  | 'id'
  | 'name'
  | 'mrn'
  | 'email'
  | 'phoneNumber'
  | 'dateOfBirth'
  | 'lastVisit'
  | 'dueDate'
  | 'status'
  | 'timeUpdated'
  | 'gestationalAge';

export type PatientSearchArgsDTO = SearchArgs & {
  statusFilter?: StatusFilter;
  startDueDate?: string;
  endDueDate?: string;
  gestationalAgeFilter?: GestationalAgeFilter;
  endGestationalAge?: number;
  sortBy?: PatientSortBy;
};

export type CreatePatientDTO = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  shouldSendIntakeFormLink: boolean;
};

export type LegalSex = 'male' | 'female' | 'other' | null;

export type PatientInfoDTO = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  mrn: string | null;
  vaccinationNotes: string | null;
  medicationNotes: string | null;
  additionalProblems: string | null;
  importantNotes: string | null;
  preChartNotes: string | null;
  dosespotPatientId: string | null;
  practiceID?: string;
  phoneNumber?: string | null;
  patientType?: string | null;
  middleName?: string | null;
  legalSex?: LegalSex | null;
  preferredName?: string | null;
  genderIdentity?: string | null;
  race?: string | null;
  ethnicity?: string | null;
  preferredLanguage?: string | null;
  pronouns?: string | null;
  dateOfBirth?: string | null;
  streetAddress1?: string | null;
  streetAddress2?: string | null;
  city?: string | null;
  county?: string | null;
  country?: string | null;
  state?: string | null;
  zipCode?: string | null;
  socialSecurityNumber?: string | null;
  relationshipStatus?: string | null;
  partnerName?: string | null;
  insurances?: Array<Partial<PatientInsurance>> | null;
  cashPayOnly?: boolean | null;
  contactList?: Array<Partial<ContactInfo>> | null;
  preferredPharmacyName?: string | null;
  preferredPharmacyPhone?: string | null;
  preferredPharmacyAddress?: string | null;
  hasPeriods?: boolean | null;
  arePeriodsRegular?: boolean | null;
  averagePeriodLength?: number | null;
  averagePeriodCycleLength?: number | null;
  problemsWithPeriods?: MultipleChoiceQuestion[] | null;
  sexuallyActive?: 'yes' | 'no' | 'never' | null;
  sexuallyActiveWith?: MultipleChoiceQuestion[] | null;
  sexualActivityType?: MultipleChoiceQuestion[] | null;
  ageOfFirstSexualActivity?: number | null;
  sexualPartnersNumber?: '0' | '1' | '2+' | null;
  sexualProtectionType?: MultipleChoiceQuestion[] | null;
  lastPapTestDate?: string | null;
  lastPapTestResult?: string | null;
  papAbnormalityHistoryAndTreatment?: string | null;
  lastSTIScreeningDate?: string | null;
  previousInfectionDiseasesOrSTI?: MultipleChoiceQuestion[] | null;
  lastMammogramDate?: string | null;
  mammogramAbnormalityHistoryAndTreatment?: string | null;
  pregnant?: boolean | null;
  lmp?: string | null;
  edd?: string | null;
  dateToCalculateDueDate?: 'edd' | 'lmp' | 'dateOfConception' | null;
  dateOfConception?: string | null;
  unsureDueDate?: boolean | null;
  hadUltrasounds?: boolean | null;
  ultraSoundDetails?: string | null;
  howDidFindPractice?:
    | 'friend'
    | 'family'
    | 'internet_search'
    | 'community'
    | 'social_media'
    | 'healthcare_provider'
    | 'other'
    | null;
  practiceFindingDetails?: string | null;
  hasChronicMedicalConditions?: boolean | null;
  chronicMedicalConditions?: MultipleChoiceQuestion[] | null;
  hasCurrentMentalHealthProblemOrDiagnosis?: boolean | null;
  currentMentalHealthProblemOrDiagnosisDetails?:
    | MultipleChoiceQuestion[]
    | null;
  hasBloodBorneIllness?: boolean | null;
  bloodBorneIllnessDetails?: MultipleChoiceQuestion[] | null;
  hasPreviousAbdominalSurgery?: boolean | null;
  abdominalSurgeryDetails?: string | null;
  isCurrentlyTakingMedicationsOrSuplements?: boolean | null;
  currentMedicationsOrSupplements?: string | null;
  currentMedicationsOrSupplementsList?: Array<
    Partial<MedicationDetails>
  > | null;
  hasAllergies?: boolean | null;
  allergies?: string | null;
  allergyList?: Array<AllergyDetails> | null;
  hasFamilyMedicalConditions?: boolean | null;
  familyMedicalConditions?: MultipleChoiceQuestion[] | null;
  usesTobacco?:
    | 'yes'
    | 'no'
    | 'quit-1-plus-year-ago'
    | 'quite-under-1-year-ago'
    | null;
  tobaccoOrCigarettesPerDay?: number | null;
  consumesAlcohol?: boolean | null;
  alcoholUseDetails?: string | null;
  consumesRecreationalDrugs?: boolean | null;
  recreationalDrugUseDetails?: string | null;
  hasHealthyDiet?: 'no' | 'somewhat' | 'mostly' | 'yes' | null;
  hasDieataryRequirements?: string | null;
  practiceRegularExercise?: boolean | null;
  regularExerciseType?: MultipleChoiceQuestion[] | null;
  regularExerciseHoursPerWeek?: number | null;
  hasSocialSupport?: 'yes' | 'sometimes' | 'no' | null;
  employmentStatus?:
    | 'full-time'
    | 'part-time'
    | 'unemployed'
    | 'full-time-student'
    | 'part-time-student'
    | 'retired'
    | null;
  doFeelSafe?: 'yes' | 'somewhat' | 'no' | null;
  doFeelFinanciallySecure?: 'yes' | 'for_now' | 'no' | null;
  doHaveAdequateTransportation?: 'yes' | 'sometimes' | 'no' | null;
  housingSituation?:
    | 'has_housing'
    | 'not_has_housing'
    | 'worried_about_future'
    | 'staying_with_family_or_friends'
    | 'other'
    | null;
  doHaveEnoughHealthyFood?: boolean | null;
  lackOfHealthyFoodDetails?: string | null;
  nationalOrLocalPrograms?: MultipleChoiceQuestion[] | null;
  doFeelSafeOnCurrentRelationship?: 'yes' | 'somewhat' | 'no' | 'na' | null;
  isPartnerControlling?: 'yes' | 'somewhat' | 'no' | 'na' | null;
  doHaveConcernsAboutChildSafety?: 'yes' | 'somewhat' | 'no' | 'na' | null;
  hasPartnerForcedSexually?: 'yes' | 'somewhat' | 'no' | 'na' | null;
  hasPartnerEverThreatenedOrMadeFeelUnsafe?:
    | 'yes'
    | 'somewhat'
    | 'no'
    | 'na'
    | null;
  hasPreviousPartnerAbusiveExperience?: boolean | null;
  previousPartnerAbusiveExperienceDetails?: string | null;
  preferredCommunicationMethods?: MultipleChoiceQuestion[] | null;
  specialNeeds?: MultipleChoiceQuestion[] | null;
  additionalInfo?: string | null;
  externalID?: string | null;
  externalImportedData?: Record<string, any> | null;
  pregnancies?: {
    pregnancy: Partial<PregnancyInfoDTO> | null;
    children: ChildInfoDTO[];
  }[];
  status: PatientStatus;
};

export type PregnancyInfoDTO = {
  id: string;
  patientID: string;
  isActive: boolean;
  dateOfBirthOrEndOfPregnancy: string;
  amountOfGestationWeeks: number;
  pregnancyOutcome:
    | 'sab'
    | 'tab'
    | 'spontaneous_vaginal_birth'
    | 'assisted_vaginal_birth'
    | 'cesarean_section';
  cesareanSectionUrgencyType: 'elective' | 'emergency';
  cesareanSectionScarringType: 'transverse' | 'vertical' | 't-shaped';
  procedureNeededForSABorTAB: MultipleChoiceQuestion[];
  complications: string;
  locationOfDelivery: 'home' | 'birth_center' | 'hospital' | 'other';
  locationOfDeliveryOther: string;
};

export type ChildInfoDTO = {
  id: string;
  childPatientRecordID?: string;
  pregnancyID: string;
  name: string;
  isLiving: boolean;
  sex: 'female' | 'male' | 'other';
};

export type PregnancyWithChildrenDTO = PregnancyInfoDTO & {
  children: ChildInfoDTO[];
};

export type PregnancyWithChildrenForPatientUpdateDTO = {
  pregnancy: Partial<PregnancyInfoDTO>;
  children: Partial<ChildInfoDTO>[];
};

export type UpdatePatientDTO = Partial<
  PatientInfoDTO & PregnancyWithChildrenForPatientUpdateDTO
>;

export type PatientListItemDTO = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  id: string;
  address: string | null;
  patientType: string | null;
  status: PatientStatus;
  city: string | null;
  state: string | null;
  zip: string | null;
  legalSex: LegalSex;
  dateOfBirth: string | null;
  lastVisit: string | null;
  mrn: string | null;
  doseSpotPatientId: string | null;
  calculatedDueDate: string | null;
  gestationalAge: string | null;
};

export type GTPALDTO = {
  gravida: number;
  termBirths: number;
  pretermBirths: number;
  abortionsAndMiscarriages: number;
  livingChildren: number;
};

export type PreprocessedPatientProfileForEncounterDTO = {
  patientID: string;
  email: string;
  phoneNumber?: string | null;
  mrn: string;
  name: string;
  dateOfBirth?: string | null;
  age?: number | null;
  additionalProblems: string | null;
  importantNotes: string | null;
  vaccinationNotes: string | null;
  medicationNotes: string | null;
  preChartNotes: string | null;
  checklist: CheckListDTO | null;
  problemList: PatientProblem[];
  gtpal: GTPALDTO;
  isPregnant: boolean | null;
  city: string | null;
  state: string | null;
  streetAddress1: string | null;
  zipCode: string | null;
  lmp: string | null;
  calculatedDueDate: string | null;
  gestationalAge: string | null;
  status: PatientStatus;
};

export type PatientProblem = {
  name: string;
  abbr: string;
};

export type PatientProfileForEncounterDTO =
  PreprocessedPatientProfileForEncounterDTO & {
    checklist: CheckListDTO | null;
  };

export type UpdatePatientProfileDTO = Omit<
  Partial<PatientProfileForEncounterDTO>,
  'checklist'
> & {
  checklist?: UpdateCheckListDTO | null;
};

export type MultipleChoiceQuestion = {
  value: string;
  checked: boolean;
};

export type PatientInsurance = {
  insuranceProvider: string;
  insuranceNumber: string;
  insuranceGroupId: string;
  useForBaby: boolean;
};

export type ContactInfo = {
  name: string;
  phoneNumber: string;
  email: string;
  type: string;
  relationship?: string;
  notes: string;
};

export type MedicationDetails = {
  name: string;
  frequency: string;
  dosage: string;
  method: string;
  purpose: string;
  startDate: string | null;
  endDate: string | null;
};

export type AllergyDetails = {
  name: string;
  reaction: string;
};

export const ExternalImportedData = z
  .object({
    source: z.string(),
    gravidaPara: z.string(),
    gestationalAge: z.string(),
    lastAppointment: z.string(),
    upcomingAppointment: z.string(),
    createdDate: z.string(),
    amountDue: z.string(),
  })
  .partial()
  .passthrough();

export type ExternalImportedData = z.infer<typeof ExternalImportedData>;

export type PatientStatus = 'active' | 'archived' | 'prospective';
export const PatientValidStatuses = ['active', 'archived', 'prospective'];
export const PatientActiveStatuses: PatientStatus[] = ['active', 'prospective'];

export type PatientForLabsDTO = {
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  dateOfBirth: string | null;
  legalSex: 'male' | 'female' | 'other' | null;
  streetAddress1: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
};
