import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';
import { useSnackbar } from '../../../components/Snack';

export const deletePatient = async (patientID: string) => {
  const response = await axios.delete(`/v2/patients/${patientID}`);
  return response.data;
};

export function useDeletePatientMutation(onSuccess?: () => void) {
  const { showMessage } = useSnackbar();
  const queryClient = useQueryClient();

  const deletePatientMutation = useMutation({
    mutationKey: ['deletePatient'],
    mutationFn: deletePatient,
    onError: () => {
      showMessage({
        type: 'error',
        message: 'An error occurred while deleting the patient',
      });
    },
    onSuccess: () => {
      showMessage({
        type: 'success',
        message: `The patient was successfuly archived`,
      });
      queryClient.invalidateQueries({ queryKey: ['patients'] });
      onSuccess && onSuccess();
    },
  });

  return { deletePatientMutation };
}
