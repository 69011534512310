import { ChangeEvent, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import StyledDataGrid from '../../../components/StyledDataGrid';
import BasicTextfield from '../../../components/Textfield';
import CollapsibleComponent from '../../../components/CollapsibleComponent';
import ButtonType from '../../../components/Button';
import { colors } from '../../../theme';
import dayjs from 'dayjs';
import { FluidInfo } from './types/birth.types';

type FluidsProps = {
  data: FluidInfo[];
  disabled: boolean;
  handleNote: (note: string) => void;
  handleSave: (property: string, value: FluidInfo[]) => void;
};

const Fluids = ({ data, disabled, handleSave, handleNote }: FluidsProps) => {
  const rows = data || [];

  const [fluid, setFluid] = useState<FluidInfo>({});

  const handleFluidInput = (event: ChangeEvent<HTMLInputElement>) => {
    setFluid((prevFluid) => ({
      ...prevFluid,
      [event.target.name]: event.target.value,
    }));
  };

  const createNote = () => {
    const note = `Fluid balance: 
      ${fluid.input ? `fluid input was ${fluid.input},` : ''}
      ${fluid.type ? `type ${fluid.type},` : ''}
      ${fluid.output ? `and the urine output was ${fluid.output},` : ''}
     at ${dayjs(new Date()).format('HH:mm:ss')}.`;
    handleNote(note);
  };

  const saveFluid = () => {
    const newResult: FluidInfo = {
      id: rows.length + 1,
      hour: dayjs(new Date()).format('HH:mm:ss'),
      input: fluid.input,
      type: fluid.type,
      output: fluid.output,
    };
    handleSave('fluids', [...rows, newResult]);
    createNote();
    setFluid({
      input: '',
      type: '',
      output: '',
    });
  };

  const fluidColumns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: 'hour',
      headerName: 'Hour',
      headerAlign: 'left',
      flex: 1.5,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'input',
      headerName: 'Input',
      headerAlign: 'left',
      flex: 1.5,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'type',
      headerName: 'Type',
      headerAlign: 'left',
      flex: 1.5,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'output',
      headerName: 'Urine output',
      headerAlign: 'left',
      flex: 1.5,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
  ];

  return (
    <CollapsibleComponent label="Fluid balance">
      <div className="flex flex-col w-full rounded-b-lg bg-grayCard px-2.5">
        <div className="flex flex-col w-full">
          {!disabled && (
            <>
              <div className="grid grid-cols-2 gap-4 mt-2">
                <BasicTextfield
                  id="fluid"
                  variant="filled"
                  name="input"
                  label="Fluid input"
                  value={fluid.input}
                  onChange={handleFluidInput}
                />
                <BasicTextfield
                  id="type"
                  variant="filled"
                  name="type"
                  label="Type"
                  value={fluid.type}
                  onChange={handleFluidInput}
                />
                <BasicTextfield
                  containerClasses="col-span-2"
                  id="urine"
                  variant="filled"
                  name="output"
                  label="Urine Output"
                  value={fluid.output}
                  onChange={handleFluidInput}
                />
              </div>
              <ButtonType
                variant="contained"
                onClick={saveFluid}
                text="Add Fluids"
                classes={'max-h-[30px] mt-2'}
              />
            </>
          )}

          <StyledDataGrid
            rows={rows}
            columns={fluidColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            autoHeight
            slots={{
              noRowsOverlay: () => (
                <div className="flex justify-center items-center h-full">
                  No fluid balance yet.
                </div>
              ),
            }}
            sx={{
              backgroundColor: colors.white,
              overflow: 'auto',
              fontSize: '12px',
              textAlign: 'center',
              marginTop: 2,
              marginBottom: 2,
            }}
          />
        </div>
      </div>
    </CollapsibleComponent>
  );
};

export default Fluids;
