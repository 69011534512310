import * as React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { cn } from '@aster/client/utils/cn';

type ButtonVariant = 'contained' | 'outlined' | 'text';

type ButtonsProps = {
  variant: ButtonVariant;
  text: string;
  icon?: boolean;
  classes?: any;
  notRounded?: boolean;
  specificIcon?: any;
  loading?: boolean;
  startIcon?: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonType = React.forwardRef(
  (
    {
      variant,
      text,
      icon,
      onClick,
      classes,
      className,
      notRounded,
      disabled,
      specificIcon,
      startIcon,
      type,
      loading,
    }: ButtonsProps,
    ref
  ) => {
    return (
      <Button
        ref={ref as React.RefObject<HTMLButtonElement>}
        type={type ?? 'button'}
        disableElevation
        startIcon={startIcon}
        variant={variant}
        className={cn(
          `          
            px-[15px]  
            h-[40px]
            min-w-fit
            text-body
            font-semibold
            bg-transparent
            rounded-full
            !normal-case
          `,
          {
            'bg-aster-main hover:bg-primary-dark': variant === 'contained',
            'border-gray-300 bg-white text-aster-secondary hover:text-primary hover:border-gray-600 hover:bg-gray-50':
              variant === 'outlined',
            'rounded-md': notRounded,
          },
          classes,
          className
        )}
        onClick={onClick}
        disabled={disabled}
      >
        {loading && (
          <CircularProgress
            className={cn(variant === 'contained' && 'text-white')}
            size={20}
          />
        )}
        {!loading && icon && specificIcon && specificIcon}
        {!loading && text}
        {!loading && icon && !specificIcon && (
          <span className="ml-3 pt-1">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        )}
      </Button>
    );
  }
);

export default ButtonType;
