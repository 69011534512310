import React, { useState } from 'react';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { Snackbar } from '@mui/material';
import {
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  FilledInput,
  styled,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AsterLogo from '../assets/aster';
import { colors } from '../theme';
import BasicTextfield from '../components/Textfield';
import { useNavigate } from 'react-router-dom';

const CssInput = styled(FilledInput)({
  '&.MuiFilledInput-root': {
    color: colors.gray,
    overflow: 'hidden',
    borderRadius: '10px',
    backgroundColor: colors.white,
    border: '1px solid',
    width: '100%',
    borderColor: colors.grayLight,
    '&:hover': {
      backgroundColor: colors.white,
    },
    '&.Mui-focused': {
      backgroundColor: colors.white,
      boxShadow: colors.grayLight,
    },
    '&.Mui-error': {
      borderColor: colors.red,
    },
    '&.MuiFormLabel-root': {
      color: colors.primary,
      fontWeight: 600,
    },
  },
});

const CssLabel = styled(InputLabel)({
  '&.MuiInputLabel-root': {
    '&.Mui-focused': {
      fontWeight: 600,
      color: colors.primary,
    },
    '&.Mui-error': {
      color: colors.red,
    },
  },
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [state, setState] = useState({
    username: '',
    password: '',
    code: '',
  });

  const handleFormInput = (event: any) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };
  const { code, username, password } = state;

  const handleForgotPwd = (event: any) => {
    setLoading(true);
    event.preventDefault();
    const user = username || localStorage.getItem('user');
    confirmResetPassword({
      username: user || '',
      confirmationCode: code,
      newPassword: password,
    })
      .then(() => {
        navigate('/signin');
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  const resendCode = (event: any) => {
    event.preventDefault();
    setError(undefined);
    if (username) {
      localStorage.setItem('user', username);
      resetPassword({ username })
        .then(() => {
          setReset(true);
          setOpenSnackbar(true);
        })
        .catch((err) => {
          setError(err.message);
          setReset(false);
        });
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <div className="flex flex-col items-center h-screen w-screen pt-8 md:pt-32 bg-grayBackground">
      <div className="align-middle mb-10">
        <AsterLogo />
      </div>
      {!reset && (
        <>
          <div className="flex flex-col items-center  lg:w-1/3 sm:w-full  min-h-min rounded-[20px] bg-white px-11 max-w-[480px]">
            <div className="text-h4 mt-6 text-primary font-semibold">
              Reset Password
            </div>
            <div className="text-body text-gray-500 mb-9">
              Enter your work email
            </div>
            <BasicTextfield
              id="email"
              variant="filled"
              label="Work email"
              name="username"
              value={state.username}
              onChange={handleFormInput}
              error={error}
              classes={`my-6`}
            />
            {error && (
              <div className="grid grid-cols-6 w-full">
                <p className="text-red-500 !content-start col-span-5">
                  {' '}
                  {error}
                </p>
              </div>
            )}
            <button
              className="bg-primary mt-6 py-3 px-12 rounded-full text-white mb-10"
              disabled={loading}
              onClick={resendCode}
            >
              {loading && (
                <i className="fa fa-spinner fa-spin py-5 text-white"></i>
              )}
              {!loading && <p>Send code</p>}
            </button>
          </div>
          <div className="text-center sm:text-left bg-grayBackground my-4 sm:my-6">
            <span
              className="text-secondary cursor-pointer"
              onClick={() => navigate('/signin')}
            >
              Back to sign in
            </span>
          </div>
        </>
      )}
      {reset && (
        <>
          <div className="flex flex-col items-center  lg:w-1/3 sm:w-full  min-h-min rounded-[20px] bg-white px-11 max-w-[480px]">
            <div className="text-h4 mt-6 text-primary font-semibold">
              Reset Password
            </div>
            <div className="text-body text-gray-500 mb-9">
              Enter the code sent to your email address
            </div>
            <BasicTextfield
              id="code"
              variant="filled"
              label="Verification Code"
              name="code"
              value={state.code}
              onChange={handleFormInput}
              error={error}
              classes={`my-6`}
            />

            <FormControl variant="filled" fullWidth>
              <CssLabel htmlFor="filled-adornment-password" error={error}>
                Password
              </CssLabel>
              <CssInput
                id="filled-adornment-password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                disableUnderline={true}
                value={state.password}
                onChange={handleFormInput}
                error={error}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {error && (
              <div className="grid grid-cols-6 w-full">
                <p className="text-red-500 !content-start col-span-5">
                  {error}
                </p>
              </div>
            )}

            <button
              className="bg-primary mt-6 px-12 py-3 rounded-full text-white mb-10 hover:opacity-80"
              disabled={loading}
              onClick={handleForgotPwd}
            >
              {loading && (
                <i className="fa fa-spinner fa-spin px-5 text-white"></i>
              )}
              {!loading && <p>Submit</p>}
            </button>
          </div>
          <div className="text-center sm:text-left bg-grayBackground my-4 sm:my-6">
            <span
              className="text-secondary cursor-pointer hover:opacity-80"
              onClick={resendCode}
            >
              Resend code
            </span>
          </div>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={openSnackbar}
            onClose={() => setOpenSnackbar(false)}
            autoHideDuration={3000}
            message="Code has been resent!"
            sx={{
              backgroundColor: colors.gray,
              color: colors.white,
            }}
          />
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
