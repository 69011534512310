const UrineIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
  >
    <path
      d="M10.0082 9.16522L5.94649 11.9436C5.89008 11.9859 5.81956 12 5.74905 12C5.65033 12 5.5375 11.9577 5.48109 11.873L1.27832 6.66895H8.93638L10.107 8.69981C10.2057 8.86905 10.1493 9.0665 10.0082 9.16522Z"
      fill="#D97706"
    />
    <path
      d="M10.0086 9.16531L5.94689 11.9436C5.89047 11.986 5.81996 12.0001 5.74944 12.0001C5.65072 12.0001 5.53789 11.9578 5.48148 11.8731L1.27871 6.66903L0.079936 5.18819C-0.0469932 5.03306 -0.0187867 4.82151 0.136349 4.69458C0.291485 4.56765 0.503033 4.59586 0.629963 4.75099L2.18132 6.68314L5.81996 11.1962L9.33167 8.78452L8.11879 6.68314L5.21352 1.60597C5.11479 1.43673 5.17121 1.22518 5.34045 1.12646C5.50969 1.02774 5.72123 1.08415 5.81996 1.25339L8.93677 6.66903L10.1073 8.6999C10.2061 8.86914 10.1497 9.06659 10.0086 9.16531Z"
      fill="#D97706"
    />
    <path
      d="M4.04285 5.07527C3.88771 5.07527 3.76078 4.97655 3.70437 4.83552C3.53513 4.3137 3.35179 3.82009 3.15434 3.34058C3.08383 3.15723 3.15434 2.95979 3.33769 2.87517C3.52103 2.80465 3.71847 2.87517 3.80309 3.05851C4.01464 3.55212 4.21209 4.07394 4.38133 4.62397C4.43774 4.80731 4.33902 5.00476 4.15567 5.06117C4.11336 5.06117 4.07105 5.07527 4.04285 5.07527Z"
      fill="#D97706"
    />
    <path
      d="M2.7597 2.08539C2.63277 2.08539 2.51995 2.01487 2.44943 1.90205C2.18147 1.43664 1.89941 0.985337 1.58913 0.56224C1.47631 0.407104 1.50451 0.181452 1.65965 0.0686264C1.81479 -0.0441996 2.04044 -0.0159931 2.15326 0.139143C2.47764 0.576343 2.77381 1.05585 3.05587 1.54947C3.15459 1.71871 3.08408 1.93025 2.92894 2.02898C2.87253 2.07129 2.81612 2.08539 2.7597 2.08539Z"
      fill="#D97706"
    />
    <path
      d="M4.64937 7.48695C4.48013 7.48695 4.3391 7.37412 4.31089 7.20488C4.25448 6.93692 4.19807 6.65486 4.14165 6.3869C4.09934 6.20355 4.21217 6.00611 4.40962 5.9638C4.59296 5.92149 4.7904 6.03431 4.83271 6.23176C4.88913 6.49972 4.95964 6.78179 5.00195 7.06385C5.04426 7.2613 4.91733 7.44464 4.71989 7.47285C4.69168 7.47285 4.66347 7.48695 4.64937 7.48695Z"
      fill="#D97706"
    />
  </svg>
);

export default UrineIcon;
