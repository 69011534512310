import { useState } from 'react';
import Typography from '../../../components/Typography';
import MultipleCheckboxes from '../../../components/MultipleCheckboxes';
import { colors } from '../../../theme';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from '@mui/x-date-pickers/DateField';

import { ObstetricHistoryInfo, Pregnancy } from '../types/patient-profile';
import {
  TextField,
  Tabs,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { CheckboxItem } from '../types/question';
import {
  defaultPregnancyInfo,
  defaultProcedureNeededForSABorTAB,
} from '../defaults';
import { mergeCheckboxLists } from '../utils/merge-checkbox-lists';
import PregnancyChildrenInfo from './PregnancyChildrenInfo';
import { StyledTab } from '../../../components/StyledTab';

type ObstetricInfoProps = {
  obstetricInfo: ObstetricHistoryInfo;
  handlePregnanciesInformation: (event: any) => void;
  readonly: boolean;
};

function mergePregnanciesWithDefault(pregnancies: Pregnancy[]) {
  return pregnancies.map((pregnancy) => {
    return {
      ...pregnancy,
      pregnancy: {
        ...defaultPregnancyInfo.pregnancy,
        ...pregnancy.pregnancy,
        procedureNeededForSABorTAB: mergeCheckboxLists(
          defaultProcedureNeededForSABorTAB,
          pregnancy.pregnancy.procedureNeededForSABorTAB ?? []
        ),
      },
    };
  });
}

const abortionOrMiscarriageOptions = ['sab', 'tab'];

const isAbortionOrMiscarriage = (value?: string) => {
  if (!value) return false;
  return abortionOrMiscarriageOptions.includes(value);
};

const ObstetricInformation = (props: ObstetricInfoProps) => {
  const { pregnancies } = props.obstetricInfo;
  const [numTabs, setNumTabs] = useState<number>(pregnancies?.length ?? 0);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [tabInputs, setTabInputs] = useState<Pregnancy[]>(
    (pregnancies?.length ?? 0) > 0
      ? mergePregnanciesWithDefault(pregnancies)
      : Array.from({ length: numTabs }, () => defaultPregnancyInfo)
  );

  const handleChildrenInformation = props.handlePregnanciesInformation;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleNumTabsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(event.target.value);
    value = isNaN(value) || value < 0 ? 0 : value;
    if (value < (pregnancies?.length ?? 0)) {
      setTabInputs((prevInputs) => {
        const updatedInputs = [...prevInputs];
        updatedInputs.splice(value);
        handleChildrenInformation(updatedInputs);
        setActiveTab(value > 0 ? value - 1 : 0);
        return updatedInputs;
      });
    } else if (value > (pregnancies?.length ?? 0)) {
      setTabInputs((prevInputs) => {
        const updatedInputs = [
          ...prevInputs,
          ...Array.from({ length: value - (pregnancies?.length ?? 0) }, () => ({
            ...structuredClone(defaultPregnancyInfo),
          })),
        ];
        handleChildrenInformation(updatedInputs);
        return updatedInputs;
      });
    }

    setNumTabs(value);
  };

  const handleInputChange = (
    index: number,
    field: keyof Pregnancy['pregnancy'],
    value: any
  ) => {
    setTabInputs((prevInputs) => {
      const updatedInputs = [...prevInputs];
      updatedInputs[index].pregnancy[field] = value;
      handleChildrenInformation(updatedInputs);
      return updatedInputs;
    });
  };

  const handleChildrenChange = (index: number, value: any) => {
    setTabInputs((prevInputs) => {
      const updatedInputs = [...prevInputs];
      updatedInputs[index].children = value;
      handleChildrenInformation(updatedInputs);
      return updatedInputs;
    });
  };

  const handleProcedureNeededForSABorTAB = (index: number, value: string) => {
    setTabInputs((prevInputs) => {
      const updatedInputs = [...prevInputs];
      updatedInputs[index].pregnancy.procedureNeededForSABorTAB = updatedInputs[
        index
      ].pregnancy.procedureNeededForSABorTAB?.map((item: CheckboxItem) =>
        item.value === value ? { ...item, checked: !item.checked } : item
      );
      return updatedInputs;
    });
  };

  const radio = () => {
    return (
      <Radio
        sx={{
          color: colors.gray,
          '&.Mui-checked': {
            color: colors.green,
          },
        }}
      />
    );
  };

  return (
    <div className="container pb-10">
      <Typography
        text="Obstetric History"
        variant="h5"
        customClass="font-semibold mb-5"
      ></Typography>
      <Typography
        text="How many previous pregnancies have you had?"
        variant="body"
        customClass="text-gray-500 font-semibold mb-5"
      />
      <TextField
        label="Number of pregnancies"
        value={numTabs}
        disabled={props.readonly}
        type="number"
        onChange={handleNumTabsChange}
        className="mb-4 w-fit"
      />
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        allowScrollButtonsMobile
        variant="scrollable"
        className="max-w-full"
        indicatorColor="primary"
      >
        {Array.from({ length: numTabs }, (_, index) => (
          <StyledTab key={index} label={`Pregnancy ${index + 1}`} />
        ))}
      </Tabs>
      {Array.from({ length: numTabs }, (_, index) => (
        <div
          key={index}
          style={{ display: activeTab === index ? 'block' : 'none' }}
          className="h-fit bg-grayCard rounded-[10px] px-3 flex flex-col my-2 py-2 pb-4"
        >
          <div>
            <Typography
              text={`Questions for Pregnancy ${index + 1}`}
              variant="h6"
              customClass="mb-4"
            />
            <div className="flex flex-wrap gap-4 mb-5">
              <div className="mb-5">
                <Typography
                  text="What was the outcome of this pregnancy?"
                  variant="body"
                  customClass="text-gray-500 mb-2 font-semibold"
                />

                <RadioGroup
                  aria-label={`pregnancyOutcome-${index}`}
                  name={`pregnancyOutcome-${index}`}
                  value={tabInputs[index].pregnancy.pregnancyOutcome}
                  onChange={(e) =>
                    handleInputChange(index, 'pregnancyOutcome', e.target.value)
                  }
                  row
                >
                  <FormControlLabel
                    value="sab"
                    control={radio()}
                    disabled={props.readonly}
                    label="Spontaneous abortion (SAB) or miscarriage"
                  />
                  <FormControlLabel
                    value="tab"
                    control={radio()}
                    disabled={props.readonly}
                    label="Therapeutic abortion (TAB)"
                  />
                  <FormControlLabel
                    value="spontaneous_vaginal_birth"
                    control={radio()}
                    disabled={props.readonly}
                    label="Normal spontaneous vaginal birth"
                  />
                  <FormControlLabel
                    value="assisted_vaginal_birth"
                    control={radio()}
                    disabled={props.readonly}
                    label="Assisted vaginal birth (e.g., forceps, vacuum)"
                  />
                  <FormControlLabel
                    value="cesarean_section"
                    control={radio()}
                    disabled={props.readonly}
                    label="Cesarean section"
                  />
                </RadioGroup>
                {tabInputs[index].pregnancy.pregnancyOutcome ===
                  'cesarean_section' && (
                  <div className="pl-8">
                    <Typography
                      text="Urgency:"
                      variant="body"
                      customClass="text-gray-500 mt-2"
                    />
                    <RadioGroup
                      aria-label={`cesareanSectionUrgencyType-${index}`}
                      name={`cesareanSectionUrgencyType-${index}`}
                      value={
                        tabInputs[index].pregnancy.cesareanSectionUrgencyType
                      }
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          'cesareanSectionUrgencyType',
                          e.target.value
                        )
                      }
                      row
                    >
                      <FormControlLabel
                        value="elective"
                        control={radio()}
                        disabled={props.readonly}
                        label="Elective"
                      />
                      <FormControlLabel
                        value="emergency"
                        control={radio()}
                        disabled={props.readonly}
                        label="Emergency"
                      />
                    </RadioGroup>
                    <Typography
                      text="Scarring:"
                      variant="body"
                      customClass="text-gray-500"
                    />
                    <RadioGroup
                      aria-label={`cesareanSectionScarringType-${index}`}
                      name={`cesareanSectionScarringType-${index}`}
                      value={
                        tabInputs[index].pregnancy.cesareanSectionScarringType
                      }
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          'cesareanSectionScarringType',
                          e.target.value
                        )
                      }
                      row
                    >
                      <FormControlLabel
                        value="transverse"
                        control={radio()}
                        disabled={props.readonly}
                        label="Transverse"
                      />
                      <FormControlLabel
                        value="vertical"
                        control={radio()}
                        disabled={props.readonly}
                        label="Vertical"
                      />
                      <FormControlLabel
                        value="t-shaped"
                        control={radio()}
                        disabled={props.readonly}
                        label="T-shaped"
                      />
                    </RadioGroup>
                  </div>
                )}
              </div>

              <div>
                <Typography
                  text="Date of birth or end of pregnancy"
                  variant="body"
                  customClass="text-gray-500 mb-2 font-semibold"
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateField
                    label="Date of birth"
                    disabled={props.readonly}
                    format={
                      isAbortionOrMiscarriage(
                        tabInputs[index].pregnancy.pregnancyOutcome
                      )
                        ? 'MM/YYYY'
                        : 'MM/DD/YYYY'
                    }
                    value={
                      tabInputs[index].pregnancy.dateOfBirthOrEndOfPregnancy &&
                      dayjs(
                        tabInputs[index].pregnancy.dateOfBirthOrEndOfPregnancy
                      )
                    }
                    maxDate={dayjs()}
                    sx={{
                      borderRadius: 1,
                    }}
                    onChange={(v) =>
                      handleInputChange(index, 'dateOfBirthOrEndOfPregnancy', v)
                    }
                  />
                </LocalizationProvider>
              </div>

              <div>
                <Typography
                  text="How many weeks in gestation?"
                  variant="body"
                  customClass="text-gray-500 font-semibold"
                />
                <TextField
                  id={`amountOfGestationWeeks-${index}`}
                  label="Weeks"
                  type="number"
                  disabled={props.readonly}
                  value={tabInputs[index].pregnancy.amountOfGestationWeeks}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      'amountOfGestationWeeks',
                      parseInt(e.target.value)
                    )
                  }
                  style={{ marginTop: '8px' }}
                />
              </div>
            </div>

            {tabInputs[index].pregnancy.pregnancyOutcome === 'sab' ||
            tabInputs[index].pregnancy.pregnancyOutcome === 'tab' ? (
              <div className="mb-5">
                <Typography
                  text="Any procedure needed for SAB or TAB?"
                  variant="body"
                  customClass="text-gray-500 mb-2 font-semibold"
                />

                <MultipleCheckboxes
                  aria-label={`procedureNeededForSABorTAB-${index}`}
                  disabled={props.readonly}
                  handleCheckboxChange={(e: any) => {
                    handleProcedureNeededForSABorTAB(index, e);
                  }}
                  items={
                    tabInputs[index].pregnancy.procedureNeededForSABorTAB ??
                    defaultProcedureNeededForSABorTAB
                  }
                />
              </div>
            ) : null}

            <div className="mb-5">
              <Typography
                text="Were there any complications with the pregnancy, delivery, or during the postpartum period?"
                variant="body"
                customClass="text-gray-500 font-semibold pb-2"
              />
              <TextField
                id={`complications-${index}`}
                label="Complications"
                disabled={props.readonly}
                value={tabInputs[index].pregnancy.complications}
                onChange={(e) =>
                  handleInputChange(index, 'complications', e.target.value)
                }
                style={{ marginTop: '8px' }}
              />
            </div>

            <div className="mb-5">
              <Typography
                text="What was the location of the delivery?"
                variant="body"
                customClass="text-gray-500 mb-2 font-semibold"
              />

              <RadioGroup
                aria-label={`locationOfDelivery-${index}`}
                name={`locationOfDelivery-${index}`}
                value={tabInputs[index].pregnancy.locationOfDelivery}
                onChange={(e) =>
                  handleInputChange(index, 'locationOfDelivery', e.target.value)
                }
                row
              >
                <FormControlLabel
                  disabled={props.readonly}
                  value="home"
                  control={radio()}
                  label="Home"
                />
                <FormControlLabel
                  value="birth_center"
                  disabled={props.readonly}
                  control={radio()}
                  label="Birth center"
                />
                <FormControlLabel
                  value="hospital"
                  disabled={props.readonly}
                  control={radio()}
                  label="Hospital"
                />
                <FormControlLabel
                  value="other"
                  disabled={props.readonly}
                  control={radio()}
                  label="Other"
                />
              </RadioGroup>

              {tabInputs[index].pregnancy.locationOfDelivery === 'other' && (
                <TextField
                  id={`otherLocationOfDelivery-${index}`}
                  label="Other location"
                  value={tabInputs[index].pregnancy.locationOfDeliveryOther}
                  disabled={props.readonly}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      'locationOfDeliveryOther',
                      e.target.value
                    )
                  }
                  style={{ marginTop: '8px' }}
                />
              )}
            </div>

            {tabInputs[index].pregnancy.pregnancyOutcome !== 'sab' &&
            tabInputs[index].pregnancy.pregnancyOutcome !== 'tab' ? (
              <PregnancyChildrenInfo
                disabled={props.readonly}
                childrenInfo={tabInputs[index].children ?? []}
                onChildrenInfoChange={(v) => handleChildrenChange(index, v)}
              />
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ObstetricInformation;
