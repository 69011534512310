import SearchInput from '../../../components/SearchInput';
import Typography from '../../../components/Typography';
import { useLabsStore } from '../store/labs.store';
import { LabTests } from './LabTests';

export const SelectOrder = () => {
  const searchQuery = useLabsStore((state) => state.searchQuery);
  const search = useLabsStore((state) => state.search);

  return (
    <section className="flex flex-col mx-6 my-5 overflow-hidden">
      <SearchInput
        value={searchQuery}
        onChange={(e) => {
          search(e.currentTarget.value);
        }}
        placeholder="Search lab tests"
      />
      <Typography
        variant="body"
        text="Available Panels"
        customClass="font-semibold my-4"
      />
      <LabTests />
    </section>
  );
};
