import EFax from '../features/efax/Efax';
import { EFaxSetupProvider } from '../features/efax/EFaxSetupContext';

const EFaxPage = () => {
  return (
    <EFaxSetupProvider>
      <EFax />
    </EFaxSetupProvider>
  );
};

export default EFaxPage;
