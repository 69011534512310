import { useMemo } from 'react';
import Combobox, {
  MultiselectTags,
  Option,
} from '../../../components/Autocomplete';
import { useGetStaffs } from '../queries/use-get-staffs.query';
import useSearchQueryArgs from '../../../hooks/useSearchQueryArgs';
import { useSearchPatientsQuery } from '../../patients/queries/search-patients.query';
import {
  PatientActiveStatuses,
  PatientSortBy,
} from '@aster/shared/dtos/patient';
import { InviteeWithColor } from '../types';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type SelectInviteesProps = {
  invitedPatients: Option[];
  setInvitedPatients: (newPatients: Option[]) => void;
  invitedStaff: Option[];
  setInvitedStaff: (newStaff: Option[]) => void;
  staffColorMap: Record<string, InviteeWithColor>;
};

const SelectInvitees = ({
  invitedPatients,
  setInvitedPatients,
  invitedStaff,
  setInvitedStaff,
  staffColorMap,
}: SelectInviteesProps) => {
  const { paginationModel, searchQuery, search, sortModel } =
    useSearchQueryArgs();
  const { paginatedPatients, arePatientsLoading, arePatientsFetching } =
    useSearchPatientsQuery({
      search: searchQuery,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sortBy: sortModel?.sortBy as PatientSortBy,
      sortDir: sortModel?.sortDir,
      statusFilter: PatientActiveStatuses,
    });
  const { staffMembers, areStaffLoading } = useGetStaffs();
  const patientOptions = useMemo(() => {
    if (
      paginatedPatients?.items &&
      !arePatientsLoading &&
      !arePatientsFetching
    ) {
      return paginatedPatients.items.map((r) => ({
        id: r.id,
        label: `${r.firstName} ${r.lastName}`,
      }));
    }
    return [];
  }, [paginatedPatients?.items, arePatientsLoading, arePatientsFetching]);

  const staffOptions = useMemo(() => {
    if (staffMembers && !areStaffLoading) {
      return staffMembers?.map((r) => ({
        id: r.id,
        label: `${r.firstName} ${r.lastName}`,
        color: staffColorMap[r.id]?.color,
      }));
    }
    return [];
  }, [areStaffLoading, staffMembers]);
  return (
    <>
      <Combobox
        // freeSolo silences gigantic console warning
        freeSolo
        icon={
          <FontAwesomeIcon
            icon={faAngleDown}
            style={{ fontSize: 12, marginRight: 10 }}
          />
        }
        autoCompleteStyles={{
          '& .MuiAutocomplete-popupIndicator': {
            transform: 'none',
          },
        }}
        multiple
        label="Patients"
        value={invitedPatients}
        isServerSide={true}
        loading={arePatientsLoading || arePatientsFetching}
        onChange={(_, newValue) => {
          setInvitedPatients(newValue as Option[]);
        }}
        renderTags={(value, getTagProps) => (
          <MultiselectTags value={value} getTagProps={getTagProps} />
        )}
        onInputChange={(_: any, newInputValue: string) => {
          search(newInputValue);
        }}
        isOptionEqualToValue={(option, value) =>
          (option as Option).id === (value as Option).id
        }
        options={patientOptions}
        width={'100%'}
      />
      <Combobox
        // freeSolo silences gigantic console warning
        freeSolo
        icon={
          <FontAwesomeIcon
            icon={faAngleDown}
            style={{ fontSize: 12, marginRight: 10 }}
          />
        }
        autoCompleteStyles={{
          '& .MuiAutocomplete-popupIndicator': {
            transform: 'none',
          },
        }}
        multiple
        label="Staff"
        value={invitedStaff}
        isServerSide={true}
        loading={areStaffLoading}
        onChange={(_, newValue) => {
          setInvitedStaff(newValue as Option[]);
        }}
        renderTags={(value, getTagProps) => (
          <MultiselectTags value={value} getTagProps={getTagProps} />
        )}
        isOptionEqualToValue={(option, value) =>
          (option as Option).id === (value as Option).id
        }
        options={staffOptions}
        width={'100%'}
      />
    </>
  );
};

export default SelectInvitees;
