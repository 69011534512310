import React from 'react';
import Typography from '../../components/Typography';
import BasicCheckbox from '../../components/Checkbox';
import { useAuth } from '../../authentication/AuthProvider';
import { InviteeWithColor } from './types';
import { colorPerInitial } from '../../utils';
import { colors } from '../../theme';

interface CalendarProviderFilterProps {
  allTeamStaff: InviteeWithColor[];
  selectedTeamStaff: InviteeWithColor[];
  showMyCalendar: boolean;
  toggleMyCalendar: () => void;
  updateSelectedTeamStaff: (teamStaff: InviteeWithColor[]) => void;
  staffColorMap: Record<string, InviteeWithColor>;
}

const CalendarProviderFilter = ({
  allTeamStaff,
  selectedTeamStaff,
  showMyCalendar,
  toggleMyCalendar,
  updateSelectedTeamStaff,
  staffColorMap,
}: CalendarProviderFilterProps) => {
  const { profile } = useAuth();

  const profileColor = colorPerInitial(
    profile?.firstName[0].toUpperCase() as string
  );

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <Typography
          variant={'bodyMedium'}
          customClass="font-semibold text-black"
        >
          My Calendar
        </Typography>
        {profile && (
          <div className="flex justify-start items-center">
            <BasicCheckbox
              sx={{
                '&.Mui-checked': {
                  color: colors.black,
                },
              }}
              onClick={toggleMyCalendar}
              checked={showMyCalendar}
              classes="mr-[-14px]"
            />
            <span
              className={`w-[12px] h-[12px] rounded-[50%] mr-1 !bg-${profileColor}-100`}
            />
            <Typography variant={'bodyMedium'} customClass="text-asterGray">
              {profile.firstName + ' ' + profile.lastName}
            </Typography>
          </div>
        )}
      </div>
      <div className="flex flex-col mt-4">
        <div className="flex items-center">
          <BasicCheckbox
            sx={{
              '&.Mui-checked': {
                color: colors.black,
              },
            }}
            onClick={() =>
              allTeamStaff.length === selectedTeamStaff.length
                ? updateSelectedTeamStaff([])
                : updateSelectedTeamStaff(allTeamStaff)
            }
            checked={allTeamStaff.length === selectedTeamStaff.length}
            classes="mr-[-14px]"
          />
          <Typography
            variant={'bodyMedium'}
            customClass="font-semibold text-asterGray"
          >
            My Team
          </Typography>
        </div>
        <div className="flex flex-col justify-center items-start">
          {Object.entries(staffColorMap).map(([id, s]) => {
            const color = `!bg-${staffColorMap[s.id].color}-300`;
            if (s.id === profile?.id) return null;
            return (
              <div key={id} className="flex justify-start items-center">
                <BasicCheckbox
                  onClick={() => {
                    const updatedStaff = selectedTeamStaff.some(
                      (ss) => ss.id === s.id
                    )
                      ? selectedTeamStaff.filter((ss) => ss.id !== s.id)
                      : [...selectedTeamStaff, s];
                    updateSelectedTeamStaff(updatedStaff);
                  }}
                  checked={selectedTeamStaff.some((ss) => ss.id === s.id)}
                  classes="mr-[-14px]"
                />
                <span
                  className={`w-[12px] h-[12px] rounded-[50%] mr-1 ${color}`}
                />
                <Typography variant={'bodyMedium'} customClass="text-asterGray">
                  {s.firstName + ' ' + s.lastName}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CalendarProviderFilter;
