export const chunkString = (str: string, maxChars: number) => {
  const result: string[] = [];
  let i = 0;
  const len = str.length;

  while (i < len) {
    result.push(str.substring(i, i + maxChars));
    i += maxChars;
  }
  return result;
};
