const Reset = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
    >
      <rect x="0.5" y="1" width="24" height="24" rx="5" fill="white" />
      <rect x="0.5" y="1" width="24" height="24" rx="5" stroke="#E8E5DA" />
      <path
        d="M16.8444 15.5648C15.8903 17.1456 14.1169 18.125 12.2174 18.125C9.27646 18.125 6.875 15.8309 6.875 13C6.875 10.1691 9.27646 7.875 12.2174 7.875C13.6517 7.875 15.0136 8.43344 16.0054 9.3871V8.625C16.0054 8.321 16.2612 8.08333 16.5652 8.08333C16.8692 8.08333 17.125 8.321 17.125 8.625V10.9167C17.125 11.2207 16.8692 11.4583 16.5652 11.4583H14.1739C13.8699 11.4583 13.6141 11.2207 13.6141 10.9167C13.6141 10.6127 13.8699 10.375 14.1739 10.375H15.4267C14.6322 9.48856 13.4605 8.95833 12.2174 8.95833C9.88397 8.95833 7.99457 10.7765 7.99457 13C7.99457 15.2235 9.88397 17.0417 12.2174 17.0417C13.7233 17.0417 15.1254 16.2648 15.8775 15.0185C16.0336 14.7593 16.3744 14.6747 16.6393 14.8217L16.8444 15.5648ZM16.8444 15.5648C17.0026 15.3027 16.9067 14.97 16.6394 14.8218L16.8444 15.5648Z"
        fill="#586068"
        stroke="#586068"
        stroke-width="0.25"
      />
    </svg>
  );
};

export default Reset;
