import { ChangeEvent, forwardRef, HTMLAttributes } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { InputAdornment } from '@mui/material';

import BasicTextfield, { CustomTextFieldProps } from './Textfield';

import { cn } from '@aster/client/utils/cn';

type CustomProps = Pick<HTMLAttributes<HTMLInputElement>, 'onChange'>;

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) =>
          onChange?.({
            target: {
              value: values.value,
            },
          } as ChangeEvent<HTMLInputElement>)
        }
        thousandSeparator
        valueIsNumericString
      />
    );
  }
);

export type CurrencyFieldProps = CustomTextFieldProps;

const CurrencyField = ({ ...props }: CurrencyFieldProps) => {
  return (
    <BasicTextfield
      {...props}
      variant="filled"
      inputProps={{
        className: cn(
          '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
        ),
      }}
      InputProps={{
        inputComponent: NumericFormatCustom,
      }}
      startAdornment={<InputAdornment position="start">$</InputAdornment>}
    />
  );
};

export default CurrencyField;
