import { useState, useMemo } from 'react';
import { CircularProgress, Tabs } from '@mui/material';
import { GridEventListener, GridRowSelectionModel } from '@mui/x-data-grid';
import ButtonType from '../../components/Button';
import EfaxIcon from '../../assets/icons/EfaxIcon';
import axios from '../../app/axiosConfig';
import { useQuery } from '@tanstack/react-query';
import StyledDataGrid from '../../components/StyledDataGrid';
import SearchInput from '../../components/SearchInput';
import StyledHorizontalTab from './StyledHorizontalTab';
import Typography from '../../components/Typography';
import ColumnsSentEfax from './columnsSent';
import ColumnsInboxEfax from './columnsInbox';
import ComposeFaxModal from './ComposeFax';
import FaxDetailModal from './FaxDetail';
import { useSnackbar } from '../../components/Snack';
import { usePracticeDetailsQuery } from '../../hooks/use-practice-details.query';
import { useAuth } from '../../authentication/AuthProvider';
import { useEFaxSearch } from './hooks/usePaginatedSearch';
import SetUpFaxModalInitialStep from './SetUpFaxInitialStep';
import SetUpFaxModalConfirmation from './SetUpFaxConfirmation';
import EFaxNotSetup from './EFaxNotSetup';
import { useUserUsagePreferences } from '../../hooks/useUserUsagePreference';

const EFAX_TAB_PREFERENCE_KEY = '__efax-tab__';

interface FaxNumber {
  faxNumber: string;
}

const EFax = () => {
  const { showMessage } = useSnackbar();
  const [composeOpen, setComposeOpen] = useState(false);
  const { storePreference, readPreference } = useUserUsagePreferences();
  const [activeTab, setActiveTab] = useState(
    readPreference<number>(EFAX_TAB_PREFERENCE_KEY) ?? 0
  );
  const [faxDetailOpen, setFaxDetailOpen] = useState(false);
  const [selectedFax, setSelectedFax] = useState(null);
  const { profile } = useAuth();

  const { practiceInfo, isPracticeLoading } = usePracticeDetailsQuery(
    profile?.practiceId
  );

  const {
    search,
    paginatedFaxes,
    areFaxesFetching,
    areFaxesLoading,
    setPaginationModel,
    setDataGridSortModel,
    paginationModel,
  } = useEFaxSearch(
    activeTab === 0 ? 'received' : 'sent',
    practiceInfo?.consensusCustomerKey != null
  );

  const { data: faxNumbersData } = useQuery({
    queryKey: ['fax-numbers'],
    queryFn: async () => {
      const response = await axios.get<FaxNumber[]>('/v2/efax/fax-numbers');
      return response.data;
    },
    enabled: practiceInfo?.consensusCustomerKey != null,
  });

  const formatFaxNumber = (faxNumber: string) => {
    return faxNumber.replace(/(\d+)(\d{3})(\d{3})(\d{4})/, '+$1 $2 $3 $4');
  };

  const faxNumbers =
    faxNumbersData
      ?.map((faxNumber) => formatFaxNumber(faxNumber.faxNumber))
      ?.join(', ') ?? '';

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const rows = useMemo(() => {
    return (
      paginatedFaxes?.items.map((fax) => ({
        id: fax.id,
        from: formatFaxNumber(fax.from ?? ''),
        to: formatFaxNumber(fax.to ?? ''),
        patientName: fax.patientName,
        sentByName: fax.sentByName,
        pages: fax.pages ?? '-',
        status: fax.status ?? '-',
      })) || []
    );
  }, [paginatedFaxes]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    storePreference(EFAX_TAB_PREFERENCE_KEY, newValue);
    setActiveTab(newValue);
  };

  const columns = useMemo(() => {
    return activeTab === 0 ? ColumnsInboxEfax : ColumnsSentEfax;
  }, [activeTab]);

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    if (activeTab === 1 && params.row.status !== 'COMPLETE') {
      showMessage({
        message: 'Only faxes with status complete can be viewed.',
        type: 'info',
      });
      return;
    }

    setSelectedFax(params.row);
    setFaxDetailOpen(true);
  };

  if (isPracticeLoading || areFaxesLoading) {
    return (
      <div className="flex flex-col justify-center items-center w-full h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex flex-col full-w-container overflow-auto">
      {practiceInfo?.consensusCustomerKey ? (
        <>
          <div className="flex justify-between mb-4 flex-wrap gap-y-2">
            <div className="flex flex-col">
              <div className="flex">
                <EfaxIcon />
                <span className="text-h6 text-gray-500 ml-2 ">Fax</span>
              </div>
              <p className="text-h4 text-black font-semibold m-0">
                Fax
                {faxNumbers && (
                  <span className="text-sm text-gray-500 ml-2 ">
                    ({faxNumbers})
                  </span>
                )}
              </p>
            </div>

            <div className="flex gap-4 flex-wrap self-center">
              <ButtonType
                variant="contained"
                onClick={() => setComposeOpen(true)}
                text="Compose a fax"
              />
            </div>
          </div>

          <div className="flex items-center justify-between mb-4">
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="fax tabs"
              className="min-w-max items-center"
            >
              <StyledHorizontalTab
                label={<Typography variant="bodyMedium" text="Inbox" />}
              />
              <StyledHorizontalTab
                label={<Typography variant="bodyMedium" text="Sent" />}
              />
              <StyledHorizontalTab
                label={<Typography variant="bodyMedium" text="Archive" />}
                disabled
              />
            </Tabs>

            <SearchInput
              onChange={(e) => search(e.currentTarget.value)}
              placeholder="Search by number or name..."
              className="flex-grow max-w-6xl rounded-xl"
            />
          </div>

          <StyledDataGrid
            rows={rows}
            rowCount={paginatedFaxes?.queryMetadata.rowCount ?? -1}
            loading={areFaxesLoading || areFaxesFetching}
            paginationMode="server"
            sortingMode="server"
            onPaginationModelChange={setPaginationModel}
            onSortModelChange={setDataGridSortModel}
            paginationModel={paginationModel}
            pageSizeOptions={[2, 10, 25, 50]}
            columns={columns}
            autoHeight
            disableColumnFilter
            disableColumnMenu
            onRowClick={handleRowClick}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={setRowSelectionModel}
            getRowClassName={() => 'cursor-pointer'}
          />

          <ComposeFaxModal
            open={composeOpen}
            onClose={() => setComposeOpen(false)}
          />
          {selectedFax && (
            <FaxDetailModal
              open={faxDetailOpen}
              onClose={() => setFaxDetailOpen(false)}
              fax={selectedFax}
            />
          )}
        </>
      ) : (
        <EFaxNotSetup />
      )}
      <SetUpFaxModalInitialStep />
      <SetUpFaxModalConfirmation />
      <ComposeFaxModal
        open={composeOpen}
        onClose={() => setComposeOpen(false)}
      />
      {selectedFax && (
        <FaxDetailModal
          open={faxDetailOpen}
          onClose={() => setFaxDetailOpen(false)}
          fax={selectedFax}
        />
      )}
    </div>
  );
};

export default EFax;
