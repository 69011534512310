import { InputAdornment } from '@mui/material';
import { CustomTextFieldProps, MaskedBasicTextfield } from './Textfield';

type PhoneNumberFieldProps = Omit<CustomTextFieldProps, 'startAdornmnent'>;

export const PhoneNumberField = ({ value, ...rest }: PhoneNumberFieldProps) => {
  const adornment = value ? null : (
    <InputAdornment position="start">+1</InputAdornment>
  );

  return (
    <MaskedBasicTextfield
      mask="+1 (___) ___-____"
      replacement={{ _: /\d/ }}
      variant="filled"
      value={value}
      startAdornment={adornment}
      {...rest}
    />
  );
};
