const HouseMedicalPrimary = () => (
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Primary"
      d="M11.6654 0.155281C11.431 -0.0517603 11.0756 -0.0517603 10.8373 0.155281L0.21175 9.53074C-0.0460756 9.75731 -0.0734207 10.1558 0.157059 10.4136C0.387539 10.6714 0.78209 10.6988 1.03991 10.4683L2.50092 9.17916V16.8748C2.50092 18.6015 3.89943 20 5.62608 20H16.8766C18.6033 20 20.0018 18.6015 20.0018 16.8748V9.17916L21.4628 10.4683C21.7206 10.6949 22.1152 10.6714 22.3456 10.4136C22.5761 10.1558 22.5488 9.76122 22.291 9.53074L11.6654 0.155281ZM3.75098 16.8748V8.07364L11.2514 1.45613L18.7517 8.07364V16.8748C18.7517 17.9101 17.9118 18.7499 16.8766 18.7499H5.62608C4.59087 18.7499 3.75098 17.9101 3.75098 16.8748ZM10.6263 6.87436C9.93488 6.87436 9.37626 7.43298 9.37626 8.12442V9.37448H8.1262C7.43476 9.37448 6.87614 9.9331 6.87614 10.6245V11.8746C6.87614 12.566 7.43476 13.1247 8.1262 13.1247H9.37626V14.3747C9.37626 15.0662 9.93488 15.6248 10.6263 15.6248H11.8764C12.5678 15.6248 13.1264 15.0662 13.1264 14.3747V13.1247H14.3765C15.0679 13.1247 15.6266 12.566 15.6266 11.8746V10.6245C15.6266 9.9331 15.0679 9.37448 14.3765 9.37448H13.1264V8.12442C13.1264 7.43298 12.5678 6.87436 11.8764 6.87436H10.6263ZM10.6263 8.12442H11.8764V9.99951C11.8764 10.3433 12.1576 10.6245 12.5014 10.6245H14.3765V11.8746H12.5014C12.1576 11.8746 11.8764 12.1559 11.8764 12.4996V14.3747H10.6263V12.4996C10.6263 12.1559 10.3451 11.8746 10.0013 11.8746H8.1262V10.6245H10.0013C10.1654 10.6245 10.3255 10.5581 10.4427 10.4409C10.5599 10.3237 10.6263 10.1636 10.6263 9.99951V8.12442Z"
      fill="black"
    />
  </svg>
);

export default HouseMedicalPrimary;
