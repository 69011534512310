import axios from '../../../app/axiosConfig';

export const deleteFiles = async (ids: string[]) =>
  axios.delete(`/documents/`, {
    data: ids,
  });

export const fetchPracticeFiles = async () => {
  const res = await axios.get('/documents/');
  return res.data;
};

export const fetchPatientFiles = async (id: string) => {
  const res = await axios.get(`/documents/${id}`);
  return res.data;
};
