import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs, { Dayjs } from 'dayjs';
import CalendarProviderFilter from './CalendarProviderFilter';
import { CalendarEvent, InviteeWithColor } from './types';
import ButtonType from '../../components/Button';
import { Add } from '@mui/icons-material';
import InviteMembersModal from '../members/modals/InviteMembersModal';
import useInviteMembersMutation from '../members/mutations/invite-members-mutation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

type CalendarSidebarProps = {
  currentDate: Dayjs;
  handleNav: (date: Dayjs) => void;
  handleCreateAppt: (date: Dayjs | null) => void;
  events: CalendarEvent[];
  selectedTeamStaff: InviteeWithColor[];
  allTeamStaff: InviteeWithColor[];
  showMyCalendar: boolean;
  toggleMyCalendar: () => void;
  updateSelectedTeamStaff: (teamStaff: InviteeWithColor[]) => void;
  staffColorMap: Record<string, InviteeWithColor>;
};

const CalendarSidebar = ({
  currentDate,
  handleNav,
  handleCreateAppt,
  events,
  allTeamStaff,
  selectedTeamStaff,
  showMyCalendar,
  toggleMyCalendar,
  updateSelectedTeamStaff,
  staffColorMap,
}: CalendarSidebarProps) => {
  const [value, setValue] = React.useState<Dayjs | null>(
    currentDate ?? dayjs()
  );

  const [openInviteModal, setOpenInviteModal] = useState(false);

  const { inviteMembersMutation } = useInviteMembersMutation();

  return (
    <>
      <div className="w-full h-full flex flex-col justify-start  items-center md:items-start">
        <ButtonType
          className="w-[185px]"
          variant="contained"
          text="New Appointment"
          classes={'mx-2 bg-asterGreen-900 hover:bg-asterGreen-700 self-center'}
          icon
          specificIcon={
            <FontAwesomeIcon style={{ marginRight: 10 }} icon={faPlus} />
          }
          onClick={() => handleCreateAppt(value)}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            sx={{
              flexShrink: 0,
              width: 250,
              '& .MuiButtonBase-root.MuiPickersDay-root:not(.Mui-selected)[aria-current="date"]':
                {
                  marginTop: '3px',
                  paddingTop: '2px',
                  height: '28px',
                  width: '30px',
                },
            }}
            value={value}
            onChange={(newValue: Dayjs) => {
              handleNav(newValue);
              setValue(newValue);
            }}
          />
        </LocalizationProvider>
        {events && (
          <CalendarProviderFilter
            allTeamStaff={allTeamStaff}
            selectedTeamStaff={selectedTeamStaff}
            showMyCalendar={showMyCalendar}
            toggleMyCalendar={toggleMyCalendar}
            updateSelectedTeamStaff={updateSelectedTeamStaff}
            staffColorMap={staffColorMap}
          />
        )}
        <ButtonType
          variant={'outlined'}
          text={'Invite Member'}
          notRounded
          className="text-p12 h-[28px] px-[10px] mt-4 border-beige text-asterGray"
          startIcon={<Add />}
          onClick={() => setOpenInviteModal(true)}
        />
      </div>
      <InviteMembersModal
        open={openInviteModal}
        title="Invite to your practice"
        description="By adding this member you are granting them full access."
        confirm="Send invites"
        dismiss="Cancel"
        handleClose={() => setOpenInviteModal(false)}
        handleConfirm={inviteMembersMutation}
        handleCancel={() => setOpenInviteModal(false)}
      />
    </>
  );
};

export default CalendarSidebar;
