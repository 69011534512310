const House = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5454 10.6838C22.5859 10.7243 22.6263 10.8051 22.6263 10.9265C22.6263 11.0074 22.6263 11.0882 22.5859 11.1287L22.1815 11.614C22.1006 11.6949 22.0197 11.7353 21.8984 11.7353C21.8175 11.7353 21.777 11.7353 21.6962 11.6544L20.0785 10.3199V20.1471C20.0785 20.511 19.755 20.7941 19.4315 20.7941H14.255C13.891 20.7941 13.6079 20.511 13.6079 20.1471V14.9706H11.0197V20.1471C11.0197 20.511 10.6962 20.7941 10.3726 20.7941H5.19616C4.83219 20.7941 4.5491 20.511 4.5491 20.1471V10.3199L2.89101 11.6544C2.81013 11.7353 2.76969 11.7353 2.68881 11.7353C2.56748 11.7353 2.4866 11.6949 2.40572 11.614L2.00131 11.1287C1.96087 11.0882 1.96087 11.0074 1.96087 10.9265C1.96087 10.8051 2.00131 10.7243 2.08219 10.6838L11.4645 3C11.7072 2.79779 11.9903 2.71691 12.2734 2.71691C12.5969 2.71691 12.88 2.79779 13.1226 3L22.5454 10.6838ZM18.744 19.5L18.7844 9.26838L12.3138 3.97059L5.84322 9.26838V19.5H9.68513V14.3235C9.68513 13.9596 10.0087 13.6765 10.3322 13.6765H10.3726H14.255C14.5785 13.6765 14.8616 14 14.8616 14.3235V19.5404L18.744 19.5Z"
        fill="#586068"
      />
    </svg>
  );
};

export default House;
