const CalendarPlus = () => {
  return (
    <svg
      width="44"
      height="51"
      viewBox="0 0 44 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.7031 15.875H1.29688C0.613281 15.875 0.125 15.3867 0.125 14.7031V11.1875C0.125 8.64844 2.17578 6.5 4.8125 6.5H9.5V1.42188C9.5 0.835938 9.98828 0.25 10.6719 0.25H14.5781C15.1641 0.25 15.75 0.835938 15.75 1.42188V6.5H28.25V1.42188C28.25 0.835938 28.7383 0.25 29.4219 0.25H33.3281C33.9141 0.25 34.5 0.835938 34.5 1.42188V6.5H39.1875C41.7266 6.5 43.875 8.64844 43.875 11.1875V14.7031C43.875 15.3867 43.2891 15.875 42.7031 15.875ZM1.29688 19H42.7031C43.2891 19 43.875 19.5859 43.875 20.1719V45.5625C43.875 48.1992 41.7266 50.25 39.1875 50.25H4.8125C2.17578 50.25 0.125 48.1992 0.125 45.5625V20.1719C0.125 19.5859 0.613281 19 1.29688 19ZM32.1562 32.6719C32.1562 32.0859 31.5703 31.5 30.9844 31.5H25.125V25.6406C25.125 25.0547 24.5391 24.4688 23.9531 24.4688H20.0469C19.3633 24.4688 18.875 25.0547 18.875 25.6406V31.5H13.0156C12.332 31.5 11.8438 32.0859 11.8438 32.6719V36.5781C11.8438 37.2617 12.332 37.75 13.0156 37.75H18.875V43.6094C18.875 44.293 19.3633 44.7812 20.0469 44.7812H23.9531C24.5391 44.7812 25.125 44.293 25.125 43.6094V37.75H30.9844C31.5703 37.75 32.1562 37.2617 32.1562 36.5781V32.6719Z"
        fill="#FFADAD"
      />
    </svg>
  );
};

export default CalendarPlus;
