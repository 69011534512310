import { cn } from '@aster/client/utils/cn';
import Typography from '../components/Typography';
import Tooltip from '@mui/material/Tooltip';

type TagProps = {
  tagText: string;
  rightText?: string;
  color?: string;
  tooltip?: string;
  className?: string;
  textColor?: string;
};
const Tag = ({
  tagText,
  rightText,
  color,
  tooltip,
  className,
  textColor,
}: TagProps) => {
  return (
    <Tooltip
      title={tooltip}
      className={`${tooltip ? 'hover:cursor-pointer' : ''}`}
    >
      <div
        className={cn(
          `flex w-fit px-[5px] h-5 bg-${
            color ?? 'grayBackground'
          } rounded-[5px]`,
          rightText &&
            `border border-${color ?? 'grayBackground'} bg-transparent pl-0`,
          className
        )}
      >
        <Typography
          variant="bodySmall"
          text={tagText}
          customClass={cn(
            'text-center font-semibold rounded-s-[5px] text-primary',
            rightText && `bg-${color ?? 'grayBackground'} px-[5px]`,
            textColor && `text-${textColor}`
          )}
        />

        {rightText && (
          <Typography variant="bodySmall" text={rightText} customClass="mx-1" />
        )}
      </div>
    </Tooltip>
  );
};

export default Tag;
