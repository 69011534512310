import { extendTailwindMerge } from 'tailwind-merge';

export const customTwMerge = extendTailwindMerge({
  extend: {
    theme: {
      colors: [],
    },
    classGroups: {
      'text-color': [
        {
          text: [
            'aster',
            'asterGray',
            'beige',
            'black',
            'gray',
            'green',
            'neutral-600',
            'grayBackground',
            'grayCard',
            'grayBlock',
            'grayLight',
            'pastelBlue',
            'pastelCyan',
            'pastelGreen',
            'pastelOrange',
            'pastelRed',
            'pastelYellow',
            'pastelPink',
            'pastelPurple',
            'primary',
            'purple',
            'red',
            'secondary',
            'transparent',
            'white',
          ],
        },
      ],
      'font-size': [
        {
          text: [
            'body',
            'bodyMedium',
            'bodySmall',
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            'p10',
            'p12',
          ],
        },
      ],
    },
  },
});
