import { createTheme } from '@mui/material/styles';
import { colors } from '../theme';

export function initializeMuiTheme() {
  const rootElement = document.getElementById('root');

  return createTheme({
    typography: {
      fontFamily: [
        'Figtree',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    palette: {
      primary: { main: colors.primary },
      secondary: { main: colors.secondary },
      error: { main: colors.red },
      success: { main: colors.green },
      warning: { main: colors.warning },
    },
    components: {
      MuiPopover: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiDialog: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiModal: {
        defaultProps: {
          container: rootElement,
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        twsm: 640,
        twmd: 768,
        twlg: 1024,
        twxl: 1280,
        tw2xl: 1536,
      },
    },
  });
}
