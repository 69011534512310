import BasicTextfield from '../../../components/Textfield';
import Typography from '../../../components/Typography';
import { Checkbox, FormControlLabel } from '@mui/material';
import { colors } from '../../../theme';
import { InsuranceInfo } from '../types/patient-profile';

type InsuranceInfoProps = {
  insurance: InsuranceInfo;
  handleInfo: (event: any) => void;
  handleCheck: (event: any, property: string) => void;
  readonly: boolean;
};

const Insurance = (props: InsuranceInfoProps) => {
  const {
    insuranceFirstProvider,
    insuranceFirstNumber,
    insuranceFirstGroupId,
    useFirstForBaby,
    insuranceSecondProvider,
    insuranceSecondNumber,
    insuranceSecondGroupId,
    useSecondForBaby,
    cashOnly,
  } = props.insurance;

  const handleInfo = props.handleInfo;
  const handleCheck = props.handleCheck;

  return (
    <div className="container w-fit pb-10">
      <Typography
        text="Insurance"
        variant="h5"
        customClass="font-semibold mb-5"
      ></Typography>
      <FormControlLabel
        className="h-[24px] mb-4"
        key="cashonly"
        control={
          <Checkbox
            sx={{
              color: colors.grayLight,
              '&.Mui-checked': {
                color: colors.green,
              },
            }}
            checked={cashOnly}
            onChange={(event: any) => handleCheck(event, 'cashOnly')}
            disableRipple
          />
        }
        label="Cash pay only"
        disabled={props.readonly}
      />
      <div className="h-fit flex flex-wrap justify-center md:justify-start gap-2 md:gap-10 2xl:gap-20">
        <div className="rounded-[10px] bg-grayCard p-5">
          <Typography
            text="Primary Insurance"
            variant="h6"
            customClass="font-semibold mb-4"
          ></Typography>

          <BasicTextfield
            classes="mb-5 w-[300px]"
            id="firstProvider"
            variant="filled"
            label="Insurance Provider"
            name="insuranceFirstProvider"
            value={insuranceFirstProvider}
            onChange={handleInfo}
            disabled={props.readonly}
          />
          <BasicTextfield
            id="insuranceFirstNumber"
            variant="filled"
            label="Insurance Number"
            name="insuranceFirstNumber"
            value={insuranceFirstNumber}
            onChange={handleInfo}
            classes={`mt-4 mb-5 w-[300px]`}
            disabled={props.readonly}
          />
          <BasicTextfield
            id="insuranceFirstGroupId"
            variant="filled"
            label="Insurance Group Id"
            name="insuranceFirstGroupId"
            value={insuranceFirstGroupId}
            onChange={handleInfo}
            classes={`mt-4 w-[300px]`}
            disabled={props.readonly}
          />
          <FormControlLabel
            className="h-[24px] mt-4"
            key="babyprimary"
            control={
              <Checkbox
                sx={{
                  color: colors.grayLight,
                  '&.Mui-checked': {
                    color: colors.green,
                  },
                }}
                checked={useFirstForBaby}
                onChange={(event: any) => handleCheck(event, 'useFirstForBaby')}
                disableRipple
                disabled={props.readonly}
              />
            }
            label="Apply this insurance to newborns"
            disabled={props.readonly}
          />
        </div>
        <div className="rounded-[10px] bg-grayCard p-5 mt-10 md:mt-0">
          <Typography
            text="Secondary Insurance"
            variant="h6"
            customClass="font-semibold mb-4"
          ></Typography>

          <BasicTextfield
            classes="mb-5 w-[300px]"
            id="insuranceSecondProvider"
            variant="filled"
            label="Insurance Provider"
            name="insuranceSecondProvider"
            value={insuranceSecondProvider}
            onChange={handleInfo}
            disabled={props.readonly}
          />
          <BasicTextfield
            id="insuranceSecondNumber"
            variant="filled"
            label="Insurance Number"
            name="insuranceSecondNumber"
            value={insuranceSecondNumber}
            onChange={handleInfo}
            classes={`mt-4 mb-5 w-[300px]`}
            disabled={props.readonly}
          />
          <BasicTextfield
            id="insuranceSecondGroupId"
            variant="filled"
            label="Insurance Group Id"
            name="insuranceSecondGroupId"
            value={insuranceSecondGroupId}
            onChange={handleInfo}
            classes={`mt-4 w-[300px]`}
            disabled={props.readonly}
          />
          <FormControlLabel
            className="h-[24px] mt-4"
            key="babysecondary"
            control={
              <Checkbox
                sx={{
                  color: colors.grayLight,
                  '&.Mui-checked': {
                    color: colors.green,
                  },
                }}
                checked={useSecondForBaby}
                onChange={(event: any) =>
                  handleCheck(event, 'useSecondForBaby')
                }
                disableRipple
                disabled={props.readonly}
              />
            }
            label="Apply this insurance to newborns"
            disabled={props.readonly}
          />
        </div>
      </div>
    </div>
  );
};

export default Insurance;
