import Divider from '@mui/material/Divider/Divider';
import CollapsibleComponent from '../../../components/CollapsibleComponent';
import Typography from '../../../components/Typography';
import { CircularProgress } from '@mui/material';
import LabTestCard from './LabTestCard';
import { usePatientLabOrders } from '../queries/query-patient-lab-orders';
import { useParams } from 'react-router-dom';
import { useLabsStore } from '../store/labs.store';
import { Button } from '@aster/client/ui/Button/Button';
import { usePatientProfileQuery } from '../../notes/queries/patient-profile.query';
import { isPatientReadonly } from '../../patients/utils/is-patient-readonly';

export function LabOrderList() {
  const { patient: patientID } = useParams();
  const { labOrders, labOrdersWithResults, pendingLabOrders, isLoadingOrders } =
    usePatientLabOrders(patientID);
  const newOrderIDs = useLabsStore((state) => state.newOrderIDs);
  const openLabOrderDetails = useLabsStore(
    (state) => state.openLabOrderDetails
  );
  const openManualUploadResultsDialog = useLabsStore(
    (state) => state.openManualUploadResultsDialog
  );
  const { patientProfile } = usePatientProfileQuery(patientID);
  return (
    <>
      <CollapsibleComponent label="Pending tests">
        <div className="flex flex-col justify-center items-center pl-5 py-3 gap-4">
          {isLoadingOrders && <CircularProgress size="1.5rem" />}
          {!isLoadingOrders && labOrders?.length === 0 && (
            <Typography
              customClass="text-center my-3"
              variant="body"
              text="No pending tests"
            />
          )}
          {labOrders &&
            pendingLabOrders?.map((order) => (
              <LabTestCard
                isNew={newOrderIDs.includes(order.id)}
                key={order.id}
                labOrder={order}
                showDetails={() => openLabOrderDetails(order.id)}
              />
            ))}
        </div>
      </CollapsibleComponent>
      <Divider />
      <CollapsibleComponent
        label="Test results"
        actions={
          <Button
            variant="outline"
            disabled={isPatientReadonly(patientProfile)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              openManualUploadResultsDialog();
            }}
          >
            Manually Enter Results
          </Button>
        }
      >
        <div className="flex flex-col justify-center items-center pl-5 py-3 gap-4">
          {isLoadingOrders && <CircularProgress size="1.5rem" />}
          {!isLoadingOrders && labOrders?.length === 0 && (
            <Typography
              customClass="text-center my-3"
              variant="body"
              text="No results available"
            />
          )}
          {labOrders &&
            labOrdersWithResults?.map((order) => (
              <LabTestCard
                isNew={newOrderIDs.includes(order.id)}
                key={order.id}
                labOrder={order}
                showDetails={() => openLabOrderDetails(order.id)}
              />
            ))}
        </div>
      </CollapsibleComponent>
    </>
  );
}
