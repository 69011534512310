import { SendFaxBody } from '@aster/shared/dtos/efax';
import axios from '../../../app/axiosConfig';
import { useSnackbar } from '../../../components/Snack';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';

const sendEFax = async (efaxBody: SendFaxBody) =>
  await axios.post('/v2/efax/send', efaxBody);

export const useSendEFaxMutation = (onSuccess?: () => void) => {
  const { showMessage } = useSnackbar();
  const queryClient = useQueryClient();

  const sendEFaxMutation = useMutation({
    mutationKey: ['sendEFax'],
    mutationFn: sendEFax,
    scope: {
      id: createMutationScopeID(MutationScope.EFAX),
    },
    onSuccess: () => {
      showMessage({
        message: 'The EFax has been sent successfully',
        type: 'success',
      });
      onSuccess?.();
      queryClient.invalidateQueries({ queryKey: ['faxes'] });
    },
    onError: () => {
      showMessage({
        message: 'An error occurred while sending the EFax. Please try again.',
        type: 'error',
      });
    },
  });

  return { sendEFaxMutation };
};
