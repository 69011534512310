const Workflows = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 27 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.6079 0.382352C25.6594 0.382352 26.5491 1.27206 26.5491 2.32353V6.20588C26.5491 7.29779 25.6594 8.14706 24.6079 8.14706H20.7256C19.6337 8.14706 18.7844 7.29779 18.7844 6.20588V4.91176H8.43145V6.20588C8.43145 6.85294 8.10792 7.37868 7.66307 7.74265L11.0601 13.4449C11.2219 13.4044 11.4241 13.3235 11.6263 13.3235H15.5087C16.6006 13.3235 17.4498 14.2132 17.4498 15.2647V19.1471C17.4498 20.239 16.6006 21.0882 15.5087 21.0882H11.6263C10.5748 21.0882 9.68513 20.239 9.68513 19.1471V15.2647C9.68513 14.9007 9.80645 14.5368 10.0087 14.2537L6.4094 8.14706H2.60792C1.51601 8.14706 0.666748 7.29779 0.666748 6.20588V2.32353C0.666748 1.27206 1.51601 0.382352 2.60792 0.382352H6.49028C7.54175 0.382352 8.43145 1.27206 8.43145 2.32353V3.61765H18.7844V2.32353C18.7844 1.27206 19.6337 0.382352 20.7256 0.382352H24.6079ZM1.96087 6.20588C1.96087 6.56985 2.24395 6.85294 2.60792 6.85294H6.49028C6.81381 6.85294 7.13734 6.56985 7.13734 6.20588V2.32353C7.13734 2 6.81381 1.67647 6.49028 1.67647H2.60792C2.24395 1.67647 1.96087 2 1.96087 2.32353V6.20588ZM15.5491 14.6176H11.6667C11.3028 14.6176 11.0197 14.9412 11.0197 15.2647V19.1471C11.0197 19.511 11.3028 19.7941 11.6667 19.7941H15.5491C15.8726 19.7941 16.1962 19.511 16.1962 19.1471V15.2647C16.1962 14.9412 15.8726 14.6176 15.5491 14.6176ZM25.255 6.20588V2.32353C25.255 2 24.9315 1.67647 24.6079 1.67647H20.7256C20.3616 1.67647 20.0785 2 20.0785 2.32353V6.20588C20.0785 6.56985 20.3616 6.85294 20.7256 6.85294H24.6079C24.9315 6.85294 25.255 6.56985 25.255 6.20588Z"
        fill="#586068"
      />
    </svg>
  );
};

export default Workflows;
