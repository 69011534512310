import { cn } from '@aster/client/utils/cn';
import { Box } from '@mui/material';

type LinkTabProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
  classes?: string;
};

export const HorizontalLinkTab = ({
  value,
  index,
  classes,
  children,
}: LinkTabProps) => {
  return (
    <div
      className={cn(`overflow-y-auto h-full`, classes)}
      hidden={value !== index}
    >
      {value === index && children}
    </div>
  );
};

export const LinkTab = (props: LinkTabProps) => {
  const { children, value, index, classes, ...other } = props;
  return (
    <div
      className={cn(
        'w-full h-full overflow-y-auto p-6 pt-2 md:pt-6 overflow-x-hidden',
        classes
      )}
      role="tabpanel"
      hidden={value !== index}
      id={`tab-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="w-full" sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};
