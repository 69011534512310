import {
  AppointmentType,
  AppointmentTypeDisplayNames,
  CreateAppointmentDTO,
} from '@aster/shared/dtos/appointment';

export const getAppointmentType = (
  type: string
): CreateAppointmentDTO['type'] => {
  // if the type is a valid key in AppointmentTypeDisplayNames, we return the type
  if (AppointmentTypeDisplayNames[type]) {
    return type as CreateAppointmentDTO['type'];
  }
  // if the passed type is not valid, we check if it is a valid display name and return the corresponding type
  const foundAppointmentType = Object.entries(AppointmentTypeDisplayNames).find(
    ([key, value]) => value === type
  );
  return (
    (foundAppointmentType?.[0] as CreateAppointmentDTO['type']) ??
    AppointmentType.general
  );
};
