import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faFileArrowDown,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import Typography from '../../../../components/Typography';
import { useLabsStore } from '../../store/labs.store';
import { downLoadFileFromUrl } from '../../../documents/utils/s3-files.utils';
import { useSnackbar } from '../../../../components/Snack';
import { Button } from '@aster/client/ui/Button/Button';

type FillResultsHeaderProps = {
  pdfFileUrl?: string | null;
};

export default function FillResultsHeader({
  pdfFileUrl,
}: FillResultsHeaderProps) {
  const selectedFile = useLabsStore((state) => state.selectedFile);
  const goToUploadResults = useLabsStore((state) => state.goToUploadResults);
  const { showMessage } = useSnackbar();

  return (
    <header className="col-span-3 3xl:col-span-2 flex flex-col gap-3">
      <Typography variant="h6" text="Upload Results" />
      <div className="p-6 rounded-lg border border-neutral-300 justify-between items-center inline-flex">
        <div className="flex items-center gap-3">
          <FontAwesomeIcon icon={faFile} size="lg" />
          <Typography
            variant="bodyMedium"
            text={selectedFile?.name}
            customClass="font-semibold"
          />
        </div>
        <div className="flex items-center gap-3">
          <Button
            className="text-sm"
            variant="outline"
            size="sm"
            disabled={!pdfFileUrl}
            onClick={() =>
              downLoadFileFromUrl(pdfFileUrl as string, 'results.pdf', () =>
                showMessage({
                  message:
                    'An error occured while downloading the results PDF. Please try again.',
                  type: 'error',
                })
              )
            }
          >
            <FontAwesomeIcon icon={faFileArrowDown} /> Download
          </Button>
          <Button
            className="text-red-700 hover:text-red-700 text-sm"
            onClick={() => goToUploadResults()}
            variant="outline"
            size="sm"
          >
            <FontAwesomeIcon icon={faTrash} /> Delete
          </Button>
        </div>
      </div>
    </header>
  );
}
