import React, { ChangeEvent, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { colors } from '../../../theme';
import BasicTextfield from '../../../components/Textfield';
import CollapsibleComponent from '../../../components/CollapsibleComponent';
import ButtonType from '../../../components/Button';
import Typography from '../../../components/Typography';
import StyledDataGrid from '../../../components/StyledDataGrid';
import dayjs from 'dayjs';
import { ContractionInfo } from './types/birth.types';
import TableDialog from './TableDialog';

type ContractionProps = {
  data: ContractionInfo[];
  disabled: boolean;
  handleNote: (note: string) => void;
  handleSave: (property: string, event: ContractionInfo[]) => void;
};

const ContractionCounter = ({
  data,
  disabled,
  handleSave,
  handleNote,
}: ContractionProps) => {
  const [running, setRunning] = useState<boolean>(false);
  const [manual, setManual] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const rows = data || [];
  const [currentContractions, setCurrentContractions] = useState({
    start: '',
    end: '',
    duration: '',
    frequency: '',
    intensity: '',
  });
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const handleFormInput = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentContractions({
      ...currentContractions,
      [event.target.name]: event.target.value,
    });
  };

  const createNote = (
    startTime: string | null,
    endTime: string,
    duration: string,
    frequency: string | null,
    intensity?: string
  ) => {
    const note = `Contraction: Started at ${startTime}, ended at ${endTime}, lasted ${duration} seconds
    ${
      frequency !== null
        ? `, and occurred with a frequency of ${frequency} minutes`
        : ''
    }
    ${intensity ? `and had an intensity of ${intensity}.` : ''}`;
    handleNote(note);
  };

  const frequencyValue = () => {
    let frequency = null;
    if (rows.length > 0) {
      const lastContraction = rows[rows.length - 1];
      const timeSinceLastContraction =
        ((startTime as Date).getTime() -
          dayjs(lastContraction.end, 'HH:mm:ss').toDate().getTime()) /
        (1000 * 60);
      frequency =
        timeSinceLastContraction > 0
          ? timeSinceLastContraction.toFixed(2)
          : '-';
    }
    return frequency;
  };

  const startStop = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (!running) {
      setStartTime(new Date());
    } else {
      const endTime = new Date();
      const duration = (
        (endTime.getTime() - (startTime as Date).getTime()) /
        1000
      ).toFixed(2);
      const frequency = frequencyValue();
      const newResult: ContractionInfo = {
        id: rows.length + 1,
        start: dayjs(startTime).format('HH:mm:ss'),
        end: dayjs(endTime).format('HH:mm:ss'),
        duration,
        frequency,
        intensity: '-',
      };
      handleSave('contractions', [...rows, newResult]);
      createNote(
        dayjs(startTime).format('HH:mm:ss'),
        dayjs(endTime).format('HH:mm:ss'),
        duration,
        frequency
      );
    }
    setRunning(!running);
  };

  const saveManual = () => {
    const newResult: ContractionInfo = {
      id: rows.length + 1,
      start: currentContractions.start,
      end: currentContractions.end,
      duration: currentContractions.duration,
      frequency: currentContractions.frequency,
      intensity: currentContractions.intensity,
    };
    handleSave('contractions', [...rows, newResult]);

    createNote(
      currentContractions.start,
      currentContractions.end,
      currentContractions.duration,
      currentContractions.frequency,
      currentContractions.intensity
    );

    setCurrentContractions({
      start: '',
      end: '',
      duration: '',
      frequency: '',
      intensity: '',
    });

    setManual(!manual);
  };

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: 'id',
      headerName: '#',
      headerAlign: 'left',
      flex: 0.2,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'start',
      headerName: 'Start',
      headerAlign: 'left',
      flex: 1.5,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'end',
      headerName: 'End',
      headerAlign: 'left',
      flex: 1.5,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'duration',
      headerName: 'Dur.',
      type: 'number',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'frequency',
      headerName: 'Freq.',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'intensity',
      headerName: 'Intensity',
      headerAlign: 'left',
      flex: 1.5,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
  ];

  return (
    <>
      <CollapsibleComponent
        label="Contraction counter"
        actions={
          !disabled && (
            <div className="flex flex-row">
              <ButtonType
                variant="contained"
                onClick={startStop}
                text={running ? 'Stop' : 'Start'}
                disabled={manual}
                classes={'max-h-[30px]'}
              />
              <ButtonType
                variant="text"
                onClick={(e) => {
                  e.stopPropagation();
                  setManual(!manual);
                }}
                text={manual ? 'Auto' : 'Manual'}
                disabled={running}
                classes={'max-h-[30px]'}
              />
            </div>
          )
        }
      >
        <div className="flex flex-col self-stretch p-2.5 rounded-b-lg bg-grayCard xl:max-w-[450px]">
          {manual && (
            <div className="flex flex-col w-full h-full mt-2">
              <div className="flex flex-row flex-1 gap-2 pb-2">
                <BasicTextfield
                  id="start-contraction"
                  variant="filled"
                  name="start"
                  label="Start"
                  value={currentContractions.start}
                  onChange={handleFormInput}
                />
                <BasicTextfield
                  id="end-contraction"
                  variant="filled"
                  name="end"
                  label="End"
                  value={currentContractions.end}
                  onChange={handleFormInput}
                />
                <BasicTextfield
                  id="duration"
                  variant="filled"
                  name="duration"
                  label="Duration"
                  value={currentContractions.duration}
                  onChange={handleFormInput}
                />
              </div>
              <div className="flex flex-row flex-1 gap-2 pb-2">
                <BasicTextfield
                  id="frequency"
                  variant="filled"
                  name="frequency"
                  label="Frequency"
                  value={currentContractions.frequency}
                  onChange={handleFormInput}
                />
                <BasicTextfield
                  id="intensity"
                  variant="filled"
                  name="intensity"
                  label="Intensity"
                  value={currentContractions.intensity}
                  onChange={handleFormInput}
                />
                <ButtonType
                  variant="contained"
                  onClick={saveManual}
                  text="Save"
                  classes={'max-h-[30px] mt-3'}
                />
              </div>
            </div>
          )}
          <StyledDataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            onCellClick={() => setShowDetailsModal(true)}
            autoHeight
            slots={{
              noRowsOverlay: () => (
                <div className="flex justify-center items-center h-full">
                  No contractions yet.
                </div>
              ),
            }}
            sx={{
              backgroundColor: colors.white,
              overflow: 'auto',
              fontSize: '12px',
              textAlign: 'center',
            }}
          />
          <Typography
            text="*Duration is in seconds and Frequency is in minutes"
            variant="bodySmall"
            customClass="text-gray-500"
          />
        </div>
      </CollapsibleComponent>
      <TableDialog
        open={showDetailsModal}
        handleClose={() => setShowDetailsModal(false)}
      >
        <StyledDataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
          autoHeight
          slots={{
            noRowsOverlay: () => (
              <div className="flex justify-center items-center h-full">
                No contractions yet.
              </div>
            ),
          }}
          sx={{
            backgroundColor: colors.white,
            overflow: 'auto',
            width: '100%',
            fontSize: '12px',
            textAlign: 'center',
          }}
        />
        <Typography
          text="*Duration is in seconds and Frequency is in minutes"
          variant="bodySmall"
          customClass="text-gray-500"
        />
      </TableDialog>
    </>
  );
};

export default ContractionCounter;
