import { useSnackbar } from '../../../components/Snack';
import axios from '../../../app/axiosConfig';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  MutationScope,
  createMutationScopeID,
} from '../../../mutations/utils/create-mutation-scope-id';
import { UpdatePatientDTO } from '@aster/shared/dtos/patient';

export const useUpdatePatientMutation = (patientID: string) => {
  const { showMessage } = useSnackbar();
  const queryClient = useQueryClient();

  const updatePatient = async (newForm: UpdatePatientDTO) =>
    axios.put(`/v2/patients/${patientID}`, newForm);

  const updatePatientMutation = useMutation({
    mutationKey: ['updatePatient'],
    mutationFn: updatePatient,
    scope: {
      id: createMutationScopeID(MutationScope.PATIENT, patientID),
    },
    onSuccess: () => {
      showMessage({
        message: 'Patient updated successfully',
        type: 'success',
      });
      queryClient.invalidateQueries({ queryKey: ['patients', patientID] });
      queryClient.invalidateQueries({
        queryKey: ['patientProfile', patientID],
      });
      queryClient.invalidateQueries({
        queryKey: ['patientForLabs', patientID],
      });
      queryClient.invalidateQueries({
        queryKey: ['isPatientValidForLabs', patientID],
      });
    },
    onError: () => {
      showMessage({
        message: 'An error occurred while updating patient. Please try again.',
        type: 'error',
      });
    },
  });

  return { updatePatientMutation };
};
