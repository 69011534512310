import React from 'react';

import Typography from '../../../../../components/Typography';
import { cn } from '@aster/client/utils/cn';

export type SectionTitleProps = React.HTMLAttributes<HTMLHeadingElement> & {
  icon?: React.ReactNode;
  action?: React.ReactNode;
  children: React.ReactNode;
};

export const SectionTitle = ({
  className,
  icon,
  action,
  children,
  ...rest
}: SectionTitleProps) => (
  <div className="flex justify-between">
    <Typography
      variant="h5"
      customClass={cn('flex items-center gap-3', className)}
      {...rest}
    >
      {icon && <span className="text-gray">{icon}</span>} {children}
    </Typography>
    {action}
  </div>
);
