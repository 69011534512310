import { useMutation } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';
import {
  MutationScope,
  createMutationScopeID,
} from '../../../mutations/utils/create-mutation-scope-id';

type CompleteFormMutationProps = {
  onSuccess: () => void;
};

export const useCompleteFormMutation = ({
  onSuccess,
}: CompleteFormMutationProps) => {
  const completeForm = async (token: string) =>
    axios.post(`/v2/i/patients/complete-form/${token}`);

  const completeFormMutation = useMutation({
    mutationKey: ['completeFormLink'],
    mutationFn: completeForm,
    scope: {
      id: createMutationScopeID(MutationScope.INTAKE_FORM),
    },
    onSuccess: () => {
      onSuccess();
    },
  });

  return { completeFormMutation };
};
