import { styled } from '@mui/system';
import { colors } from '../../theme';

export const CalendarWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  '& a': {
    color: `${colors.gray} !important`,
    textDecoration: 'none!important',
    fontSize: '12px !important',
  },
  '& .fc-media-screen': {
    width: '100%',
    height: '100%',
  },
  '& .fc .fc-scrollgrid-liquid': {
    height: '90% !important',
    [theme.breakpoints.down('md')]: {
      height: '500px !important',
    },
  },
  '& .fc-view-harness .fc-view-harness-active': {
    height: '90% !important',
  },
  '& .fc .fc-timegrid-slot': {
    fontSize: 12,
    height: '2px !important',
  },
  '& .fc-datagrid-cell-frame': {
    height: '3px !important',
  },
  '& .fc-timeline-lane-frame': {
    height: '3px !important',
  },
  '& .fc .fc-timegrid-slot-minor': {
    borderTopStyle: 'none',
  },
  '&  .fc-scrollgrid-section > td': {
    border: 0,
  },
  '& .fc-col-header-cell': {
    borderWidth: '0 1px 0 1px',
    '& .fc-col-header-cell-cushion': {
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 500,
      fontSize: 12,
      textTransform: 'uppercase',
    },
  },
  '& .fc-view ': {
    height: '100%',
    '& > .fc-scrollgrid': {
      border: 0,
    },
  },
  '& .fc-daygrid-day': {
    paddingTop: 10,
  },
  '& .fc-daygrid-day.fc-day-today': {
    backgroundColor: 'transparent !important',
    //today month
    '& .fc-daygrid-day-number': {
      borderRadius: '100%',
      padding: 15,
      backgroundColor: `${colors.red} !important`,
      color: `white !important`,
    },
  },
  '& .fc-daygrid-day-top': {
    // month and year
    justifyContent: 'center',
    '& .fc-daygrid-day-number': {
      color: colors.gray,
      fontWeight: 500,
      fontSize: 10,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 26,
      height: 26,
      margin: '4px 0',
      borderRadius: '50%',
      float: 'none',
      lineHeight: 1,
    },
  },
  '& .fc-h-event': {
    background: 'initial',
  },
  '& .fc-event': {
    border: 0,
    padding: '0 ',
    fontSize: 10,
    backgroundColor: 'transparent',
  },
  '& .fc-license-message': {
    display: 'none',
  },
  '.cell': {
    backgroundColor: 'white !important',
    borderRight: `1px solid ${colors.grayLight} !important`,
  },
  '.cell-year': {
    '& .fc-daygrid-day-events': {
      display: 'none !important',
    },
  },
}));
