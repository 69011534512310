import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import ButtonType from '../../components/Button';
import Typography from '../../components/Typography';
import { useEFaxSetup } from './EFaxSetupContext';
import PlanSummary from './PlanSummary';

const SetUpFaxModalConfirmation = () => {
  const {
    isSettingUpEFax,
    goToInitialStep,
    isEFaxSetupOpen,
    cancelSetup,
    eFaxSetupStep,
    confirmSetup,
    areaCode,
  } = useEFaxSetup();

  return (
    <Dialog
      open={isEFaxSetupOpen && eFaxSetupStep === 'confirmation'}
      onClose={cancelSetup}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <div className="flex justify-between items-center w-full">
          <Typography variant="h4">Add a fax line</Typography>
          <ButtonType
            onClick={goToInitialStep}
            variant="text"
            text="Go Back"
            className="text-bodyMedium"
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-2">
            <Typography variant="h6">Review</Typography>
            <Typography variant="bodyMedium">
              You are getting a new EFax number with country code <b>+1</b> and
              area code <b>{areaCode}</b>
            </Typography>
          </div>
          <PlanSummary />
        </div>
      </DialogContent>
      <DialogActions className=" bg-white flex flex-col items-center">
        <ButtonType
          variant="contained"
          text="Confirm"
          onClick={confirmSetup}
          disabled={isSettingUpEFax}
          loading={isSettingUpEFax}
          classes="min-h-[42px] w-full"
        />
      </DialogActions>
    </Dialog>
  );
};

export default SetUpFaxModalConfirmation;
