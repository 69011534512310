import { HTMLAttributes } from 'react';
import { useDropzone } from 'react-dropzone';
import Typography from './Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/pro-light-svg-icons';
import { cn } from '@aster/client/utils/cn';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@aster/client/ui/Button/Button';

type PDFUploadFieldProps = Partial<
  Omit<HTMLAttributes<HTMLInputElement>, 'onDrop'>
> & {
  pdfName?: string | null;
  onDrop?: (pdf: File) => void;
  onDownload?: () => void;
  onCancel?: () => void;
  onRetry?: () => void;
  isLoading?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  multiple?: boolean;
};

export const PDFUploadField = ({
  className,
  pdfName,
  onDrop,
  onDownload,
  onCancel,
  onRetry,
  multiple = false,
  isLoading = false,
  isError = false,
  isSuccess = false,
  ...rest
}: PDFUploadFieldProps) => {
  const isDisabled = isLoading;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    multiple,
    onDrop: ([pdf]) => {
      onDrop?.(pdf);
    },
    disabled: isDisabled,
  });

  const classNames = cn(
    'cursor-pointer border border-gray-300 bg-gray-100 hover:bg-gray-200 border-dashed w-auto mx-auto content-center text-center overflow-hidden h-[90px] rounded-[8px] transition-color duration-300 ease-in-out',
    { 'bg-gray-200': isDragActive },
    className,
    { 'cursor-default bg-white border-solid hover:bg-white': isDisabled }
  );

  return (
    <div className="space-y-1">
      <div
        {...getRootProps({
          className: cn('group', classNames),
        })}
      >
        <input {...getInputProps({})} {...rest} />
        {pdfName ? (
          <div className="flex flex-col items-center justify-center gap-3">
            <Typography variant="bodyMedium" customClass="font-semibold">
              {pdfName}
            </Typography>
            {isLoading && (
              <div className="flex items-center gap-2">
                <CircularProgress size="1.25rem" className="text-aster-main" />
                <Typography variant="bodyMedium">Uploading</Typography>
                <Button
                  variant="danger-link"
                  size="link"
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onCancel?.();
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
            {isSuccess && (
              <div className="flex gap-2 items-center">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  color="#15803D"
                  size="lg"
                />
                <Typography variant="bodyMedium">Uploaded!</Typography>
              </div>
            )}
            {isError && (
              <div className="flex gap-2 items-center">
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  color="#B91C1C"
                  size="lg"
                />
                <Typography variant="bodyMedium">
                  Oops, something went wrong!
                </Typography>

                <Button
                  className="text-bodySmall py-1 h-7"
                  variant="outline"
                  size="sm"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    onRetry?.();
                  }}
                >
                  Retry
                </Button>
              </div>
            )}
          </div>
        ) : (
          <Typography variant="bodyMedium">
            Click to upload or drag and drop a PDF file
          </Typography>
        )}
      </div>
    </div>
  );
};
