import { cn } from '@aster/client/utils';
import React from 'react';

export type FormControlProps = React.HTMLAttributes<HTMLDivElement>;

const FormControl = React.forwardRef<HTMLDivElement, FormControlProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        className={cn('group flex flex-col', className)}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    );
  }
);
FormControl.displayName = 'FormControl';

export { FormControl };
