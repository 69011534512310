import dayjs from 'dayjs';

export function isWeekend(date: dayjs.Dayjs) {
  return date.day() === 0 || date.day() === 6;
}

export const parseDate = (date: string | undefined) => {
  const parsedDate = dayjs(date?.length ?? 0 > 2 ? date : null);
  return parsedDate.isValid() ? parsedDate.toISOString() : undefined;
};
