import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ImageIcon from '@mui/icons-material/Image';

export const DocumentIcon = (fileName: string, classes?: string) => {
  const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'];
  const extension = fileName.split('.').pop();

  if (extension) {
    if (extension === 'pdf') {
      return <PictureAsPdfIcon className={classes} />;
    }
    if (imageTypes.includes(extension.toLowerCase())) {
      return <ImageIcon className={classes} />;
    }
  }

  return <InsertDriveFileIcon />;
};
