import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreateAppointmentDTO } from '@aster/shared/dtos/appointment';
import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';
import { useSnackbar } from '../../../components/Snack';
import axios from '../../../app/axiosConfig';
import { logAnalyticEvent } from '../../../app/firebase';
import { AxiosResponse } from 'axios';

const createAppointmentInner = async (appointment: CreateAppointmentDTO) => {
  return await axios.post('/appointments', appointment);
};

export const useCreateAppointmentMutation = ({
  timeRef,
  onSuccess,
  onError,
}: {
  timeRef: React.MutableRefObject<{
    startTime: string;
    endTime: string;
  }>;
  onSuccess?: (
    _: AxiosResponse<any, any>,
    variables: CreateAppointmentDTO
  ) => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();

  const { showMessage } = useSnackbar();

  const {
    mutate: createAppointment,
    isPending: isCreatingAppointment,
    ...rest
  } = useMutation({
    mutationKey: ['createAppointment'],
    mutationFn: createAppointmentInner,
    scope: {
      id: createMutationScopeID(MutationScope.APPOINTMENT),
    },
    onMutate: (newAppt: CreateAppointmentDTO) => {
      const previousAppointments = queryClient.getQueryData([
        'fetchAppts',
        timeRef.current.startTime,
        timeRef.current.endTime,
      ]);
      queryClient.setQueryData<CreateAppointmentDTO[]>(
        ['fetchAppts', timeRef.current.startTime, timeRef.current.endTime],
        (old) => {
          if (!old) return [newAppt];
          return [...old, newAppt];
        }
      );
      return { previousAppointments };
    },
    onError: (_, appointment, context) => {
      const [patientID] = appointment.invitedPatientIDs;

      queryClient.setQueryData(
        ['appointmentsByPatient', patientID],
        context?.previousAppointments
      );

      showMessage({
        message:
          'An error occured while creating appointment. Please try again!',
        type: 'error',
      });

      onError?.();
    },
    onSuccess: (_, variables) => {
      const [patientID] = variables.invitedPatientIDs;

      queryClient.invalidateQueries({
        queryKey: ['appointmentsByPatient', patientID],
      });

      logAnalyticEvent('calendar', 'create_appt');
      showMessage({
        message: 'Appointment created successfully!',
        type: 'success',
      });

      onSuccess?.(_, variables);
    },
  });

  return { createAppointment, isCreatingAppointment, ...rest };
};
