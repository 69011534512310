import { useQuery } from '@tanstack/react-query';

import { createStorageKey } from '@aster/shared/utils/storage';

import axios from '../../../app/axiosConfig';
import { useSnackbar } from '../../../components/Snack';
import { getS3SignedUrl } from '@aster/shared/utils/s3-util';

const getPracticeSettings = async (id: string, showImageIssue: () => void) => {
  const { data: practiceSettings } = await axios.get(`/v2/practices/${id}`);

  const logoUrl = await getS3SignedUrl({
    Bucket: import.meta.env.VITE_DOCUMENTS_BUCKET,
    s3key: createStorageKey({
      practiceId: practiceSettings.id,
      scope: 'assets',
      name: 'logo',
    }),
    fileName: 'logo',
    onError: showImageIssue,
  });

  if (logoUrl) practiceSettings.logoUrl = logoUrl;

  return practiceSettings;
};

export const usePracticeSettingsQuery = (id?: string, opts = {}) => {
  const { showMessage } = useSnackbar();
  const showImageIssue = () =>
    showMessage({ message: 'Failed to load practice logo', type: 'error' });

  const { data: practiceSettings, ...rest } = useQuery({
    queryKey: ['practiceSettings'],
    queryFn: () => getPracticeSettings(id as string, showImageIssue),
    enabled: !!id,
    ...opts,
  });

  return { practiceSettings, ...rest };
};
