import styled from '@mui/system/styled';
import { colors } from '../theme';
import Chip from '@mui/material/Chip';

export const FilterChip = styled(Chip)`
  &.MuiChip-root {
    border-radius: 4px;
    background-color: ${colors.grayBackground};
    color: ${colors.gray};
    font-weight: 500;
    padding: 0.5rem;
  }
`;

export default FilterChip;
