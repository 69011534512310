import { Input, InputProps } from '@mui/base/Input';
import Search from '../assets/icons/Search';
import { ReactNode } from 'react';
import { cn } from '@aster/client/utils/cn';

interface InputAdornmentProps {
  children: ReactNode;
}

const InputAdornment = ({ children }: InputAdornmentProps) => {
  return (
    <div className="mr-2 inline-flex items-center justify-center">
      {children}
    </div>
  );
};

const SearchInput = ({ className, ...props }: InputProps) => {
  return (
    <Input
      {...props}
      slotProps={{
        root: {
          className: cn(
            'bg-grayBackground w-full px-4 py-2 min-h-[40px] rounded-[6.25rem] text-bodySmall flex items-center',
            className
          ),
        },
        input: {
          className: 'w-full bg-transparent text-gray-500',
        },
      }}
      id={props.id}
      startAdornment={
        <InputAdornment>
          <Search />
        </InputAdornment>
      }
    />
  );
};

export default SearchInput;
