import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import Typography from './Typography';
import { colors } from '../theme';

interface StyledTabsProps {
  children?: React.ReactNode;
  value: string;
  onChange: (e: React.SyntheticEvent<Element, Event>, newValue: string) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => <Tabs {...props} />)({
  border: `1px solid ${colors.grayLight}`,
  width: '100%',
  maxHeight: 20,
  borderRadius: 5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&.MuiTabs-root': {
    marginTop: 10,
    marginLeft: 1,
    minHeight: '35px !important',
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
});

const StyledTab = styled(Tab)(({ totalTabs }: { totalTabs: number }) => ({
  width: `${100 / totalTabs}%`,
  textTransform: 'none',
  backgroundColor: `${colors.white} !important`,
  fontSize: 12,
  fontWeight: 600,
  borderRight: `1px solid ${colors.grayLight}`,
  '&.Mui-selected': {
    color: colors.white,
    backgroundColor: `${colors.aster} !important`,
    '&:disabled': {
      backgroundColor: `${colors.grayBlock} !important`,
      color: colors.black,
    },
  },
  '&:hover': {
    backgroundColor: `${colors.grayBlock} !important`,
  },
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
}));

const TabsSelectComponent = (props: {
  tabs: TabProps[];
  value?: string;
  question?: string;
  disabled?: boolean;
  property: string;
  handleChange: (property: string, newValue: string) => void;
}) => {
  const { tabs, value, question, property, disabled, handleChange } = props;

  return (
    <div className="flex flex-row mb-3 w-full">
      {question && (
        <Typography
          variant="bodySmall"
          text={question}
          customClass="mt-4 mr-2 text-gray-500 min-w-[80px]"
        />
      )}
      <StyledTabs
        value={value ?? ''}
        onChange={(e, newValue) => handleChange(property, newValue)}
      >
        {tabs.map((tab) => (
          <StyledTab
            key={tab.value}
            disabled={disabled}
            label={tab.label}
            value={tab.value}
            totalTabs={tabs.length}
          />
        ))}
      </StyledTabs>
    </div>
  );
};

export default TabsSelectComponent;
