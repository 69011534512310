import { Token } from '@aster/shared/dtos/token';
import axios from '../../../app/axiosConfig';
import { useQuery } from '@tanstack/react-query';

const validateToken = async (token?: string) => {
  const response = await axios.get<Token>(`token/${token}/validate`);
  return response.data;
};

export const useValidateToken = (token?: string) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['token', token],
    queryFn: () => validateToken(token),
  });
  return { tokenData: data, tokenIsValidating: isLoading, tokenError: error };
};
