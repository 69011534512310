import CalendarApp from '../features/calendar/CalendarApp';

const Calendar = () => {
  return (
    <div className="flex flex-col w-full h-full overflow-auto">
      <CalendarApp />
    </div>
  );
};

export default Calendar;
