import { cn } from '@aster/client/utils/cn';
import RadioOptions from './RadioOptions';
import BasicTextfield from './Textfield';
type RadioProps = {
  value: boolean | null;
  question: string;
  handleOption?: any;
  handleInfo?: any;
  required?: boolean;
  field?: string;
  info?: string;
  hide?: boolean;
  label?: string;
  classes?: string;
  inforWidth?: string;
  disabled?: boolean;
};

const RadioYN = (props: RadioProps) => {
  return (
    <div className={cn('ml-[2px]', props.classes)}>
      <div className="flex flex-col gap-1">
        <div className={`col-span-${props.value && !props.hide ? '8' : '12'}`}>
          <RadioOptions
            value={props.value}
            options={[
              { value: false, text: 'No', key: 'no' },
              { value: true, text: 'Yes', key: 'yes' },
            ]}
            required={props.required}
            disabled={props.disabled}
            label={props.question}
            handleChange={(event: any) =>
              props.handleOption(event, props.value)
            }
          />
        </div>
        <div className="col-span-5">
          {props.value && !props.hide && (
            <BasicTextfield
              id={props.field || 'text'}
              variant="filled"
              disabled={props.disabled}
              label={props.label || 'Details'}
              name={props.field}
              value={props.info ?? ''}
              onChange={(event: any) => props.handleInfo(event)}
              classes={`mt-1 w-[${
                props.inforWidth ? props.inforWidth : '500px'
              }]`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RadioYN;
