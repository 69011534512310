import React, { useState } from 'react';
import { TabProps } from '@mui/material/Tab';
import BasicTextfield from '../../../components/Textfield';
import TabsSelectComponent from '../../../components/TabsSelectComponent';
import CollapsibleComponent from '../../../components/CollapsibleComponent';
import PerineumPicture from '../../../assets/Perineum.webp';
import dayjs from 'dayjs';
import { PerineumInfo } from './types/birth.types';

const tabsPerineal: TabProps[] = [
  { label: 'Abrasion', value: 'abrasion' },
  { label: '1°', value: '1°' },
  { label: '2°', value: '2°' },
  { label: '3°', value: '3°' },
];

const tabsPosition: TabProps[] = [
  { label: 'Left', value: 'left' },
  { label: 'Bilateral', value: 'bilateral' },
  { label: 'Right', value: 'right' },
];

const tabsEpisiotomy: TabProps[] = [
  { label: 'Mediolateral Left', value: 'mediolateral left' },
  { label: 'Medial', value: 'medial' },
  { label: 'Mediolateral Right', value: 'mediolateral right' },
];

const tabsRepair: TabProps[] = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

type PerineumProps = {
  data: PerineumInfo;
  disabled: boolean;
  handleNote: (note: string) => void;
  handleSave: (property: string, event: PerineumInfo) => void;
};

const Perineum = ({
  data,
  disabled,
  handleSave,
  handleNote,
}: PerineumProps) => {
  const [extraNote, setExtraNote] = useState('');
  const newNote = (property: string, value: string) => {
    const note = `Perineum: ${
      property ? `${property} ` : ''
    } ${value} at ${dayjs(new Date()).format('MM/DD/YYYY HH:mm:ss')}`;
    handleNote(note);
  };

  const handlePerineum = (property: string, newValue: string) => {
    handleSave('perineum', { ...data, [property]: newValue });
    newNote(property, newValue);
  };

  const handleExtraNote = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setExtraNote(value);
    handleSave('perineum', { ...data, [name]: value });
  };

  return (
    <div className="mb-4">
      <CollapsibleComponent label="Perineum">
        <div className="flex flex-col h-full rounded-b-lg bg-grayCard px-2.5">
          <div className="flex justify-center bg-white">
            <img
              src={PerineumPicture}
              alt="Perineum"
              className="my-2 w-[40%]"
            />
          </div>
          <TabsSelectComponent
            question="Perineal"
            disabled={disabled}
            tabs={tabsPerineal}
            value={data.perineal}
            handleChange={handlePerineum}
            property="perineal"
          />
          <TabsSelectComponent
            question="Labial"
            disabled={disabled}
            tabs={tabsPosition}
            value={data.labial}
            handleChange={handlePerineum}
            property="labial"
          />
          <TabsSelectComponent
            question="Periurethral"
            disabled={disabled}
            tabs={tabsPosition}
            value={data.periurethal}
            handleChange={handlePerineum}
            property="periurethal"
          />
          <TabsSelectComponent
            question="Episiotomy"
            disabled={disabled}
            tabs={tabsEpisiotomy}
            value={data.episiotomy}
            handleChange={handlePerineum}
            property="episiotomy"
          />
          <TabsSelectComponent
            question="Repair"
            disabled={disabled}
            tabs={tabsRepair}
            value={data.repair}
            handleChange={handlePerineum}
            property="repair"
          />
          <BasicTextfield
            id="note"
            variant="filled"
            name="note"
            label="Note"
            disabled={disabled}
            value={data.note}
            onChange={handleExtraNote}
            onBlur={() => newNote('note', extraNote)}
            classes="mb-4"
          />
        </div>
      </CollapsibleComponent>
    </div>
  );
};

export default Perineum;
