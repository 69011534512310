const Play = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
    >
      <rect x="0.5" y="1" width="24" height="24" rx="5" fill="white" />
      <rect x="0.5" y="1" width="24" height="24" rx="5" stroke="#E8E5DA" />
      <path
        d="M17.3764 11.6891C17.9295 12.0883 17.9295 12.9117 17.3764 13.3109L11.0852 17.8512C10.4238 18.3285 9.5 17.8559 9.5 17.0403L9.5 7.95972C9.5 7.14411 10.4238 6.67153 11.0852 7.14883L17.3764 11.6891Z"
        fill="#09BC8A"
      />
    </svg>
  );
};

export default Play;
