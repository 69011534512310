import {
  history,
  imaging,
  initialOB,
  laborAdmissionNote,
  laborSoap,
  labs,
  soap,
} from './templatesFiles/GeneralTemplates';
import {
  followup,
  initialVisit,
  initialVisitExtended,
  physicalPrenatal,
  ppVisit,
  week1014,
  week1519,
  week2025,
  week2629,
  week3034,
  week3536,
  week3740,
  week4042,
} from './templatesFiles/PrenatalTemplates';

export const HISTORY = 'history';
export const LABS = 'labs';
export const IMAGING = 'imaging';
export const SOAP = 'soap';
export const LABOR_SOAP = 'laborSOAP';
export const LABOR_ADMISSION_NOTE = 'laborAdmissionNote';
export const INITIAL_OB = 'initialOB';
export const PHYSICAL_PRENATAL = 'physicalPrenatal';
export const PRENATAL_INITIAL_VISIT = 'prenatalIniVis';
export const PRENATAL_INITIAL_VISIT_EXT = 'prenatalIniVisExt';
export const WEEK_1014 = 'week1014';
export const WEEK_1519 = 'week1519';
export const WEEK_2025 = 'week2025';
export const WEEK_2629 = 'week2629';
export const WEEK_3034 = 'week3034';
export const WEEK_3536 = 'week3536';
export const WEEK_3740 = 'week3740';
export const WEEK_4042 = 'week4042';
export const PP_VISIT = 'ppVisit';
export const FOLLOWUP = 'followup';

export type TemplateTypes =
  | typeof HISTORY
  | typeof LABS
  | typeof IMAGING
  | typeof SOAP
  | typeof LABOR_SOAP
  | typeof LABOR_ADMISSION_NOTE
  | typeof INITIAL_OB
  | typeof PHYSICAL_PRENATAL
  | typeof PRENATAL_INITIAL_VISIT
  | typeof PRENATAL_INITIAL_VISIT_EXT
  | typeof WEEK_1014
  | typeof WEEK_1519
  | typeof WEEK_2025
  | typeof WEEK_2629
  | typeof WEEK_3034
  | typeof WEEK_3536
  | typeof WEEK_3740
  | typeof WEEK_4042
  | typeof PP_VISIT
  | typeof FOLLOWUP;

export const getMappedTemplates = (patientData: any, profile: any) => ({
  [HISTORY]: history(patientData, profile),
  [LABS]: labs(),
  [IMAGING]: imaging(),
  [SOAP]: soap(patientData, profile),
  [LABOR_SOAP]: laborSoap(patientData),
  [LABOR_ADMISSION_NOTE]: laborAdmissionNote(patientData, profile),
  [INITIAL_OB]: initialOB(patientData, profile),
  [PHYSICAL_PRENATAL]: physicalPrenatal(),
  [PRENATAL_INITIAL_VISIT]: initialVisit(patientData, profile),
  [PRENATAL_INITIAL_VISIT_EXT]: initialVisitExtended(patientData, profile),
  [WEEK_1014]: week1014(patientData, profile),
  [WEEK_1519]: week1519(patientData, profile),
  [WEEK_2025]: week2025(patientData),
  [WEEK_2629]: week2629(patientData),
  [WEEK_3034]: week3034(patientData),
  [WEEK_3536]: week3536(patientData),
  [WEEK_3740]: week3740(patientData),
  [WEEK_4042]: week4042(patientData),
  [PP_VISIT]: ppVisit(patientData, profile),
  [FOLLOWUP]: followup(patientData, profile),
});
