import React, { useState } from 'react';
import { signUp } from 'aws-amplify/auth';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AsterLogo from '../assets/aster';
import BasicTextfield from '../components/Textfield';
import {
  FormControl,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import BasicCheckbox from '../components/Checkbox';
import { logAnalyticEvent } from '../app/firebase';
import { useQuery } from '@tanstack/react-query';
import axios from '../app/axiosConfig';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { z } from 'zod';
import ButtonType from '../components/Button';
import { colors } from '../theme';

type SignUpFormValues = {
  firstname: string;
  lastname: string;
  practice: string;
  email: string;
  password: string;
};

const SignUp = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const practiceId = searchParams.get('practiceId');

  const fetchValidateToken = async (token: string | null) => {
    if (token) {
      const response = await axios.get(`token/${token}/validate`);
      return response.data;
    }
    return;
  };

  const {
    data: tokenData,
    isLoading: checkingToken,
    isError: invalidToken,
  } = useQuery({
    queryKey: ['inviteToken'],
    queryFn: () => fetchValidateToken(token),
    enabled: Boolean(token),
  });

  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
      firstname: '',
      lastname: '',
      practice: '',
    },
    validatorAdapter: zodValidator,
    onSubmit: ({ value }) => {
      handleSignUp(value);
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSignUp = (formData: SignUpFormValues) => {
    setLoading(true);
    const { password, firstname, lastname, practice } = formData;
    const username = tokenData
      ? tokenData.metadata.targetEmail
      : formData.email;
    signUp({
      username,
      password,
      options: {
        userAttributes: {
          email: username,
          family_name: lastname,
          given_name: firstname,
          'custom:practiceName': tokenData
            ? tokenData.metadata.practiceName
            : practice,
          'custom:practiceId': tokenData ? tokenData.metadata.practiceId : '',
          'custom:inviteToken': token ? token : '',
        },
      },
    })
      .then(() => {
        const userInfo = {
          firstname,
          lastname,
          email: username,
          practice,
        };
        localStorage.setItem('user', username);
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        logAnalyticEvent('authentication', 'signup');
        setLoading(false);
      })
      .then(() => navigate('/verify'))
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  if (checkingToken) {
    return (
      <div className="flex flex-col items-center h-screen w-full pt-8 2xl:pt-16 bg-grayBackground overflow-auto">
        <div className="align-middle mb-8">
          <AsterLogo />
        </div>
        <CircularProgress size={20} />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center h-screen w-full pt-8 2xl:pt-16 bg-grayBackground overflow-auto">
      <div className="align-middle">
        <AsterLogo />
      </div>
      {invalidToken ? (
        <div className="flex flex-col items-center lg:w-1/3 sm:w-full min-w-min rounded-[20px] bg-white px-[35px] py-[30px] max-w-[480px] mt-[40px]">
          <div className="text-h4 text-primary font-semibold">Expired link</div>
          <div className="text-body text-gray-500 text-center pb-5">
            This invitation link has expired for security reasons. Please
            request a new invitation from your practice administrator.
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center lg:w-1/3 sm:w-full min-w-min rounded-[20px] bg-white px-[35px] py-[30px] max-w-[480px] mt-[40px]">
          <div className="text-h4 text-primary font-semibold">
            Welcome to Aster
          </div>
          <div className="text-body text-gray-500 text-center pb-5">
            {practiceId && tokenData
              ? `You have been invited by ${tokenData?.metadata.sourceName}, lets set up your account`
              : `Nice to meet you, let's set up your account`}
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              void form.handleSubmit();
            }}
          >
            <FormControl fullWidth>
              <div className="grid grid-cols-2 gap-2 w-full mb-5">
                <form.Field
                  name="firstname"
                  validators={{ onBlur: z.string().min(1) }}
                  children={(field) => (
                    <BasicTextfield
                      id="firstname"
                      error={field.state.meta.errors.length > 0}
                      helperText={field.state.meta.errors.join('\r')}
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        field.handleChange(event.target.value)
                      }
                      required
                      variant="filled"
                      label="First name"
                      width="100%"
                    />
                  )}
                ></form.Field>
                <form.Field
                  name="lastname"
                  validators={{ onBlur: z.string().min(1) }}
                  children={(field) => (
                    <BasicTextfield
                      id="lastname"
                      error={field.state.meta.errors.length > 0}
                      helperText={field.state.meta.errors.join('\r')}
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        field.handleChange(event.target.value)
                      }
                      required
                      variant="filled"
                      label="Last name"
                      width="100%"
                    />
                  )}
                ></form.Field>
              </div>
              {!token && !practiceId && (
                <form.Field
                  name="practice"
                  validators={{ onBlur: z.string().min(1) }}
                  children={(field) => (
                    <BasicTextfield
                      required
                      id="practice"
                      variant="filled"
                      label="Practice"
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        field.handleChange(event.target.value)
                      }
                      error={field.state.meta.errors.length > 0}
                      helperText={field.state.meta.errors.join('\r')}
                      width="100%"
                      classes="mb-5"
                    />
                  )}
                ></form.Field>
              )}
              <form.Field
                name="email"
                validators={
                  tokenData ? undefined : { onBlur: z.string().email() }
                }
                children={(field) => (
                  <BasicTextfield
                    required
                    id="email"
                    variant="filled"
                    label="Email"
                    name={field.name}
                    value={
                      tokenData
                        ? tokenData.metadata.targetEmail
                        : field.state.value
                    }
                    onBlur={field.handleBlur}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      field.handleChange(event.target.value)
                    }
                    error={field.state.meta.errors.length > 0}
                    helperText={field.state.meta.errors.join('\r')}
                    width="100%"
                    classes={`mb-5 ${tokenData && 'pointer-events-none'}`}
                  />
                )}
              ></form.Field>
              <form.Field
                name="password"
                validators={{
                  onChange: z
                    .string()
                    .min(8)
                    .refine((value) => {
                      const hasUppercase = /[A-Z]/.test(value);
                      const hasLowercase = /[a-z]/.test(value);
                      const hasNumber = /\d/.test(value);
                      const hasSpecialChar = /[!@#$%^&*()]/.test(value);
                      return (
                        hasUppercase &&
                        hasLowercase &&
                        hasNumber &&
                        hasSpecialChar
                      );
                    }, 'Password must contain at least one uppercase letter, lowercase letter, number, and special character'),
                }}
                children={(field) => (
                  <BasicTextfield
                    required
                    width="100%"
                    label="Password"
                    variant="filled"
                    id="filled-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      field.handleChange(event.target.value)
                    }
                    error={field.state.meta.errors.length > 0}
                    helperText={field.state.meta.errors.join('\r')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              ></form.Field>
              {error && (
                <div className="grid grid-cols-6 w-full">
                  <p className="text-red-500 !content-start col-span-5">
                    {' '}
                    {error}
                  </p>
                </div>
              )}
              {!token && !practiceId && (
                <div className="pt-5 pb-5 bg-pastelOrange bg-opacity-20 rounded-2xl inline-flex mt-5 px-3 text-center">
                  <span className="text-gray-500 text-bodySmall font-normal font-['Figtree']">
                    Aster is currently open to a select group of women's health
                    practices. Interested in joining? Contact{' '}
                    <a
                      className="text-secondary"
                      href="mailto:fifi@astercare.com?subject=Access%20to%20Aster&body=Hi%20Fifi,"
                    >
                      fifi@astercare.com
                    </a>
                    .
                  </span>
                </div>
              )}
              <div className="flex justify-center items-center mt-6">
                <BasicCheckbox
                  checked={checked}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setChecked(event.target.checked);
                  }}
                  sx={{
                    color: colors.grayLight,
                    '&.Mui-checked': {
                      color: colors.green,
                    },
                  }}
                />
                <span className="text-bodySmall text-gray-500 ml-[-8px]">
                  I agree to Aster’s{' '}
                  <Link
                    to="https://astercare.com/terms-of-use"
                    target="_blank"
                    className="text-secondary hover:opacity-80"
                  >
                    Terms of Use
                  </Link>{' '}
                  and{' '}
                  <Link
                    to="https://astercare.com/baa"
                    target="_blank"
                    className="text-secondary hover:opacity-80"
                  >
                    BAA
                  </Link>
                  .
                </span>
              </div>
              <form.Subscribe
                selector={(state) => [state.canSubmit]}
                children={([canSubmit]) => (
                  <div className="flex w-full justify-center">
                    <ButtonType
                      loading={loading}
                      text="Register"
                      variant="contained"
                      type="submit"
                      disabled={!canSubmit || !checked}
                      classes="px-12 py-6 mt-1"
                    />
                  </div>
                )}
              ></form.Subscribe>
            </FormControl>
          </form>
        </div>
      )}
      {!invalidToken && !checkingToken && (
        <div className="flex gap-2 text-center text-gray-500 sm:text-left my-4 sm:my-6">
          <span>Already have an account?</span>
          <span
            className="text-secondary cursor-pointer hover:opacity-80"
            onClick={() => navigate('/signin')}
          >
            Sign in
          </span>
        </div>
      )}
    </div>
  );
};

export default SignUp;
