import { Tabs, tabsClasses } from '@mui/material';
import StyledTab from '../StyledTab';
import { useState } from 'react';
import PregnancyInformation from '../forms/PregancyInformation';
import ObstetricInformation from '../forms/ObstetricInformation';
import MedicalHistoryInformation from '../forms/MedicalHistory';
import Lifestyle from '../forms/Lifestyle';
import SocialInformation from '../forms/SocialInformation';
import GynecologicHistory from '../forms/GynecologicHistory';
import AdditionalInformation from '../forms/AdditionalInformation';
import { LinkTab } from '../../../components/Tabs';
import { usePatientForm } from '../hooks/usePatientForm';

type HealthTabProps = Pick<
  ReturnType<typeof usePatientForm>,
  | 'gynHis'
  | 'handleGynHisOption'
  | 'handleGynHisInfo'
  | 'handleCheckGynHisInfo'
  | 'handleCheckboxesGynHist'
  | 'handleGynDate'
  | 'pregnancyInformation'
  | 'handlePregancyInfo'
  | 'handlePregnancyCheck'
  | 'handlePregancyInfoOption'
  | 'handlePregnancyDates'
  | 'obstetricInformation'
  | 'handlePregnanciesInformation'
  | 'medicalHistory'
  | 'handleMedicalHistoryOption'
  | 'handleMedicalHistoryInfo'
  | 'handleMedicalHistoryAllergies'
  | 'handleMedicalHistoryCurrentMedications'
  | 'handleCheckboxesMedical'
  | 'lifestyleInformation'
  | 'handleLifestyleOption'
  | 'handleLifestylelInfo'
  | 'handleCheckboxesLifestyle'
  | 'socialInformation'
  | 'handleCheckboxesSocialInfo'
  | 'handleSocialInfoOption'
  | 'handleSocialInfo'
  | 'handleAdditionalInfo'
  | 'additionalInformation'
> & { readonly: boolean };

const HealthTab = ({
  gynHis,
  handleGynHisOption,
  handleGynHisInfo,
  handleCheckGynHisInfo,
  handleCheckboxesGynHist,
  handleGynDate,
  pregnancyInformation,
  handlePregancyInfo,
  handlePregnancyCheck,
  handlePregancyInfoOption,
  handlePregnancyDates,
  obstetricInformation,
  handlePregnanciesInformation,
  medicalHistory,
  handleMedicalHistoryOption,
  handleMedicalHistoryInfo,
  handleMedicalHistoryAllergies,
  handleMedicalHistoryCurrentMedications,
  handleCheckboxesMedical,
  lifestyleInformation,
  handleLifestyleOption,
  handleLifestylelInfo,
  handleCheckboxesLifestyle,
  socialInformation,
  handleCheckboxesSocialInfo,
  handleSocialInfoOption,
  handleSocialInfo,
  handleAdditionalInfo,
  additionalInformation,
  readonly,
}: HealthTabProps) => {
  const [value, setValue] = useState(0);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className="flex grow h-full">
      <Tabs
        orientation={'vertical'}
        allowScrollButtonsMobile
        variant="scrollable"
        value={value}
        onChange={handleChangeTab}
        aria-label="Vertical tabs"
        className="shrink-0"
        sx={{
          borderRight: 1,
          borderColor: 'divider',
          overflow: 'auto',
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        <StyledTab label="Gynecologic History" />
        <StyledTab label="Pregnancy Information" />
        <StyledTab label="Obstetric History" />
        <StyledTab label="Medical History" />
        <StyledTab label="Lifestyle" />
        <StyledTab label="Social Information" />
        <StyledTab label="Additional Information" />
      </Tabs>
      <LinkTab value={value} index={0}>
        <GynecologicHistory
          readonly={readonly}
          gynHisInfo={gynHis}
          handleOption={handleGynHisOption}
          handleInfo={handleGynHisInfo}
          handleCheck={handleCheckGynHisInfo}
          handleCheckboxes={handleCheckboxesGynHist}
          handleDate={handleGynDate}
        />
      </LinkTab>
      <LinkTab value={value} index={1}>
        <PregnancyInformation
          readonly={readonly}
          pregnancyInfo={pregnancyInformation}
          handleInfo={handlePregancyInfo}
          handleCheck={handlePregnancyCheck}
          handleOption={handlePregancyInfoOption}
          handlePregnancyDates={handlePregnancyDates}
        />
      </LinkTab>
      <LinkTab value={value} index={2}>
        <ObstetricInformation
          readonly={readonly}
          obstetricInfo={obstetricInformation}
          handlePregnanciesInformation={handlePregnanciesInformation}
        />
      </LinkTab>
      <LinkTab value={value} index={3}>
        <MedicalHistoryInformation
          readonly={readonly}
          medicalHistory={medicalHistory}
          handleOption={handleMedicalHistoryOption}
          handleInfo={handleMedicalHistoryInfo}
          handleAllergies={handleMedicalHistoryAllergies}
          handleMedications={handleMedicalHistoryCurrentMedications}
          handleCheckboxes={handleCheckboxesMedical}
        />
      </LinkTab>
      <LinkTab value={value} index={4}>
        <Lifestyle
          readonly={readonly}
          lifestyleInfo={lifestyleInformation}
          handleOption={handleLifestyleOption}
          handleInfo={handleLifestylelInfo}
          handleCheckboxes={handleCheckboxesLifestyle}
        />
      </LinkTab>
      <LinkTab value={value} index={5}>
        <SocialInformation
          socialInfo={socialInformation}
          readonly={readonly}
          handleCheckboxes={handleCheckboxesSocialInfo}
          handleOption={handleSocialInfoOption}
          handleInfo={handleSocialInfo}
        />
      </LinkTab>
      <LinkTab value={value} index={6}>
        <AdditionalInformation
          readonly={readonly}
          additionalInfo={additionalInformation}
          handleInfo={handleAdditionalInfo}
        />
      </LinkTab>
    </div>
  );
};

export default HealthTab;
