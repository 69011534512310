import { SVGProps } from 'react';

const Filter = ({ ref, ...props }: SVGProps<HTMLOrSVGElement>) => {
  return (
    <svg
      {...props}
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0999999 1.65C0.0999999 1.075 0.55 0.6 1.125 0.6H11.85C12.425 0.6 12.9 1.075 12.9 1.65C12.9 1.9 12.8 2.125 12.65 2.3L8.5 7.425V11C8.5 11.45 8.125 11.8 7.675 11.8C7.5 11.8 7.325 11.75 7.175 11.625L4.875 9.8C4.625 9.6 4.5 9.325 4.5 9.025V7.425L0.325 2.3C0.175 2.125 0.0999999 1.9 0.0999999 1.65ZM1.475 1.8L5.55 6.825C5.65 6.95 5.7 7.075 5.7 7.2V8.925L7.3 10.2V7.2C7.3 7.075 7.325 6.95 7.425 6.825L11.525 1.8H1.475Z"
        fill="#586068"
      />
    </svg>
  );
};

export default Filter;
