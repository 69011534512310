import { TryVitalMarkerQuestionDTO } from '@aster/shared/dtos/labs';
import axios from '../../../app/axiosConfig';
import { useQuery } from '@tanstack/react-query';

const fetchPatientLabOrders = async (labTestID: string) => {
  const response = await axios.get<TryVitalMarkerQuestionDTO[]>(
    `/v2/labs/tests/${labTestID}/questions`
  );
  return response.data;
};

export function useSelectedLabTestQuestions(labTestID: string | undefined) {
  const { data: labTestQuestions, isLoading: isLoadingQuestions } = useQuery({
    queryKey: ['labTestQuestions', labTestID],
    queryFn: () => fetchPatientLabOrders(labTestID as string),
    enabled: labTestID !== undefined,
  });

  return { labTestQuestions, isLoadingQuestions };
}
