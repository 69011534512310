import Typography from '../components/Typography';

type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body' | 'bodySmall';

type PropsTextArea = {
  name: string;
  textValue: string;
  border?: string;
  topLabel?: string;
  topLabelVariant?: Variant;
  placeholder?: string;
  handleChange: any;
  disabled?: boolean;
  rows?: number;
  headerClassName?: string;
};
const TextArea = ({
  name,
  textValue,
  topLabel,
  topLabelVariant,
  placeholder,
  handleChange,
  disabled,
  rows,
  headerClassName,
}: PropsTextArea) => {
  return (
    <div className="py-2">
      {topLabel && (
        <Typography
          variant={topLabelVariant ? topLabelVariant : 'bodySmall'}
          text={topLabel}
          customClass={headerClassName}
        />
      )}
      <textarea
        rows={rows ? rows : 1}
        cols={50}
        name={name}
        placeholder={placeholder ? placeholder : 'Insert notes...'}
        value={textValue}
        onChange={handleChange}
        className={`rounded-[10px] border border-grayLight focus:border-gray focus:outline-none p-2 w-full text-bodyMedium`}
        disabled={disabled}
      ></textarea>
    </div>
  );
};

export default TextArea;
