// This code is sourced from a third-party library. Please refer to the documentation
// for usage details, as they don't provide a standalone library and/or extra ways to
// bundle their dependency.
//
// See: https://github.com/frontapp/front-chat-sdk/blob/main/lib/hooks/use-front-chat-boot/index.ts

import { useEffect, useRef, useState } from 'react';

import { boot } from '../helpers/boot';
import {
  type FrontChat,
  type FrontChatOptions,
  type FrontChatParams,
} from '../types';

/*
 * Types.
 */

type Initialize = (params?: FrontChatParams) => void;
type Shutdown = (params?: FrontChatParams) => void;

export interface UseFrontChatBootReturn {
  frontChat: FrontChat | undefined;
  isInitialized: boolean;
  initialize: Initialize | undefined;
  shutdown: Shutdown | undefined;
}

enum FrontChatStatusesEnum {
  IDLE = 'idle',
  READY = 'ready',
  INITIALIZED = 'initialized',
}

/*
 * Hook.
 */

export function useFrontChatBoot(
  element?: HTMLElement,
  options?: FrontChatOptions
): UseFrontChatBootReturn {
  const scriptTagAppended = useRef(false);

  const [status, setStatus] = useState<FrontChatStatusesEnum>(
    FrontChatStatusesEnum.IDLE
  );

  useEffect(() => {
    (async () => {
      if (scriptTagAppended.current) {
        return;
      }
      scriptTagAppended.current = true;

      await boot(element, options);

      setStatus(FrontChatStatusesEnum.READY);
    })().catch(console.error);
  }, [element, options]);

  if (status === FrontChatStatusesEnum.IDLE) {
    return {
      frontChat: undefined,
      isInitialized: false,
      initialize: undefined,
      shutdown: undefined,
    };
  }

  const frontChat: FrontChat = (cmdType, params) => {
    if (!window.FrontChat) {
      console.error(
        '[front-chat-sdk] Have not finished setting up window.FrontChat'
      );
      return;
    }

    if (cmdType === 'init') {
      const onInitCompleted = () => {
        setStatus(FrontChatStatusesEnum.INITIALIZED);
      };

      return window.FrontChat(cmdType, { ...params, onInitCompleted });
    }

    if (cmdType === 'shutdown') {
      setStatus(FrontChatStatusesEnum.READY);
    }

    return window.FrontChat(cmdType, params);
  };

  const initialize = (params?: FrontChatParams) => {
    if (status === FrontChatStatusesEnum.INITIALIZED) {
      return;
    }

    frontChat('init', params);
  };

  const shutdown = (params?: FrontChatParams) => {
    if (status === FrontChatStatusesEnum.READY) {
      return;
    }

    frontChat('shutdown', params);
  };

  return {
    frontChat,
    isInitialized: status === FrontChatStatusesEnum.INITIALIZED,
    initialize,
    shutdown,
  };
}
