// This code is sourced from a third-party library. Please refer to the documentation
// for usage details, as they don't provide a standalone library and/or extra ways to
// bundle their dependency.
//
// See: https://github.com/frontapp/front-chat-sdk/blob/main/lib/hooks/use-front-chat/index.ts

import {
  type UseFrontChatBootReturn,
  useFrontChatBoot,
} from './use-front-chat-boot';

/*
 * Hook.
 */

export function useFrontChat(element?: HTMLElement): UseFrontChatBootReturn {
  const { frontChat, isInitialized, initialize, shutdown } =
    useFrontChatBoot(element);

  return { frontChat, isInitialized, initialize, shutdown };
}
