const SignOut = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.10792 0.970588H7.44616C7.68881 0.970588 7.93145 1.21323 7.93145 1.45588V1.77941C7.93145 2.0625 7.68881 2.26471 7.44616 2.26471H2.10792C1.74395 2.26471 1.46087 2.58823 1.46087 2.91176V14.5588C1.46087 14.9228 1.74395 15.2059 2.10792 15.2059H7.44616C7.68881 15.2059 7.93145 15.4485 7.93145 15.6912V16.0147C7.93145 16.2978 7.68881 16.5 7.44616 16.5H2.10792C1.01601 16.5 0.166748 15.6507 0.166748 14.5588V2.91176C0.166748 1.86029 1.01601 0.970588 2.10792 0.970588ZM13.391 1.77941C13.5528 1.57721 13.8763 1.57721 14.0785 1.77941L20.7109 8.41176C20.9131 8.61397 20.9131 8.89706 20.7109 9.09926L14.0785 15.7316C13.8763 15.9338 13.5528 15.9338 13.391 15.7316L13.1079 15.4485C12.9057 15.2463 12.9057 14.9632 13.1079 14.761L18.4057 9.42279H7.12263C6.83954 9.42279 6.63734 9.22059 6.63734 8.9375V8.53309C6.63734 8.29044 6.83954 8.04779 7.12263 8.04779H18.4057L13.0675 2.75C12.9057 2.54779 12.9057 2.26471 13.0675 2.0625L13.391 1.77941Z"
        fill="#586068"
      />
    </svg>
  );
};

export default SignOut;
