import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { GridColDef } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import StyledDataGrid from '../../../components/StyledDataGrid';
import ButtonType from '../../../components/Button';
import BasicTextfield from '../../../components/Textfield';
import CollapsibleComponent from '../../../components/CollapsibleComponent';
import { colors } from '../../../theme';

import { createId } from '@paralleldrive/cuid2';
import dayjs from 'dayjs';
import { BabyVitalsInfo } from './types/baby.types';
import { cn } from '@aster/client/utils/cn';

type BabyVitalsProps = {
  data: BabyVitalsInfo[];
  disabled: boolean;
  displayVertical?: boolean;
  handleNote: (note: string) => void;
  handleSave: (property: string, event: Partial<BabyVitalsInfo>[]) => void;
};

const BabyVitals = React.memo(
  ({
    data,
    disabled,
    displayVertical,
    handleSave,
    handleNote,
  }: BabyVitalsProps) => {
    const rows = data || [];
    const [open, setOpen] = useState(false);
    const [state, setState] = useState<Partial<BabyVitalsInfo>>({});

    const handleFormInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({
        ...state,
        [event.target.name]: event.target.value,
      });
    };

    const createNote = () => {
      const note = `Baby Vitals: 
      ${state.heartRate ? `Heart Rate: ${state.heartRate},` : ''}
      ${state.respirationRate ? `Resp Rate: ${state.respirationRate},` : ''}
      ${state.temperature ? `Temperature: ${state.temperature},` : ''}
      ${state.color ? `Color: ${state.color}` : ''}
     at ${dayjs(new Date()).format('MM/DD/YYYY HH:mm:ss')}.`;

      handleNote(note);
    };

    const saveBabyVitals = () => {
      const newResult: Partial<BabyVitalsInfo> = {
        id: createId(),
        heartRate: state.heartRate,
        respirationRate: state.respirationRate,
        temperature: state.temperature,
        color: state.color,
        spo2: state.spo2,
      };
      handleSave('babyVitals', [...rows, newResult]);
      createNote();
      setState({
        id: '',
        heartRate: '',
        respirationRate: '',
        temperature: '',
        color: '',
        spo2: '',
      });
    };

    const handleClose = () => setOpen(false);

    const columns: GridColDef<(typeof rows)[number]>[] = [
      {
        field: 'heartRate',
        headerName: 'HR',
        headerAlign: 'left',
        flex: 1,
        headerClassName: 'bg-grayBackground',
        sortable: false,
      },
      {
        field: 'respirationRate',
        headerName: 'RR',
        headerAlign: 'left',
        flex: 1,
        headerClassName: 'bg-grayBackground',
        sortable: false,
      },
      {
        field: 'temperature',
        headerName: 'Temp.',
        headerAlign: 'left',
        flex: 1,
        headerClassName: 'bg-grayBackground',
        sortable: false,
      },
      {
        field: 'color',
        headerName: 'Color',
        headerAlign: 'left',
        flex: 1,
        headerClassName: 'bg-grayBackground',
        sortable: false,
      },
    ];

    const VitalsTable = () => {
      return (
        <div className="grid w-full">
          <StyledDataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            onCellClick={() => setOpen(true)}
            disableRowSelectionOnClick
            autoHeight
            sx={{
              backgroundColor: colors.white,
              width: '100%',
              overflow: 'auto',
              fontSize: '12px',
              textAlign: 'center',
            }}
          />
        </div>
      );
    };

    return (
      <CollapsibleComponent label="Child vitals" color="bg-beige">
        {!disabled && (
          <>
            <div className="flex flex-col h-full w-full rounded-b-lg bg-beige px-2.5 pb-2.5">
              <div
                className={cn(
                  'flex gap-4 pb-2 justify-center',
                  !displayVertical ? 'md:flex-row ' : 'flex-col'
                )}
              >
                <BasicTextfield
                  id="HR"
                  variant="filled"
                  name="heartRate"
                  label="HR (bpm)"
                  value={state.heartRate}
                  onChange={handleFormInput}
                  onKeyDown={saveBabyVitals}
                />
                <BasicTextfield
                  id="respRate"
                  variant="filled"
                  name="respirationRate"
                  label="Resp rate (bpm)"
                  value={state.respirationRate}
                  onChange={handleFormInput}
                  onKeyDown={saveBabyVitals}
                />
              </div>
              <div
                className={`flex flex-col ${
                  !displayVertical ? 'md:flex-row ' : ''
                } gap-4 pb-2  justify-center`}
              >
                <BasicTextfield
                  id="temperature"
                  variant="filled"
                  name="temperature"
                  label="Temperature (ºF)"
                  value={state.temperature}
                  onChange={handleFormInput}
                  onKeyDown={saveBabyVitals}
                />
                {displayVertical && (
                  <BasicTextfield
                    id="spo2"
                    variant="filled"
                    name="spo2"
                    label="SpO2"
                    value={state.spo2}
                    onChange={handleFormInput}
                    onKeyDown={saveBabyVitals}
                  />
                )}
                {!displayVertical && (
                  <BasicTextfield
                    id="color"
                    variant="filled"
                    name="color"
                    label="Color"
                    value={state.color}
                    onChange={handleFormInput}
                    onKeyDown={saveBabyVitals}
                  />
                )}
              </div>
              <div className="flex justify-end  mb-2">
                <ButtonType
                  variant="contained"
                  text="Add"
                  classes={'max-h-[30px] w-[20px]'}
                  icon
                  specificIcon={<AddIcon sx={{ fontSize: '15px' }} />}
                  onClick={() => saveBabyVitals()}
                />
              </div>
              <div className="w-full">
                <VitalsTable />
              </div>
            </div>
            <Dialog open={open} keepMounted onClose={handleClose} fullWidth>
              <div className="flex flex-col items-center">
                <DialogTitle
                  sx={{ m: 0, p: 2, height: 10, fontWeight: 600 }}
                  id="customized-dialog-title"
                >
                  Vitals
                </DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent className="flex flex-col w-full items-center">
                  <VitalsTable />
                </DialogContent>
              </div>
            </Dialog>
          </>
        )}
        {disabled && (
          <div className="flex flex-col items-center rounded-b-lg bg-beige py-2.5 px-2 overflow-auto">
            <VitalsTable />
          </div>
        )}
      </CollapsibleComponent>
    );
  }
);

export default BabyVitals;
