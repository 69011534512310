import { useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import SelectDropdown from '../../components/SelectDropdown';
import { CALENDAR_VIEW, CALENDAR_VIEW_OPTIONS } from './constants';
import { useUserUsagePreferences } from '../../hooks/useUserUsagePreference';

const CalendarViewMenu = (props: any) => {
  const { calendarRef } = props;
  const { storePreference, readPreference } = useUserUsagePreferences();

  const viewSettings =
    readPreference<string>(CALENDAR_VIEW) || CALENDAR_VIEW_OPTIONS[1].value;
  const [view, setView] = useState(viewSettings);

  useEffect(() => {
    calendarRef.current?.getApi().changeView(viewSettings);
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    storePreference(CALENDAR_VIEW, value);

    setView(value);
    calendarRef.current?.getApi().changeView(value);
  };

  return (
    <SelectDropdown
      classes="[&_.MuiSelect-select]:pl-[12px] [&_.MuiSelect-select]:py-[2.5px] [&_.MuiInputBase-root]:rounded-[5px] [&_.MuiSelect-select]:text-bodySmall [&_.MuiSelect-select]:font-semibold [&_.MuiInputBase-root]:border-beige"
      options={CALENDAR_VIEW_OPTIONS}
      handleChange={handleChange}
      value={view}
      label=""
    />
  );
};

export default CalendarViewMenu;
