import { Tab, TabProps, styled } from '@mui/material';
import { colors } from '../../theme';

const StyledHorizontalTab = styled((props: TabProps, index: any) => (
  <Tab disableRipple {...props} classes={{ root: 'text-bodyMedium' }} />
))(() => ({
  fontWeight: 500,
  textTransform: 'initial',
  display: 'flex',
  whiteSpace: 'nowrap',
  borderRadius: '0.5rem',
  padding: '0.5rem 1.5rem',
  minHeight: '1.25rem',
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: `${colors['aster-neutral']} !important`,
  },
}));

export default StyledHorizontalTab;
