import Dialog from '@mui/material/Dialog';
import GettingStarted from './GettingStarted';
import AddPatients from './AddPatients';
import AddClinic from './AddClinic';
import AddClinician from './AddClinician';

type ModalProps = {
  open: boolean;
  handleClose: () => void;
  step: number;
  incrementStep: () => void;
};

const DosespotWizard = ({
  open,
  handleClose,
  step,
  incrementStep,
}: ModalProps) => {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      className=""
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: [1, 2].includes(step) ? '550px' : '800px',
          width: '100%',
        },
      }}
    >
      {step === 0 && <GettingStarted incrementStep={incrementStep} />}
      {step === 1 && <AddClinic incrementStep={incrementStep} />}
      {step === 2 && <AddClinician incrementStep={incrementStep} />}
      {step === 3 && <AddPatients incrementStep={incrementStep} />}
    </Dialog>
  );
};

export default DosespotWizard;
