import Typography from '../../../components/Typography';
import { PDFUploadField } from '../../../components/PDFUploadField';
import { useProcessResultsPDFMutation } from '../mutations/process-results-pdf.mutation';
import ButtonType from '../../../components/Button';
import Divider from '@mui/material/Divider';
import { useLabsStore } from '../store/labs.store';

export default function UploadAndProcessPDF() {
  // We create the id on the client when there is no previous order.
  const selectedFile = useLabsStore((state) => state.selectedFile);
  const labsOrderID = useLabsStore((state) => state.generatedLabOrderID);
  const setSelectedFile = useLabsStore((state) => state.setSelectedFile);
  const setProcessedResults = useLabsStore(
    (state) => state.setProcessedResults
  );
  const goToFillResults = useLabsStore((state) => state.goToFillResults);
  const {
    processResultsPDF,
    isPending: isProcessingPDF,
    isError,
    isSuccess,
  } = useProcessResultsPDFMutation({
    labsOrderID,
    onSuccess: (processedResults) => {
      if (selectedFile) {
        setProcessedResults(processedResults);
      }
    },
  });

  const onPDFDrop = async (file: File) => {
    setSelectedFile(file);
    await processResultsPDF(file);
  };

  return (
    <section>
      <Typography variant="h6" text="Upload a PDF" customClass="mb-3" />
      <Typography
        variant="bodySmall"
        text="We’ll analyze your PDF and extract the lab results. You can make adjustments and additions on the next step."
        customClass="mb-5"
      />

      <PDFUploadField
        isLoading={isProcessingPDF}
        isError={isError}
        isSuccess={isSuccess}
        onDrop={onPDFDrop}
        onCancel={() => setSelectedFile(null)}
        onRetry={() => processResultsPDF(selectedFile as File)}
        pdfName={selectedFile?.name}
      />
      <Divider className="border-gray-200 my-5" />
      <div className="flex justify-end items-center">
        <ButtonType
          onClick={goToFillResults}
          disabled={!isSuccess}
          text="Continue"
          variant="contained"
        />
      </div>
    </section>
  );
}
