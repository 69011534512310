import { Tabs, tabsClasses } from '@mui/material';
import StyledTab from '../StyledTab';
import { useState } from 'react';
import { usePatientForm } from '../hooks/usePatientForm';
import Preferences from '../forms/Preferences';
import { LinkTab } from '../../../components/Tabs';

type PreferencesTabProps = Pick<
  ReturnType<typeof usePatientForm>,
  | 'acccessInfo'
  | 'handleCheckboxesAccess'
  | 'handleCheckboxesCommu'
  | 'commuInformation'
> & { readonly: boolean };

const PreferencesTab = ({
  acccessInfo,
  handleCheckboxesAccess,
  handleCheckboxesCommu,
  commuInformation,
  readonly,
}: PreferencesTabProps) => {
  const [value, setValue] = useState(0);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className="flex grow h-full">
      <Tabs
        orientation={'vertical'}
        allowScrollButtonsMobile
        variant="scrollable"
        value={value}
        onChange={handleChangeTab}
        aria-label="Vertical tabs"
        className="shrink-0"
        sx={{
          borderRight: 1,
          borderColor: 'divider',
          overflow: 'auto',
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        <StyledTab label="Communication Preferences" />
      </Tabs>
      <LinkTab value={value} index={0}>
        <Preferences
          readonly={readonly}
          accessInfo={acccessInfo}
          handleCheckboxesAccess={handleCheckboxesAccess}
          handleCheckboxesCommu={handleCheckboxesCommu}
          commuInfo={commuInformation}
        />
      </LinkTab>
    </div>
  );
};

export default PreferencesTab;
