import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from '@mui/x-date-pickers/DateField';
import RadioYN from '../../../components/RadioYN';
import { PregrancyInfo } from '../types/patient-profile';
import { FormControlLabel, Checkbox } from '@mui/material';
import { colors } from '../../../theme';
import SelectDropdown from '../../../components/SelectDropdown';
import Typography from '../../../components/Typography';
import BasicTextfield from '../../../components/Textfield';

type PregnancyInfoProps = {
  pregnancyInfo: PregrancyInfo;
  handleInfo: (event: any) => void;
  handleOption: (property: string, value: any) => void;
  handlePregnancyDates: (event: any, option: string) => void;
  handleCheck: (event: any, property: string) => void;
  readonly: boolean;
};

const PregnancyInformation = (props: PregnancyInfoProps) => {
  const {
    pregnant,
    hadUltrasounds,
    ultraSoundDetails,
    lmp,
    edd,
    dateOfConception,
    unsureDueDate,
    dateToCalculateDueDate,
  } = props.pregnancyInfo;

  const handleInfo = props.handleInfo;
  const handleOption = props.handleOption;
  const handleCheck = props.handleCheck;
  const handlePregnancyDates = props.handlePregnancyDates;

  return (
    <div className="grid grid-cols-1">
      <p className="text-h5 font-semibold mb-5">Pregnancy Information</p>

      <div className="flex flex-col justify-center mb-5">
        <RadioYN
          classes="mb-1"
          disabled={props.readonly}
          value={pregnant}
          hide
          question="Are you currently pregnant?"
          handleOption={(event: any) =>
            handleOption('pregnant', event.target.value === 'true')
          }
        />

        {pregnant && (
          <>
            <Typography
              text="Please provide one or several of the following dates to best
              estimate your due date"
              variant="body"
              customClass="mt-5 mb-4 text-gray-500 font-semibold"
            ></Typography>

            <div className="w-fit grid grid-cols-1 xl:grid-cols-2 grid-flow-row gap-y-5 gap-x-10 xl:gap-x-40">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                  label="Last Menstrual Period (LMP)"
                  disabled={props.readonly}
                  value={lmp && dayjs(lmp)}
                  sx={{
                    marginTop: 4,
                    width: '300px',
                    backgroundColor: 'white',
                    borderRadius: 1,
                  }}
                  onChange={(e: any) => {
                    handlePregnancyDates(e, 'lmp');
                  }}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                  label="Estimated Due Date (EDD)"
                  disabled={props.readonly}
                  value={edd && dayjs(edd)}
                  sx={{
                    marginTop: 4,
                    width: '300px',
                    backgroundColor: 'white',
                    borderRadius: 1,
                  }}
                  onChange={(e: any) => {
                    handlePregnancyDates(e, 'edd');
                  }}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                  label="Date of Conception"
                  disabled={props.readonly}
                  value={dateOfConception && dayjs(dateOfConception)}
                  sx={{
                    marginTop: 4,
                    width: '300px',
                    backgroundColor: 'white',
                    borderRadius: 1,
                  }}
                  onChange={(e: any) => {
                    handlePregnancyDates(e, 'dateOfConception');
                  }}
                />
              </LocalizationProvider>

              <SelectDropdown
                name="dateToCalculateDueDate"
                disabled={props.readonly}
                label="Date to calculate due date"
                options={[
                  { value: 'edd', text: 'Estimated Due Date (EDD)' },
                  { value: 'lmp', text: 'Last Menstrual Period (LMP)' },
                  { value: 'dateOfConception', text: 'Date of conception' },
                ]}
                value={dateToCalculateDueDate ?? ''}
                width="300px"
                handleChange={(event: any) => handleInfo(event)}
              />
            </div>
          </>
        )}
      </div>

      <FormControlLabel
        className="h-[24px] mb-5"
        key="unsureDueDate"
        control={
          <Checkbox
            sx={{
              color: colors.grayLight,
              '&.Mui-checked': {
                color: colors.green,
              },
            }}
            disabled={props.readonly}
            checked={unsureDueDate}
            onChange={(event: any) => handleCheck(event, 'unsureDueDate')}
            disableRipple
          />
        }
        label="Unsure due date?"
      />

      <div className="w-full">
        <RadioYN
          classes="mb-5"
          disabled={props.readonly}
          value={hadUltrasounds}
          question="Have you had any ultrasound scans?"
          field="ultraSoundDetails"
          handleOption={(event: any) =>
            handleOption('hadUltrasounds', event.target.value === 'true')
          }
          inforWidth="300px"
          hide
        />
        {hadUltrasounds && (
          <BasicTextfield
            id="ultraSoundDetails"
            variant="filled"
            label="Ultrasound details"
            disabled={props.readonly}
            name="ultraSoundDetails"
            value={ultraSoundDetails}
            multiline
            minRows={3}
            maxRows={5}
            onChange={handleInfo}
            classes="w-[300px]"
          />
        )}
      </div>
    </div>
  );
};

export default PregnancyInformation;
