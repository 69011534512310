import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';

type TableDialog = {
  isLoading?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  open: boolean;
  handleClose: () => void;
};
export default function TableDialog({
  children,
  open,
  handleClose,
  isLoading,
}: TableDialog) {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <div className="flex flex-col items-center">
        <DialogTitle sx={{ m: 0, p: 2, height: 10, fontWeight: 600 }}>
          Vitals
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className="flex flex-col items-center w-full">
          {isLoading ? (
            <div className="flex flex-col justify-center items-center h-full w-full">
              <CircularProgress />
            </div>
          ) : (
            children
          )}
        </DialogContent>
      </div>
    </Dialog>
  );
}
