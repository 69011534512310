import { PatientProfileForEncounterDTO } from '@aster/shared/dtos/patient';
import {
  displayAge,
  displayBirthDate,
  displayGTPAL,
  displayGestationalAge,
} from '../templateUtils';

export const physicalPrenatal = () =>
  ` 
Limited physical exam: <br>
Uterus: <strong>[Fundus non-palpable / fundus palpated at __fb]</strong> pubic symphysis. <br>
FHT: <strong>[Unable to auscultate FHT's with doppler / FHT's auscultated at __bpm.]</strong> <br>
`;

export const initialVisit = (
  patientData?: PatientProfileForEncounterDTO,
  profile?: any
) =>
  `
   <strong> S: ${patientData?.name}</strong> is a ${displayAge(
    patientData?.age
  )} ${displayGTPAL(
    patientData?.gtpal
  )}, who comes in for an initial prenatal visit.<br>
Accompanied by _______.<br>
  History reviewed and consistent with risk criteria for care with <strong> ${
    profile.practiceName
  }</strong><br>
  <strong> ${
    patientData?.name
  }</strong> is a <strong>[new client, repeat client]</strong> and plans a <strong>[home birth, birth center birth, hospital birth]</strong> birth with <strong> ${
    profile.practiceName
  }</strong><br>
  <strong> ${
    patientData?.name
  }</strong> <strong> [attended orientation/info session, was oriented today]</strong>.<br>
  <strong> ${
    patientData?.name
  }</strong> received the Care Guide and Info Guide. Discussed resources available in The Care Guide<br>
  <strong> ${
    patientData?.name
  }</strong> <strong> [has had no prior prenatal care, was previously seeing _____]</strong>. The number of visits with their previous provider is ____.<br>
  <strong> ${
    patientData?.name
  }</strong> is currently <strong> ${displayGestationalAge(
    patientData?.gestationalAge
  )}</strong> pregnant.<br>
  First trimester u/s discussed and <strong>[in records, requested, declined]</strong>.<br>
  Dating confirmed by <strong>[LMP, Conception,1st trimester u/s]</strong>.<br>
  Physical exam completed.<br>
  Pelvic exam <strong>[done, declined, not offered]</strong>.<br>
  HRP Disclosures Needed: <strong>[__________, none]</strong><br>
  Signs of Preterm Labor <strong>[not present, not applicable due to early gestation, noted _____]</strong><br>
  Reviewed diet, hydration, supplements<br>
  Exercise discussed<br>
  Sleep discussed<br>
  Reviewed New Labs/Ultrasounds<br>
  Reviewed History Progress Note<br>
  Discussed IP HRP disclosure.<br>
  Discussed other HRP disclosures.<br>
  Discussed Early Pregnancy ICD Packet<br>
  Introduced educational program: library, childbirth education, other classes and events.<br>
  Discussed general nutritional needs of pregnancy.<br>
  Documented current & recommended supplements.<br>
  Reviewed warning signs. ${patientData?.name} expresses understanding.<br>
  Explained routine<strong> S:</strong> self care, appointments, phone contact, student participation<br>
  Discussed finances. Questions directed to office manager.<br>
  Reviewed genetic history and exposure history.<br>
  Discussed and offered prenatal genetic screening.<br>
  Discussed emotional health.<br>
  Discussed Family Support<br>
  Discussed CDC Recommendation for Influenza Vaccine<br>
  Made referrals to outside agencies/providers, as needed<br>
  CC: <strong> ${
    patientData?.name
  }</strong> <strong> [has no major concerns, complains of]</strong><br>
  <strong> O:</strong> Vitals: HR: XX BP: XX Temp: XX, Physical Exam findings (FHR, fundal height, FM)<br>
  <strong> A:</strong> ${displayAge(patientData?.age)} ${displayGTPAL(
    patientData?.gtpal
  )} with IUP XXWeeks X Day, S=D. Appropriate for OOH birth midwifery care at this time.<br>
  <strong> P:</strong> RTC <strong>[number of days/weeks]</strong><br>
  Send paperwork to <strong> ${patientData?.name}</strong>, as needed.<br>
  <strong>[Labs drawn. Consents signed/discussed. Treatment plans discussed such as what to do at next PN visit.]</strong><br>
  <strong>[student midwife]</strong> student midwife, participated in the care of this client under my direct supervision.<br>
  `;

export const initialVisitExtended = (
  patientData?: PatientProfileForEncounterDTO,
  profile?: any
) => `
  <strong>Subjective:</strong><br>
  CC/HPI: <strong>${
    patientData?.name
  }</strong> is in clinic today to initiate prenatal care. Client is oriented to the midwifery model of care and the birth center facility. <strong>[This is a planned and welcomed pregnancy / This is an unplanned but welcomed pregnancy.]</strong> Family desires planned location at birth <strong>[center / home.]</strong> Current symptoms of pregnancy include <strong>[__]</strong>.<br>
  <br>
  <strong>DATING CURRENT PREGNANCY:</strong> Working EDD of <strong>${
    patientData?.calculatedDueDate
      ? patientData.calculatedDueDate
      : 'XX/XX/XXXX'
  }</strong> based on <strong>[_____]</strong>. This is congruent with the best clinical estimate of gestational age, current dating information and is in accordance with ACOG standards.<br>
  <br>
  <strong>Dating Information:</strong> LMP: <strong>${
    patientData?.lmp ? patientData.lmp : 'XX/XX/XXXX'
  }</strong><br>
  <br>
  <strong>LMP EDD:</strong><br>
  <br>
  <strong>US EDD:</strong><br>
  <br>
  <strong>Difference between LMP and US EDD:</strong> _ days - indicating use of US/LMP EDD per ACOG guidelines.<br>
  <br>
  <strong>Positive urine hCG:</strong><br>
  <br>
  <strong>Menses hx:</strong> regular q 28d x 5d, menarche at XX YO<br>
  <br>
  <strong>Notes:</strong><br>
  <br>
  <strong>OB HX:</strong> G_P_<br>
  <br>
  #1 date NSVD at _ w GA in (location). _ hour labor. _#_, (female/male), "baby name". Notes/complications: Pregnancy: normal course / significant for _. Labor/Delivery: normal course / significant for _. Postpartum: normal course / significant for _. Breastfed without difficulties.<br>
  <br>
  <strong>GENERAL HEALTH & PAST MEDICAL HX:</strong> non-remarkable<br>
  <br>
  <strong>PSYCH HX:</strong><br>
  <br>
  - Mood disorder: current stable mood, no hx of mood disorder<br>
  - Interpersonal Violence (IPV): feels safe at home, denies hx<br>
  <br>
  <strong>PAST SURGICAL HX:</strong> non-remarkable<br>
  <br>
  <strong>GYN HX:</strong><br>
  <br>
  - Last PAP: _, normal. Denies hx of abnormal findings. Next PAP indicated in _.<br>
  - STI Hx: none<br>
  - Non-sexually transmitted vaginal infections Hx: none<br>
  - Breast health: normal<br>
  <br>
  <strong>SOCIAL HX & NUTRITION / PHYSICAL ACTIVITY:</strong><br>
  <br>
  Current stress level is reported as _, main source of stress is from ___. Eats a well balanced diet, minimal processed foods, adequate hydration. Regular exercise weekly. No dietary restrictions. She denies use of alcohol, tobacco, drugs in current pregnancy.<br>
  <br>
  <strong>FAMILY MEDICAL HX:</strong> non-remarkable<br>
  <br>
  <strong>FAMILY GENETIC HX:</strong> non-remarkable<br>
  <br>
  <strong>ALLERGIES:</strong> No known drug allergies<br>
  <br>
  <strong>MEDICATIONS & SUPPLEMENTS:</strong><br>
  <br>
  - Prenatal Vitamin: dose daily<br>
  <br>
  <strong>OBJECTIVE</strong> Vitals: HR: BP: Temp, (FHR, exam findings if applicable)<br>
  <br>
  Limited physical exam: Uterus: Fundus non-palpable/ fundus palpated at __fb > pubic symphysis. Unable to auscultate FHT's with doppler/FHT's auscultated at __bpm.<br>
  <br>
  <strong>ASSESSMENT & PLAN</strong><br>
  <br>
  1. Normal course of pregnancy: <strong>[Maternal Age]</strong> <strong>[Parity]</strong> with a (known/presumed) SLIUP at <strong>[Gestational Age]</strong> today based on dating information. VS, all WNL. S=D. FHTs reassuring/Unable to auscultate FHT’s likely due to early gestational age.<br>
  <br>
  2. Risk assessment:<br>
  <br>
  3. Care plan: admitted into care for primary maternity care. Informed consent discussion regrading <strong> ${
    profile.practiceName
  }</strong> midwifery care services, CDM scope of practice, R/B of planned out of hospital delivery, and client consents.<br>
  <br>
  4. Pre-eclampsia risk assessment: low risk, no follow-up recommendations indicated. Moderate risk, informed discussion regarding low-dose aspirin therapy as prophylaxis, recommend initiating therapy at 12wGA until delivered. Risk factors include <strong>[__]</strong>. Client plans to <strong>[__]</strong>.<br>
  <br>
  5. Hospital MD back-up plan: recommend client establish care with MD with hospital privileges in case consult or transfer of care is indicated by presenting risk factor. Client plan to establish care with <strong>[__]</strong>.<br>
  <br>
  6. Dating of pregnancy: EDD <strong>${
    patientData?.calculatedDueDate
      ? patientData.calculatedDueDate
      : 'XX/XX/XXXX'
  }</strong> confirmed with client. Dating US not indicated. / Working EDD <strong>${
  patientData?.calculatedDueDate ? patientData.calculatedDueDate : 'XX/XX/XXXX'
}</strong> confirmed with client. Dating US indicated. Referral sent to <strong>[__]</strong>.<br>
  <br>
  7. Labs: Reviewed initial routine prenatal labs per JFBC protocol and additional labs indicated by client’s health history. Client elects the following labs today/at next visit: all routine labs.<br>
  <br>
  8. Prenatal genetic screening: Informed discussion regarding options and R/B. Client elects the following genetic screening: NIPT, fetal anatomy US in 2nd trimester, and declines all others.<br>
  <br>
  9. Diet & exercise assessment: BMI <strong>[__]</strong>, (18-25 normal, 25-30 overweight, 30+ obese). Target weight gain for this pregnancy ( BMI normal 25-35 pounds, BMI overweight 15-25 pounds, BMI obese 11-20 pounds). Continue to maintain a well balanced diet with adequate protein and iron rich foods. Continue regular exercise regime, encouraging minimum of 30 minutes of aerobic activity 3-4 days/week. Stable food security.<br>
  <br>
  10. Interpersonal violence screen: (positive/negative), reports feeling safe at home.<br>
  <br>
  11. 1st trimester client education: nutrition and physical activity in pregnancy, teratogens, warning signs and when/how to call midwife. For supplements/vitamins - continue prenatal vitamin daily. Recommend 4-6000 IU vitamin D daily and magnesium/calcium supplement daily or PRN.<br>
  <br>
  12. RTC for routine prenatal visit in 4 weeks<br>
  `;

export const week1014 = (
  patientData?: PatientProfileForEncounterDTO,
  profile?: any
) =>
  `
  <strong> S:</strong> ${displayAge(patientData?.age)} ${displayGTPAL(
    patientData?.gtpal
  )} at <strong> ${displayGestationalAge(
    patientData?.gestationalAge
  )}</strong>  estimated by ${displayBirthDate(
    patientData?.calculatedDueDate
  )}<br>
  Presenting to <strong> ${
    profile.practiceName
  }</strong> for: Routine prenatal care<br>
  Prenatal labs completed and reviewed with patient. WNL<br>
  Genetic testing ordered (NIPT & carrier testing) and completed. NT sono scheduled\reviewed <br>
  <strong>[Reports/denies]</strong> bleeding & Cramping<br>
  <strong> O:</strong> Vitals: HR: BP: Temp, (FHR, exam findings if applicable)<br>
  <strong> A:</strong> ${displayAge(patientData?.age)} ${displayGTPAL(
    patientData?.gtpal
  )} at <strong> ${displayGestationalAge(
    patientData?.gestationalAge
  )}</strong> with IUP. S=D. Appropriate for OOH care.<br>
  <strong> P:</strong> Reviewed warning signs in pregnancy<br>
  Reviewed when and how to call midwives<br>
  Discussed MSAFP genetic screen at 16 weeks.<br>
  Return 4 weeks or sooner if indicated for routine care. If develops vaginal bleeding, cramping or other concerns contact sooner.<br>
  `;

export const week1519 = (
  patientData?: PatientProfileForEncounterDTO,
  profile?: any
) =>
  `<strong> S:</strong> ${displayAge(patientData?.age)} ${displayGTPAL(
    patientData?.gtpal
  )} at <strong> ${displayGestationalAge(
    patientData?.gestationalAge
  )}</strong> estimated by ${displayBirthDate(
    patientData?.calculatedDueDate
  )}<br>
  Presenting to <strong> ${
    profile.practiceName
  }</strong> for: Routine prenatal care<br>
  NIPT, NT SONO and carrier testing reviewed & discussed with client. WNL <br>
  MSAFP completed & anatomy sono scheduled at (xyz location)<br>
  <strong>[Reports/denies]</strong> bleeding & Cramping<br>
  <strong> O:</strong> Vitals: HR: BP: Temp, (FHR, exam findings if applicable)<br>
  <strong> A:</strong> ${displayAge(patientData?.age)} ${displayGTPAL(
    patientData?.gtpal
  )} at <strong> ${displayGestationalAge(
    patientData?.gestationalAge
  )}</strong> with IUP. S=D. Appropriate for OOH care.<br>
  <strong> P:</strong> Reviewed warning signs in pregnancy<br>
  Reviewed when and how to call midwives<br>
  MSAFP genetic screen completed<br>
  20 week anatomy sonogram discussed. Form faxed and number provided for patient to call to schedule.<br>
  Return 4 weeks or sooner if indicated for routine care. If develops vaginal bleeding, cramping or other concerns contact sooner.<br>
  `;

export const week2025 = (patientData?: PatientProfileForEncounterDTO) =>
  `
  <strong> S:</strong> ${displayAge(patientData?.age)} ${displayGTPAL(
    patientData?.gtpal
  )} at <strong> ${displayGestationalAge(
    patientData?.gestationalAge
  )}</strong>  estimated by ${displayBirthDate(
    patientData?.calculatedDueDate
  )}<br>
  reviewed 20 week anatomy sono WNL<br>
  Patient feeling fetal movement<br>
  Discussed PTL and PEC<br>
  Denies HA, vision changes and RUQ pain<br>
  <strong>[Reports/denies]</strong> bleeding, LOF & Cramping<br>
  Discussed 3rd trimester labs, ordered and reviewed directions for GDM screen. Patient <strong>[consents/declined]</strong> to GDM testing.<br>
  <strong> O:</strong>  Vitals: HR: XX BP: XX Temp: XX, Physical Exam findings (FHR, Fundal height, FM)<br>
  <strong> A:</strong> ${displayAge(patientData?.age)} ${displayGTPAL(
    patientData?.gtpal
  )} at <strong> ${displayGestationalAge(
    patientData?.gestationalAge
  )}</strong> with IUP. S=D. Appropriate for OOH care.<br>
  <strong> P:</strong> Reviewed warning signs in pregnancy<br>
  Reviewed when and how to call midwives<br>
  20 week anatomy sono reviewed. WNL<br>
  reviewed and ordered 3rd trimester labs (CBC, RPR, HIV, antibody screen, GDM)<br>
  Provided instructions on GDM screening. Consent signed<br>
  Return 4 weeks or sooner if indicated for routine care. If develops vaginal bleeding, cramping or other concerns contact sooner.<br>
  `;

export const week2629 = (patientData?: PatientProfileForEncounterDTO) =>
  `
  <strong> S:</strong> ${displayAge(patientData?.age)} ${displayGTPAL(
    patientData?.gtpal
  )} at <strong> ${displayGestationalAge(
    patientData?.gestationalAge
  )}</strong>  estimated by ${displayBirthDate(
    patientData?.calculatedDueDate
  )}<br>
  reviewed 28 week sono<br>
  TDAP offered and <strong>[accepted/declined]</strong><br>
  Discussed kick counts<br>
  <strong>[Reports/denies]</strong> bleeding, LOF and <strong>[Reports/denies]</strong>CTX<br>
  <strong>[Endorses/denies]</strong> + FM <br>
  Scheduled/reviewed 3rd trimester labs. [WNL/abnormal labs]<br>
  Discussed preparing for labor <br>
  <strong>[Reports/denies]</strong> HA, vision changes and RUQ pain<br>
  <strong>[Reports/denies]</strong> any signs of preterm labor<br>
  <strong> O:</strong> Vitals: HR: XX BP: XX Temp: XX, Physical Exam findings (FHR, Fundal height, FM, Fetal position)<br>
  <strong> A:</strong> ${displayAge(patientData?.age)} ${displayGTPAL(
    patientData?.gtpal
  )} at <strong> ${displayGestationalAge(
    patientData?.gestationalAge
  )}</strong> with IUP. S=D. Appropriate for OOH care.<br>
  <strong> P:</strong> Reviewed warning signs in pregnancy<br>
  Reviewed when and how to call midwives<br>
  3rd trimester labs scheduled/completed & reviewed (CBC, RPR, HIV, antibody screen, GDM)<br>
  Return 2 weeks or sooner if indicated for routine care. If develops vaginal bleeding, cramping or other concerns contact sooner.<br>
  `;

export const week3034 = (patientData?: PatientProfileForEncounterDTO) =>
  `
  <strong> S:</strong> ${displayAge(patientData?.age)} ${displayGTPAL(
    patientData?.gtpal
  )} at <strong> ${displayGestationalAge(
    patientData?.gestationalAge
  )}</strong>  estimated by ${displayBirthDate(
    patientData?.calculatedDueDate
  )}<br>
  Birth plan discussed<br>
  Labor prep discussed<br>
  Perineal massage and other preparation include: <strong>[_____]</strong> <br>
  Vitamin K dicussed<br>
  Erythromycin discussed<br>
  Reviewed Red flags symptoms<br>
  <strong> O:</strong>  Vitals: HR: XX BP: XX Temp: XX, Physical Exam findings (FHR, Fundal height, FM, Fetal position)<br>
  3rd Trimester Labs CBC, RPR, HIV, antibody screen, GDM):<br>
  3rd Trimester Ultrasound completed or waived: <br>
  <strong> A:</strong> ${displayAge(patientData?.age)} ${displayGTPAL(
    patientData?.gtpal
  )} at <strong> ${displayGestationalAge(
    patientData?.gestationalAge
  )}</strong> with IUP. S=D. Appropriate for OOH care.<br>
  <strong> P:</strong> Reviewed warning signs in pregnancy<br>
  Reviewed when and how to call midwives<br>
  Reviewed and <strong>[consent/decline]</strong> for GBS swab next visit<br>
  Return 2 weeks or sooner if indicated for routine care. If develops vaginal bleeding, cramping or other concerns contact sooner.<br>
  `;

export const week3536 = (patientData?: PatientProfileForEncounterDTO) =>
  `
  <strong> S:</strong> ${displayAge(patientData?.age)} ${displayGTPAL(
    patientData?.gtpal
  )} at <strong> ${displayGestationalAge(
    patientData?.gestationalAge
  )}</strong>  estimated by ${displayBirthDate(
    patientData?.calculatedDueDate
  )}<br>
  <strong>[Reports/denies]</strong> bleeding, LOF and CTX<br>
  <strong>[Reports/endorses]</strong> + FM <br>
  <strong>[Reports/denies]</strong> HA, vision changes and RUQ pain<br>
  <strong>[Reports/denies]</strong> any sign of preterm labor<br>
  <strong> O:</strong>  Vitals: HR: XX BP: XX Temp: XX, Physical Exam findings (FHR, Fundal height, FM, Fetal position)<br>
  <strong> A:</strong> ${displayAge(patientData?.age)} ${displayGTPAL(
    patientData?.gtpal
  )} at <strong> ${displayGestationalAge(
    patientData?.gestationalAge
  )}</strong> with IUP. S=D. Appropriate for OOH care.<br>
  <strong>Ultrasound:</strong> (confirming baby position: vertex, breech, transverse)<br>
  <strong> P:</strong> Reviewed warning signs in pregnancy<br>
  Reviewed when and how to call midwives<br>
  <strong>[Completed/declined]</strong> GBS swab today<br>
  Return 2 weeks or sooner if indicated for routine care. If develops vaginal bleeding, cramping or other concerns contact sooner.<br>
  `;

export const week3740 = (patientData?: PatientProfileForEncounterDTO) =>
  `
  <strong> S:</strong> ${displayAge(patientData?.age)} ${displayGTPAL(
    patientData?.gtpal
  )} at <strong> ${displayGestationalAge(
    patientData?.gestationalAge
  )}</strong>  estimated by ${displayBirthDate(
    patientData?.calculatedDueDate
  )}<br>
  <strong>[Reports/denies]</strong> bleeding, LOF and CTX<br>
  <strong>[Reports/endorses]</strong> + FM <br>
  <strong>[Reports/denies]</strong> HA, vision changes and RUQ pain<br>
  Discussed membrane sweep, offered and <strong>[accepted/declined]</strong><br>
  Discussed antenatal testing after 41 weeks<br>
  <strong> O:</strong> Vitals: HR: XX BP: XX Temp: XX, Physical Exam findings (FHR, Fundal height, FM, Fetal position, EFW ie. leopalds)<br>
  Bishops score if applicable:<br>
  <strong> A:</strong> ${displayAge(patientData?.age)} ${displayGTPAL(
    patientData?.gtpal
  )} at <strong> ${displayGestationalAge(
    patientData?.gestationalAge
  )}</strong> with IUP. S=D. Appropriate for OOH care.<br>
  <strong> P:</strong> Reviewed warning signs in pregnancy<br>
  Reviewed when and how to call midwives<br>
  Return 1 week or sooner if indicated for routine care. If develops vaginal bleeding, cramping or other concerns contact sooner.<br>
  `;

export const week4042 = (patientData?: PatientProfileForEncounterDTO) =>
  `
  <strong> S:</strong> ${displayAge(patientData?.age)} ${displayGTPAL(
    patientData?.gtpal
  )} at <strong> ${displayGestationalAge(
    patientData?.gestationalAge
  )}</strong>  estimated by ${displayBirthDate(
    patientData?.calculatedDueDate
  )}<br>
  <strong>[Reports/denies]</strong> bleeding, LOF and CTX<br>
  <strong>[Reports/endorses]</strong> + FM <br>
  <strong>[Reports/denies]</strong> HA, vision changes and RUQ pain<br>
  Discussed membrane sweep, offered and <strong>[accepted/declined]</strong><br>
  Discussed post term and what to expect if reaches 42 weeks.
  <strong> O:</strong> Vitals: HR: XX BP: XX Temp: XX, Physical Exam findings (FHR, Fundal height, FM, Fetal position, EFW ie. leopalds):<br>
  Bishops score if applicable:
  <strong> A:</strong> ${displayAge(patientData?.age)} ${displayGTPAL(
    patientData?.gtpal
  )} at <strong> ${displayGestationalAge(
    patientData?.gestationalAge
  )}</strong> with IUP. S=D. Appropriate for OOH care.<br>
  <strong> P:</strong> Reviewed warning signs in pregnancy<br>
  reviewed when and how to call midwives<br>
  Return 1 week or sooner if indicated for routine care. If develops vaginal bleeding, cramping or other concerns contact sooner.<br>
  `;

export const ppVisit = (
  patientData?: PatientProfileForEncounterDTO,
  profile?: any
) =>
  `
 ${patientData?.name} , ${displayAge(
    patientData?.age
  )}, at <strong>Xw Xd</strong> postpartum, after <strong>[type of delivery]</strong> at GA of delivery <strong>[location of delivery]</strong><br>
<strong>Bleeding:</strong><br>
Bleeding wnl for 1 day pp. Endorses lochia (Rubra, serosa, alba), denies large clots<br>
<strong>Feeding:</strong><br>
breastfeeding observed. Feeding (how many times a day) <br>
nipples (intact, skin breakdown, inverted)<br>
<strong>Mood:</strong><br>
Depression score: <br>
Bladder/Bowels:<br>
Endorses normal/abnormal bladder and bowel functioning<br>
<strong>Pain:</strong><br>
Pain medications currently:<br>
 `;

export const followup = (
  patientData?: PatientProfileForEncounterDTO,
  profile?: any
) =>
  `
  <strong> S:</strong> <strong> ${patientData?.name}</strong> is a ${displayAge(
    patientData?.age
  )} old${displayGTPAL(patientData?.gtpal)} returning for a follow-up visit.<br>
  Accompanied by _______.<br>
  History reviewed and consistent with risk criteria for care with <strong> ${
    profile.practiceName
  }</strong><br>
  <strong> ${
    patientData?.name
  }</strong> is a <strong>[new client, repeat client]</strong> and plans a <strong>[home birth, birth center birth, hospital birth]</strong> birth with <strong> ${
    profile.practiceName
  }</strong><br>
  <strong> ${
    patientData?.name
  }</strong> <strong> [attended orientation/info session, was oriented today]</strong>.<br>
  <strong> ${
    patientData?.name
  }</strong> received the Care Guide and Info Guide. Discussed resources available in The Care Guide<br>
  <strong> ${
    patientData?.name
  }</strong> <strong>[has had no prior prenatal care, was previously seeing _____]</strong>. The number of visits with their previous provider is ____.<br>
  <strong> ${
    patientData?.name
  }</strong> is currently <strong> ${displayGestationalAge(
    patientData?.gestationalAge
  )}</strong>  pregnant.<br>
  First trimester u/s discussed and <strong>[in records, requested, declined]</strong>.<br>
  Dating confirmed by <strong>[LMP, Conception,1st trimester u/s]</strong>.<br>
  Physical done.<br>
  Pelvic exam <strong>[done, declined, not offered]</strong>.<br>
  HRP Disclosures Needed: <strong>[__________, none]</strong><br>
  Signs of Preterm Labor <strong>[not present, not applicable due to early gestation, noted _____]</strong><br>
  Reviewed diet, hydration, supplements<br>
  Exercise discussed<br>
  Sleep discussed<br>
  Reviewed New Labs/Ultrasounds<br>
  Reviewed History Progress Note<br>
  Discussed IP HRP disclosure.<br>
  Discussed other HRP disclosures.<br>
  Discussed Early Pregnancy ICD Packet<br>
  Introduced educational program: library, childbirth education, other classes and events.<br>
  Discussed general nutritional needs of pregnancy.<br>
  Documented current & recommended supplements.<br>
  Reviewed warning signs. <strong> ${
    patientData?.name
  }</strong> expresses understanding.<br>
  Explained routine<strong> S:</strong> self care, appointments, phone contact, student participation<br>
  Discussed finances. Questions directed to office manager.<br>
  Reviewed genetic history and exposure history.<br>
  Discussed and offered prenatal genetic screening.<br>
  Discussed emotional health.<br>
  Discussed Family Support<br>
  Discussed CDC Recommendation for Influenza Vaccine<br>
  Made referrals to outside agencies/providers, as needed<br>
  CC: <strong> ${
    patientData?.name
  }</strong> <strong>[has no major concerns, complains of]</strong><br>
  <strong> O:</strong> See Flow, Vitals <strong>[insert]</strong><br>
  <strong> A:</strong> ${displayAge(patientData?.age)} ${displayGTPAL(
    patientData?.gtpal
  )} with IUP XXWeeks X Day, S=D. Appropriate for OOH birth midwifery care at this time.<br>
  <strong> P:</strong> RTC <strong>[number of days/weeks]</strong><br>
  Send paperwork to <strong> ${patientData?.name}</strong>, as needed.<br>
  <strong>[labs drawn? consents signed/discussed? treatment plans? what to do next PN visit?]</strong><br>
  <strong>[student midwife]</strong> student midwife, participated in the care of this client under my direct supervision.<br>
  `;
