import { QueryClient } from '@tanstack/react-query';

export function configureTanstackQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
}
