import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/system/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import { faClose } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Divider from '@mui/material/Divider';
import { useLabsStore } from '../store/labs.store';
import UploadAndProcessPDF from './UploadAndProcessPDF';
import FillResults from './FillResults/FillResults';
import { cn } from '@aster/client/utils/cn';

export function ManuallyAddLabResultsDialog({
  handleClose,
  ...props
}: DialogProps & { handleClose: () => void }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const manualUploadResultsDialogState = useLabsStore(
    (state) => state.manualUploadResultsDialogState
  );

  return (
    <Dialog
      fullScreen={fullScreen}
      classes={{
        paper: cn('overflow-hidden', {
          'h-full': manualUploadResultsDialogState === 'fill',
        }),
      }}
      maxWidth={manualUploadResultsDialogState === 'upload' ? 'md' : 'xl'}
      {...props}
    >
      <DialogTitle className="text-h4 font-semibold flex items-center justify-between">
        Add Lab Results
        <IconButton onClick={handleClose}>
          <FontAwesomeIcon
            icon={faClose}
            size="sm"
            color="black"
            className="aspect-square"
          />
        </IconButton>
      </DialogTitle>
      <Divider className="border-gray-200 mx-6" />
      <DialogContent className="flex flex-col p-6 pb-4">
        {manualUploadResultsDialogState === 'upload' ? (
          <UploadAndProcessPDF />
        ) : (
          <FillResults />
        )}
      </DialogContent>
    </Dialog>
  );
}
