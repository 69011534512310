import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import { styled } from '@mui/system';
import { colors } from '../theme';

export const PushButtonTabs = Tabs;

export const PushButtonTab = styled(BaseTab)`
  cursor: pointer;
  flex: 1 1 100%;
  font-size: 0.75rem;
  font-weight: 400;
  background-color: transparent;
  padding: 0.38rem 0.5rem;
  min-width: 5.5rem;
  max-height: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.4375rem;

  &:focus {
    outline: 1px solid ${colors.blue};
  }

  &.${tabClasses.selected} {
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 600;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    background: var(--System-Background-Light-Primary, #fff);
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.12),
      0px 3px 1px 0px rgba(0, 0, 0, 0.04);
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const PushButtonTabPanel = styled(BaseTabPanel)`
  width: 100%;
`;

export const PushButtonTabsList = styled(BaseTabsList)`
  width: 100%;
  max-height: 2rem;
  background-color: ${colors.grayBackground};
  border-radius: 0.5rem;
  padding: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
