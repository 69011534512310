import { cn } from '@aster/client/utils/cn';
import { HTMLAttributes } from 'react';

export type NotificationBadgeProps = HTMLAttributes<HTMLSpanElement> & {
  count: number;
};

const NotificationBadge = ({
  count,
  className,
  ...rest
}: NotificationBadgeProps) => {
  return (
    <span
      className={cn(
        'bg-red-500 font-semibold p-[6px] text-[10px] text-white rounded-full leading-[10px]',
        className
      )}
      {...rest}
    >
      <span className="inline-flex min-w-[10px] justify-center leading-[10px]">
        {count}
      </span>
    </span>
  );
};

export default NotificationBadge;
