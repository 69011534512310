const StaffMedicalPrimary = () => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Primary"
      d="M5.52346 5.42961V6.12004H13.8087V5.42961V2.93542L9.66606 1.38194L5.52346 2.93542V5.42961ZM4.1426 6.81047V5.42961V2.93542C4.1426 2.3615 4.50076 1.84367 5.04016 1.64086L9.18276 0.0873829C9.49345 -0.0291276 9.83867 -0.0291276 10.1537 0.0873829L14.2963 1.64086C14.8357 1.84367 15.1938 2.35718 15.1938 2.93542V5.42961V6.81047V7.50091C15.1938 10.5518 12.7212 13.0244 9.67038 13.0244C6.61953 13.0244 4.14691 10.5518 4.14691 7.50091V6.81047H4.1426ZM13.8087 7.50091H5.52346C5.52346 9.78797 7.379 11.6435 9.66606 11.6435C11.9531 11.6435 13.8087 9.78797 13.8087 7.50091ZM5.1351 15.4021C2.96455 16.1529 1.40244 18.2026 1.38087 20.6191H17.9513C17.9297 18.2026 16.3676 16.1529 14.2013 15.4021L11.1764 18.6169C10.3565 19.4842 8.97563 19.4842 8.16006 18.6169L5.1351 15.4021ZM6.1017 14.4139L9.1655 17.6718C9.43736 17.961 9.89908 17.961 10.1709 17.6718L13.2347 14.4139C13.5627 14.0643 14.0503 13.9004 14.5034 14.0471C17.304 14.949 19.3321 17.5726 19.3321 20.6752C19.3321 21.4088 18.7366 22 18.0074 22H1.32477C0.595499 22 0 21.4045 0 20.6752C0 17.5726 2.02815 14.949 4.82872 14.0471C5.28181 13.9004 5.76943 14.0643 6.09739 14.4139H6.1017ZM9.32084 1.97744H10.0113C10.2011 1.97744 10.3565 2.13279 10.3565 2.32266V3.01309H11.0469C11.2368 3.01309 11.3921 3.16844 11.3921 3.35831V4.04874C11.3921 4.23861 11.2368 4.39396 11.0469 4.39396H10.3565V5.08439C10.3565 5.27426 10.2011 5.42961 10.0113 5.42961H9.32084C9.13098 5.42961 8.97563 5.27426 8.97563 5.08439V4.39396H8.2852C8.09533 4.39396 7.93998 4.23861 7.93998 4.04874V3.35831C7.93998 3.16844 8.09533 3.01309 8.2852 3.01309H8.97563V2.32266C8.97563 2.13279 9.13098 1.97744 9.32084 1.97744Z"
      fill="black"
    />
  </svg>
);

export default StaffMedicalPrimary;
