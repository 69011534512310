import { CheckboxItem } from '../types/question';

export function mergeCheckboxLists(
  list1: CheckboxItem[],
  list2: CheckboxItem[]
): CheckboxItem[] {
  const mergedList = list1.map((item) => {
    const item2 = list2.find((i) => i.value === item.value);
    if (item2) {
      return {
        ...item,
        checked: item2.checked,
      };
    }
    return item;
  });
  return mergedList;
}
