import Typography from '../../../../components/Typography';

export type ReadonlyFieldProps = {
  label: string;
  text: string;
};

export const ReadonlyField = ({ label, text }: ReadonlyFieldProps) => {
  return (
    <div>
      <Typography variant="bodySmall">{label}</Typography>
      <Typography variant="h6">{text}</Typography>
    </div>
  );
};
