const LeftArrow = () => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1831 16.4961L4.78076 10.1367C4.56592 9.92188 4.56592 9.62109 4.78076 9.40625L11.1831 3.04688C11.355 2.83203 11.6987 2.83203 11.9136 3.04688L12.2144 3.34766C12.3862 3.5625 12.3862 3.86328 12.2144 4.07812L7.22998 9.01953H20.6362C20.894 9.01953 21.1519 9.27734 21.1519 9.53516V9.96484C21.1519 10.2656 20.894 10.4805 20.6362 10.4805H7.22998L12.2144 15.4648C12.3862 15.6797 12.3862 15.9805 12.2144 16.1953L11.8706 16.4961C11.6987 16.7109 11.355 16.7109 11.1831 16.4961ZM2.41748 18C2.1167 18 1.90186 17.7852 1.90186 17.4844V2.01562C1.90186 1.75781 2.1167 1.5 2.41748 1.5H2.76123C3.01904 1.5 3.27686 1.75781 3.27686 2.01562V17.4844C3.27686 17.7852 3.01904 18 2.76123 18H2.41748Z"
        fill="#586068"
      />
    </svg>
  );
};

export default LeftArrow;
