import { useNavigate } from 'react-router-dom';

import Typography from '../components/Typography';
import ButtonType from '../components/Button';

import AsterLogo from '../assets/aster';
import Illustration from '../assets/aster_baggu_illustration_black.webp';

type ErrorProps = {
  type: 404 | 500;
};

type ErrorCopy = {
  headline: string | React.ReactNode;
  paragraphs: (string | React.ReactNode)[];
  allowRefresh?: boolean;
};

const errorCopySet: Record<ErrorProps['type'], ErrorCopy> = {
  404: {
    headline: <>Oops! We can’t find that page.</>,
    paragraphs: [<>Sorry about that. Try checking that the URL is correct.</>],
  },
  500: {
    headline: <>Oops! Something went wrong</>,
    paragraphs: [
      <>Sorry about that! Try refreshing the page.</>,
      <>
        If the problem persists,{' '}
        <a href="mailto:support@astercare.com" className="underline">
          email support
        </a>{' '}
        and we’ll get right on it.
      </>,
      <>
        You can also try forcing the app to refresh by clicking the button
        below.
      </>,
    ],
    allowRefresh: true,
  },
};

const Error = ({ type }: ErrorProps) => {
  const copy = errorCopySet[type];

  const navigate = useNavigate();

  // NOTE: Since `5xx` exceptions often cause the site to crash altogether,
  // performing a full navigation and triggering a page reload from scratch
  // scratch could help re-attempt the issue.
  //
  // See `src/Router.tsx:23`.
  const goBackHome = () => {
    if (type === 500) {
      return (window.location.href = '/');
    }

    navigate('/', { replace: true });
  };

  const refresh = () => {
    fetch(window.location.href, {
      headers: {
        Pragma: 'no-cache',
        Expires: '-1',
        'Cache-Control': 'no-cache',
      },
    }).then(() => window.location.reload());
  };

  return (
    <div className="absolute top-0 left-0 w-screen h-screen z-20">
      <div className="h-screen w-screen bg-grayBackground text-black p-32 relative overflow-hidden">
        <img
          src={Illustration}
          className="absolute top-0 left-[calc(50%-512px)] min-w-[1024px] lg:left-0 lg:w-full h-auto opacity-[0.1] z-0 pointer-events-none user-select-none"
          alt="Aster Baggu Illustration Black"
        />
        <div className="mx-auto w-fit px-9 py-3 mb-10 relative z-20">
          <AsterLogo />
        </div>
        <div className="bg-white p-24 text-center p-8 w-fit mx-auto rounded-[20px] relative z-10">
          <div className="mb-6">
            <Typography
              variant="h1"
              customClass="text-2xl text-aster-main mb-6"
            >
              {copy.headline}
            </Typography>
            {copy.paragraphs.map((paragraph, i) => (
              <Typography
                key={i}
                variant="bodyMedium"
                customClass="text-gray-500 mb-6"
              >
                {paragraph}
              </Typography>
            ))}
          </div>
          <div className="flex gap-x-4 justify-center">
            {copy.allowRefresh && (
              <ButtonType
                variant="outlined"
                text="Force Refresh"
                onClick={refresh}
              />
            )}
            <ButtonType
              variant="contained"
              text="Return to Aster"
              onClick={goBackHome}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
