import { Tab, TabProps, styled } from '@mui/material';
import { colors } from '../../theme';

const StyledTab = styled((props: TabProps, index: any) => (
  <Tab
    disableRipple
    {...props}
    classes={{ root: 'text-bodyMedium', selected: 'font-semibold' }}
  />
))(() => ({
  marginTop: '2px',
  paddingRight: 10,
  textTransform: 'initial',
  fontSize: 15,
  width: '210px',
  minWidth: '210px !important',
  alignItems: 'start',
  whiteSpace: 'nowrap',
  color: colors.asterGray,
  '&.Mui-selected': {
    color: `${colors.primary} !important`,
    backgroundColor: 'white',
    borderRadius: '100px',
  },
}));

export default StyledTab;
