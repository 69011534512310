const Messages = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6778 19.2574C23.9609 19.5 24.0013 19.9044 23.88 20.2684C23.7182 20.5919 23.3947 20.8346 23.0307 20.8346C20.8469 20.8346 19.1079 19.9853 17.9351 19.2574C17.1667 19.4191 16.3579 19.5404 15.5491 19.5404C12.0307 19.5404 9.03807 17.8824 7.7844 15.5772C7.37998 15.4963 7.01601 15.4559 6.65204 15.375C5.47925 16.1029 3.74028 16.9522 1.5969 16.9522C1.19248 16.9522 0.868954 16.7096 0.707189 16.386C0.585866 16.0221 0.666748 15.6176 0.909395 15.375C0.909395 15.3346 1.7991 14.364 2.44616 13.1507C1.31381 12.0184 0.666748 10.6434 0.666748 9.14706C0.666748 5.58823 4.42778 2.67647 9.07851 2.67647C12.5565 2.67647 15.5491 4.33456 16.8028 6.68015C20.8469 7.125 23.9609 9.79412 23.9609 13.0294C23.9609 14.5662 23.2734 15.9412 22.1815 17.0331C22.7881 18.2463 23.6778 19.2169 23.6778 19.2574ZM6.93513 14.0809C7.62263 14.2426 8.35057 14.3235 9.07851 14.3235C13.0013 14.3235 16.1962 12.0184 16.1962 9.14706C16.1962 6.31618 13.0013 3.97059 9.07851 3.97059C5.15572 3.97059 1.96087 6.31618 1.96087 9.14706C1.96087 10.5625 2.68881 11.5735 3.33587 12.2206L4.02337 12.8676L3.57851 13.7169C3.21454 14.4853 2.72925 15.1324 2.40572 15.5772C3.90204 15.4154 5.11528 14.8088 5.9241 14.2831L6.4094 13.9596L6.93513 14.0809ZM18.6631 18.1654C19.4719 18.6912 20.6851 19.2978 22.1815 19.4596C21.8579 19.0147 21.3726 18.3676 21.0087 17.5993L20.6042 16.75L21.2513 16.1029C21.8984 15.4559 22.6667 14.4449 22.6667 13.0294C22.6667 10.6434 20.402 8.62132 17.3285 8.05515C17.4094 8.41912 17.4903 8.78309 17.4903 9.14706C17.4903 12.6654 13.8506 15.4963 9.3616 15.6176C10.6153 17.1949 12.88 18.2463 15.5491 18.2463C16.2366 18.2463 16.9645 18.1654 17.652 18.0037L18.1778 17.8419L18.6631 18.1654Z"
        fill="#586068"
      />
    </svg>
  );
};

export default Messages;
