import { Checkbox, FormControlLabel } from '@mui/material';
import { colors } from '../theme';

type Item = {
  value: string;
  checked: boolean;
};

type PropsCheckboxes = {
  items: Item[];
  handleCheckboxChange: any;
  separateInHalf?: boolean;
  disabled?: boolean;
};

const MultipleCheckboxes = ({
  items,
  handleCheckboxChange,
  separateInHalf,
  disabled,
}: PropsCheckboxes) => {
  if (items) {
    const midpoint = Math.ceil(items.length / 2);
    const firstHalf = items.slice(0, midpoint);
    const secondHalf = items.slice(midpoint);
    if (separateInHalf) {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4 mt-4">
          <div>
            {firstHalf.map((item: any) => (
              <div key={item.value} className="pl-1">
                <FormControlLabel
                  className="flex items-center h-1"
                  key={item.value}
                  disabled={disabled}
                  control={
                    <Checkbox
                      sx={{
                        color: colors.grayLight,
                        '&.Mui-checked': {
                          color: colors.green,
                        },
                      }}
                      checked={item.checked}
                      onChange={() => handleCheckboxChange(item.value)}
                      disableRipple
                    />
                  }
                  label={
                    <span className="leading-tigh md:text-sm sm:text-xs sm:leading-snug ">
                      {item.text}
                    </span>
                  }
                />
                <br />
              </div>
            ))}
          </div>
          <div>
            {secondHalf.map((item: any) => (
              <div key={item.value} className="pl-1">
                <FormControlLabel
                  className="flex items-center h-1"
                  key={item.value}
                  disabled={disabled}
                  control={
                    <Checkbox
                      sx={{
                        color: colors.grayLight,
                        '&.Mui-checked': {
                          color: colors.green,
                        },
                      }}
                      checked={item.checked}
                      onChange={() => handleCheckboxChange(item.value)}
                      disableRipple
                    />
                  }
                  label={
                    <span className="leading-tigh md:text-sm sm:text-xs sm:leading-snug ">
                      {item.text}
                    </span>
                  }
                />
                <br />
              </div>
            ))}
          </div>
        </div>
      );
    }
  }

  return (
    <div className="flex-1 mt-4">
      {items.map((item: any) => (
        <div key={item.value} className="pl-1">
          <FormControlLabel
            className="flex items-center h-1 leading-normal sm:leading-tight sm:text-sm"
            key={item.value}
            disabled={disabled}
            control={
              <Checkbox
                sx={{
                  color: colors.grayLight,
                  '&.Mui-checked': {
                    color: colors.green,
                  },
                }}
                checked={item.checked}
                onChange={() => handleCheckboxChange(item.value)}
                disableRipple
                disabled={disabled}
              />
            }
            label={<span>{item.text}</span>}
          />
          <br />
        </div>
      ))}
    </div>
  );
};

export default MultipleCheckboxes;
