import { cn } from '@aster/client/utils/cn';
import { HTMLAttributes } from 'react';

type WeeksChipProps = HTMLAttributes<HTMLSpanElement> & {
  weeks: `${number}w${number}d`;
};

export const WeeksChip = ({ weeks, className, ...rest }: WeeksChipProps) => {
  const week = Number.parseInt(weeks.split('w')[0]) ?? '-';

  const classNames = cn(
    'px-[8px] py-[3px] rounded-full text-[14px] font-medium bg-grayBackground text-white',
    {
      'bg-sky-500': week >= 0,
      'bg-indigo-500': week >= 14,
      'bg-pink-600': week >= 28,
    },
    className
  );

  return (
    <span className={classNames} {...rest}>
      {weeks}
    </span>
  );
};
