import Typography from '../../components/Typography';
import Ribbon from './Ribbon';

type CardProps = {
  icon: any;
  title: string;
  description: string;
  warning?: string;
  button: any;
};

export const Card = ({
  icon,
  title,
  description,
  button,
  warning,
}: CardProps) => {
  return (
    <div className="w-[250px] h-[258px] bg-grayBackground rounded-[35px] p-5 pt-7 flex flex-col items-center justify-center relative">
      {warning && (
        <Ribbon className="top-[1.7rem] left-[9rem] ">{warning}</Ribbon>
      )}
      <div>{icon}</div>

      <Typography variant="h6" text={title} customClass="mt-5" />
      <Typography
        variant="bodySmall"
        text={description}
        customClass="text-gray-500 text-center mt-[0.62rem]"
      />

      {button}
    </div>
  );
};
