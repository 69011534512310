import { create } from 'zustand';
import { createLabsRootSlice, LabsRootSlice } from './root-labs.slice';
import { createCreateLabsSlice, CreateLabsSlice } from './create-labs.slice';
import {
  createManuallyUploadLabsSlice,
  ManuallyUploadLabsSlice,
} from './manually-upload-labs.slice';

export const useLabsStore = create<
  LabsRootSlice & CreateLabsSlice & ManuallyUploadLabsSlice
>()((...a) => ({
  ...createLabsRootSlice(...a),
  ...createCreateLabsSlice(...a),
  ...createManuallyUploadLabsSlice(...a),
}));
