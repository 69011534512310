import { useQuery } from '@tanstack/react-query';
import axios from '../../../../app/axiosConfig';
import { VitalsDTO } from '@aster/shared/dtos/vitals';

export const cacheKey = (patientID: string) => ['vitals', patientID];

const fetchEncounterVitals = async (patientID: string) =>
  axios.get<VitalsDTO[]>(`/vitals/byPatient/${patientID}`).then((response) =>
    response.data.map((vital) => ({
      ...vital,
    }))
  );

export function usePatientVitals(patientID: string) {
  const { data, isLoading } = useQuery({
    queryKey: cacheKey(patientID),
    queryFn: () => fetchEncounterVitals(patientID),
  });

  return { vitals: data, isLoading };
}
