const OfficeBuilding = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M12.3555 13.6V3.64447C12.3555 2.95718 11.7983 2.40002 11.111 2.40002H4.88879C4.2015 2.40002 3.64435 2.95718 3.64435 3.64447V13.6M12.3555 13.6L13.5999 13.6M12.3555 13.6H9.24435M3.64435 13.6L2.3999 13.6M3.64435 13.6H6.75546M6.13324 4.8889H6.75546M6.13324 7.37779H6.75546M9.24435 4.8889H9.86657M9.24435 7.37779H9.86657M6.75546 13.6V10.4889C6.75546 10.1453 7.03404 9.86668 7.37768 9.86668H8.62212C8.96577 9.86668 9.24435 10.1453 9.24435 10.4889V13.6M6.75546 13.6H9.24435"
      stroke="#586068"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default OfficeBuilding;
