import { pdfjs } from 'react-pdf';

export function configureReactPDF() {
  // @ts-expect-error This does not exist outside of polyfill which this is doing
  if (typeof Promise.withResolvers === 'undefined') {
    if (window)
      // @ts-expect-error This does not exist outside of polyfill which this is doing
      window.Promise.withResolvers = function () {
        let resolve, reject;
        const promise = new Promise((res, rej) => {
          resolve = res;
          reject = rej;
        });
        return { promise, resolve, reject };
      };
  }
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;
}
