import { getAnalytics, logEvent } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyAP6mN7FxjDd2iB9AiLexxbUmO96fQMyPg',
  authDomain: 'aster-healthcare.firebaseapp.com',
  projectId: 'aster-healthcare',
  storageBucket: 'aster-healthcare.appspot.com',
  messagingSenderId: '148287877853',
  appId: '1:148287877853:web:9414a9a4483bf7f4f0a134',
  measurementId: 'G-RJNV050EYL',
};

// TODO: key can be a type;
export const logAnalyticEvent = (key: string, event: string, id?: string) => {
  const analytics = getAnalytics();
  logEvent(analytics, key, { name: event, id });
};
