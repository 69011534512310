import { GTPALDTO } from '@aster/shared/dtos/patient';
import dayjs from 'dayjs';

export const displayAge = (age?: number | null): string => {
  if (!age) return '<strong> XX</strong> years old';
  return `<strong> ${age.toString()}</strong> years old`;
};

export const displayGTPAL = (gtpal?: GTPALDTO | null): string => {
  if (gtpal) {
    const {
      gravida,
      termBirths,
      pretermBirths,
      abortionsAndMiscarriages,
      livingChildren,
    } = gtpal;
    return `<strong>G${gravida}T${termBirths}P${pretermBirths}A${abortionsAndMiscarriages}L${livingChildren}</strong>`;
  }
  return '<strong>GXP0000</strong>';
};

export const displayBirthDate = (calculatedEDD?: string | null): string => {
  if (calculatedEDD !== null)
    return `<strong>EDD ${dayjs(calculatedEDD).format('MM/DD/YYYY')}</strong>`;
  return '<strong>LMP/EDD MM/DD/YYYY</strong>';
};

export const displayGestationalAge = (ga?: string | null): string => {
  return ga ?? `XXw XXd`;
};
