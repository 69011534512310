import {
  useState,
  useMemo,
  useEffect,
  ReactNode,
  useContext,
  createContext,
} from 'react';
import { logAnalyticEvent } from '../app/firebase';
import { useNavigate } from 'react-router-dom';
import UnauthenticatedRouter from './UnAuthenticatedRouter';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession, signIn, signOut } from 'aws-amplify/auth';
import TagManager from 'react-gtm-module';
import { LOCAL_STORAGE_KEYS } from './util/constants';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from '../components/Snack';
import { ASTER_EMAIL_DOMAIN } from '@aster/shared/dtos/constants';
import { StaffListItemDTO } from '@aster/shared/dtos/staff';
import { AuthError } from 'aws-amplify/auth';
import { APIProvider } from '@vis.gl/react-google-maps';

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

const NO_PROFILE_ERROR =
  'PreTokenGeneration failed with error Authenticated user does not have a profile.';

export type Profile = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  practiceId: string;
  practiceName: string;
};

type AuthProviderProps = {
  children: ReactNode;
};

type Credentials = {
  username: string;
  password: string;
};

const getSessionProfile = () => {
  const profile = localStorage.getItem(LOCAL_STORAGE_KEYS.PROFILE);
  if (profile) {
    return JSON.parse(profile) as Profile;
  }
  return false;
};

const getSessionStaff = () => {
  const staff = localStorage.getItem(LOCAL_STORAGE_KEYS.STAFF);
  if (staff) {
    return JSON.parse(staff) as StaffListItemDTO;
  }
  return false;
};

type AuthState = {
  isAsterAdmin: boolean;
  profile: Profile | null;
  setSessionStaff: (staffJsonString: string) => void;
  clearSessionStaff: () => void;
  setSessionProfile: (profileJsonString: string) => void;
  logout: (pathname?: string) => void;
  login: (credentails: Credentials) => Promise<void>;
};

const defaultAuthState: AuthState = {
  isAsterAdmin: false,
  profile: null,
  setSessionStaff: (staffJsonString: string) => {
    console.error('setSessionStaff function is not defined');
  },
  clearSessionStaff: () => {
    console.error('clearSessionStaff function is not defined');
  },
  setSessionProfile: (profileJsonString: string) => {
    console.error('setSessionProfile function is not defined');
  },
  logout: (pathname?: string) => {
    console.error('logout function is not defined');
  },
  login: async (credentials: Credentials) => {
    console.error('login function is not defined');
  },
};

const AuthContext = createContext<AuthState>(defaultAuthState);

function clearSessionProfile() {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.PROFILE);
  logAnalyticEvent('authentication', 'logout');
}

function AuthProvider({ children }: AuthProviderProps) {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState<Profile | null>(null);
  const { showMessage } = useSnackbar();

  const authContext = useMemo(
    () => ({
      setSessionStaff(staffJsonString: string) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.STAFF, staffJsonString);
      },
      clearSessionStaff() {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.STAFF);
      },
      profile: userProfile,
      isAsterAdmin: userProfile?.email.endsWith(ASTER_EMAIL_DOMAIN) ?? false,
      setSessionProfile(profileJsonString: string) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.PROFILE, profileJsonString);
        setUserProfile(JSON.parse(profileJsonString));
      },
      async logout(pathname?: string) {
        const { clearSessionStaff } = authContext;
        clearSessionProfile();
        clearSessionStaff();
        await signOut();
        logAnalyticEvent('authentication', 'logout');
        if (pathname) {
          navigate(pathname);
          return;
        }
        navigate('/signin');
      },
      async login({
        username,
        password,
      }: {
        username: string;
        password: string;
      }) {
        try {
          const { setSessionProfile } = authContext;
          await signOut();
          const signinOutput = await signIn({ username, password });
          if (
            !signinOutput.isSignedIn &&
            signinOutput.nextStep.signInStep === 'CONFIRM_SIGN_UP'
          ) {
            showMessage({
              message: 'Please verify your email address',
              type: 'info',
            });
            navigate('/verify');
            return;
          }
          const session = await fetchAuthSession();
          const jwtIdToken = session.tokens?.idToken?.toString();
          const tokenPayload = JSON.parse(
            atob(jwtIdToken?.split('.')[1] || '')
          );

          const profile = tokenPayload?.profile;
          setSessionProfile(profile);
          logAnalyticEvent('authentication', 'login', username);
          navigate('/');
        } catch (error) {
          if (
            error instanceof AuthError &&
            error.name === 'UserAlreadyAuthenticatedException'
          ) {
            window.location.reload();
            return;
          }
          if (error instanceof Error && error.message === NO_PROFILE_ERROR) {
            console.error('Error in login', error.name);
            throw new Error(
              'This account is missing a profile. Please contact support.'
            );
          }
          throw error;
        }
      },
    }),
    [navigate, showMessage, userProfile]
  );

  useEffect(() => {
    const checkActiveUser = async () => {
      const profile = getSessionProfile();
      if (profile) {
        setUserProfile(profile);
        return;
      }
      setUserProfile(null);
    };
    checkActiveUser();
  }, []);

  // NOTE: The value used as part of the revalidation array has great effect
  // on determining whether the hook will trigger a re-render or not - using
  // `context.user` instead of `context.authState` ensures we only trigger a
  // re-render if the user changes, which is considered a better practice.
  //
  // See: https://ui.docs.amplify.aws/react/connected-components/authenticator/advanced#prevent-re-renders
  const { authStatus } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    if (userProfile) {
      const tagManagerArgs = {
        gtmId: 'GTM-MBDFRNJS',
        dataLayer: {
          userID: userProfile.id,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          email: userProfile.email,
        },
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, [userProfile]);

  if (authStatus === 'configuring') {
    return <CircularProgress className="m-auto align-self-center" />;
  }

  return (
    <AuthContext.Provider value={authContext}>
      <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
        {authStatus === 'authenticated' && userProfile ? (
          children
        ) : (
          <main className="h-screen w-full">
            <UnauthenticatedRouter />
          </main>
        )}
      </APIProvider>
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth, getSessionProfile, getSessionStaff };
