import { EncounterDTO } from '@aster/shared/dtos/encounter';
import { PatientProfileForEncounterDTO } from '@aster/shared/dtos/patient';
import { VitalsDTO } from '@aster/shared/dtos/vitals';
import GenerateEncountersPdf from '../GenerateEncounterPDF';
import dayjs from 'dayjs';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from '../../patients/utils/save-as';

export const resultSeverityClassColor = (
  interpretation: string | null | undefined,
  propertyModified: 'text' | 'bg' = 'text'
) => {
  switch (interpretation) {
    case 'critical':
      return `${propertyModified}-red`;
    case 'abnormal':
      return `${propertyModified}-secondary`;
    default:
      return '';
  }
};

export const downloadEncountersPdf = async ({
  encounters,
  vitals,
  patientProfile,
}: {
  encounters: EncounterDTO[] | undefined;
  vitals: VitalsDTO[] | undefined;
  patientProfile: PatientProfileForEncounterDTO | undefined;
}) => {
  if (!vitals || !patientProfile || !encounters) return null;
  const DownloadedEncounters = GenerateEncountersPdf({
    encounters: encounters,
    patientProfile,
    vitals,
  });
  const fileName = `${patientProfile?.name}-${dayjs().format('MM-DD-YY')}.pdf`;
  const blob = await pdf(DownloadedEncounters).toBlob();
  saveAs(blob, fileName);
};
