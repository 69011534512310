import { useParams } from 'react-router-dom';
import LoadingGuestToken from './LoadingGuestToken';
import { useAgreeWithLabConsentsMutation } from './mutations/agree-with-lab-consents.mutation';
import { useEffect } from 'react';
import FormCompleted from './FormCompleted';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVial } from '@fortawesome/pro-light-svg-icons';

export default function () {
  const { token } = useParams();
  const { agreeWithLabConsentsMutation } = useAgreeWithLabConsentsMutation();

  useEffect(() => {
    if (agreeWithLabConsentsMutation.status === 'idle' && token) {
      agreeWithLabConsentsMutation.mutate(token);
    }
  }, [agreeWithLabConsentsMutation, token]);

  if (agreeWithLabConsentsMutation.status === 'pending') {
    return (
      <LoadingGuestToken
        icon={<FontAwesomeIcon icon={faVial} size="4x" />}
        text="Just a moment, we are recording your consent. Thank you!"
      />
    );
  }

  if (agreeWithLabConsentsMutation.isError || !token) {
    return (
      <div className="h-full flex flex-col justify-center items-center">
        <div className="flex flex-col text-center items-center w-full min-w-min rounded-[20px] bg-white px-[35px] py-[30px] max-w-[480px]">
          <FontAwesomeIcon icon={faVial} size="4x" />
          <div className="text-body text-primary font-semibold mb-4 mt-8 w-full">
            Hi there, this link is invalid or has expired
          </div>
          <div className="text-bodySmall text-gray-500 text-center pb-5 sm:min-w-[55ch]">
            There is no action required from you at this time. If you have any
            questions, please contact &nbsp;
            <a
              className="text-blue-500 hover:text-blue-800"
              href="mailto:support@astercare.com"
            >
              Aster Support
            </a>
            .
          </div>
        </div>
      </div>
    );
  }

  return (
    <FormCompleted
      text="Thank you! We have recorded your consent."
      subtext="If you click the link by mistake, please contact your provider."
    />
  );
}
