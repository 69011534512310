import { Component, ErrorInfo, ReactNode } from 'react';

import { datadogRum } from '@datadog/browser-rum';

type ErrorBoundaryProps = {
  fallback: ReactNode;
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export type ExtendedError = Error & {
  cause?: Error;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const applicationError: ExtendedError = new Error(error.message);

    applicationError.name = `ApplicationError`;
    applicationError.stack = info.componentStack as string | undefined;
    applicationError.cause = error;

    // NOTE: See https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/
    datadogRum.addError(applicationError);
  }

  render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return fallback;
    }

    return children;
  }
}

export default ErrorBoundary;
