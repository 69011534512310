const BookSpells = () => {
  return (
    <svg
      width="44"
      height="51"
      viewBox="0 0 44 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.875 35.3086C43.875 36.1875 43.4844 36.8711 42.8984 37.1641C42.6055 38.4336 42.6055 42.8281 42.8984 44.3906C43.4844 44.9766 43.875 45.5625 43.875 46.2461V47.8086C43.875 49.3711 42.6055 50.25 41.3359 50.25H9.5C4.12891 50.25 0.125 46.2461 0.125 40.875V9.625C0.125 4.35156 4.12891 0.25 9.5 0.25H41.3359C42.8984 0.25 43.875 1.22656 43.875 2.78906V35.3086ZM26.6875 15.875L24.0508 21.1484L18.875 23.6875L24.0508 26.3242L26.6875 31.5L29.2266 26.3242L34.5 23.6875L29.2266 21.1484L26.6875 15.875ZM15.75 9.625L12.625 11.1875L15.75 12.75L17.3125 15.875L18.875 12.75L22 11.1875L18.875 9.625L17.3125 6.5L15.75 9.625ZM37.2344 44V37.75H9.5C7.9375 37.75 6.375 39.0195 6.375 40.875C6.375 42.8281 7.54688 44 9.5 44H37.2344Z"
        fill="#FFD6A5"
      />
    </svg>
  );
};

export default BookSpells;
