import {
  GetObjectCommand,
  HeadObjectCommand,
  S3Client,
} from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { fetchAuthSession } from 'aws-amplify/auth';

export const getS3ClientWithCredentials = async () => {
  const { credentials } = (await fetchAuthSession()) ?? null;
  if (credentials) {
    const { accessKeyId, secretAccessKey, sessionToken } = credentials;
    return new S3Client({
      region: 'us-east-1',
      credentials: {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      },
    });
  }
  return null;
};

export async function getS3SignedUrl({
  Bucket,
  s3key,
  fileName,
  onError,
  s3Client,
  expiresIn = 180,
}: {
  Bucket: string;
  s3key: string;
  fileName: string;
  onError?: (error: Error) => void;
  s3Client?: S3Client;
  expiresIn?: number;
}) {
  try {
    const s3 = s3Client ?? (await getS3ClientWithCredentials());

    if (!s3) return null;

    const Key = s3key;
    const ResponseContentDisposition =
      'attachment; filename ="' + fileName + '"';

    // Since the process of creating a signed URL doesn't account for
    // the file's existance, we first check if the object exists, and
    // then create the signed URL.
    //
    // This is especially useful when creating signed URLs for images
    // given that an signed URL could be passed as an `href` property
    // but the picture would be broken, as the URL leads to nothing.
    //
    // See: https://stackoverflow.com/a/56860362
    const objectExists = await s3.send(
      new HeadObjectCommand({
        Bucket,
        Key,
      })
    );

    if (!objectExists) {
      onError?.(new Error('Object does not exist'));
      return null;
    }

    const url = await getSignedUrl(
      s3,
      new GetObjectCommand({
        Bucket,
        Key,
        ResponseContentDisposition,
      }),
      { expiresIn }
    );

    if (!url) {
      onError?.(new Error('Failed to get signed URL'));
      return null;
    }

    return url;
  } catch (error) {
    console.error(error);
    onError?.(error instanceof Error ? error : new Error('Unknown error'));
    return null;
  }
}
