import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Checkbox, FormControlLabel } from '@mui/material';
import ButtonType from '../../../components/Button';
import CollapsibleComponent from '../../../components/CollapsibleComponent';
import { colors } from '../../../theme';
import Input from '../../../components/Input';
import Trash from '../../../assets/icons/Trash';
import { useParams } from 'react-router-dom';
import { PatientProfileForEncounterDTO } from '@aster/shared/dtos/patient';
import { CheckListDTO, CheckListItem } from '@aster/shared/dtos/checklist';
import { useUpdatePatientProfileMutation } from '../../patients/mutations/update-patient-profile.mutation';

type DynamicCheckboxesProps = {
  data: PatientProfileForEncounterDTO;
  disabled?: boolean;
};

const DynamicCheckboxes = ({ data, disabled }: DynamicCheckboxesProps) => {
  const { patient } = useParams();
  const [show, setShow] = useState(false);
  const [optionCheck, setOptionCheck] = useState('');
  const [items, setItems] = useState<CheckListItem[]>([]);

  useEffect(() => {
    if (data?.checklist?.items) {
      setItems(data.checklist.items);
    }
  }, [data]);

  const { updatePatientProfileMutation } = useUpdatePatientProfileMutation(
    patient as string
  );

  const saveInfo = (newItems: CheckListItem[]) => {
    const info = {
      ...data,
      checklist: {
        ...(data.checklist as CheckListDTO),
        items: newItems,
      },
    };
    updatePatientProfileMutation.mutate(info);
  };

  const addCheck = () => {
    setShow(true);
  };

  const addOption = () => {
    const tempOpt = {
      text: optionCheck,
      checked: false,
    };
    const updatedOptions = [...items, tempOpt];
    setItems(updatedOptions);
    setShow(false);
    setOptionCheck('');
    saveInfo(updatedOptions);
  };

  const deleteOption = (value: CheckListItem) => {
    const updatedOptions = items.filter((item) => item.text !== value.text);
    setItems(updatedOptions);
    saveInfo(updatedOptions);
  };

  const handleCheckboxChange = (value: CheckListItem) => {
    const updatedOptions = items.map((option) =>
      option.text === value.text
        ? { ...option, checked: !option.checked }
        : option
    );
    setItems(updatedOptions);
    saveInfo(updatedOptions);
  };

  return (
    <div className="flex flex-col w-full">
      <CollapsibleComponent
        label="To do this encounter"
        containerClass="relative flex flex-col"
      >
        {!disabled && (
          <div className="flex flex-row absolute right-2 top-2">
            <ButtonType
              variant="contained"
              onClick={addCheck}
              text="Add"
              classes={'max-h-[30px]'}
              icon
              specificIcon={<AddIcon sx={{ fontSize: '15px' }} />}
            />
          </div>
        )}

        {show && (
          <div className="w-full p-3 bg-grayCard">
            <Input
              name="Add option"
              textValue={optionCheck}
              topLabel=" "
              placeholder="New checklist item"
              handleChange={(e: any) => setOptionCheck(e.target.value)}
              handleOnBlur={addOption}
              handleOnKeyDown={addOption}
            />
          </div>
        )}
        <div className="flex flex-col w-full h-full rounded-b-lg bg-grayCard self-stretch p-2.5">
          <div className="flex-1 mt-2">
            {items.map((item: any, index: any) => (
              <div
                className="flex flex-row justify-between py-1 bg-grayCard"
                key={index}
              >
                <FormControlLabel
                  className="h-[24px]"
                  key={item.index}
                  control={
                    <Checkbox
                      sx={{
                        color: colors.grayLight,
                        '&.Mui-checked': {
                          color: colors.green,
                        },
                      }}
                      checked={item.checked}
                      onChange={() => handleCheckboxChange(item)}
                      disableRipple
                      disabled={disabled}
                    />
                  }
                  label={item.text}
                />
                {!disabled && (
                  <span onClick={(e) => deleteOption(item)}>
                    <Trash />
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      </CollapsibleComponent>
    </div>
  );
};

export default DynamicCheckboxes;
