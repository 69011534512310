import FormIllustration from '../../assets/icons/FormIllustration';
import Typography from '../../components/Typography';
import AsterLogo from '../../assets/aster';
import GreenCheckMark from '../../assets/icons/GreenCheckMark';

export interface FormCompletedProps {
  text: string;
  subtext?: string;
}

const FormCompleted = ({ text, subtext }: FormCompletedProps) => {
  return (
    <div className="grid grid-rows-3 place-content-center h-full p-10">
      <div className="row-start-2 flex flex-col justify-center items-center">
        <FormIllustration />
        <GreenCheckMark classes="mt-8 mb-3" />
        <Typography
          customClass="text-green-500"
          variant="bodySmall"
          text={text}
        />
        {subtext && (
          <Typography
            customClass="text-gray-500"
            variant="bodySmall"
            text={subtext}
          />
        )}
      </div>
      <div className="flex justify-center items-end row-start-3">
        <AsterLogo />
      </div>
    </div>
  );
};

export default FormCompleted;
