import { cn } from '@aster/client/utils/cn';
import { Typography } from '@mui/material';

export type SelectableProps = {
  text: string;
  onClick?: () => void;
  selected?: boolean;
};

export const Selectable = ({ text, selected, onClick }: SelectableProps) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        'inline-flex px-2.5 py-[5px] rounded-[5px] border border-beige bg-white cursor-pointer',
        {
          'bg-black border-black': selected,
        }
      )}
    >
      <Typography
        className={cn('text-asterGray text-sm text-semibold', {
          'text-white': selected,
        })}
      >
        {text}
      </Typography>
    </div>
  );
};
