export const getAddressFieldsFromGoogle = (
  place: google.maps.places.PlaceResult
) => {
  if (!place.address_components) return;
  const addressComponents = place.address_components;
  const streetAddress1 =
    (addressComponents.find((component) =>
      component.types.includes('street_number')
    )?.long_name ?? '') +
    ' ' +
    (addressComponents.find((component) => component.types.includes('route'))
      ?.long_name ?? '');
  const city =
    addressComponents.find((component) => component.types.includes('locality'))
      ?.long_name ?? '';
  const state =
    addressComponents.find((component) =>
      component.types.includes('administrative_area_level_1')
    )?.short_name ?? '';
  const zipCode =
    addressComponents.find((component) =>
      component.types.includes('postal_code')
    )?.long_name ?? '';

  return {
    streetAddress1,
    city,
    state,
    zipCode,
  };
};
