import styled from '@mui/system/styled';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { DataGridProps } from '@mui/x-data-grid/models/props/DataGridProps';
import { colors } from '../theme';

const StyledDataGrid = styled((props: DataGridProps) => (
  <DataGrid {...props} />
))(({ theme }) => ({
  '&, [class^=MuiDataGrid]': { border: 'none' },
  '.MuiDataGrid-columnHeaderCheckbox': {
    backgroundColor: colors.grayBackground,
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none !important',
  },
}));

export default StyledDataGrid;
