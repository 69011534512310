import Typography from '../../components/Typography';

export default function PlanSummary() {
  return (
    <div className="mt-4">
      <Typography customClass="mb-3" variant="h6">
        Plan Summary
      </Typography>
      <div className="flex flex-col gap-1">
        <ul className="flex flex-col gap-1">
          <li>
            <Typography variant="bodyMedium">
              {'>'} Subscription fee: $7.99 per month
            </Typography>
          </li>
          <li>
            <Typography variant="bodyMedium">
              {'>'} Total of inbound and outbound pages per month: 200
            </Typography>
          </li>
          <li>
            <Typography variant="bodyMedium">
              {'>'} Activation fee: Free
            </Typography>
          </li>
        </ul>
      </div>
    </div>
  );
}
