interface DownloadProps {
  className?: string;
}

const Download = ({ className }: DownloadProps) => (
  <svg
    className={className}
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.20312 1.20312V9.14258L10.9863 6.35938C11.25 6.06641 11.6895 6.06641 11.9824 6.35938C12.2461 6.62305 12.2461 7.0625 11.9824 7.32617L7.96875 11.3105C7.70508 11.6035 7.26562 11.6035 7.00195 11.3105L3.01758 7.32617C2.72461 7.0625 2.72461 6.62305 3.01758 6.35938C3.28125 6.06641 3.7207 6.06641 4.01367 6.35938L6.79688 9.14258V1.20312C6.79688 0.822266 7.08984 0.5 7.5 0.5C7.88086 0.5 8.20312 0.822266 8.20312 1.20312ZM3.75 9.40625L5.15625 10.8125H1.875C1.61133 10.8125 1.40625 11.0469 1.40625 11.2812V13.625C1.40625 13.8887 1.61133 14.0938 1.875 14.0938H13.125C13.3594 14.0938 13.5938 13.8887 13.5938 13.625V11.2812C13.5938 11.0469 13.3594 10.8125 13.125 10.8125H9.81445L11.2207 9.40625H13.125C14.1504 9.40625 15 10.2559 15 11.2812V13.625C15 14.6797 14.1504 15.5 13.125 15.5H1.875C0.820312 15.5 0 14.6797 0 13.625V11.2812C0 10.2559 0.820312 9.40625 1.875 9.40625H3.75ZM12.6562 12.4531C12.6562 12.8633 12.334 13.1562 11.9531 13.1562C11.543 13.1562 11.25 12.8633 11.25 12.4531C11.25 12.0723 11.543 11.75 11.9531 11.75C12.334 11.75 12.6562 12.0723 12.6562 12.4531Z"
      fill="#586068"
    />
  </svg>
);

export default Download;
