import {
  LocalizationProvider,
  DateCalendar,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers';
import Typography from '../../../components/Typography';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { colors } from '../../../theme';
import styled from '@mui/system/styled';
import ListItemButton from '@mui/material/ListItemButton';
import { EncounterDTO } from '@aster/shared/dtos/encounter';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import ButtonType from '../../../components/Button';
import { useUpdateEncounterMutation } from '../mutations/update-encounter.mutation';
const StyledButtonList = styled(ListItemButton)({
  '&.Mui-selected': {
    backgroundColor: colors.gray,
    color: colors.white,
    '&:hover': {
      color: colors.gray,
    },
  },
  '& .MuiTypography-root': {
    fontSize: 12,
    fontWeight: '600',
    textAlign: 'center',
  },
});

const EncounterStartTimeUpdater = ({
  encounterInfo,
  closeEditPopover,
}: {
  encounterInfo: EncounterDTO | undefined;
  closeEditPopover: () => void;
}) => {
  const [startDate, setStartDate] = useState(() =>
    encounterInfo?.startTime
      ? dayjs(encounterInfo.startTime)
      : dayjs().set('hour', 7).set('minute', 0)
  );
  const startTimeIntervalHours = useMemo<dayjs.Dayjs[]>(() => {
    const startHours: dayjs.Dayjs[] = [];
    //set first and last time based on current day so changing date will not affect the time
    let currInterval = startDate.clone().hour(8);
    const lastHourOfDay = currInterval.endOf('day');

    while (currInterval.isBefore(lastHourOfDay)) {
      startHours.push(currInterval);
      currInterval = currInterval.add(5, 'minute');
    }

    return startHours;
  }, [startDate]);

  const { updateEncounter } = useUpdateEncounterMutation(
    encounterInfo?.id,
    () => {
      closeEditPopover();
    },
    'Encounter date updated successfully'
  );

  const CustomDay = (props: PickersDayProps<dayjs.Dayjs>) => {
    const selected = [startDate.format('DD-MM-YYYY')].includes(
      props.day.format('DD-MM-YYYY')
    );
    return <PickersDay {...props} selected={selected} />;
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        updateEncounter.mutate({
          startTime: startDate.toISOString(),
        });
      }}
      className="flex flex-col px-5 py-6"
    >
      <Typography
        text="Edit encounter date"
        variant="h5"
        customClass="font-semibold mb-5"
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="flex border border-grayLight rounded-lg px-4 gap-4">
          <DateCalendar
            slots={{
              day: CustomDay,
            }}
            slotProps={{
              calendarHeader: {
                classes: { label: '!text-h5' },
              },
            }}
            disableHighlightToday
            value={startDate}
            onChange={(newValue: dayjs.Dayjs) => {
              setStartDate((currentDate) =>
                currentDate
                  .clone()
                  .date(newValue.date())
                  .month(newValue.month())
                  .year(newValue.year())
              );
            }}
            sx={{ width: '100%' }}
          />
          <div className="flex flex-col items-center pt-4">
            <Typography
              variant="bodySmall"
              customClass="text-black font-semibold mb-4"
              text={startDate.format('ddd, MMM DD')}
            />
            <List
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                maxHeight: 250,
                '[&::-webkit-scrollbar]': 'hidden',
                '-ms-overflow-style': 'none',
                'scrollbar-width': 'none',
              }}
            >
              {startTimeIntervalHours.map((item) => (
                <li key={item.toString()}>
                  <StyledButtonList
                    sx={{ width: 130 }}
                    autoFocus={dayjs(item).isSame(startDate)}
                    selected={dayjs(item).isSame(startDate)}
                    className="rounded-full hover:opacity-80 cursor-pointer"
                    onClick={() => {
                      setStartDate(item);
                    }}
                  >
                    <ListItemText primary={`${item.format('hh:mm a')}`} />
                  </StyledButtonList>
                </li>
              ))}
            </List>
          </div>
        </div>
      </LocalizationProvider>
      <ButtonType
        loading={updateEncounter.status === 'pending'}
        variant="contained"
        text="Update"
        type="submit"
        className="mt-3 w-fit ml-auto text-bodyMedium leading-[150%] px-[1.25rem]"
      />
    </form>
  );
};

export default EncounterStartTimeUpdater;
