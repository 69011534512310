const Timer = () => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 0.8C10.025 0.8 12.9 3.675 12.9 7.2C12.9 10.75 10.025 13.6 6.5 13.6C2.95 13.6 0.0999999 10.75 0.0999999 7.2C0.0999999 5.425 0.825 3.8 2 2.65C2.325 2.325 2.825 2.325 3.15 2.65C3.45 2.975 3.45 3.475 3.125 3.775C2.25 4.65 1.7 5.875 1.7 7.2C1.7 9.85 3.85 12 6.5 12C9.15 12 11.3 9.85 11.3 7.2C11.3 4.825 9.55 2.85 7.3 2.475V3.2C7.3 3.65 6.925 4 6.5 4C6.05 4 5.7 3.65 5.7 3.2V1.6C5.7 1.175 6.05 0.8 6.5 0.8ZM4.925 4.775L6.925 6.775C7.15 7.025 7.15 7.4 6.925 7.625C6.675 7.875 6.3 7.875 6.075 7.625L4.075 5.625C3.825 5.4 3.825 5.025 4.075 4.775C4.3 4.55 4.675 4.55 4.925 4.775Z"
        fill="#09BC8A"
      />
    </svg>
  );
};

export default Timer;
