type CreateStorageKeyOptions = {
  practiceId: string;
  scope: 'assets' | 'documents' | 'labs' | 'efax';
  name: string;
};

export const createStorageKey = ({
  practiceId,
  scope,
  name,
}: CreateStorageKeyOptions) => {
  return [practiceId, scope, name].join('/');
};
