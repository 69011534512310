import { TextareaAutosize } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import ButtonType from '../../../components/Button';
import Typography from '../../../components/Typography';

type ModalProps = {
  title: string;
  description?: string;
  confirm: string;
  dismiss: string;
  open: boolean;
  handleConfirm: any;
  handleCancel?: () => void;
  handleClose: () => void;
};

const InviteMembersModal = (props: ModalProps) => {
  const {
    open,
    title,
    description,
    confirm,
    dismiss,
    handleClose,
    handleConfirm,
  } = props;

  const [emails, setEmails] = useState<string>('');

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="flex flex-col items-center w-[600px]">
        <DialogContent className="flex flex-col items-center">
          <p className="text-h4 font-semibold">{title}</p>
          <p className="mt-1">{description}</p>
          <div>
            <Typography variant="h6" text="Email" customClass="my-2" />
            <TextareaAutosize
              className="border-grayLight border rounded-lg p-2"
              value={emails}
              onInput={(e) => setEmails(e.currentTarget.value)}
              minRows={3}
              cols={60}
              placeholder="email@example.com, email2@example.com"
            />
          </div>
        </DialogContent>
      </div>
      <DialogActions className="bg-grayBackground">
        <ButtonType
          variant="text"
          onClick={() => {
            setEmails('');
            handleClose();
          }}
          text={dismiss}
          className="w-[120px] h-[44px]"
        />
        <ButtonType
          disabled={!emails}
          variant="contained"
          onClick={() => {
            handleConfirm.mutate(emails);
            setEmails('');
            handleClose();
          }}
          text={confirm}
          className="w-[150px] h-[44px]"
        />
      </DialogActions>
    </Dialog>
  );
};

export default InviteMembersModal;
