import { TabProps } from '@mui/material/Tab';
import {
  ApgarValues,
  AppearanceValues,
  PulseValues,
  GrimaceValues,
  ActivityValues,
  RespirationValues,
} from '../types/apgar.types';

export const apgarOptionScores: Record<ApgarValues, number> = {
  'blue-or-pale': 0,
  'no-heart-rate': 0,
  'no-response': 0,
  'none-limp-floppy': 0,
  'not-breathing': 0,
  'blue-extremities': 1,
  'less-100-bpm': 1,
  'grimace-weak-cry': 1,
  'some-limb-flexion': 1,
  'slow-irregular': 1,
  'pink-all-over': 2,
  'greater-100-bpm': 2,
  'sneeze-cough-cry': 2,
  'flexion-resistance': 2,
  'strong-cry': 2,
} as const;

export const tabsAppearance: TabProps[] = [
  { label: 'Blue or Pale', value: 'blue-or-pale' satisfies AppearanceValues },
  {
    label: 'Blue extremities',
    value: 'blue-extremities' satisfies AppearanceValues,
  },
  {
    label: 'Pink all over',
    value: 'pink-all-over' satisfies AppearanceValues,
  },
];

export const tabsPulse: TabProps[] = [
  { label: 'No heart rate', value: 'no-heart-rate' satisfies PulseValues },
  { label: '< 100 bpm', value: 'less-100-bpm' satisfies PulseValues },
  { label: '> 100 bpm', value: 'greater-100-bpm' satisfies PulseValues },
];

export const tabsGrimace: TabProps[] = [
  { label: 'No response', value: 'no-response' },
  {
    label: 'Grimace, weak cry',
    value: 'grimace-weak-cry' satisfies GrimaceValues,
  },
  {
    label: 'Sneeze, cough, cry',
    value: 'sneeze-cough-cry' satisfies GrimaceValues,
  },
];

export const tabsActivity: TabProps[] = [
  {
    label: 'None, limp, floppy',
    value: 'none-limp-floppy' satisfies ActivityValues,
  },
  {
    label: 'Some limb flexion',
    value: 'some-limb-flexion' satisfies ActivityValues,
  },
  {
    label: 'Flexion, resistance',
    value: 'flexion-resistance' satisfies ActivityValues,
  },
];

export const tabsRespiration: TabProps[] = [
  {
    label: 'Not breathing',
    value: 'not-breathing' satisfies RespirationValues,
  },
  {
    label: 'Slow, irregular',
    value: 'slow-irregular' satisfies RespirationValues,
  },
  { label: 'Strong cry', value: 'strong-cry' satisfies RespirationValues },
];
