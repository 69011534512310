import { useParams } from 'react-router-dom';
import PatientProfileForEncounterHeader from '../sections/PatientProfileForEncounterHeader';
import { CircularProgress } from '@mui/material';
import {
  PushButtonTab,
  PushButtonTabPanel,
  PushButtonTabs,
  PushButtonTabsList,
} from '../../../components/PushButtonTabs';
import { Chip } from '../../../components/Chip';
import Prechart from './Prechart';
import EncounterLabs from '../../labs/EncounterLabs';
import { usePatientProfileQuery } from '../queries/patient-profile.query';
import { cn } from '@aster/client/utils/cn';

type PatientProfileForEncounterProps = {
  isEncounterSigned: boolean;
  enforceSaveMissing: boolean;
  handleUnsaved: (isDirty: boolean) => void;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  handleNote?: (note: string) => void;
};

const PatientProfileForEncounter = ({
  isEncounterSigned,
  enforceSaveMissing,
  handleUnsaved,
  children,
  className,
  handleNote,
}: PatientProfileForEncounterProps) => {
  const { patient, encounterId } = useParams();
  const { patientProfile, isPatientProfileLoading } =
    usePatientProfileQuery(patient);

  if (isPatientProfileLoading || !patientProfile) {
    return (
      <div className="flex flex-col justify-center items-center h-full w-[400px]">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      className={cn(
        'w-full lg:w-[400px] h-full lg:shadow-lg px-[15px] overflow-auto flex flex-col gap-[15px] pb-4',
        className
      )}
    >
      <PatientProfileForEncounterHeader
        name={patientProfile.name}
        calculatedDueDate={patientProfile.calculatedDueDate}
        gestationalAge={patientProfile.gestationalAge}
        gtpal={patientProfile.gtpal}
        age={patientProfile.age}
        dateOfBirth={patientProfile.dateOfBirth}
        problemList={patientProfile.problemList}
        mrn={patientProfile.mrn}
        isPregnant={patientProfile.isPregnant}
      />

      <PushButtonTabs defaultValue="prechart">
        <PushButtonTabsList>
          <PushButtonTab value="prechart">Prechart</PushButtonTab>
          <PushButtonTab value="labs">Labs</PushButtonTab>
        </PushButtonTabsList>
        <PushButtonTabPanel className="mt-4" value="prechart">
          {children || (
            <Prechart
              isEncounterSigned={isEncounterSigned}
              patientID={patient}
              encounterID={encounterId as string}
              enforceSaveMissing={enforceSaveMissing}
              handleUnsaved={handleUnsaved}
              handleNote={handleNote}
            />
          )}{' '}
        </PushButtonTabPanel>
        <PushButtonTabPanel value="labs">
          <EncounterLabs
            isEncounterSigned={isEncounterSigned}
            patientID={patient}
          />
        </PushButtonTabPanel>
      </PushButtonTabs>
    </div>
  );
};

export default PatientProfileForEncounter;
