import dayjs from 'dayjs';

import { WeeksChip } from './WeeksChip';

import { ReadonlyTable } from '../../../../../components/ReadonlyTable';
import { useNavigate } from 'react-router-dom';

export type FlowsheetRow = {
  weeks: `${number}w${number}d`;
  date: Date;
  bp: string;
  pulse: number;
  fh: string;
  fht: number;
  fetalPosition: string;
  fm: boolean;
  notes: string;
  link: string;
};

export type FlowsheetProps = {
  rows: FlowsheetRow[];
};

export const stripBreaks = (html: string) =>
  html
    .replace(/\<\/?p\>/gi, '') // Inline all paragraphs
    .replace(/\<\/?br\>/gi, ' ') // Convert line breaks into spaces
    .replace(/\<h\d\>(.*?)\<\/h\d\>/gi, '<span>$1 - </span>'); // Transform headings

// NOTE: Grabbed from https://stackoverflow.com/a/74669340
export const escapeHTML = (html: string) => {
  html = stripBreaks(html);

  const texts = [];

  const doc = new DOMParser().parseFromString(html, 'text/html');
  const walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT);

  let node;

  while ((node = walker.nextNode()) !== null) {
    texts.push(node.nodeValue);
  }

  return texts.join('');
};

export const Flowsheet = ({ rows }: FlowsheetProps) => {
  const navigate = useNavigate();

  const pagination = ReadonlyTable.usePagination();

  return (
    <div>
      <div className="border border-grayBorder p-5 rounded-[16px] overflow-x-scroll relative">
        <ReadonlyTable<FlowsheetRow>
          data={rows}
          config={{
            weeks: {
              title: 'Weeks',
              render: (weeks) => <WeeksChip weeks={weeks} />,
            },
            date: {
              title: 'Date',
              render: (date) => dayjs(date).format('MM/DD/YYYY'),
            },
            bp: {
              title: 'BP',
            },
            pulse: {
              title: 'Pulse',
            },
            fh: {
              title: 'FH',
            },
            fht: {
              title: 'FHT',
            },
            fetalPosition: {
              title: 'Fetal Position',
              render: (fetalPosition) =>
                ({
                  vertex: 'Vertex',
                  breech: 'Breech',
                  transverse: 'Transverse',
                }[fetalPosition]),
            },
            fm: {
              title: 'FM',
              render: (fm) => (fm ? '+' : '-'),
            },
            notes: {
              title: 'Note',
              className: 'w-full min-w-[150px]',
              render: (notes) => {
                const escapedNotes = escapeHTML(notes);
                const inlineRichText = stripBreaks(notes);

                return (
                  <div className="overflow-hidden text-ellipsis max-w-[64ch]">
                    <abbr
                      title={escapedNotes}
                      className="truncate no-underline cursor-pointer"
                      dangerouslySetInnerHTML={{ __html: inlineRichText }}
                    />
                  </div>
                );
              },
            },
          }}
          handlers={{
            onRowClick: ({ link }) => navigate(link, { replace: true }),
          }}
          pagination
          paginationNode={pagination.node}
        />
      </div>
      <div className="pt-2.5">
        <ReadonlyTable.PaginationControls ref={pagination.ref} />
      </div>
    </div>
  );
};
