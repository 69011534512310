interface AsterLogoProps {
  className?: string;
}

const AsterLogo = ({ className }: AsterLogoProps) => {
  return (
    <svg
      className={className}
      width="110"
      height="31"
      viewBox="0 0 110 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.60932 30.5C3.63295 30.5 0.809814 27.3498 0.809814 21.6309C0.809814 12.9071 6.98243 6.07351 15.2604 6.07351C18.3228 6.07351 20.5717 6.99434 21.8158 8.06058L24.9261 6.12197H27.51L23.682 24.3449C23.5384 25.0234 23.4427 25.4111 23.4427 25.7504C23.4427 26.4289 23.7777 26.7197 24.3519 26.7197C25.0696 26.7197 25.7395 26.2835 26.4573 25.5565L26.9836 25.0234L27.51 26.6228L27.0315 27.2528C25.1175 29.6276 23.2992 30.5 20.8588 30.5C18.0836 30.5 16.5045 29.3368 16.2174 27.1074C16.1217 26.6712 16.1217 26.1381 16.2174 25.5081C14.3991 28.4645 12.0545 30.5 8.60932 30.5ZM12.0066 26.1381C13.6814 26.1381 15.2604 25.0234 16.5524 23.0363L19.1841 10.4354C18.2271 9.36914 17.1266 8.83603 15.6911 8.83603C12.0066 8.83603 8.65717 14.5065 8.65717 21.2916C8.65717 24.7326 9.70986 26.1381 12.0066 26.1381Z"
        fill="#203A3B"
      />
      <path
        d="M37.1479 30.5C33.5592 30.5 30.4011 29.9669 28.3436 29.1914L28.0086 23.0848H30.1619L31.2146 24.3934C33.4157 27.059 34.899 27.689 37.1958 27.689C40.1146 27.689 41.4066 26.6712 41.4066 24.878C41.4066 23.0848 40.1146 22.2609 36.5259 21.3401C30.5925 19.8376 28.2957 17.996 28.2957 13.7795C28.2957 8.98142 32.6022 6.07351 38.6313 6.07351C41.7894 6.07351 44.6603 6.60662 46.67 7.3336L47.005 13.1494H44.8517L43.799 11.6955C42.22 9.51454 41.1673 8.88449 38.8227 8.88449C36.4302 8.88449 34.9468 9.80533 34.9468 11.5501C34.9468 13.2948 36.2388 14.1672 40.4017 15.088C46.0958 16.3966 48.1055 18.5291 48.1055 22.6971C48.1055 27.5921 43.8469 30.5 37.1479 30.5Z"
        fill="#203A3B"
      />
      <path
        d="M58.4179 30.5C53.4415 30.5 50.6184 28.5614 50.6184 22.8425V9.8538H47.4124V7.57593C50.8576 5.58885 52.5324 4.42569 55.212 1.95396L56.6953 0.5H58.2743V6.55816H65.4039V9.8538H58.2743V22.6971C58.2743 25.2658 59.2792 26.4774 61.3367 26.4774C62.5808 26.4774 63.6814 26.0897 65.0211 24.975L66.1217 26.3805C64.3034 29.3853 61.8152 30.5 58.4179 30.5Z"
        fill="#203A3B"
      />
      <path
        d="M86.4927 23.4241L87.8325 24.6357C85.9185 28.1737 82.3298 30.5 77.162 30.5C69.6018 30.5 64.3862 26.2835 64.3862 18.2868C64.3862 10.5808 69.8889 6.07351 76.8271 6.07351C84.0045 6.07351 87.7847 10.1446 87.7847 17.1721V18.0444H72.4728C72.6642 24.2964 75.3438 26.1381 79.5545 26.1381C82.282 26.1381 84.483 25.3627 86.4927 23.4241ZM76.8271 8.88449C74.3389 8.88449 72.8556 11.3562 72.5206 15.6212L80.368 15.185C80.5594 11.2593 79.411 8.88449 76.8271 8.88449Z"
        fill="#203A3B"
      />
      <path
        d="M101.151 30.0153H86.8921V28.1252L87.9448 27.8344C89.6196 27.3498 89.7153 26.7197 89.7153 24.7811V12.8102C89.7153 11.1139 89.4282 10.5808 88.2319 10.29L86.9878 9.95073V8.10905L96.3185 6.07351H97.4191L96.8449 11.7924C98.9503 8.10905 101.821 6.07351 104.692 6.07351C107.515 6.07351 109.19 7.76979 109.19 10.5323C109.19 13.101 107.515 14.8457 105.027 14.8457C102.73 14.8457 101.199 13.3433 101.199 11.2593C101.199 11.017 101.199 10.8716 101.247 10.6777C100.003 11.1139 98.6153 12.0832 97.4191 13.6826V24.7811C97.4191 26.7197 97.6583 27.3498 99.4766 27.7375L101.151 28.1252V30.0153Z"
        fill="#203A3B"
      />
    </svg>
  );
};

export default AsterLogo;
